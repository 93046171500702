import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { TransportModel } from '../../domain/models/TransportModel';

export class TransportEntity extends BasicEntity {
  constructor(data?: Partial<TransportEntity>) {
    super(data);
  }

  toDomain(): TransportModel {
    return new TransportModel({
      id: this.id,
      name: this.name
    });
  }
}
