import styled from 'styled-components';

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  padding: 24px;
  margin-bottom: 20px;
  height: 150px;
`;

export const Badge = styled.p`
  font-family: 'Roboto' !important;
  color: #2c3941;
  background-color: ${props => props.theme.colors.gray};
  padding: 4px 8px;
  border-radius: 50px;
  margin-bottom: 8px;
  width: max-content;
  margin: 0 0 10px 0;
  margin-right: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > section {
    > div {
      display: flex;
    }
  }

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      > p {
        margin: 0 !important;
      }

      > img {
        margin-right: 12px;
      }
    }
  }
`;

export const Title = styled.h3`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #000000;
  width: 550px;
`;

export const ContainerBtn = styled.div`
  border-left: 1px solid #ebe9f1;
  width: 180px;

  button {
    margin-left: 31px;
    width: 138px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: none;

    > img {
      margin-right: 10px;
    }
  }

  > button:nth-child(1) {
    border: 1px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondary};
    margin-bottom: 12px;
  }

  > button:nth-child(2) {
    border: 1px solid ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
  }
`;
