import { api, temp } from '../index';

export async function getAbout(token: string) {
  try {
    const resposta = await api.get('/about', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(About) erro: ', erro);
    return null;
  }
}

export async function getAboutById(token: string, id: string) {
  try {
    const resposta = await api.get(`/about/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return resposta.data;
  } catch (erro) {
    console.warn('(About) erro: ', erro);
    return null;
  }
}

export async function editAbout(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/about/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(About) erro: ', erro);
    return null;
  }
}
