import { api } from '../index';

export async function createOccurrenceNotification(
  token: string,
  occurrenceId: string
) {
  try {
    return await api.post(
      '/notifications/occurrence',
      {
        occurrence_id: occurrenceId
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
  } catch (erro) {
    console.warn('(Notification) erro: ', erro);
    return null;
  }
}
