import styled from 'styled-components';

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Column1 = styled.div`
  margin-right: 12px;
`;
export const Column2 = styled.div``;
