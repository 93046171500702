import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 16px;
`;

export const Nav = styled.nav``;

export const NavBtn = styled.button`
  background: none;
  border: none;

  :focus {
    border-bottom: 4px solid ${props => props.theme.colors.secondary};
    font-weight: 700;
  }
`;

export const ShowOccurence = styled.button`
  background: ${props => props.theme.colors.secondary};
  border: none;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  padding: 14px 26px;
  margin-left: 20px;
`;

export const Content = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px 16px;
  gap: 16px;

  > select {
    height: 50px;
    width: 100%;
    background: ${props => props.theme.colors.white};
    border: 1px solid #ebe9f1;
    border-radius: 5px;

    > option {
      background-color: ${props => props.theme.colors.white};
    }
  }
`;

export const Search = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px 22px;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  background-color: ${props => props.theme.colors.white};

  > label {
    cursor: pointer;
    margin-right: 12px;
  }

  > input {
    border: none;
    outline: none;
    width: 100%;
  }
`;

export const RadioGroup = styled.fieldset`
  width: 760px;
  margin: 20px 0;
  display: flex;
  justify-content: space-evenly;

  :focus {
    font-weight: 700;
  }
`;

export const List = styled.section`
  padding: 16px;
`;

export const SearchGroup = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;

  > div:nth-child(1) {
    width: 55%;
    display: flex;
    justify-content: space-between;
  }

  > div:nth-child(2),
  > div:nth-child(3) {
    display: flex;
    align-items: center;

    > label {
      width: 170px;
    }

    > select {
      height: 50px;
      width: 200px;
      background: ${props => props.theme.colors.white};
      border: 1px solid #ebe9f1;
      border-radius: 5px;

      > option {
        background-color: ${props => props.theme.colors.white};
      }
    }
  }
`;

export const ContainerBtn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  padding: 24px;
`;

export const PrevNext = styled.button<{ to: string; disabled: boolean }>`
  background-color: transparent;
  cursor: pointer;
  border: none;
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  > img {
    width: 36px;
    height: 36px;
  }

  > img {
    ${props => {
      if (props.to == 'next') {
        return `
          transform: rotate(0deg);
        `;
      } else if (props.to == 'prev') {
        return `
          transform: rotate(190deg);
        `;
      }
    }}
  }
`;

export const AtualPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border: none;
  background-color: ${props => props.theme.colors.secondary};
  border-radius: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.white};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;
