import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  border: none;
  padding: 40px 60px;

  > button {
    background: #fff;
    border-radius: 6px;
    border: none;
    position: absolute;
    right: 20px;
    top: 20px;

    img {
      width: 25px;
      height: 25px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    > img {
      width: 60px;
      height: 60px;
      margin-bottom: 24px;
    }

    > h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #28c76f;
    }

    > p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: #6e6b7b;
    }
  }
`;
