import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { contentIcon, imgIcon, titleIcon } from '../../assets';
import { BlueComponent } from '../../components';
import { getAboutApi } from '../../services';
import { ADD_ABOUT_API, RootState } from '../../store';
import { ModalEditar } from './Modals';
import {
  Container,
  ImageIcon,
  ImageOpen,
  NoStyle,
  Icon,
  TitleTab,
  Content,
  NoStyleTwo,
  CardContainer,
  CardValues
} from './index.style';

import renderHTML from 'react-render-html';
import { ImageB3, ImageValues } from '../Sobre/index.styled';

const API: React.FC = () => {
  const { token, about_api } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getAboutApi(token).then(resp => {
      dispatch({ type: ADD_ABOUT_API, about_api: resp });
    });
  }, []);

  useEffect(() => {
    console.log('api', about_api);
  }, [about_api]);

  const [contentEditar, setContentEditar] = useState({
    id: '',
    name: '',
    title: '',
    description: '',
    image: {
      id: '',
      file: ''
    },
    items: [
      {
        image: {
          id: '',
          home: ''
        },
        order: 0,
        title: '',
        description: ''
      }
    ]
  });

  const [isModalEditar, setModalEditar] = useState(false);

  return (
    <Container>
      <Tabs defaultActiveKey={'Abertura'} className="mb-3">
        {about_api?.map((item: any) => {
          return (
            <Tab eventKey={item.name} title={<TitleTab>{item.name}</TitleTab>}>
              <BlueComponent
                btnEdit={true}
                isModal={false}
                isShow={false}
                onHide={() => {}}
                onClickCancel={() => {}}
                onClickSave={() => {}}
                onClickEdit={() => {
                  setModalEditar(true);
                  setContentEditar(item);
                }}
                titleComponent={item.name}
              >
                {item.name == 'Abertura' && (
                  <>
                    <Row>
                      <Col>
                        <Content>
                          <div>
                            <ImageIcon src={titleIcon} />
                            <div>
                              <p>Títutlo</p>
                              <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                            </div>
                          </div>
                          <div>
                            <ImageIcon src={contentIcon} />
                            <div>
                              <p>Texto</p>
                              <NoStyle>{renderHTML(item.description)}</NoStyle>
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col>
                        <div style={{ display: 'flex' }}>
                          <ImageIcon
                            src={imgIcon}
                            style={{ marginRight: '10px' }}
                          />
                          <ImageB3 src={item?.image.file} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {item.name == 'Bloco 1' && (
                  <>
                    <Row>
                      <Col>
                        <Content>
                          <div>
                            <ImageIcon src={titleIcon} />
                            <div>
                              <p>Títutlo</p>
                              <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                            </div>
                          </div>
                          <div>
                            <ImageIcon src={contentIcon} />
                            <div>
                              <p>Texto</p>
                              <NoStyle>{renderHTML(item.description)}</NoStyle>
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col>
                        <div style={{ display: 'flex' }}>
                          <ImageIcon
                            src={imgIcon}
                            style={{ marginRight: '10px' }}
                          />
                          <ImageB3 src={item?.image.file} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {item.name == 'Bloco 2' && (
                  <>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                        </div>
                      </div>
                    </Content>

                    <CardContainer>
                      {item.items.map((chave: any) => {
                        return (
                          <CardValues>
                            <div>
                              <ImageIcon src={titleIcon} />
                              <div>
                                <p>Subtítulo</p>
                                <NoStyle>{renderHTML(chave.title)}</NoStyle>
                              </div>
                            </div>
                            <div>
                              <ImageIcon src={contentIcon} />
                              <div>
                                <p>Texto</p>
                                <NoStyle>
                                  {renderHTML(chave.description)}
                                </NoStyle>
                              </div>
                            </div>
                            <div>
                              <ImageValues src={chave.image?.file} />
                            </div>
                          </CardValues>
                        );
                      })}
                    </CardContainer>
                  </>
                )}
                {item.name == 'Bloco 3' && (
                  <>
                    <Row>
                      <Col>
                        <Content>
                          <div>
                            <ImageIcon src={titleIcon} />
                            <div>
                              <p>Títutlo</p>
                              <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                            </div>
                          </div>
                          <div>
                            <ImageIcon src={contentIcon} />
                            <div>
                              <p>Texto</p>
                              <NoStyle>{renderHTML(item.description)}</NoStyle>
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col>
                        <div style={{ display: 'flex' }}>
                          <ImageIcon
                            src={imgIcon}
                            style={{ marginRight: '10px' }}
                          />
                          <ImageB3 src={item?.image.file} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
                {item.name == 'Bloco 4' && (
                  <>
                    <Row>
                      <Col>
                        <Content>
                          <div>
                            <ImageIcon src={titleIcon} />
                            <div>
                              <p>Títutlo</p>
                              <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                            </div>
                          </div>
                          <div>
                            <ImageIcon src={contentIcon} />
                            <div>
                              <p>Texto</p>
                              <NoStyle>{renderHTML(item.description)}</NoStyle>
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col>
                        <div style={{ display: 'flex' }}>
                          <ImageIcon
                            src={imgIcon}
                            style={{ marginRight: '10px' }}
                          />
                          <ImageB3 src={item?.image.file} />
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </BlueComponent>
            </Tab>
          );
        })}
      </Tabs>

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setModalEditar(false)}
        content={contentEditar}
      />
    </Container>
  );
};

export default API;
