import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { BlueComponent, RadioText, SwitchGreen } from '../../../../components';
import CustomControllerSelect from '../../../../components/CustomControllerSelect';
import { Container, LbSwitch } from './index.styled';
import { IProps } from './index.types';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_NOTIFICATIONS, ADD_REGIONS, RootState } from '../../../../store';
import {
  createNotifications,
  getRegions,
  getNotifications,
  editNotification
} from '../../../../services';
import Select, { components } from 'react-select';
import InputSearchMap from '../../../../components/InputSearchMap';
import {
  convertDateTimeToInput,
  convertOfTimestampsToDateTime,
  convertToDateAndHours
} from '../../../../utils/date';

const ModalCriar: React.FC<IProps> = ({ isModal, onHide, content }) => {
  const referencia = useRef<any>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: {
      title: '',
      message: '',
      address: '',
      regions: [
        {
          id: ''
        }
      ],
      link: '',
      type: '',
      scheduling: '',
      scheduled: false,
      status: 'Pending'
    }
  });

  const { token, regions, notifications } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  const [regioes, setRegioes] = useState<any[]>([]);

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  useEffect(() => {
    if (regions != undefined) {
      let aux: any[] = [];
      regions.forEach(chave => {
        if (chave.enabled == true) {
          aux.push({ label: chave.state, value: chave.id });
        }
      });
      setRegioes([...aux]);
    }
  }, [regions]);

  useEffect(() => {
    if (content != undefined) {
      let aux = {
        ...content,
        scheduling: convertDateTimeToInput(content.scheduling)
      };
      reset(aux);
      console.log(aux, 'content', getValues(), 'valuex');
    }
  }, [content]);

  function onSubmit(dados: any) {
    let aux: any = {};
    if (dados.scheduled) {
      aux = {
        ...dados,
        scheduling: convertDateTimeToInput(dados.scheduling)
      };
    } else {
      aux = {
        ...dados,
        scheduled: false,
        scheduling: null,
        status: 'Finished'
      };

      delete aux.scheduled;
      delete aux.scheduling;
      // delete aux.status;
    }

    editNotification(token, aux?.id, aux).then(resp => {
      getNotifications(token).then(resposta => {
        dispatch({ type: ADD_NOTIFICATIONS, notifications: resposta });
        // console.log(resposta, 'dados resp')
        onHide();
      });
    });
  }

  const dt = watch('scheduled');

  return (
    <>
      <Container>
        <BlueComponent
          btnEdit={false}
          isModal={true}
          isShow={isModal}
          onHide={onHide}
          onClickCancel={onHide}
          onClickSave={() => {
            referencia.current.click();
          }}
          onClickEdit={() => {}}
          titleComponent={<div>Editar bloco</div>}
        >
          <Form onSubmit={handleSubmit(onSubmit)}>
            <button ref={referencia} type="submit" style={{ display: 'none' }}>
              Ok
            </button>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Tipo de notificação</Form.Label>
                  <Form.Select {...register('type')}>
                    {[
                      { label: 'Institucional', value: 'Institucional' },
                      { label: 'Alerta manual', value: 'Alerta manual' }
                    ].map(chave => {
                      return <option value={chave.value}>{chave.label}</option>;
                    })}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label> Endereço </Form.Label>
                  <Controller
                    name="address"
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <>
                        <InputSearchMap
                          type="text"
                          placeholder="Digite..."
                          data-cy="occurence-form-address"
                          value={value}
                          {...field}
                        />
                        {errors?.address && (
                          <p className="text-danger">
                            {errors?.address?.message}
                          </p>
                        )}
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Regiões</Form.Label>

                  <Select
                    placeholder="Regiões"
                    options={regioes}
                    onChange={(e: any) => {
                      let aux: any[] = [];
                      e.forEach((chave: any) => {
                        aux.push({ id: chave.value });
                      });
                      setValue(`regions`, aux);
                    }}
                    isMulti={true}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Link de direcionamento</Form.Label>
                  <Form.Control type="url" {...register('link')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Titulo da mensagem</Form.Label>
                      <Form.Control {...register('title')} />
                    </Form.Group>
                  </Col>
                </Row>
                <br />
                <Row>
                  <Col>
                    <Form.Group>
                      <LbSwitch>
                        Agendar
                        <SwitchGreen
                          checked={getValues(`scheduled`)}
                          onClick={e => {
                            setValue(`scheduled`, e);
                          }}
                        />
                      </LbSwitch>
                    </Form.Group>
                  </Col>
                  <Col>
                    {dt == true && (
                      <Form.Group>
                        <Form.Label>Data e Hora do agendamento</Form.Label>
                        <Form.Control
                          type="datetime-local"
                          {...register('scheduling', {
                            onChange: e => {
                              console.log(e.target.value);
                            }
                          })}
                        />
                      </Form.Group>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Texto da mensagem</Form.Label>
                  <Form.Control as="textarea" {...register('message')} />
                </Form.Group>
              </Col>
            </Row>
            <br />
          </Form>
        </BlueComponent>
      </Container>
    </>
  );
};

export default ModalCriar;
