import { EOccurrenceStatus } from '../../../../shared/enums/ocurrenceStatus';
import { EVictimsRace } from '../../../../shared/enums/victimsRace';
import { EVictimsSituation } from '../../../../shared/enums/victimsSituation';
import { EVictimsType } from '../../../../shared/enums/victimsType';
import { EVictimsTypePerson } from '../../../../shared/enums/victimsTypePerson';
import {
  CreateEditOccurrenceModel,
  OccurrenceAnimalVictimsModel,
  OccurrenceContextInfoModel,
  OccurrenceHumanVictimsModel,
  OccurrenceTransportModel
} from '../../domain/models/CreateEditOccurrenceModel';

export class CreateEditOccurrenceEntity {
  id: string = '';
  documentNumber: number = 0;
  status: EOccurrenceStatus = EOccurrenceStatus.Waiting;
  userId?: string;
  user?: OccurrenceUserEntity;
  adminId?: string;
  admin?: OccurrenceUserEntity;
  address: string = '';
  country?: string;
  stateId: string = '';
  regionId?: string;
  cityId: string = '';
  neighborhoodId?: string;
  subNeighborhoodId?: string;
  localityId?: string;
  latitude: string = '';
  longitude: string = '';
  date: string = '';
  sourceId: string = '';
  policeAction: boolean = false;
  agentPresence: boolean = false;
  description: string = '';
  relatedRecord?: string;
  numberCiviliansDead: number = 0;
  numberCiviliansWounded: number = 0;
  numberAgentDead: number = 0;
  numberAgentWounded: number = 0;
  createdAt?: Date;
  updatedAt?: Date;
  contextInfo: OccurrenceContextInfoEntity = new OccurrenceContextInfoEntity();
  transports: OccurrenceTransportEntity[] = [];
  victims: OccurrenceHumanVictimsEntity[] = [];
  animalVictims: OccurrenceAnimalVictimsEntity[] = [];

  constructor(data?: Partial<CreateEditOccurrenceEntity>) {
    Object.assign(this, {
      ...data,
      id: data?.id || null,
      userId: data?.userId || null,
      adminId: data?.adminId || null,
      user: data?.user ? new OccurrenceUserEntity(data?.user) : null,
      admin: data?.admin ? new OccurrenceUserEntity(data?.admin) : null,
      date: data?.date || null,
      regionId: data?.regionId || null,
      neighborhoodId: data?.neighborhoodId || null,
      subNeighborhoodId: data?.subNeighborhoodId || null,
      localityId: data?.localityId || null,
      createdAt: data?.createdAt ? new Date(data?.createdAt) : null,
      updatedAt: data?.updatedAt ? new Date(data?.updatedAt) : null,
      contextInfo: new OccurrenceContextInfoEntity(data?.contextInfo),
      transports: data?.transports?.map(
        transport => new OccurrenceTransportEntity(transport)
      ),
      victims: data?.victims?.map(
        victim => new OccurrenceHumanVictimsEntity(victim)
      ),
      animalVictims: data?.animalVictims?.map(
        animalVictim => new OccurrenceAnimalVictimsEntity(animalVictim)
      )
    });
  }

  toDomain(): CreateEditOccurrenceModel {
    return new CreateEditOccurrenceModel({
      id: this.id,
      documentNumber: this.documentNumber,
      status: this.status,
      userId: this.userId,
      user: this.user?.toDomain(),
      adminId: this.adminId,
      admin: this.admin?.toDomain(),
      address: this.address,
      country: this.country,
      stateId: this.stateId,
      regionId: this.regionId,
      cityId: this.cityId,
      neighborhoodId: this.neighborhoodId,
      subNeighborhoodId: this.subNeighborhoodId,
      localityId: this.localityId,
      latitude: this.latitude,
      longitude: this.longitude,
      date: new Date(this.date),
      sourceId: this.sourceId,
      policeAction: this.policeAction,
      agentPresence: this.agentPresence,
      description: this.description,
      relatedRecord: this.relatedRecord,
      numberCiviliansDead: this.numberCiviliansDead,
      numberCiviliansWounded: this.numberCiviliansWounded,
      numberAgentDead: this.numberAgentDead,
      numberAgentWounded: this.numberAgentWounded,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      contextInfo: this.contextInfo?.toDomain(),
      transports: this.transports?.map(transport => transport?.toDomain()),
      victims: this.victims?.map(victim => victim?.toDomain()),
      animalVictims: this.animalVictims?.map(animalVictim =>
        animalVictim?.toDomain()
      )
    });
  }
}

export class OccurrenceUserEntity {
  id: string = '';
  name?: string;
  nickname?: string;

  constructor(data?: Partial<OccurrenceUserEntity>) {
    Object.assign(this, data);
  }

  toDomain(): OccurrenceUserEntity {
    return new OccurrenceUserEntity({
      id: this.id,
      name: this.name,
      nickname: this.nickname
    });
  }
}

export class OccurrenceContextInfoEntity {
  mainReasonId: string = '';
  complementaryReasons?: string[];
  clippings?: string[] = [];
  massacre: boolean = false;
  policeUnit?: string;
  relatedNews?: string;
  observations?: string;

  constructor(data?: Partial<OccurrenceContextInfoEntity>) {
    Object.assign(this, data);
  }

  toDomain(): OccurrenceContextInfoModel {
    return new OccurrenceContextInfoModel({
      mainReasonId: this.mainReasonId,
      complementaryReasons: this.complementaryReasons,
      clippings: this.clippings,
      massacre: this.massacre,
      policeUnit: this.policeUnit,
      relatedNews: this.relatedNews,
      observations: this.observations
    });
  }
}

export class OccurrenceTransportEntity {
  id: string = '';
  occurrenceId: string = '';
  transportId: string = '';
  interruptedTransport: boolean = false;
  dateInterruption?: string;
  releaseDate?: string;
  transportDescription?: string;

  constructor(data?: Partial<OccurrenceTransportEntity>) {
    Object.assign(this, {
      ...data,
      id: data?.id || null,
      occurrenceId: data?.occurrenceId || null,
      transportId: data?.transportId || null,
      dateInterruption: data?.dateInterruption || null,
      releaseDate: data?.releaseDate || null
    });
  }

  toDomain(): OccurrenceTransportModel {
    return new OccurrenceTransportModel({
      id: this.id,
      occurrenceId: this.occurrenceId,
      transportId: this.transportId,
      interruptedTransport: this.interruptedTransport,
      dateInterruption: this.dateInterruption
        ? new Date(this.dateInterruption)
        : undefined,
      releaseDate: this.releaseDate ? new Date(this.releaseDate) : undefined,
      transportDescription: this.transportDescription
    });
  }
}

export class OccurrenceHumanVictimsEntity {
  id: string = '';
  occurrenceId: string = '';
  type: EVictimsType = EVictimsType.People;
  situation: EVictimsSituation = EVictimsSituation.Wounded;
  name?: string;
  circumstances: string[] = [];
  deathDate?: string;
  personType: EVictimsTypePerson = EVictimsTypePerson.Civilian;
  qualifications?: string[];
  age?: number = 0;
  ageGroupId: string = '';
  genreId: string = '';
  race: EVictimsRace = EVictimsRace.Unknown;
  placeId: string = '';
  serviceStatusId?: string;
  partieId?: string;
  politicalPositionId?: string;
  politicalStatusId?: string;
  coorporationId?: string;
  agentPositionId?: string;
  agentStatusId?: string;
  unit?: string;
  generalObservation?: string;

  constructor(data?: Partial<OccurrenceHumanVictimsEntity>) {
    Object.assign(this, {
      ...data,
      id: data?.id || null,
      occurrenceId: data?.occurrenceId || null,
      type: EVictimsType.People,
      age: data?.age || null,
      deathDate: data?.deathDate || null,
      partieId: data?.partieId || null,
      politicalPositionId: data?.politicalPositionId || null,
      politicalStatusId: data?.politicalStatusId || null,
      coorporationId: data?.coorporationId || null,
      agentPositionId: data?.agentPositionId || null,
      agentStatusId: data?.agentStatusId || null,
      serviceStatusId: data?.serviceStatusId || null
    });
  }

  toDomain(): OccurrenceHumanVictimsModel {
    return new OccurrenceHumanVictimsModel({
      id: this.id,
      occurrenceId: this.occurrenceId,
      type: this.type,
      situation: this.situation,
      name: this.name,
      circumstances: this.circumstances,
      deathDate: this.deathDate ? new Date(this.deathDate) : undefined,
      personType: this.personType,
      qualifications: this.qualifications,
      age: this.age,
      ageGroupId: this.ageGroupId,
      genreId: this.genreId,
      race: this.race,
      placeId: this.placeId,
      serviceStatusId: this.serviceStatusId,
      partieId: this.partieId,
      politicalPositionId: this.politicalPositionId,
      politicalStatusId: this.politicalStatusId,
      coorporationId: this.coorporationId,
      agentPositionId: this.agentPositionId,
      agentStatusId: this.agentStatusId,
      unit: this.unit,
      generalObservation: this.generalObservation
    });
  }
}

export class OccurrenceAnimalVictimsEntity {
  id: string = '';
  occurrenceId: string = '';
  type: EVictimsType = EVictimsType.Animal;
  situation: EVictimsSituation = EVictimsSituation.Wounded;
  circumstances: string[] = [];
  animalTypeId: string = '';
  name?: string;
  deathDate?: string;

  constructor(data?: Partial<OccurrenceAnimalVictimsEntity>) {
    Object.assign(this, {
      ...data,
      id: data?.id || null,
      occurrenceId: data?.occurrenceId || null,
      type: EVictimsType.Animal,
      deathDate: data?.deathDate || null
    });
  }

  toDomain(): OccurrenceAnimalVictimsModel {
    return new OccurrenceAnimalVictimsModel({
      id: this.id,
      occurrenceId: this.occurrenceId,
      type: this.type,
      situation: this.situation,
      circumstances: this.circumstances,
      animalTypeId: this.animalTypeId,
      name: this.name,
      deathDate: this.deathDate ? new Date(this.deathDate) : undefined
    });
  }
}
