export abstract class AppError {
  public readonly slug: string;
  public readonly message: string;
  public readonly stackTrace: string;

  constructor(
    slug: string = '',
    message: string = '',
    stackTrace: string = ''
  ) {
    this.slug = slug;
    this.message = message;
    this.stackTrace = stackTrace;
  }

  toString = (): string =>
    `[${this.constructor.name}]: \nslug: ${this.slug}, \nmessage: ${this.message}, \nstackTrace: ${this.stackTrace}`;
}

export class AppUnknownError extends AppError {
  constructor(
    slug: string = 'unknown',
    message: string = 'Unknown error',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace);
  }
}
