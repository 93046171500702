import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOccurrencesByParams } from '../../../../../services';
import { ADD_ALERT, RootState } from '../../../../../store';
import {
  ScrollView,
  Title,
  ModalStyled,
  ModalBodyStyled,
  Text,
  ModalHeaderStyled,
  DivBtn,
  ButtonOcccurrences
} from './index.styled';
import { IProps } from './index.types';
import moment from 'moment';

import { CardOccurrence } from '../../../../index';
import ModalReprovar from './ModalReprovar';
import RegisterForm from './RegisterForm';
import SocketContext from '../../../../../context/socket';

const ModalAlert: React.FC<IProps> = ({ isModal, onHide }) => {
  function convertData(data: any) {
    return moment(data).format('DD/MM/YYYY HH:mm');
  }

  const { listOfOccurrences } = useContext(SocketContext);
  const { token, alert } = useSelector((state: RootState) => state.clickState);

  const [ocorrencias, setOcorrencias] = useState([
    {
      id: '',
      document_number: 0,
      address: '',
      country: '',
      latitude: '',
      longitude: '',
      date: '',
      agent_presence: false,
      police_action: false,
      number_civilians_dead: 0,
      number_civilians_wounded: 0,
      number_agent_dead: 0,
      number_agent_wounded: 0,
      description: '',
      related_record: '',
      massacre: false,
      police_unit: '',
      interrupted_transport: false,
      related_news: '',
      observations: '',
      date_interruption: '',
      release_date: '',
      transport_description: '',
      status: '',
      createdAt: '',
      updatedAt: '',
      user: {
        id: '',
        name: '',
        nickname: '',
        email: '',
        phone_number: '',
        password_hash: '',
        security_question: 0,
        security_answer: '',
        state: '',
        city: '',
        active: false,
        trusted: false,
        role: '',
        createdAt: '',
        updatedAt: '',
        region: {
          id: '',
          region: '',
          state: '',
          enabled: false
        }
      },
      admin: {
        name: ''
      },
      state: {
        name: ''
      },
      city: '',
      neighborhood: '',
      subneighborhood: '',
      locality: '',
      source: '',
      region: {
        id: '',
        region: '',
        state: '',
        enabled: false
      },
      main_reason: '',
      complementary_reasons: [],
      clippings: [],
      transports: [],
      victims: []
    }
  ]);

  const typeOccurrences = 'Waiting';
  const order = 'DESC';
  const initial_date = '2022-01-01';
  const final_date = '2030-12-31';
  const page = 1;
  const [isRegisterModal, setRegisterModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getOccurrencesByParams(
      token,
      order,
      page,
      50,
      typeOccurrences,
      `${initial_date}T00:01:00.000Z`,
      `${final_date}T23:59:00.000Z`
    ).then(resp => {
      setOcorrencias(resp?.data);
      dispatch({ type: ADD_ALERT, alert: resp?.data?.length });
    });
  }, [listOfOccurrences]);

  const [isAlterarStatusOcorrencia, setModalAlterarStatusOcorrencia] =
    useState(false);
  const [idOccorencia, setIdOcorrencia] = useState('');
  const [ocorrenciaTemp, setOccorenciaTemp] = useState<any>(null);
  const [ordenacao, setOrdenacao] = useState('dtoc');

  return (
    <>
      <ModalStyled
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        fullscreen
      >
        <ModalBodyStyled>
          <ModalHeaderStyled>
            <Title>Ocorrências para a aprovação</Title>
            <Text>
              Há <b>{alert} ocorrências</b> para aprovação
            </Text>
          </ModalHeaderStyled>

          <ScrollView>
            {ocorrencias?.map(ocorrencia => {
              let userName =
                ocorrencia?.user?.name || ocorrencia?.user?.nickname || '';
              userName = !!userName ? userName : 'Desconhecido';

              return (
                <>
                  <CardOccurrence
                    id={ocorrencia.id}
                    document_number={ocorrencia.document_number}
                    status={ocorrencia.status}
                    user={userName}
                    date={convertData(ocorrencia.date)}
                    createdAt={convertData(ocorrencia.createdAt)}
                    address={ocorrencia.address}
                    civiliansWounded={ocorrencia.number_civilians_wounded}
                    civiliansDead={ocorrencia.number_civilians_dead}
                    agentDead={ocorrencia.number_agent_dead}
                    agentWounded={ocorrencia.number_agent_wounded}
                    description={ocorrencia.description}
                    state={
                      ocorrencia.state === null ? '' : ocorrencia.state.name
                    }
                    aprove={() => {
                      if (ocorrencia.status == 'Waiting') {
                        setRegisterModal(true);
                        setIdOcorrencia(ocorrencia.id);
                        setOccorenciaTemp(ocorrencia);
                      }
                    }}
                    reprove={() => {
                      if (ocorrencia.status == 'Waiting') {
                        setModalAlterarStatusOcorrencia(true);
                        setIdOcorrencia(ocorrencia.id);
                        setOccorenciaTemp(ocorrencia);
                      }
                    }}
                  />
                </>
              );
            })}
          </ScrollView>

          <DivBtn>
            <ButtonOcccurrences
              to="/occurrences"
              onClick={() => {
                onHide();
              }}
            >
              Ver todas ocorrências
            </ButtonOcccurrences>
          </DivBtn>
        </ModalBodyStyled>
      </ModalStyled>

      <ModalReprovar
        open={isAlterarStatusOcorrencia}
        onClose={() => {
          setOccorenciaTemp(null);
          setModalAlterarStatusOcorrencia(false);
        }}
        occurrence={ocorrenciaTemp}
        dataDt={{
          order: order,
          page: page,
          take: 50,
          typeOccurrences: typeOccurrences,
          initial_date: initial_date,
          final_date: final_date
        }}
        onHide={onHide}
        setOcorrencias={setOcorrencias}
      />

      <RegisterForm
        isModal={isRegisterModal}
        onHide={() => {
          setRegisterModal(false);
          setOccorenciaTemp(null);
        }}
        dataDt={{
          order: order,
          page: page,
          take: 50,
          typeOccurrences: typeOccurrences,
          initial_date: initial_date,
          final_date: final_date
        }}
        setOcorrencias={setOcorrencias}
        itemEdit={ocorrenciaTemp}
      />
    </>
  );
};

export default ModalAlert;
