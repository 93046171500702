import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  padding-top: 13px;
`;

export const InputSelect = styled.select`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
  padding-right: 20px;
`;

export const InputText = styled.input`
  background: ${props => (props.disabled ? '#DDDDDD' : '#ffffff')};
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 50px;
`;

export const InputArea = styled.input`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  height: 50px;
  resize: none;
  outline: none;
`;

export const DeleteButtonContainer = styled.div`
  align-items: center;
`;
