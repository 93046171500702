import React from 'react';
import { Form } from 'react-bootstrap';

const FormItemCount: React.FC<{
  label: string;
  value: number;
}> = ({ label, value }) => {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control type="text" value={value} disabled={true} />
    </Form.Group>
  );
};

export default FormItemCount;
