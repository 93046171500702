import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const CardOccurrence = styled.div`
  width: 100%;
  z-index: 3;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  padding-top: 16px;
`;

export const Card = styled(Container)`
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  flex-direction: row;
  border-radius: 6px;
  overflow: hidden;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  max-height: 210px;
`;

export const CardLeft = styled.div`
  border-right: 1px solid #ebe9f1;
  width: 25%;

  > header {
    height: 43px;
    display: flex;
    align-items: center;
    padding: 16px 20px;
    background-color: ${props => props.theme.colors.tertiary};
    color: ${props => props.theme.colors.white};

    > b {
      margin-left: 8px;
      color: ${props => props.theme.colors.white};
      font-size: 12px;
      line-height: 23px;
    }

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 23px;
  }

  > div {
    padding: 20px 20px 0;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      > img {
        margin-right: 12px;
      }

      > div {
        > h3 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          margin: 0 !important;
        }

        & > p {
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 21px;
          margin: 0 !important;
          padding: 0 !important;
          color: #6e6b7b;
          opacity: 0.6;
        }
      }
    }
  }
`;

export const CardRight = styled.div`
  width: 75%;
  padding: 20px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > p {
    font-style: normal;
    font-weight: bold;
    line-height: 23px;
  }

  & > div {
    display: flex;
    justify-content: space-between;

    & > div {
      > h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 23px;
        margin: 0 !important;
      }

      > p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: #6e6b7b;
        opacity: 0.6;
        margin: 0 !important;
      }
    }
  }
`;

export const CardDetails = styled.div`
  > div {
    > h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 23px;
      color: #5e5873;
    }

    & > div {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      > img {
        margin-right: 15px;
      }

      > p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        opacity: 0.6;
        margin: 0 !important;
      }
    }
  }
`;

export const CardButtons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const ButtonOutlined = styled.button`
  height: 42px;
  width: 203px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.black};
  background: transparent;
  border: 1px solid #2c3941;

  &:hover {
    background: #f2f2f2;
    transition: background-color 200ms linear;
  }
`;

export const Button = styled.button`
  height: 42px;
  width: 203px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  margin-bottom: 12px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background: linear-gradient(90deg, #f83600, #fe8c00);
  border: none;

  &:hover {
    background: linear-gradient(90deg, #e53c0d, #e53c0d);
    transition: background-color 200ms linear;
  }

  & > div {
    margin-right: 8px;
    color: white;
  }
`;

export const DescriptionText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
`;
