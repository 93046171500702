import React, { useContext, useEffect, useState } from 'react';
import { Form, ProgressBar, Tooltip, Image, Button } from 'react-bootstrap';
import { DataInput } from '../../../../../components';
import { DashboardContext } from '../../../providers/DashboardProvider';
import {
  Container,
  Row,
  RowBottom,
  CardHeader,
  Card,
  Charts,
  Content,
  ImgStatus,
  Text,
  LoadingContainer,
  OccurrencesMouth,
  OccurrencesTop,
  Progress,
  ProgressContent,
  TotalContent,
  TotalOccurrences,
  Block,
  BlockHeader,
  ContainerDate,
  Title,
  FilterContainer,
  FilterField,
  TitleHeader
} from './styled';

import orangeImg from '../../../../../assets/orange.svg';
import redImg from '../../../../../assets/red.svg';
import blueImg from '../../../../../assets/blue.svg';
import alertImg from '../../../../../assets/alert.svg';
import orangeAgent from '../../../../../assets/agentesFeridos.png';
import orangePerson from '../../../../../assets/civisFeridos.png';
import redAgent from '../../../../../assets/agentesMortos.png';
import redPerson from '../../../../../assets/civisMortos.png';
import semVitimas from '../../../../../assets/semVitimas.png';
import { PieChart } from 'react-minimal-pie-chart';
import Loading from '../../../../../components/Loading';
import { BarChart, XAxis, Bar, AreaChart, Area } from 'recharts';
import { NeighborhoodModel } from '../../../../../shared/domain/models/NeighborhoodModel';
import { RefactorSelectWithSearch } from '../../../../../components/SelectWithSearch';
import ReloadRequestComponent from '../../components/ReloadRequestComponent';

const Dashboard: React.FC = () => {
  const { statisticsState, statisticsUsecase } = useContext(DashboardContext);

  const [stateName, setStateName] = useState('');

  const [dataOfTheMonth, setDataOfTheMonth] = useState<any>({});

  const defaultValueForChart = [
    {
      value: 2,
      color: '#cfcfcf'
    },
    {
      value: 1,
      color: '#a1a1a1'
    }
  ];

  function getYearsList(): { label: string; value: string }[] {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2020; year--) {
      years.push({ label: year.toString(), value: year.toString() });
    }
    return years;
  }

  async function initState() {
    statisticsUsecase.resetStatisticsFilter();
    await statisticsUsecase.getRegions();
    await statisticsUsecase.getCities();
    await statisticsUsecase.getNeighborhoods();
    await requestStatistics();
    await requestAnnualStatistics();
  }

  async function requestStatistics() {
    await statisticsUsecase.getStatistics();
  }

  async function requestAnnualStatistics() {
    await statisticsUsecase.getAnnualStatistics();
  }

  useEffect(() => {
    requestAnnualStatistics();
  }, [statisticsState.annualStatisticsQueryParams.year]);

  useEffect(() => {
    requestStatistics();
  }, [
    statisticsState.statisticsQueryParams.initialdate,
    statisticsState.statisticsQueryParams.finaldate,
    statisticsState.statisticsQueryParams.region,
    statisticsState.statisticsQueryParams.cities,
    statisticsState.statisticsQueryParams.neighborhoods
  ]);

  useEffect(() => {
    initState();
  }, []);

  return (
    <Container>
      <Block>
        <BlockHeader>
          <TitleHeader>Estatísticas</TitleHeader>
          <ContainerDate>
            <DataInput
              id="intialDate"
              name="intialDate"
              htmlFor="intialDate"
              value={statisticsState.statisticsQueryParams.initialdate}
              onChange={e => statisticsUsecase.setInitialData(e.target.value)}
            />
            <p>a</p>
            <DataInput
              id="finalDate"
              name="finalDate"
              htmlFor="finalDate"
              value={statisticsState.statisticsQueryParams.finaldate}
              onChange={e => statisticsUsecase.setFinalData(e.target.value)}
            />
          </ContainerDate>
        </BlockHeader>

        <FilterContainer>
          <Form.Select
            onChange={e => {
              const stateName = e.target.options[e.target.selectedIndex].text;
              setStateName(stateName);
              statisticsUsecase.setRegion(e.target.value);
              statisticsUsecase.setCities([]);
              statisticsUsecase.setNeighborhoods([]);
            }}
          >
            {statisticsState.regionsRequestStatus.maybeMap({
              loading: () => <option>Carregando...</option>,
              succeeded(data) {
                return data?.map(region =>
                  region.enabled ? (
                    region.state === 'Geral' ||
                    region.state === 'Institucional' ? (
                      <option value={''}>{region.state}</option>
                    ) : (
                      <option value={region.id}>{region.state}</option>
                    )
                  ) : null
                );
              }
            })}
          </Form.Select>

          <FilterField>
            <RefactorSelectWithSearch
              options={statisticsState.citiesRequestStatus.maybeMap({
                idle: () => [],
                loading: () => [],
                failed: () => [],
                succeeded(data) {
                  const filteredCities = data.filter(
                    city => city.state.name === stateName
                  );
                  return filteredCities.map(city => {
                    return { value: city.id, label: city.name };
                  });
                }
              })}
              placeholder="Selecione as cidades..."
              onChange={selectedCities => {
                statisticsUsecase.setCities(selectedCities);
                const selectedCityIds = selectedCities.map(
                  (city: { value: any }) => city.value
                );
                const selectedNeighborhoods =
                  statisticsState.statisticsQueryParams.neighborhoods.filter(
                    neighborhood =>
                      selectedCityIds.includes(neighborhood.cityId)
                  );

                statisticsUsecase.setNeighborhoods(selectedNeighborhoods);
              }}
              value={statisticsState.statisticsQueryParams.cities.map(
                citie => citie.value
              )}
              isDisabled={!statisticsState.statisticsQueryParams.region}
              isMulti={true}
            />
          </FilterField>

          <FilterField>
            <RefactorSelectWithSearch
              options={statisticsState.neighborhoodsRequestStatus.maybeMap({
                idle: () => [],
                loading: () => [],
                failed: () => [],
                succeeded(data) {
                  let neighborhoodsAux: NeighborhoodModel[] = [];
                  data.forEach(neighborhood => {
                    if (
                      statisticsState.statisticsQueryParams.cities.some(
                        city => city.value === neighborhood.city.id
                      )
                    ) {
                      neighborhoodsAux.push(neighborhood);
                    }
                  });
                  return neighborhoodsAux.map(neighborhood => ({
                    value: neighborhood.id,
                    label: neighborhood.name,
                    cityId: neighborhood.city.id
                  }));
                }
              })}
              placeholder="Selecione os bairros..."
              onChange={selectedNeiborhoods =>
                statisticsUsecase.setNeighborhoods(selectedNeiborhoods)
              }
              value={statisticsState.statisticsQueryParams.neighborhoods.map(
                neighborhood => neighborhood.value
              )}
              isDisabled={
                statisticsState.statisticsQueryParams.cities.length === 0
              }
              isMulti={true}
            />
          </FilterField>
        </FilterContainer>

        {statisticsState.statisticsRequestStatus.maybeMap({
          loading: () => (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          ),
          failed: () => {
            return <ReloadRequestComponent onClick={requestStatistics} />;
          },
          succeeded(data) {
            return (
              <>
                <RowBottom>
                  <CardHeader>
                    <img
                      src={orangePerson}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.occurence.total}</h3>
                      <p>Tiroteios/disparos</p>
                    </div>
                  </CardHeader>
                  <CardHeader>
                    <img
                      src={semVitimas}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.occurence.no_wounded}</h3>
                      <p>Sem vitimas</p>
                    </div>
                  </CardHeader>
                  <CardHeader>
                    <img
                      src={redPerson}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.victim.dead_civilians}</h3>
                      <p>Civis mortos</p>
                    </div>
                  </CardHeader>
                  <CardHeader>
                    <img
                      src={redAgent}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.victim.dead_agents}</h3>
                      <p>Agentes mortos</p>
                    </div>
                  </CardHeader>
                  <CardHeader>
                    <img
                      src={orangePerson}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.victim.wounded_civilians}</h3>
                      <p>Civis feridos</p>
                    </div>
                  </CardHeader>
                  <CardHeader>
                    <img
                      src={orangeAgent}
                      style={{ width: '48px', height: '48px' }}
                    />
                    <div>
                      <h3>{data.victim.wounded_agents}</h3>
                      <p>Agentes feridos</p>
                    </div>
                  </CardHeader>
                </RowBottom>

                <Row>
                  <Card>
                    <Title>Ocorrências</Title>
                    {data.occurence.without_shot === 0 &&
                    data.occurence.with_shot === 0 ? (
                      <Charts>
                        <PieChart
                          style={{
                            width: '212px'
                          }}
                          data={defaultValueForChart}
                        />
                      </Charts>
                    ) : (
                      <Charts>
                        <PieChart
                          style={{
                            width: '212px'
                          }}
                          data={[
                            {
                              title: 'Tiros sem baleados',
                              value: data.occurence.without_shot,
                              color: '#FF9F43'
                            },
                            {
                              title: 'Tiros com baleados',
                              value: data.occurence.with_shot,
                              color: '#EA5455'
                            }
                          ]}
                        />
                      </Charts>
                    )}

                    <Content>
                      <div>
                        <ImgStatus src={orangeImg} />
                        <Text>Tiros sem baleados</Text>
                      </div>
                      <Text>{data.occurence.without_shot}</Text>
                    </Content>
                    <Content>
                      <div>
                        <ImgStatus src={redImg} />
                        <Text>Tiro com baleados</Text>
                      </div>
                      <Text>{data.occurence.with_shot}</Text>
                    </Content>
                    <Content>
                      <div>
                        <ImgStatus src={blueImg} />
                        <Text>Total</Text>
                      </div>
                      <Text>{data.occurence.total}</Text>
                    </Content>
                  </Card>

                  <Card>
                    <Title>Vitimas Civis</Title>
                    <Charts>
                      <PieChart
                        style={{
                          width: '212px'
                        }}
                        lineWidth={20}
                        data={
                          data.victim.wounded_civilians === 0 &&
                          data.victim.dead_civilians === 0
                            ? defaultValueForChart
                            : [
                                {
                                  title: 'Feridos',
                                  value: data.victim.wounded_civilians,
                                  color: '#FF9F43'
                                },
                                {
                                  title: 'Mortes',
                                  value: data.victim.dead_civilians,
                                  color: '#EA5455'
                                }
                              ]
                        }
                      />
                    </Charts>

                    <Content>
                      <div>
                        <ImgStatus src={orangeImg} />
                        <Text>Feridos</Text>
                      </div>
                      <Text>{data.victim.wounded_civilians}</Text>
                    </Content>
                    <Content>
                      <div>
                        <ImgStatus src={redImg} />
                        <Text>Mortes</Text>
                      </div>
                      <Text>{data.victim.dead_civilians}</Text>
                    </Content>
                  </Card>

                  <Card>
                    <Title>Vitimas Agentes</Title>
                    <Charts>
                      <PieChart
                        style={{
                          width: '212px'
                        }}
                        lineWidth={20}
                        data={
                          data.victim.wounded_agents === 0 &&
                          data.victim.dead_agents === 0
                            ? defaultValueForChart
                            : [
                                {
                                  title: 'Feridos',
                                  value: data.victim.wounded_agents,
                                  color: '#FF9F43'
                                },
                                {
                                  title: 'Mortes',
                                  value: data.victim.dead_agents,
                                  color: '#EA5455'
                                }
                              ]
                        }
                      />
                    </Charts>
                    <Content>
                      <div>
                        <ImgStatus src={orangeImg} />
                        <Text>Feridos</Text>
                      </div>
                      <Text>{data.victim.wounded_agents}</Text>
                    </Content>
                    <Content>
                      <div>
                        <ImgStatus src={redImg} />
                        <Text>Mortes</Text>
                      </div>
                      <Text>{data.victim.dead_agents}</Text>
                    </Content>
                  </Card>
                </Row>
              </>
            );
          }
        })}
      </Block>

      <Block>
        <BlockHeader>
          <TitleHeader>Estatísticas Anuais</TitleHeader>
          <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
            <select
              style={{
                width: '130px',
                height: '40px'
              }}
              onChange={e => {
                statisticsUsecase.setYear(e.target.value);
                setDataOfTheMonth({});
              }}
            >
              {getYearsList().map(year => {
                return <option value={year.value}>{year.label}</option>;
              })}
            </select>
          </Row>
        </BlockHeader>

        {statisticsState.annualStatisticsRequestStatus.maybeMap({
          loading: () => (
            <LoadingContainer>
              <Loading />
            </LoadingContainer>
          ),
          failed: () => {
            return <ReloadRequestComponent onClick={requestAnnualStatistics} />;
          },
          succeeded(data) {
            return (
              <Row>
                <OccurrencesMouth>
                  <OccurrencesTop>
                    <BarChart
                      style={{ backgroundColor: '#FFF' }}
                      width={650}
                      height={300}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5
                      }}
                      barSize={20}
                      data={data.months}
                    >
                      <XAxis
                        dataKey="month"
                        padding={{ left: 10, right: 10 }}
                      />
                      <Tooltip />
                      <Bar
                        dataKey="total"
                        fill="#7367F0"
                        background={{ fill: '#F8F8F8' }}
                        onClick={e => setDataOfTheMonth(e)}
                      />
                    </BarChart>
                  </OccurrencesTop>
                  <hr />
                  <div>
                    <div style={{ backgroundColor: '#FFF' }}>
                      <p>Total no mês</p>
                      <h3>{dataOfTheMonth.total}</h3>
                    </div>
                    <ProgressBar
                      now={dataOfTheMonth.total}
                      max={data.total_occurrences}
                    />
                    <ProgressContent>
                      <Progress>
                        <div style={{ backgroundColor: '#FFF' }}>
                          <p>Mortes Civis</p>
                          <h3>{dataOfTheMonth.dead_civilians}</h3>
                        </div>
                        <ProgressBar
                          now={dataOfTheMonth.dead_civilians}
                          max={dataOfTheMonth.total}
                          variant="danger"
                        />
                        <div style={{ backgroundColor: '#FFF' }}>
                          <p>Feridos Civis</p>
                          <h3>{dataOfTheMonth.wounded_civilians}</h3>
                        </div>
                        <ProgressBar
                          now={dataOfTheMonth.wounded_civilians}
                          max={dataOfTheMonth.total}
                          variant="warning"
                        />
                      </Progress>
                      <Progress>
                        <div style={{ backgroundColor: '#FFF' }}>
                          <p>Mortes Policiais</p>
                          <h3>{dataOfTheMonth.dead_agents}</h3>
                        </div>
                        <ProgressBar
                          now={dataOfTheMonth.dead_agents}
                          max={dataOfTheMonth.total}
                          variant="danger"
                        />
                        <div style={{ backgroundColor: '#FFF' }}>
                          <p>Feridos Policiais</p>
                          <h3>{dataOfTheMonth.wounded_agents}</h3>
                        </div>
                        <ProgressBar
                          now={dataOfTheMonth.wounded_agents}
                          max={dataOfTheMonth.total}
                          variant="warning"
                        />
                      </Progress>
                    </ProgressContent>
                  </div>
                </OccurrencesMouth>

                <div style={{ width: '48%' }}>
                  <TotalOccurrences style={{ marginBottom: '20px' }}>
                    <TotalContent>
                      <div>
                        <div>
                          <Image src={alertImg} />
                        </div>
                        <h3>{data.total_occurrences}</h3>
                        <p>Total de ocorrências</p>
                      </div>
                      <p>{statisticsState.annualStatisticsQueryParams.year}</p>
                    </TotalContent>
                    <AreaChart
                      width={760}
                      height={94}
                      style={{ backgroundColor: '#FFF' }}
                      data={data.months}
                      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    >
                      <defs>
                        <linearGradient
                          id="colorred"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1"
                        >
                          <stop
                            offset="5%"
                            stopColor="#7367F0"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#7367F0"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="total"
                        stroke="#7367F0"
                        fillOpacity={1}
                        fill="url(#colorred)"
                      />
                    </AreaChart>
                  </TotalOccurrences>
                  <Row>
                    <TotalOccurrences style={{ marginRight: '20px' }}>
                      <TotalContent>
                        <div>
                          <div>
                            <Image
                              src={redPerson}
                              style={{
                                marginRight: '10px',
                                width: '48px',
                                height: '48px'
                              }}
                            />
                            <Image
                              src={redAgent}
                              style={{ width: '48px', height: '48px' }}
                            />
                          </div>
                          <h3>{data.total_dead}</h3>
                          <p>Total de mortes</p>
                        </div>
                        <p>
                          {statisticsState.annualStatisticsQueryParams.year}
                        </p>
                      </TotalContent>
                      <AreaChart
                        style={{ backgroundColor: '#FFF' }}
                        width={350}
                        height={200}
                        data={data.months}
                        margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                      >
                        <defs>
                          <linearGradient
                            id="colorUv"
                            x1="0"
                            y1="0"
                            x2="0"
                            y2="1"
                          >
                            <stop
                              offset="5%"
                              stopColor="#FD444E"
                              stopOpacity={0.8}
                            />
                            <stop
                              offset="95%"
                              stopColor="#FD444E"
                              stopOpacity={0}
                            />
                          </linearGradient>
                        </defs>
                        <Tooltip />
                        <Area
                          type="monotone"
                          dataKey="dead_agents"
                          stroke="#FD444E"
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                        <Area
                          type="monotone"
                          dataKey="dead_civilians"
                          stroke="#FD444E"
                          fillOpacity={1}
                          fill="url(#colorUv)"
                        />
                      </AreaChart>
                    </TotalOccurrences>
                    <TotalOccurrences>
                      <TotalContent>
                        <div>
                          <div>
                            <Image
                              src={orangePerson}
                              style={{
                                marginRight: '10px',
                                width: '48px',
                                height: '48px'
                              }}
                            />
                            <Image
                              src={orangeAgent}
                              style={{ width: '48px', height: '48px' }}
                            />
                          </div>
                          <h3>{data.total_wounded}</h3>
                          <p>Total de feridos</p>
                        </div>
                        <p>
                          {statisticsState.annualStatisticsQueryParams.year}
                        </p>
                      </TotalContent>
                      <div>
                        <AreaChart
                          style={{ backgroundColor: '#FFF' }}
                          width={350}
                          height={200}
                          data={data.months}
                          margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
                        >
                          <defs>
                            <linearGradient
                              id="colorxd"
                              x1="0"
                              y1="0"
                              x2="0"
                              y2="1"
                            >
                              <stop
                                offset="5%"
                                stopColor="#FF9920"
                                stopOpacity={0.8}
                              />
                              <stop
                                offset="100%"
                                stopColor="#FF9920"
                                stopOpacity={0}
                              />
                            </linearGradient>
                          </defs>
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="wounded_agents"
                            stroke="#FF9920"
                            fillOpacity={1}
                            fill="url(#colorxd)"
                          />
                          <Area
                            type="monotone"
                            dataKey="wounded_civilians"
                            stroke="#FF9920"
                            fillOpacity={1}
                            fill="url(#colorxd)"
                          />
                        </AreaChart>
                      </div>
                    </TotalOccurrences>
                  </Row>
                </div>
              </Row>
            );
          }
        })}
      </Block>
    </Container>
  );
};

export default Dashboard;
