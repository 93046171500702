import { AdressSharedRepository } from '../../../../../shared/domain/repositories/AdressSharedRepository';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { UseContextNotifier } from '../../../../../shared/types/UseContextNotifier';
import { PostRepository } from '../../repositories/PostRepository';
import PostActionsState from './PostActionsState';

export default class PostActionsUsecase extends UseContextNotifier<PostActionsState> {
  postRepository: PostRepository;
  adressSharedRepository: AdressSharedRepository;

  constructor(
    state: PostActionsState = new PostActionsState(),
    setState: React.Dispatch<React.SetStateAction<PostActionsState>>,
    postRepository: PostRepository,
    adressSharedRepository: AdressSharedRepository
  ) {
    super(state, setState);
    this.postRepository = postRepository;
    this.adressSharedRepository = adressSharedRepository;
  }

  public async getRegions() {
    this.updateState({
      ...this.state,
      regionsRequestStatus: RequestStatus.loading()
    });

    let resp = await this.adressSharedRepository.getRegions();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          regionsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          regionsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async createPost({ data }: { data: any }) {
    this.updateState({
      ...this.state,
      createPostRequestStatus: RequestStatus.loading()
    });

    let resp = await this.postRepository.createPost(data);

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          createPostRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          createPostRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async editPost({ id, data }: { id: string; data: any }) {
    this.updateState({
      ...this.state,
      editPostRequestStatus: RequestStatus.loading()
    });

    let resp = await this.postRepository.editPost(id, data);

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          editPostRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          editPostRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async deletePost({ id }: { id: string }) {
    this.updateState({
      ...this.state,
      deletePostRequestStatus: RequestStatus.loading()
    });

    let resp = await this.postRepository.deletePost(id);

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          deletePostRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          deletePostRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }
}
