import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { PlaceModel } from '../../domain/models/PlaceModel';

export class PlaceEntity extends BasicEntity {
  constructor(data?: Partial<PlaceEntity>) {
    super(data);
  }

  toDomain(): PlaceModel {
    return new PlaceModel({
      id: this.id,
      name: this.name
    });
  }
}
