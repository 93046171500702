import { IRequestStatus } from '../../../../../shared/interface/requestStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { PostModel } from '../../models/PostModel';

export default class PostDetailsState {
  postDetailsRequestStatus: IRequestStatus<PostModel>;

  constructor(
    postDetailsRequestStatus: IRequestStatus<PostModel> = RequestStatus.idle()
  ) {
    this.postDetailsRequestStatus = postDetailsRequestStatus;
  }
}
