import { IRequestStatus } from '../../../../../shared/interface/requestStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';

export default class PostActionsState {
  regionsRequestStatus: IRequestStatus<any>;
  createPostRequestStatus: IRequestStatus<any>;

  editPostRequestStatus: IRequestStatus<any>;
  deletePostRequestStatus: IRequestStatus<any>;

  constructor(
    regionsRequestStatus: IRequestStatus<any> = RequestStatus.idle(),
    createPostRequestStatus: IRequestStatus<any> = RequestStatus.idle(),

    editPostRequestStatus: IRequestStatus<any> = RequestStatus.idle(),
    deletePostRequestStatus: IRequestStatus<any> = RequestStatus.idle()
  ) {
    this.regionsRequestStatus = regionsRequestStatus;
    this.createPostRequestStatus = createPostRequestStatus;
    this.editPostRequestStatus = editPostRequestStatus;
    this.deletePostRequestStatus = deletePostRequestStatus;
  }
}
