import { api } from '../index';

export async function getHome(token: string) {
  try {
    const resposta = await api.get('/home', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Home) erro:', erro);
    return null;
  }
}

export async function putHome(token: string, dados: any, id: string) {
  try {
    const resposta = await api.put(`/home/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Home) erro:', erro);
    return null;
  }
}

export async function createHome(token: string, dados: any) {
  try {
    const resposta = await api.post(`/home`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Home) erro:', erro);
    return null;
  }
}
