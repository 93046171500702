import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { AgeGroupModel } from '../../domain/models/AgeGroupModel';

export class AgeGroupEntity extends BasicEntity {
  constructor(data?: Partial<AgeGroupEntity>) {
    super(data);
  }

  toDomain(): AgeGroupModel {
    return new AgeGroupModel({
      id: this.id,
      name: this.name
    });
  }
}
