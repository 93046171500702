import { RegionModel } from '../../../../../shared/domain/models/RegionModel';
import { IRequestStatus } from '../../../../../shared/interface/requestStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { PostModel } from '../../models/PostModel';

export default class PostListState {
  postsRequestStatus: IRequestStatus<PostModel[]>;
  regionsRequestStatus: IRequestStatus<RegionModel[]>;
  queryParams: PostListQueryParams;

  constructor(
    postsRequestStatus: IRequestStatus<PostModel[]> = RequestStatus.idle(),
    regionsRequestStatus: IRequestStatus<RegionModel[]> = RequestStatus.idle(),
    queryParams: PostListQueryParams = new PostListQueryParams()
  ) {
    this.postsRequestStatus = postsRequestStatus;
    this.regionsRequestStatus = regionsRequestStatus;
    this.queryParams = queryParams;
  }
}

export class PostListQueryParams {
  page: string = '1';
  take: string = '300';
  order: string = 'DESC';
  region: string = '';
  authors: string = '';
  date: string = '';
  search: string = '';

  constructor(data?: Partial<PostListQueryParams>) {
    Object.assign(this, data);
  }
}
