import { BasicModel } from './BasicModel';
import { CityModel } from './CityModel';

export class SubNeighborhoodModel extends BasicModel {
  city: CityModel;

  constructor({
    id,
    name,
    city
  }: {
    id: string;
    name: string;
    city: CityModel;
  }) {
    super({ id, name });
    this.city = city;
  }
}
