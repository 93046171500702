import React, { useEffect, useState } from 'react';
import { Container } from './index.style';
import { Modal } from 'react-bootstrap';
import { InterfaceProps } from './index.types';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  api,
  deleteUsers,
  getRegions,
  getStates,
  getUsers
} from '../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_REGIONS,
  ADD_STATES,
  ADD_USERS,
  RootState
} from '../../../../store';
import trash from '../../../../assets/trashDel.png';
import { ModalEditTrue } from '../../../../components';

const ModalApagar: React.FC<InterfaceProps> = ({
  isModal,
  onHide,
  user,
  role
}) => {
  const [isModalErrorDelete, setModalErrorDelete] = useState(false);
  const [isModalSucessoDelete, setModalSucessoDelete] = useState(false);
  const { token } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        show={isModal}
        onHide={() => {
          onHide();
        }}
      >
        <Container>
          <img src={trash} alt="Lixeira" />
          <h3>Excluir usuário</h3>
          <h4>Você tem certeza que deseja excluir este usuário!</h4>
          <p>Essa ação não podera ser alterada</p>

          <div>
            <button onClick={onHide}>Não, cancelar</button>
            <button
              onClick={() => {
                console.log(user);

                deleteUsers(token, user.id)
                  .catch(erro => {
                    console.warn('(Usuário) Erro:', erro.response.data);
                    onHide();
                    setModalErrorDelete(true);
                  })
                  .then(resp => {
                    if (resp != null) {
                      getUsers(token).then(resp => {
                        dispatch({ type: ADD_USERS, users: resp.data });
                      });
                      onHide();
                      setModalSucessoDelete(true);
                    } else {
                      console.warn('rrr', resp);
                    }
                  });
              }}
            >
              Sim, excluir usuário
            </button>
          </div>
        </Container>
      </Modal>

      <Modal
        show={isModalErrorDelete}
        onHide={() => {
          setModalErrorDelete(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <div>
          <h1>Usuário não existe!!!!</h1>
          <p>Usuário não existe mais!!!</p>
        </div>
      </Modal>

      <Modal
        show={isModalSucessoDelete}
        onHide={() => {
          setModalSucessoDelete(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <ModalEditTrue
          close={() => {
            setModalSucessoDelete(false);
          }}
          title="Usuário excluído"
          subTitle="O usuário foi excluido com sucesso"
        />
      </Modal>
    </>
  );
};

export default ModalApagar;
