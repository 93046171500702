import React from 'react';

import {
  Formik,
  Field,
  ErrorMessage,
  FieldProps,
  FormikProps,
  FieldInputProps
} from 'formik';
import * as Yup from 'yup';
import { Button, Form, Image } from 'react-bootstrap';

import RefreshImg from '../../../assets/refresh.svg';

interface ICustomFormComponentProps<T> {
  values: T;
  handleSubmit: (e: any) => void;
  handleChange: (fieldName: string, fieldValue: any) => void;
  handleBlur: (e: any) => void;
  isInvalid: (fieldName: string) => boolean | undefined;
}

interface ICustomFormProps<T> {
  validationSchema: Yup.SchemaOf<any>;
  initialValues: T;
  forwardedRef: React.RefObject<FormikProps<T>>;
  onSubmit: (values: T) => void;
  handleFormChange: (field: string, value: any) => void;
  component: (props: ICustomFormComponentProps<T>) => JSX.Element;
}

export const CustomForm = <T extends Record<string, any>>({
  validationSchema,
  initialValues,
  forwardedRef,
  onSubmit,
  handleFormChange,
  component
}: ICustomFormProps<T>) => {
  const handleChange = (fieldName: string, fieldValue: any) => {
    forwardedRef.current?.setFieldValue(fieldName, fieldValue);
    handleFormChange(fieldName, fieldValue);
  };

  return (
    <Formik
      innerRef={forwardedRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, errors, dirty, touched, handleBlur, handleSubmit }) => {
        const isInvalid = (name: string) =>
          (touched[name] || dirty) && !!errors[name];

        return component({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isInvalid
        });
      }}
    </Formik>
  );
};

export const FormItem: React.FC<{
  name: string;
  label: string;
  failRequest?: boolean;
  onPressedRefresh?: () => void;
  component: (field: FieldInputProps<any>) => JSX.Element;
}> = ({ name, label, component, failRequest, onPressedRefresh }) => {
  return (
    <Form.Group controlId={name} key={name}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Form.Label>{label}</Form.Label>
        {failRequest ? (
          <Button
            style={{
              marginLeft: '20px',
              marginBottom: '5px',
              padding: '2px',
              backgroundColor: 'transparent',
              borderColor: 'transparent'
            }}
            onClick={onPressedRefresh}
          >
            <Image src={RefreshImg} />
          </Button>
        ) : null}
      </div>

      <Field name={name}>
        {({ field }: FieldProps) => (
          <div>
            {component(field)}
            <ErrorMessage
              render={msg => (
                <Form.Control.Feedback type="invalid">
                  {msg}
                </Form.Control.Feedback>
              )}
              name={name}
            />
          </div>
        )}
      </Field>
    </Form.Group>
  );
};
