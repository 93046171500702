import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: 110px;
  height: 71px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebe9f1;

  & > img {
    width: 48px;
    height: 48px;
  }
`;

export const ImageBloco2 = styled.img`
  width: 193px;
  height: 141px;
`;

export const DivBtns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 320px;
`;

export const DivBtn1 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: ${props => props.theme.colors.secondary};
  color: #fff;
`;

export const DivBtn2 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.secondary};
`;

export const DivBtn3 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: ${props => props.theme.colors.secondary};
`;

export const ContainerBtn3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Container = styled.div``;

export const ImagePerson = styled.img`
  width: 60px;
  height: 60px;
`;

export const ImageAwards = styled.img`
  width: 192px;
  height: 108px;
`;

export const ImagePartners = styled.img`
  width: 292px;
  height: 108px;
`;

export const ImageOpen = styled.img`
  width: 192px;
  height: 141px;
`;

export const ContainerValues = styled.div`
  width: 270px;
  height: 116px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageValues = styled.img`
  width: 80px;
  height: 80px;
`;
