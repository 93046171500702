import { createContext, useContext, useState } from 'react';
import { AppContext } from '../../../shared/providers/AppProvider';
import { DashboardRepositoryImpl } from '../data/repositories_impl/DashboardRepositoryImpl';
import { DashboardRepository } from '../domain/repositories/DashboardRepository';
import StatisticsState from '../domain/usecases/statistics/StatisticsState';
import StatisticsUsecase from '../domain/usecases/statistics/StatisticsUsecase';

interface IDashboardContext {
  statisticsState: StatisticsState;
  statisticsUsecase: StatisticsUsecase;
  dashboardRepository: DashboardRepository;
}

export const DashboardContext = createContext<IDashboardContext>(
  {} as IDashboardContext
);

export const DashboardProvider = ({ children }: any) => {
  const { apiRepository, adressSharedRepository } = useContext(AppContext);

  const [statisticsState, setStatisticsState] = useState<StatisticsState>(
    new StatisticsState()
  );

  const dashboardRepository = new DashboardRepositoryImpl(apiRepository);

  const statisticsUsecase: StatisticsUsecase = new StatisticsUsecase(
    statisticsState,
    setStatisticsState,
    dashboardRepository,
    adressSharedRepository
  );

  return (
    <DashboardContext.Provider
      value={{
        statisticsState,
        statisticsUsecase,
        dashboardRepository
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
