import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CardReports } from '../../components';
import Loading from '../../components/Loading';
import { getRegions, getReports } from '../../services';
import { ADD_REGIONS, RootState } from '../../store';
import {
  Container,
  HeaderNav,
  HeaderTitle,
  HeaderButton,
  CenterContainer,
  CardWrap
} from './index.style';
import { ModalCriar } from './Modals';

const Dados: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateModal, setCreateModal] = useState(false);

  const [reps, setReps] = useState<any[]>();
  const [region, setRegion] = useState('');
  const page = '1';
  const take = '300';
  const [order, setOrder] = useState('DESC');

  const { token, regions } = useSelector(
    (state: RootState) => state.clickState
  );

  const dispatch = useDispatch();

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  async function requestReports() {
    try {
      setIsLoading(true);

      getReports(
        token,
        order !== '' ? order : undefined,
        page,
        take,
        region !== '' ? region : undefined
      )
        .then(resp => {
          setReps(resp.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (e) {
      alert('Erro ao carregar relatórios!');
      console.error(e);
    }
  }

  useEffect(() => {
    requestReports();
  }, [order, page, take, region]);

  return (
    <Container>
      <HeaderNav>
        <HeaderTitle>Dados - Relatórios</HeaderTitle>

        <HeaderButton
          onClick={() => {
            setCreateModal(true);
          }}
        >
          Novo Relatório
        </HeaderButton>
      </HeaderNav>

      <br />

      <Row>
        <Col xs={6}></Col>
        <Col>
          <Form.Group>
            <Form.Label>Região</Form.Label>
            <Form.Select
              onChange={(e: any) => {
                setRegion(e.target.value);
              }}
            >
              <option value="">Selecione</option>
              {regions?.map((regiao, index) => {
                if (regiao.enabled == true) {
                  return (
                    <option key={index} value={regiao.id}>
                      {regiao.state}
                    </option>
                  );
                }
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Ordenar por</Form.Label>
            <Form.Select
              onChange={(e: any) => {
                setOrder(e.target.value);
              }}
            >
              {[
                { label: 'Descendente', value: 'DESC' },
                { label: 'Ascendente', value: 'ASC' }
              ].map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <br />

      <CenterContainer>
        <CardWrap>
          {isLoading ? (
            <Loading />
          ) : (
            reps?.map((post: any, index: number) => {
              return <CardReports key={index} content={post} />;
            })
          )}
        </CardWrap>
      </CenterContainer>

      <ModalCriar
        isModal={isCreateModal}
        onHide={() => setCreateModal(false)}
        setReps={setReps}
      />
    </Container>
  );
};

export default Dados;
