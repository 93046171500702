import { api } from '../index';

export async function createNotifications(token: string, dados: any) {
  try {
    const resposta = await api.post('/notifications', dados, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Notificações) erro', e);
  }
}

export async function editNotification(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/notifications/${id}`, dados, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Notificações) erro', e);
  }
}

export async function getNotifications(token: string) {
  try {
    const resposta = await api.get('/notifications', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Notificações) erro', e);
  }
}

export async function getNotificationById(token: string, id: string) {
  try {
    const resposta = await api.get(`/notifications/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Notificações) erro', e);
  }
}

export async function deleteNotification(token: string, id: string) {
  try {
    const resposta = await api.delete(`/notifications/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Notificações) erro', e);
  }
}
