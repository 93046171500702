export class RegionModel {
  id: string;
  region: string;
  state: string;
  enabled: boolean;

  constructor({
    id,
    region,
    state,
    enabled
  }: {
    id: string;
    region: string;
    state: string;
    enabled: boolean;
  }) {
    this.id = id;
    this.region = region;
    this.state = state;
    this.enabled = enabled;
  }
}
