import { BasicModel } from './BasicModel';
import { StateModel } from './StateModel';

export class CityModel extends BasicModel {
  state: StateModel;

  constructor({
    id,
    name,
    state
  }: {
    id: string;
    name: string;
    state: StateModel;
  }) {
    super({ id, name });
    this.state = state;
  }
}
