import styled from 'styled-components';
interface IProps {
  active: any;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const Button = styled.button`
  background: none;
  border: none;
  margin-right: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #6e6b7b;
`;

export const Btn = styled(Button)<IProps>`
  ${({ active }) =>
    active &&
    `   
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            letter-spacing: 0.4px;
            color: #2C3941;
            border-bottom: 4px solid #E0733D;
            font-weight: 500;
        `}
`;
