import { api } from '../index';

export async function getAboutApi(token: string) {
  try {
    const resposta = await api.get('/about-api', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Api) erro: ', erro);
    return null;
  }
}

export async function editAboutApi(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/about-api/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Api) erro: ', erro);
    return null;
  }
}
