import { LocalityModel } from '../../domain/models/LocalityModel';
import { BasicEntity } from './BasicEntity';
import CityEntity from './CityEntity';

export default class LocalityEntity extends BasicEntity {
  city: CityEntity = new CityEntity();

  constructor(data?: Partial<LocalityEntity>) {
    super(data);
    this.city = new CityEntity(data?.city);
  }

  toDomain(): LocalityModel {
    return new LocalityModel({
      id: this.id,
      name: this.name,
      city: this.city.toDomain()
    });
  }
}
