import { FileModel } from '../../domain/models/FileModel';

export default class FileEntity {
  id: string = '';
  file: string = '';

  constructor(data?: Partial<FileEntity>) {
    Object.assign(this, data);
  }

  toDomain(): FileModel {
    return new FileModel({
      id: this.id,
      file: this.file
    });
  }
}
