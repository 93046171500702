import styled from 'styled-components';

export const Content = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    margin: 25px 0 12px 0;
  }

  > p {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin-bottom: 25px;
    color: #6e6b7b !important;
  }

  > div {
    width: 70%;
    display: flex;
    justify-content: space-between;

    > button {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 10px 22px;
      color: ${props => props.theme.colors.white};
      border-radius: 5px;
    }

    > button:nth-child(1) {
      background-color: ${props => props.theme.colors.red};
    }

    > button:nth-child(2) {
      background-color: ${props => props.theme.colors.secondary};
    }
  }
`;
