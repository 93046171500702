import { CityModel } from '../../domain/models/CityModel';
import { BasicEntity } from './BasicEntity';
import StateEntity from './StateEntity';

export default class CityEntity extends BasicEntity {
  state: StateEntity = new StateEntity();

  constructor(data?: Partial<CityEntity>) {
    super(data);
    this.state = new StateEntity(data?.state);
  }

  toDomain(): CityModel {
    return new CityModel({
      id: this.id,
      name: this.name,
      state: this.state.toDomain()
    });
  }
}
