import { AuthorModel } from '../../../../shared/domain/models/AuthorModel';
import { FileModel } from '../../../../shared/domain/models/FileModel';
import { RegionModel } from '../../../../shared/domain/models/RegionModel';
import { PostType } from '../../data/entities/PostEntity';

export class PostGroupItemModel {
  image: FileModel;
  description: string;
  content: string;

  constructor({
    image,
    description,
    content
  }: {
    image: FileModel;
    description: string;
    content: string;
  }) {
    this.image = image;
    this.description = description;
    this.content = content;
  }
}

export class PostItemModel {
  group: string;
  group_items: Array<PostGroupItemModel>;
  typeBlock: string;
  quantBlock: string;

  constructor({
    group,
    group_items,
    typeBlock,
    quantBlock
  }: {
    group: string;
    group_items: Array<PostGroupItemModel>;
    typeBlock: string;
    quantBlock: string;
  }) {
    this.group = group;
    this.group_items = group_items;
    this.typeBlock = typeBlock;
    this.quantBlock = quantBlock;
  }
}

export class PostModel {
  id: string;
  title: string;
  author: AuthorModel;
  slug: string;
  region: RegionModel;
  authors: string;
  cover: FileModel;
  thumbnail: FileModel;
  type: PostType = PostType.PUBLISHED;
  emphasis: boolean;
  date_post: string;
  description: string;
  items: Array<PostItemModel>;
  createdAt: string;
  updatedAt: string;

  constructor({
    id,
    title,
    author,
    slug,
    region,
    authors,
    cover,
    thumbnail,
    type,
    emphasis,
    date_post,
    description,
    items,
    createdAt,
    updatedAt
  }: {
    id: string;
    title: string;
    author: AuthorModel;
    slug: string;
    region: RegionModel;
    authors: string;
    cover: FileModel;
    thumbnail: FileModel;
    type: PostType;
    emphasis: boolean;
    date_post: string;
    description: string;
    items: Array<PostItemModel>;
    createdAt: string;
    updatedAt: string;
  }) {
    this.id = id;
    this.title = title;
    this.author = author;
    this.slug = slug;
    this.region = region;
    this.authors = authors;
    this.cover = cover;
    this.thumbnail = thumbnail;
    this.type = type;
    this.emphasis = emphasis;
    this.date_post = date_post;
    this.description = description;
    this.items = items;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}
