import * as Yup from 'yup';
import { BaseForm } from '../../../../shared/domain/forms/BaseForm';
import { EVictimsTypePerson } from '../../../../shared/enums/victimsTypePerson';

export class CreateEditOccurrenceVictimsForm extends BaseForm<CreateEditOccurrenceVictimsForm> {
  id: string = '';
  occurrenceId: string = '';
  type: string = '';
  situation: string = '';
  personType: string = '';
  qualifications: string[] = [];
  name: string = '';
  age: string = '';
  ageGroupId: string = '';
  genreId: string = '';
  race: string = '';
  placeId: string = '';
  circumstances: string[] = [];
  generalObservation: string = '';
  serviceStatusId: string = '';
  partieId: string = '';
  politicalPositionId: string = '';
  politicalStatusId: string = '';
  coorporationId: string = '';
  agentPositionId: string = '';
  agentStatusId: string = '';
  unit: string = '';
  deathDate: string = '';

  validationSchema = ({
    agentQualificationId = '',
    politicalQualificationId = '',
    servicesQualificationsIds = []
  }: {
    agentQualificationId?: string;
    politicalQualificationId?: string;
    servicesQualificationsIds?: string[];
  }) =>
    Yup.object().shape({
      situation: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('situação da vítima')),
      personType: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('tipo de vítima')),
      qualifications: Yup.array()
        .of(Yup.string().nullable())
        .min(1)
        .required(this.requiredDefaultMsg('qualificações')),
      ageGroupId: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('faixa etária')),
      genreId: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('gênero')),
      race: Yup.string().nullable().required(this.requiredDefaultMsg('raça')),
      placeId: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('local')),
      circumstances: Yup.array()
        .of(Yup.string().nullable())
        .min(1)
        .required(this.requiredDefaultMsg('circunstâncias')),
      serviceStatusId: this.yupValidationForService(
        servicesQualificationsIds,
        'status serviço'
      ),
      partieId: this.yupValidationForPolitical(
        politicalQualificationId,
        'partido'
      ),
      politicalPositionId: this.yupValidationForPolitical(
        politicalQualificationId,
        'cargo político'
      ),
      politicalStatusId: this.yupValidationForPolitical(
        politicalQualificationId,
        'status político'
      ),
      coorporationId: this.yupValidationForAgent(
        agentQualificationId,
        'coorporação do agente'
      ),
      agentPositionId: this.yupValidationForAgent(
        agentQualificationId,
        'posição do agente'
      ),
      agentStatusId: this.yupValidationForAgent(
        agentQualificationId,
        'status do agente'
      )
    });

  constructor(data?: Partial<CreateEditOccurrenceVictimsForm>) {
    super();
    Object.assign(this, data);
  }

  yupValidationForService(
    servicesQualificationsIds: string[],
    fieldMsg: string
  ) {
    return Yup.string().when('qualifications', {
      is: (qualifications: string[]) => {
        return qualifications.some(q => servicesQualificationsIds.includes(q));
      },
      then: Yup.string().nullable().required(this.requiredDefaultMsg(fieldMsg)),
      otherwise: Yup.string().nullable()
    });
  }

  yupValidationForPolitical(
    politicalQualificationId: string,
    fieldMsg: string
  ) {
    return Yup.string().when('qualifications', {
      is: (qualifications: string[]) => {
        return qualifications.includes(politicalQualificationId);
      },
      then: Yup.string().nullable().required(this.requiredDefaultMsg(fieldMsg)),
      otherwise: Yup.string().nullable()
    });
  }

  yupValidationForAgent(agentQualificationId: string, fieldMsg: string) {
    return Yup.string().when(['qualifications', 'personType'], {
      is: (qualifications: string[], personType: string) => {
        return (
          qualifications.includes(agentQualificationId) ||
          personType?.toLowerCase() === EVictimsTypePerson.Agent.toLowerCase()
        );
      },
      then: Yup.string().nullable().required(this.requiredDefaultMsg(fieldMsg)),
      otherwise: Yup.string().nullable()
    });
  }
}
