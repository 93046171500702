import React, { useEffect, useState } from 'react';

import ModalCriar from './Modals/Create';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonSubline, TableList } from '../../components';

import purplePerson from '../../assets/purplePerson.png';
import bluePerson from '../../assets/bluePerson.png';
import learnMore from '../../assets/learnMore.png';
import { ADD_USERS } from '../../shared/actions';
import { getUsers } from '../../services/index';
import { RootState } from '../../store/index';

import {
  Nav,
  NewAdmin,
  Card,
  Number,
  Region,
  Select,
  AtualPage,
  PrevNext,
  ContainerBtn,
  Container,
  Header,
  ContainerBoxes,
  Box,
  FilterContainer,
  Search,
  Image,
  LoadingContainer
} from './style';
import { lupa } from '../../assets';
import Loading from '../../components/Loading';

enum UsersScreenType {
  APP,
  PLATFORM
}

interface IResultMetadata {
  total: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const Users: React.FC = () => {
  const nameOptions = ['Aplicativo', 'Plataforma'];

  let plataformRegions = [
    { label: 'Moderadores', numbers: 10, img: `${bluePerson}` },
    { label: 'Região Nordeste', numbers: 5, img: '' },
    { label: 'Região Sudeste', numbers: 5, img: '' },
    { label: 'Região Sul', numbers: 0, img: '' },
    { label: 'Região Norte', numbers: 0, img: '' },
    { label: 'Região Centro-oeste', numbers: 0, img: '' }
  ];

  let appRegions = [
    { label: 'Total de usuários', numbers: 50, img: `${purplePerson}` },
    { label: 'Região Nordeste', numbers: 30, img: '' },
    { label: 'Região Sudeste', numbers: 20, img: '' },
    { label: 'Região Sul', numbers: 0, img: '' },
    { label: 'Região Norte', numbers: 0, img: '' },
    { label: 'Região Centro-oeste', numbers: 0, img: '' }
  ];

  const [usersScreenType, setUsersScreenType] = useState<UsersScreenType>(
    UsersScreenType.APP
  );

  const [openCreateUserModal, setOpenCreateUserModal] = useState(false);

  const { token, users } = useSelector((state: RootState) => state.clickState);

  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [order, setOrder] = useState('ASC');

  const [resultMetada, setResultMetada] = useState<IResultMetadata>({
    total: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllUsers();
  }, [page, order, name, usersScreenType]);

  async function getAllUsers() {
    setIsLoading(true);
    await getUsers(
      token,
      page,
      50,
      order,
      name,
      '',
      usersScreenType === UsersScreenType.APP
        ? ['Mobile']
        : ['Administrador', 'Coordenador', 'Regional']
    ).then(resp => {
      dispatch({ type: ADD_USERS, users: resp.data });
      setResultMetada({
        total: resp.meta.itemCount,
        hasNextPage: resp.meta.hasNextPage,
        hasPreviousPage: resp.meta.hasPreviousPage
      });
    });
    setIsLoading(false);
  }

  return (
    <Container>
      <Header>
        <Nav>
          <ButtonSubline
            list={nameOptions}
            onClick={nameScreen => {
              nameScreen === 'Aplicativo'
                ? setUsersScreenType(UsersScreenType.APP)
                : setUsersScreenType(UsersScreenType.PLATFORM);

              setPage(1);
            }}
          />
        </Nav>
        {usersScreenType === UsersScreenType.PLATFORM && (
          <NewAdmin onClick={() => setOpenCreateUserModal(true)}>
            Adicionar moderador
          </NewAdmin>
        )}
      </Header>

      <ContainerBoxes>
        <Box>
          {usersScreenType === UsersScreenType.APP
            ? appRegions?.map(region => {
                return (
                  <Card>
                    <div>
                      <Number>{region.numbers}</Number>
                      <Region>{region.label}</Region>
                    </div>
                    {region.img ? <Image src={region.img} /> : null}
                  </Card>
                );
              })
            : plataformRegions?.map(region => {
                return (
                  <Card>
                    <div>
                      <Number>{region.numbers}</Number>
                      <Region>{region.label}</Region>
                    </div>
                    {region.img ? <Image src={region.img} /> : null}
                  </Card>
                );
              })}
        </Box>
        <FilterContainer>
          <Search>
            <label htmlFor="search">
              <img src={lupa} width={15} />
            </label>
            <input
              type="search"
              name="search"
              value={name}
              onChange={(e: any) => setName(e?.target?.value)}
              id="search"
              placeholder="Pesquisar"
            />
          </Search>
          <Select
            value={order}
            style={{
              height: '50px'
            }}
            onChange={(e: any) => setOrder(e?.target?.value)}
          >
            <option value="ASC">Ascendente</option>
            <option value="DESC">Descendente</option>
          </Select>
        </FilterContainer>

        {isLoading ? (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        ) : usersScreenType === UsersScreenType.APP ? (
          <TableList users={users} role="Mobile" />
        ) : (
          <TableList users={users} role="Administrador" />
        )}
      </ContainerBoxes>

      <ContainerBtn>
        <PrevNext
          to="prev"
          onClick={() => {
            if (resultMetada.hasPreviousPage && page > 1) {
              let cont = page - 1;
              setPage(cont);
            }
          }}
          disabled={!resultMetada.hasPreviousPage}
        >
          <Image src={learnMore} alt="" />
        </PrevNext>
        <AtualPage>{page}</AtualPage>
        <PrevNext
          to="next"
          onClick={() => {
            if (resultMetada.hasNextPage) {
              let cont = page + 1;
              setPage(cont);
            }
          }}
          disabled={!resultMetada.hasNextPage}
        >
          <Image src={learnMore} alt="" />
        </PrevNext>
      </ContainerBtn>

      <ModalCriar
        isModal={openCreateUserModal}
        onHide={() => setOpenCreateUserModal(false)}
      />
    </Container>
  );
};

export default Users;
