import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin-bottom: 24px;
  }

  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: ${props => props.theme.colors.red};
  }

  > h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #6e6b7b;
  }

  > p {
    font-style: normal;
    font-weight: normal;
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 10px 22px;
      color: ${props => props.theme.colors.white};
      border: none;
      border-radius: 6px;
    }

    > button:nth-child(1) {
      background-color: ${props => props.theme.colors.red};
    }

    > button:nth-child(2) {
      background-color: ${props => props.theme.colors.secondary};
    }
  }
`;
