import styled from 'styled-components';

export const Container = styled.div``;

export const DivHeaderTitle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  b,
  strong {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
    color: #6e6b7b !important;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 800px;
  margin-bottom: 40px;

  > div {
    > button {
      background: none;
      border: none;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.4px;
      color: #6e6b7b;
      margin-right: 24px;

      :focus {
        border-bottom: 4px solid ${props => props.theme.colors.secondary};
        font-weight: 500;
        letter-spacing: 0.4px;
        color: #2c3941;
      }
    }
  }
`;

export const Header = styled.header`
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  border-radius: 6px 6px 0px 0px;
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h3 {
    color: ${props => props.theme.colors.white};
  }

  > button {
    background: none;
    border: 2px solid ${props => props.theme.colors.white};
    border-radius: 6px;
    padding: 11px 33px;
    display: flex;
    justify-content: center;
    align-items: center;

    > p {
      color: ${props => props.theme.colors.white};
      padding: 0 !important;
      margin: 0 !important;
      font-weight: 500 !important;
      font-size: 16px;
      line-height: 17px;
      letter-spacing: 0.4px;
    }
  }
`;

export const Section = styled.section`
  padding: 20px 24px;
  display: flex;
`;

export const ContentImg = styled.div`
  display: flex;

  > img {
    margin-right: 10px;
  }
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
`;

export const Card = styled.div`
  width: 239px;
  height: 170px;
  background: #f8f8f8;
  border: 1px solid #ebe9f1;

  > h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #2c3941;
    margin: 14px 8px 0 12px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
  }
`;

export const Data = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6b7b;
`;

export const Partners = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px 20px;

  > a {
    text-decoration: none;
    width: 200px !important;
    height: 75px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));

    > img {
      width: 158.38px;
      height: 25.69px;
    }
  }
`;

export const Content = styled.div`
  > div {
    display: flex;
    align-items: flex-start;

    > div {
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-left: 10px;
      > p {
        margin: 0 !important;
        font-family: 'Montserrat' !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        font-style: normal;
      }
    }
  }
`;

export const NoStyle = styled.div`
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #2c3941;
  }
`;

export const NoStyleTwo = styled.div`
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #2c3941;
  }
`;

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
`;
