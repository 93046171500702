import React from 'react';
import { IProps } from './index.types';
import { Container } from './index.style';
import { close, ss } from '../../../assets';

const ModalSucesso: React.FC<IProps> = ({
  onDismiss = () => {},
  title,
  description
}) => {
  function closeModal() {
    onDismiss();
  }

  return (
    <>
      <Container>
        <button onClick={closeModal}>
          <img src={close} alt="" />
        </button>
        <div>
          <img src={ss} alt="" />
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </Container>
    </>
  );
};

export default ModalSucesso;
