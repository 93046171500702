import styled from 'styled-components';
import { check, close } from '../../assets';

export const ModalContent = styled.div`
  width: 404px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 29px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.white};
`;

export const ModalCheckIcon = styled.img.attrs({
  src: check
})`
  height: 60px;
  width: 60px;
  margin-bottom: 24px;
`;

export const ModalCloseIcon = styled.img.attrs({
  src: close
})`
  height: 20px;
  width: 20px;
`;

export const ModalTitle = styled.h3`
  color: ${props => props.theme.colors.success};
  font-weight: 500;
  font-size: 28px;

  margin-bottom: 12px;
`;

export const ModalSubtitle = styled.p`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
`;

export const ModalCloseButton = styled.button`
  height: 34px;
  width: 34px;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0001);
  align-self: flex-end;
  margin-top: -40px;
  margin-right: -29px;
  border: none;
`;
