import styled from 'styled-components';
import { PropsDev } from './index.types';

export const CardImage = styled.div<PropsDev>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #f8f8f8;
  border-radius: 5px;
  width: 100%;
  padding: 16px;
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ImageStyled = styled.img<PropsDev>`
  max-height: 24px;
  margin-right: 16px;
`;

export const ButtonDelete = styled.button<PropsDev>`
  text-decoration: none;
  color: transparent;
  border: none;
  background-color: transparent;
  margin-left: 16px;

  > img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return `
                top: 0px !important;
                right: 0px  !important;
            `;
    }
  }}
`;

export const ButtonUpload = styled.button<PropsDev>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #f8f8f8;
  border: 0.5px dashed #6e6b7b;

  width: 100%;
  height: 315px;

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return ` 
                width: ${props.width}px !important;
                height: ${props.height}px !important; 

            `;
    }
  }}
`;
