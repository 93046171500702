import React, { useContext, useEffect, useState } from 'react';
import { Container, Person, Item } from './index.styled';
import ImgTop from './ImgTop';
import Mensagem from './mensagem.svg';
import Sino from './sino.svg';
import Linha from './linha.svg';
import Logout from './log-out.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_LOGIN_USER, ADD_TOKEN, RootState } from '../../../store';
import { Image } from 'react-bootstrap';
import { logout } from '../../../services';
import { ModalAlert } from './Modals';
import { AppContext } from '../../../shared/providers/AppProvider';

const NavbarHeader: React.FC = ({ children }) => {
  const [messagesValue, setMessagesValue] = useState(0);
  const { appUsecase } = useContext(AppContext);
  const [nome, setNome] = useState('');
  const [tipo, setTipo] = useState('');

  const { user, alert } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  useEffect(() => {
    setMessagesValue(4);
    setNome(user.name);
    setTipo(user.role);
  }, []);

  const [isModalAlert, setModalAlert] = useState(false);

  return (
    <Container>
      <Item
        style={{ marginLeft: '20px' }}
        onClick={() => {
          setModalAlert(true);
        }}
      >
        <ImgTop src={Sino} value={alert} />
      </Item>

      {/* <Item to=""style={{marginLeft: '20px'}}>
                <ImgTop src={Mensagem} value={messagesValue} />
            </Item> */}
      <Person>
        <p> {nome} </p>
        <p> {tipo} </p>
      </Person>

      <Image src={Linha} style={{ marginLeft: '20px' }} />

      <Item
        onClick={() => {
          dispatch({ type: ADD_LOGIN_USER, user: {} });
          dispatch({ type: ADD_TOKEN, token: '' });
          appUsecase.setToken('');
          logout();
        }}
      >
        <Image src={Logout} style={{ marginLeft: '20px' }} />
      </Item>

      <ModalAlert
        isModal={isModalAlert}
        onHide={() => {
          setModalAlert(false);
        }}
      />
    </Container>
  );
};

export default NavbarHeader;
