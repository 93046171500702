import React from 'react';
import { Modal } from 'react-bootstrap';
import { RowStyled, ColumnStyled } from '../index';
import {
  CardStyled,
  HeaderStyled,
  BodyStyled,
  BtnEdit,
  BtnCancel,
  BtnSaveEdit,
  DivHeaderTitle,
  BtnEditSingle
} from './index.style';
import { editWhiteIcon, saveHome } from '../../assets';
import { IProps } from './index.types';

const BlueComponent: React.FC<IProps> = ({
  isModal,
  isShow,
  onHide,
  btnEdit,
  onClickEdit,
  titleComponent,
  onClickCancel,
  onClickSave,
  children,
  titleS
}) => {
  return (
    <>
      {isModal == false ? (
        <CardStyled>
          <HeaderStyled btnEdit={btnEdit}>
            <DivHeaderTitle>
              <div>{titleComponent}</div>
            </DivHeaderTitle>
            {btnEdit == true && titleS != 'redes' && (
              <BtnEdit
                onClick={() => {
                  onClickEdit();
                }}
              >
                <img
                  src={editWhiteIcon}
                  width={20}
                  height={20}
                  style={{ marginRight: 8 }}
                />
                <span>Editar</span>
              </BtnEdit>
            )}
            {btnEdit == true && titleS == 'redes' && (
              <BtnEditSingle
                onClick={() => {
                  onClickEdit();
                }}
              >
                <img
                  src={editWhiteIcon}
                  width={20}
                  height={20}
                  style={{ marginRight: 8 }}
                />
              </BtnEditSingle>
            )}
          </HeaderStyled>
          <BodyStyled>{children}</BodyStyled>
        </CardStyled>
      ) : (
        <Modal show={isShow} onHide={onHide} fullscreen>
          <Modal.Body>
            <CardStyled>
              <HeaderStyled btnEdit={btnEdit}>
                <DivHeaderTitle>{titleComponent}</DivHeaderTitle>
                {btnEdit == true && (
                  <BtnEdit
                    onClick={() => {
                      onClickEdit();
                    }}
                  >
                    <img
                      src={editWhiteIcon}
                      width={20}
                      height={20}
                      style={{ marginRight: 8 }}
                    />
                    <span>Editar</span>
                  </BtnEdit>
                )}
              </HeaderStyled>
              <BodyStyled>
                {children}

                <RowStyled spaceBetween={true}>
                  <ColumnStyled width={203}>
                    <BtnCancel
                      onClick={() => {
                        onClickCancel();
                      }}
                    >
                      Cancelar
                    </BtnCancel>
                  </ColumnStyled>
                  <ColumnStyled width={203}>
                    <BtnSaveEdit
                      onClick={() => {
                        onClickSave();
                      }}
                    >
                      <img
                        src={saveHome}
                        width={20}
                        height={20}
                        style={{ marginRight: 8 }}
                      />
                      Salvar edição
                    </BtnSaveEdit>
                  </ColumnStyled>
                </RowStyled>
              </BodyStyled>
            </CardStyled>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default BlueComponent;
