import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BlueComponent, ImgUpEdit, ModalSucesso } from '../../../../components';
import {
  editSocialNetworks,
  getImageById,
  getImageSocialMedia,
  getRegions,
  getSocialNetworks
} from '../../../../services';
import {
  ADD_REGIONS,
  ADD_SOCIAL_NETWORKS,
  GET_IMAGE_SOCIAL_MEDIA,
  RootState
} from '../../../../store';
import { IProps } from './index.types';
import { ImgUp } from '../../../../components';
import { Column1, Column2, ImageIcon, Line } from './index.styled';
import { imgIcon } from '../../../../assets';

const ModalEditarImagem: React.FC<IProps> = ({ isModal, onHide }) => {
  const { register, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: {
      id: '',
      file: ''
    }
  });

  const { token, regions, image_sm } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getImageById(token, '6ad5ac7d-a6a0-4dfa-b140-5d6ea20e6695').then(resp => {
      console.log(resp, 'imagems');
      dispatch({ type: GET_IMAGE_SOCIAL_MEDIA, image_sm: resp });
      reset(resp);
    });
  }, [isModal]);

  const referencia = useRef<any>();

  function onSubmit(dados: any) {
    console.log(dados);
    onHide();
    setVisible(true);
  }

  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          referencia.current.click();
        }}
        titleComponent={<div>Editar redes</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Line>
            <Column1>
              <ImageIcon src={imgIcon} />
            </Column1>
            <Column2>
              <Form.Group>
                <Form.Label>Imagem em destaque</Form.Label>
                <ImgUpEdit
                  getValues={getValues}
                  setValue={setValue}
                  id={`id`}
                  file={`file`}
                />
              </Form.Group>
            </Column2>
          </Line>
          <br />

          <button type="submit" style={{ display: 'none' }} ref={referencia}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Imagem da página alterada"
        description="Imagem da página foi alterada com sucesso."
      />
    </>
  );
};

export default ModalEditarImagem;
