import { PostModel } from '../../../../domain/models/PostModel';

export type typeContent = {
  title: string;
  author: {
    id: string;
    name: string;
    nickname: string;
    email: string;
  };
  region: {
    id: string;
    region: string;
    state: string;
    enabled: false;
  };
  cover: {
    id: string;
    file: string;
  };
  items: [
    {
      image: {
        id: string;
        file: string;
      };
      description: string;
      group: string;
    }
  ];
  comments?: any;
  createdAt: string;
  updatedAt: string;
  id: string;
  slug: string;
};

export enum EHandlePostModalType {
  CREATE = 'CREATE',
  EDIT = 'EDIT'
}

export type IProps = {
  isModal: boolean;
  onHide: () => void;
  modalType: EHandlePostModalType;
  callBackPostAction?: () => void;
  postContent?: PostModel;
};
