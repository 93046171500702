import { RaceModel } from '../../domain/models/RaceModel';

export class RaceEntity {
  raceList: string[] = [];

  constructor(data?: string[]) {
    this.raceList = data || [];
  }

  toDomain(): RaceModel {
    return new RaceModel({
      raceList: this.raceList
    });
  }
}
