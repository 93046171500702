import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { GenreModel } from '../../domain/models/GenreModel';

export class GenreEntity extends BasicEntity {
  constructor(data?: Partial<GenreEntity>) {
    super(data);
  }

  toDomain(): GenreModel {
    return new GenreModel({
      id: this.id,
      name: this.name
    });
  }
}
