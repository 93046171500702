import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavbarHeader, NavbarLeft } from '../../components';
import {
  deleteNotification,
  editNotification,
  getNotifications
} from '../../services';
import { ADD_NOTIFICATIONS, RootState } from '../../store';
import {
  ContainerStyled,
  BtnOrange,
  MenuContainer,
  TitleMenu,
  N,
  NHeader,
  NBody,
  NHeaderCreatedAt,
  NHeaderName,
  NHeaderScheduling,
  NHeaderTag,
  NHeaderTags,
  NLeftHeader,
  NRightHeader,
  ImageNotification,
  ButtonSend,
  ButtonEdit,
  ButtonDelte
} from './index.styled';
import { ModalApagar, ModalCriar, ModalEditar } from './Modals';
import moment from 'moment';
import {
  editBlack,
  calendarGreenNotification,
  calendarNotification,
  calendarOrangeNotification,
  personNotification,
  trash,
  send,
  trashGray,
  editGray
} from '../../assets';
import { convertToDateAndHours } from '../../utils/date';

const Notifications: React.FC = ({}) => {
  const [isModalCriar, setModalCriar] = useState(false);
  const { token, notifications } = useSelector(
    (state: RootState) => state.clickState
  );
  let dispatch = useDispatch();

  useEffect(() => {
    getNotifications(token).then(resposta => {
      dispatch({ type: ADD_NOTIFICATIONS, notifications: resposta });
    });
  }, []);

  const [isModalEditar, setModalEditar] = useState(false);
  const [isModalApagar, setModalApagar] = useState(false);
  const [idAux, setIdAux] = useState('');
  const [content, setContent] = useState<any>();

  return (
    <ContainerStyled>
      <MenuContainer>
        <TitleMenu>Notificações push</TitleMenu>

        <BtnOrange onClick={() => setModalCriar(true)}>
          Criar notificação
        </BtnOrange>
      </MenuContainer>

      <br />

      {notifications?.map(chave => {
        return (
          <N>
            <NHeader>
              <NLeftHeader>
                <NHeaderTags>
                  {chave?.regions?.map((id: any) => {
                    return <NHeaderTag>{id.state}</NHeaderTag>;
                  })}
                </NHeaderTags>
              </NLeftHeader>
              <NRightHeader>
                <NHeaderCreatedAt>
                  <div>
                    <ImageNotification src={calendarNotification} />
                  </div>
                  <div>
                    <h4>Criado em</h4>
                    <p>{convertToDateAndHours(chave.createdAt, true)}</p>
                  </div>
                </NHeaderCreatedAt>
                <NHeaderName>
                  <div>
                    <ImageNotification src={personNotification} />
                  </div>
                  <div>
                    <h4>Criado por</h4>
                    <p>{chave?.admin?.name}</p>
                  </div>
                </NHeaderName>
                <NHeaderScheduling>
                  <div>
                    <ImageNotification
                      src={
                        chave.scheduled == true
                          ? calendarOrangeNotification
                          : calendarGreenNotification
                      }
                    />
                  </div>
                  <div>
                    <h4>
                      {chave.scheduled && chave.delivered == false
                        ? 'Agendado para'
                        : 'Enviado em'}
                    </h4>
                    <p>
                      {chave.scheduled && chave.delivered == false
                        ? convertToDateAndHours(chave.scheduling, true)
                        : convertToDateAndHours(chave.updatedAt, true)}
                    </p>
                  </div>
                </NHeaderScheduling>
              </NRightHeader>
            </NHeader>
            <NBody>
              <div className="left">
                <h4>{chave.title}</h4>
                <p>{chave.message}</p>
              </div>
              <div className="right">
                <ButtonSend
                  disabled={
                    chave.scheduled &&
                    !chave.delivered &&
                    chave.status !== 'Finished'
                      ? false
                      : true
                  }
                  onClick={() => {
                    let aux: any = {
                      ...chave,
                      scheduled: false,
                      scheduling: null,
                      status: 'Finished',
                      delivered: true,
                      admin: chave.admin?.id
                    };

                    editNotification(token, aux?.id, aux).then(resp => {
                      getNotifications(token).then(resposta => {
                        dispatch({
                          type: ADD_NOTIFICATIONS,
                          notifications: resposta
                        });
                      });
                    });
                  }}
                >
                  <img src={send} />
                  <span
                    style={{
                      marginLeft: '8px'
                    }}
                  >
                    Enviar agora
                  </span>
                </ButtonSend>
                <div>
                  <ButtonEdit
                    disabled={
                      chave.scheduled && chave.status !== 'Finished'
                        ? false
                        : true
                    }
                    onClick={() => {
                      setModalEditar(true);
                      setContent(chave);
                    }}
                  >
                    {chave.scheduled ? (
                      <img src={editBlack} />
                    ) : (
                      <img src={editGray} />
                    )}
                    Editar
                  </ButtonEdit>
                  <ButtonDelte
                    // disabled={chave.scheduled == true ? true : false}
                    onClick={() => {
                      setModalApagar(true);
                      setIdAux(chave.id);
                    }}
                  >
                    <img src={trash} />
                  </ButtonDelte>
                </div>
              </div>
            </NBody>
          </N>
        );
      })}

      <ModalCriar isModal={isModalCriar} onHide={() => setModalCriar(false)} />

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setModalEditar(false)}
        content={content}
      />

      <ModalApagar
        isModal={isModalApagar}
        onHide={() => setModalApagar(false)}
        id={idAux}
      />
    </ContainerStyled>
  );
};

export default Notifications;
