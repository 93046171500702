import React, { useEffect, useMemo } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import Select from 'react-select';

type TFormValues = UseControllerProps<any> & {
  options?: {
    id?: string;
    name?: string;
    title?: string;
    label?: string;
    value?: any;
    type?: string;
  }[];
  [key: string]: any;
};

const CustomControllerSelect: React.FC<TFormValues> = ({
  options,
  ...props
}) => {
  const { field } = useController(props);
  const _options = useMemo(() => {
    return options;
  }, [options]);

  return (
    <>
      <Select
        isMulti
        options={_options?.map(item => ({
          value: item?.id || item?.value,
          label: item?.name || item?.title || item?.label,
          type: item?.type
        }))}
        value={field.value?.map((item: any) => ({
          value: item.id || item.value,
          label: item.name || item.title || item.label,
          type: item?.type
        }))}
        onBlur={field.onBlur}
        onChange={e => {
          const values = e?.map(({ value, label, type }) => ({
            id: value,
            label,
            type
          }));
          field.onChange(values);
        }}
        {...props}
      />
    </>
  );
};

export default CustomControllerSelect;
