import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  padding: 16px;
`;

export const CardWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 80px;
  display: grid;
  gap: 50px 50px;
  grid-template-columns: auto auto auto;
  height: auto;
`;

export const CenterContainer = styled.div``;

export const NavHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2c3941;
`;

export const ButtonNav = styled.button`
  border: none;
  color: #fff;
  padding: 14px;
  background: ${props => props.theme.colors.secondary};
  border-radius: 6px;
`;

export const NavIcons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

interface PropsBtn {
  enabled: boolean;
  onClick: () => void;
}

export const NavBtn = styled.button<PropsBtn>`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #6e6b7b;
  margin-right: 8px;

  background-color: transparent;
  border: none;

  &:hover {
    border-bottom: 4px solid orange;
    font-weight: 700;
  }

  ${props => {
    if (props.enabled == true) {
      return `
                border-bottom: 4px solid orange;
                font-weight: 700;
            `;
    }
  }}
`;
