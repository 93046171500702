import { AdressSharedRepository } from '../../../../../shared/domain/repositories/AdressSharedRepository';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { UseContextNotifier } from '../../../../../shared/types/UseContextNotifier';
import { DashboardRepository } from '../../repositories/DashboardRepository';
import StatisticsState from './StatisticsState';

export default class StatisticsUsecase extends UseContextNotifier<StatisticsState> {
  dashboardRepository: DashboardRepository;
  adressSharedRepository: AdressSharedRepository;

  constructor(
    state: StatisticsState = new StatisticsState(),
    setState: React.Dispatch<React.SetStateAction<StatisticsState>>,
    dashboardRepository: DashboardRepository,
    adressSharedRepository: AdressSharedRepository
  ) {
    super(state, setState);
    this.dashboardRepository = dashboardRepository;
    this.adressSharedRepository = adressSharedRepository;
  }

  public async getRegions() {
    this.updateState({
      ...this.state,
      regionsRequestStatus: RequestStatus.loading()
    });

    let resp = await this.adressSharedRepository.getRegions();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          regionsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          regionsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getCities() {
    this.updateState({
      ...this.state,
      citiesRequestStatus: RequestStatus.loading()
    });

    let resp = await this.adressSharedRepository.getCities();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          citiesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          citiesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getNeighborhoods() {
    this.updateState({
      ...this.state,
      neighborhoodsRequestStatus: RequestStatus.loading()
    });

    let resp = await this.adressSharedRepository.getNeighborhoods();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          neighborhoodsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          neighborhoodsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getStatistics() {
    this.updateState({
      ...this.state,
      statisticsRequestStatus: RequestStatus.loading()
    });

    let resp = await this.dashboardRepository.getStatistics(
      this.state.statisticsQueryParams
    );

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          statisticsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          statisticsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getAnnualStatistics() {
    this.updateState({
      ...this.state,
      annualStatisticsRequestStatus: RequestStatus.loading()
    });

    let resp = await this.dashboardRepository.getAnnualStatistics(
      this.state.annualStatisticsQueryParams
    );

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          annualStatisticsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          annualStatisticsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public setInitialData(data: string) {
    this.state.statisticsQueryParams.initialdate = data;

    this.updateState({
      ...this.state,
      statisticsQueryParams: {
        ...this.state.statisticsQueryParams,
        initialdate: data
      }
    });
  }

  public setFinalData(data: string) {
    this.state.statisticsQueryParams.finaldate = data;

    this.updateState({
      ...this.state,
      statisticsQueryParams: {
        ...this.state.statisticsQueryParams,
        finaldate: data
      }
    });
  }

  public setRegion(region: string) {
    this.state.statisticsQueryParams.region = region;

    this.updateState({
      ...this.state,
      statisticsQueryParams: {
        ...this.state.statisticsQueryParams,
        region: region
      }
    });
  }

  public setCities(cities: { label: string; value: string }[]) {
    this.state.statisticsQueryParams.cities = cities;

    this.updateState({
      ...this.state,
      statisticsQueryParams: {
        ...this.state.statisticsQueryParams,
        cities: cities
      }
    });
  }

  public setNeighborhoods(
    neighborhoods: { value: string; label: string; cityId: string }[]
  ) {
    this.state.statisticsQueryParams.neighborhoods = neighborhoods;

    this.updateState({
      ...this.state,
      statisticsQueryParams: {
        ...this.state.statisticsQueryParams,
        neighborhoods: neighborhoods
      }
    });
  }

  public resetStatisticsFilter() {
    const { statisticsQueryParams, annualStatisticsQueryParams } = this.state;

    statisticsQueryParams.cities = [];
    statisticsQueryParams.region = '';
    statisticsQueryParams.neighborhoods = [];
    statisticsQueryParams.initialdate = `${new Date().getFullYear()}-01-01`;
    statisticsQueryParams.finaldate = new Date().toISOString().slice(0, 10);

    annualStatisticsQueryParams.year = String(new Date().getFullYear());
  }

  public setYear(year: string) {
    this.state.annualStatisticsQueryParams.year = year;

    this.updateState({
      ...this.state,
      annualStatisticsQueryParams: {
        ...this.state.annualStatisticsQueryParams,
        year: year
      }
    });
  }
}
