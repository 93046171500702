import { Divider } from '../../../../../../../shared/styles';
import { FormContainer, FormHeader } from './index.styled';

const FormBody: React.FC<{
  title: string;
  description: string;
  children: React.ReactNode;
}> = ({ title, description, children }) => {
  return (
    <FormContainer>
      <FormHeader>
        <h3>{title}</h3>
        <p>{description}</p>
      </FormHeader>
      <Divider height={12} />
      {children}
    </FormContainer>
  );
};

export default FormBody;
