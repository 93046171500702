import { AdressSharedRepository } from '../../../../../shared/domain/repositories/AdressSharedRepository';
import { EOccurrenceStatus } from '../../../../../shared/enums/ocurrenceStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { UseContextNotifier } from '../../../../../shared/types/UseContextNotifier';
import { QualificationType } from '../../../data/entities/QualificationEntity';
import { CreateEditOccurrenceAnimalVictimsForm } from '../../forms/CreateEditOccurrenceAnimalVictimsForm';
import { CreateEditOccurrenceContextInfoForm } from '../../forms/CreateEditOccurrenceContextInfoForm';
import { CreateEditOccurrenceDetailsForm } from '../../forms/CreateEditOccurrenceDetailsForm';
import { CreateEditOccurrenceTransportForm } from '../../forms/CreateEditOccurrenceTransportForm';
import { CreateEditOccurrenceVictimsForm } from '../../forms/CreateEditOccurrenceVictimsForm';
import { CreateEditOccurrenceModel } from '../../models/CreateEditOccurrenceModel';
import { AgeGroupModel } from '../../models/AgeGroupModel';
import { QualificationModel } from '../../models/QualificationModel';
import { ReasonModel } from '../../models/ReasonModel';
import { OccurrenceRepository } from '../../repositories/OccurrenceRepository';
import CreateEditOccurrenceState from './CreateEditOccurrenceState';
import { CreateEditOccurrenceEntity } from '../../../data/entities/CreateEditOccurrenceEntity';
import { EVictimsTypePerson } from '../../../../../shared/enums/victimsTypePerson';
import { EVictimsSituation } from '../../../../../shared/enums/victimsSituation';
import { ERequestStatus } from '../../../../../shared/enums/requestStatus';

export default class CreateEditOccurrenceUsecase extends UseContextNotifier<CreateEditOccurrenceState> {
  adressSharedRepository: AdressSharedRepository;
  occurrenceRepository: OccurrenceRepository;

  constructor(
    state: CreateEditOccurrenceState = new CreateEditOccurrenceState(),
    setState: React.Dispatch<React.SetStateAction<CreateEditOccurrenceState>>,
    adressSharedRepository: AdressSharedRepository,
    occurrenceRepository: OccurrenceRepository
  ) {
    super(state, setState);
    this.adressSharedRepository = adressSharedRepository;
    this.occurrenceRepository = occurrenceRepository;
  }

  delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  formatDate = (date: Date) => {
    return date.toISOString()?.slice(0, 16);
  };

  public resetState() {
    let state = new CreateEditOccurrenceState();

    this.updateState(state);
  }

  public async init(id?: string) {
    if (id) {
      this.updateState({
        ...this.state,
        occurrenceRequestStatus: RequestStatus.loading()
      });
    } else {
      this.updateState({
        ...this.state,
        preparingDataRequestStatus: RequestStatus.loading()
      });
    }

    let requests = [
      await this.getStates(),
      await this.getSources(),
      await this.getReasons(),
      await this.getClippings(),
      await this.getTransports(),
      await this.getQualifications(),
      await this.getAgeGroups(),
      await this.getGenres(),
      await this.getRaces(),
      await this.getPlaces(),
      await this.getCircumstances(),
      await this.getStatus(),
      await this.getPositions(),
      await this.getCoorporations(),
      await this.getParties(),
      await this.getAnimals()
    ];

    await Promise.all(requests)
      .then(async _ => {
        if (id) {
          await this.getOccurrenceForEdit(id);
        } else {
          this.updateState({
            ...this.state,
            preparingDataRequestStatus: RequestStatus.succeeded(_)
          });
        }
      })
      .catch(_ => {});
  }

  public async getOccurrenceForEdit(id: string) {
    try {
      this.updateState({
        ...this.state,
        occurrenceRequestStatus: RequestStatus.loading()
      });

      const resp = await this.occurrenceRepository.getOccurrenceById(id);

      await resp.handle({
        onSuccess: async data => {
          await this.getDataFieldsForEdit(data);

          let victimsFormPopulate: CreateEditOccurrenceVictimsForm[] = [];
          if (
            data.status === EOccurrenceStatus.Waiting &&
            data.victims.length <= 0
          ) {
            if (data.numberCiviliansDead > 0) {
              for (let i = 0; i < data.numberCiviliansDead; i++) {
                victimsFormPopulate.push(
                  new CreateEditOccurrenceVictimsForm({
                    personType: EVictimsTypePerson.Civilian,
                    situation: EVictimsSituation.Dead
                  })
                );
              }
            }
            if (data.numberCiviliansWounded > 0) {
              for (let i = 0; i < data.numberCiviliansWounded; i++) {
                victimsFormPopulate.push(
                  new CreateEditOccurrenceVictimsForm({
                    personType: EVictimsTypePerson.Civilian,
                    situation: EVictimsSituation.Wounded
                  })
                );
              }
            }
            if (data.numberAgentDead > 0) {
              for (let i = 0; i < data.numberAgentDead; i++) {
                victimsFormPopulate.push(
                  new CreateEditOccurrenceVictimsForm({
                    personType: EVictimsTypePerson.Agent,
                    situation: EVictimsSituation.Dead,
                    qualifications: [this.state.agentQualification.id]
                  })
                );
              }
            }
            if (data.numberAgentWounded > 0) {
              for (let i = 0; i < data.numberAgentWounded; i++) {
                victimsFormPopulate.push(
                  new CreateEditOccurrenceVictimsForm({
                    personType: EVictimsTypePerson.Agent,
                    situation: EVictimsSituation.Wounded,
                    qualifications: [this.state.agentQualification.id]
                  })
                );
              }
            }
          } else {
            victimsFormPopulate = data.victims.map(
              victim =>
                new CreateEditOccurrenceVictimsForm({
                  ...victim,
                  age: victim.age?.toString(),
                  deathDate: victim?.deathDate
                    ? this.formatDate(victim?.deathDate)
                    : ''
                })
            );
          }

          let populateForms = {
            ...this.state,
            editableOccurrenceData: data,
            occurrenceRequestStatus: RequestStatus.succeeded(data),
            detailsForm: new CreateEditOccurrenceDetailsForm({
              ...data,
              cityId: this.state.citiesRequestStatus.maybeMap({
                succeeded: cities =>
                  cities?.some(city => city.id === data.cityId)
                    ? data.cityId
                    : '',
                idle: () => '',
                loading: () => '',
                failed: () => ''
              }),
              documentNumber: data?.documentNumber?.toString(),
              date: data?.date ? this.formatDate(data?.date) : ''
            }),
            contextInfoForm: new CreateEditOccurrenceContextInfoForm({
              ...data.contextInfo
            }),
            transportsForm: data.transports.map(
              transport =>
                new CreateEditOccurrenceTransportForm({
                  ...transport,
                  dateInterruption: transport?.dateInterruption
                    ? this.formatDate(transport?.dateInterruption)
                    : '',
                  releaseDate: transport?.releaseDate
                    ? this.formatDate(transport?.releaseDate)
                    : ''
                })
            ),
            victimsForm: victimsFormPopulate,
            animalVictimsForm: data.animalVictims.map(
              animalVictim =>
                new CreateEditOccurrenceAnimalVictimsForm({
                  ...animalVictim,
                  deathDate: animalVictim?.deathDate
                    ? this.formatDate(animalVictim?.deathDate)
                    : ''
                })
            )
          };

          this.updateState(populateForms);
        },
        onFailure: async error => {
          this.updateState({
            ...this.state,
            occurrenceRequestStatus: RequestStatus.failed(error)
          });
        }
      });
    } catch (error) {
      this.updateState({
        ...this.state,
        occurrenceRequestStatus: RequestStatus.failed(error as any)
      });
    }
  }

  public async getDataFieldsForEdit(
    data: CreateEditOccurrenceModel
  ): Promise<void> {
    if (data.stateId) {
      await this.getCitiesByIdState(data.stateId);
    }

    if (data.cityId) {
      await this.getNeighborhoodsByIdCity(data.cityId);
      await this.getSubNeighborhoodsByIdCity(data.cityId);
      await this.getLocalitiesByIdCity(data.cityId);
    }

    if (data.status === EOccurrenceStatus.Waiting && data.victims.length <= 0) {
      await this.getQualifications();
    }
  }

  public async editOccurrenceById(
    id: string,
    data: CreateEditOccurrenceEntity
  ) {
    this.updateState({
      ...this.state,
      editOccurrenceRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.editOccurrenceById(id, data);

    await resp.handle({
      onSuccess: async data => {
        this.updateState({
          ...this.state,
          editOccurrenceRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: async error => {
        this.updateState({
          ...this.state,
          editOccurrenceRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async createOccurrence(data: CreateEditOccurrenceEntity) {
    this.updateState({
      ...this.state,
      createOccurrenceRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.createOccurrence(data);

    await resp.handle({
      onSuccess: async data => {
        this.updateState({
          ...this.state,
          createOccurrenceRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: async error => {
        this.updateState({
          ...this.state,
          createOccurrenceRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  validateFormWithValidationSchema = async () => {
    const {
      detailsForm,
      contextInfoForm,
      transportsForm,
      victimsForm,
      animalVictimsForm
    } = this.state;

    try {
      let detailsFormValid = await detailsForm.validationSchema.isValid(
        detailsForm
      );
      let contextInfoFormValid = await contextInfoForm
        .validationSchema({
          isRelatedNewsRequired:
            this.state.fieldsForValidation.pressSource?.id ===
            this.state.detailsForm.sourceId
        })
        .isValid(contextInfoForm);
      let transportsFormValid = await Promise.all(
        transportsForm.map(form => form.validationSchema.isValid(form))
      ).then(results => results.every(result => result));

      let victimsFormValid = await Promise.all(
        victimsForm.map(form =>
          form
            .validationSchema({
              agentQualificationId:
                this.state.fieldsForValidation.agentQualification?.id,
              politicalQualificationId:
                this.state.fieldsForValidation.politicalQualification?.id,
              servicesQualificationsIds:
                this.state.fieldsForValidation.servicesQualifications?.map(
                  item => item.id
                )
            })
            .isValid(form)
        )
      ).then(results => results.every(result => result));

      let animalVictimsFormValid = await Promise.all(
        animalVictimsForm.map(form => form.validationSchema.isValid(form))
      ).then(results => results.every(result => result));

      return (
        detailsFormValid &&
        contextInfoFormValid &&
        transportsFormValid &&
        victimsFormValid &&
        animalVictimsFormValid
      );
    } catch (error) {
      return false;
    }
  };

  public handleFormSubmit = async () => {
    if (
      this.state.editOccurrenceRequestStatus.status ===
        ERequestStatus.Loading ||
      this.state.createOccurrenceRequestStatus.status ===
        ERequestStatus.Loading ||
      this.state.editOccurrenceRequestStatus.status ===
        ERequestStatus.Succeeded ||
      this.state.createOccurrenceRequestStatus.status ===
        ERequestStatus.Succeeded
    ) {
      return;
    }

    if (await this.validateFormWithValidationSchema()) {
      const {
        detailsForm,
        contextInfoForm,
        transportsForm,
        victimsForm,
        animalVictimsForm
      } = this.state;

      const data = new CreateEditOccurrenceEntity({
        ...detailsForm.toJson(),
        status:
          this.state?.editableOccurrenceData?.status ===
          EOccurrenceStatus.Waiting
            ? EOccurrenceStatus.Approved
            : this.state?.editableOccurrenceData?.status,
        contextInfo: contextInfoForm.toJson(),
        transports: transportsForm.map(transport => transport.toJson()),
        victims: victimsForm.map(victim => victim.toJson()),
        animalVictims: animalVictimsForm.map(animalVictim =>
          animalVictim.toJson()
        )
      });

      data.id
        ? this.editOccurrenceById(data.id, data)
        : this.createOccurrence(data);
    }
  };

  public handleDetailsFormChange = (field: string, value: any) => {
    const updatedDetailsForm = new CreateEditOccurrenceDetailsForm({
      ...this.state.detailsForm,
      [field]: value
    });

    this.updateState({
      ...this.state,
      detailsForm: updatedDetailsForm
    });
  };

  public async getStates() {
    this.updateState({
      ...this.state,
      statesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.adressSharedRepository.getStates();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          statesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          statesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getCitiesByIdState(idState: string) {
    this.updateState({
      ...this.state,
      citiesRequestStatus: RequestStatus.loading()
    });

    await this.delay(500);

    const resp = await this.adressSharedRepository.getCitiesByStateId(idState);

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          citiesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          citiesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getNeighborhoodsByIdCity(idCity: string) {
    this.updateState({
      ...this.state,
      neighborhoodsRequestStatus: RequestStatus.loading()
    });

    await this.delay(500);

    const resp = await this.adressSharedRepository.getNeighborhoodsByCityId(
      idCity
    );

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          neighborhoodsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          neighborhoodsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getSubNeighborhoodsByIdCity(idCity: string) {
    this.updateState({
      ...this.state,
      subneighborhoodsRequestStatus: RequestStatus.loading()
    });

    await this.delay(500);

    const resp = await this.adressSharedRepository.getSubNeighborhoodsByCityId(
      idCity
    );

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          subneighborhoodsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          subneighborhoodsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getLocalitiesByIdCity(idCity: string) {
    this.updateState({
      ...this.state,
      localitiesRequestStatus: RequestStatus.loading()
    });

    await this.delay(500);

    const resp = await this.adressSharedRepository.getLocalitiesByCityId(
      idCity
    );

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          localitiesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          localitiesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getSources() {
    this.updateState({
      ...this.state,
      sourcesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getSources();

    resp.handle({
      onSuccess: data => {
        const pressSource = data.find(s => s.name.toLowerCase() === 'imprensa');

        this.updateState({
          ...this.state,
          sourcesRequestStatus: RequestStatus.succeeded(data),
          fieldsForValidation: {
            ...this.state.fieldsForValidation,
            pressSource: pressSource
          }
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          sourcesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getReasons() {
    this.updateState({
      ...this.state,
      reasonsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getReasons();

    const notFoundReason: ReasonModel = {
      id: '',
      name: ''
    };

    resp.handle({
      onSuccess: data => {
        const actionPolice = data.find(
          reason => reason.name.toLowerCase() === 'ação policial'
        );

        const operationPolice = data.find(
          reason => reason.name.toLowerCase() === 'operação policial'
        );

        this.updateState({
          ...this.state,
          reasonsRequestStatus: RequestStatus.succeeded(data),
          reasonActionPolice: actionPolice ?? notFoundReason,
          reasonOperationPolice: operationPolice ?? notFoundReason
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          reasonsRequestStatus: RequestStatus.failed(error),
          reasonActionPolice: notFoundReason,
          reasonOperationPolice: notFoundReason
        });
      }
    });
  }

  public async getClippings() {
    this.updateState({
      ...this.state,
      clippingsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getClippings();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          clippingsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          clippingsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public handleContextInfoFormChange = (field: string, value: any) => {
    const updatedContextInfoForm = new CreateEditOccurrenceContextInfoForm({
      ...this.state.contextInfoForm,
      [field]: value
    });

    this.updateState({
      ...this.state,
      contextInfoForm: updatedContextInfoForm
    });
  };

  public async getTransports() {
    this.updateState({
      ...this.state,
      transportsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getTransports();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          transportsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          transportsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public handleTransportFormChange = (
    index: number,
    field: string,
    value: any
  ) => {
    const updatedTransportsForm = [...this.state.transportsForm];
    const currentTransport = updatedTransportsForm[index];
    const updatedTransport = new CreateEditOccurrenceTransportForm({
      ...currentTransport,
      [field]: value
    });

    updatedTransportsForm[index] = updatedTransport;

    this.updateState({
      ...this.state,
      transportsForm: updatedTransportsForm
    });
  };

  public addNewTransportForm() {
    this.updateState({
      ...this.state,
      transportsForm: [
        ...this.state.transportsForm,
        new CreateEditOccurrenceTransportForm()
      ]
    });
  }

  public removeTransportForm(index: number) {
    const updatedTransportsForm = [...this.state.transportsForm];
    updatedTransportsForm.splice(index, 1);
    this.updateState({
      ...this.state,
      transportsForm: updatedTransportsForm
    });
  }

  public async getQualifications() {
    this.updateState({
      ...this.state,
      qualificationsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getQualifications();

    const notFoundAgentQualification: QualificationModel = {
      id: '',
      name: '',
      type: QualificationType.NOTYPE
    };

    resp.handle({
      onSuccess: data => {
        const politicalQualification = data.find(
          q =>
            q.type === QualificationType.POLITICAL &&
            q.name.toLowerCase() === 'político'
        );

        const agentQualification = data.find(
          q =>
            q.type === QualificationType.AGENT &&
            q.name.toLowerCase() === 'agente de segurança'
        );

        const servicesQualifications = data.filter(
          q => q.type === QualificationType.SERVICE
        );

        this.updateState({
          ...this.state,
          qualificationsRequestStatus: RequestStatus.succeeded(data),
          agentQualification: agentQualification ?? notFoundAgentQualification,
          fieldsForValidation: {
            ...this.state.fieldsForValidation,
            agentQualification: agentQualification,
            politicalQualification: politicalQualification,
            servicesQualifications: servicesQualifications
          }
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          qualificationsRequestStatus: RequestStatus.failed(error),
          agentQualification: notFoundAgentQualification
        });
      }
    });
  }

  public getQualificationById(qualificationId: string) {
    let qualification: QualificationModel = {
      id: '',
      name: '',
      type: QualificationType.NOTYPE
    };
    this.state.qualificationsRequestStatus.maybeMap({
      succeeded(data) {
        qualification = data.find(
          qualification => qualification.id === qualificationId
        ) ?? {
          id: '',
          name: '',
          type: QualificationType.NOTYPE
        };
      }
    });
    return qualification;
  }

  public async getAgeGroups() {
    this.updateState({
      ...this.state,
      ageGroupsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getAgeGroups();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          ageGroupsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          ageGroupsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public getAgeGroupByName(ageGroupName: string) {
    let ageGroup: AgeGroupModel = { id: '', name: '' };
    this.state.ageGroupsRequestStatus.maybeMap({
      succeeded(data) {
        ageGroup = data.find(
          ageGroup => ageGroup.name.toLowerCase() === ageGroupName
        ) ?? {
          id: '',
          name: ''
        };
      }
    });
    return ageGroup;
  }

  public async getGenres() {
    this.updateState({
      ...this.state,
      genresRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getGenres();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          genresRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          genresRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getRaces() {
    this.updateState({
      ...this.state,
      racesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getRaces();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          racesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          racesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getPlaces() {
    this.updateState({
      ...this.state,
      placesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getPlaces();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          placesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          placesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getCircumstances() {
    this.updateState({
      ...this.state,
      circumstancesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getCircumstances();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          circumstancesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          circumstancesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getStatus() {
    this.updateState({
      ...this.state,
      statusRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getStatus();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          statusRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          statusRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getPositions() {
    this.updateState({
      ...this.state,
      positionsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getPositions();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          positionsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          positionsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getCoorporations() {
    this.updateState({
      ...this.state,
      coorporationsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getCoorporations();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          coorporationsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          coorporationsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public async getParties() {
    this.updateState({
      ...this.state,
      partiesRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getParties();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          partiesRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          partiesRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public handleVictimsFormChange = (
    index: number,
    field: string,
    value: any
  ) => {
    const updatedVictimsForm = [...this.state.victimsForm];
    const currentVictim = updatedVictimsForm[index];
    const updatedVictim = new CreateEditOccurrenceVictimsForm({
      ...currentVictim,
      [field]: value
    });

    updatedVictimsForm[index] = updatedVictim;

    this.updateState({
      ...this.state,
      victimsForm: updatedVictimsForm
    });
  };

  public checkCivilian = (typePerson: string) => {
    return typePerson.toLowerCase() === 'civilian';
  };

  public checkAgent = (typePerson: string) => {
    return typePerson.toLowerCase() === 'agent';
  };

  public checkWounded = (situation: string) => {
    return situation.toLowerCase() === 'wounded';
  };

  public checkDead = (situation: string) => {
    return situation.toLowerCase() === 'dead';
  };

  public countVictims() {
    let total = this.state.victimsForm.length;
    let civilWounded = 0;
    let civilDead = 0;
    let agentWounded = 0;
    let agentDead = 0;

    this.state.victimsForm.forEach(victim => {
      if (this.checkCivilian(victim.personType)) {
        if (this.checkWounded(victim.situation)) {
          civilWounded++;
        } else if (this.checkDead(victim.situation)) {
          civilDead++;
        }
      } else if (this.checkAgent(victim.personType)) {
        if (this.checkWounded(victim.situation)) {
          agentWounded++;
        } else if (this.checkDead(victim.situation)) {
          agentDead++;
        }
      }
    });

    this.updateState({
      ...this.state,
      victimsCount: {
        total,
        civilWounded,
        civilDead,
        agentWounded,
        agentDead
      }
    });
  }

  public async getAnimals() {
    this.updateState({
      ...this.state,
      animalsRequestStatus: RequestStatus.loading()
    });

    const resp = await this.occurrenceRepository.getAnimals();

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          animalsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          animalsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }

  public countAnimalVictims() {
    let total = this.state.animalVictimsForm.length;
    let wounded = 0;
    let dead = 0;

    this.state.animalVictimsForm.forEach(animalVictim => {
      if (this.checkWounded(animalVictim.situation)) {
        wounded++;
      } else if (this.checkDead(animalVictim.situation)) {
        dead++;
      }
    });

    this.updateState({
      ...this.state,
      animalVictimsCount: {
        total,
        wounded,
        dead
      }
    });
  }

  public addNewVictimsForm() {
    this.updateState({
      ...this.state,
      victimsForm: [
        ...this.state.victimsForm,
        new CreateEditOccurrenceVictimsForm()
      ]
    });
  }

  public removeVictimsForm(index: number) {
    const updatedVictimsForm = [...this.state.victimsForm];
    updatedVictimsForm.splice(index, 1);
    this.updateState({
      ...this.state,
      victimsForm: updatedVictimsForm
    });
  }

  public handleAnimalVictimsFormChange = (
    index: number,
    field: string,
    value: any
  ) => {
    const updatedAnimalVictimsForm = [...this.state.animalVictimsForm];
    const currentAnimalVictim = updatedAnimalVictimsForm[index];
    const updatedAnimalVictim = new CreateEditOccurrenceAnimalVictimsForm({
      ...currentAnimalVictim,
      [field]: value
    });

    updatedAnimalVictimsForm[index] = updatedAnimalVictim;

    this.updateState({
      ...this.state,
      animalVictimsForm: updatedAnimalVictimsForm
    });
  };

  public addNewAnimalVictimsForm() {
    this.updateState({
      ...this.state,
      animalVictimsForm: [
        ...this.state.animalVictimsForm,
        new CreateEditOccurrenceAnimalVictimsForm()
      ]
    });
  }

  public removeAnimalVictimsForm(index: number) {
    const updatedAnimalVictimsForm = [...this.state.animalVictimsForm];
    updatedAnimalVictimsForm.splice(index, 1);
    this.updateState({
      ...this.state,
      animalVictimsForm: updatedAnimalVictimsForm
    });
  }
}
