import React, { useState } from 'react';
import './index.style.css';

interface IProps {
  checked: boolean;
  onClick: (e: any) => void;
}

const SwitchGreen: React.FC<IProps> = ({ checked, onClick }) => {
  const [isChecked, setChecked] = useState(checked);

  return (
    <label className="switch">
      <input
        type="checkbox"
        id="togBtn"
        checked={isChecked}
        onClick={() => {
          setChecked(!isChecked);
          onClick(!isChecked);
        }}
      />
      <div className="slider round"></div>
    </label>
  );
};

export default SwitchGreen;
