import styled from 'styled-components';
import { Form, FormSelectProps } from 'react-bootstrap';

export const ModalContent = styled.div`
  width: 586px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.white};
`;

export const ModalContentSuccess = styled.div`
  width: 404px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  padding-bottom: 22px;
  background-color: ${props => props.theme.colors.white};
`;

export const ModalIcon = styled.img`
  height: 60px;
  width: 60px;
  margin-bottom: 24px;
`;

export const ModalTitle = styled.h3<{ color?: 'green' | 'reg' }>`
  color: ${props =>
    props.color === 'green'
      ? props.theme.colors.success
      : props.theme.colors.red};
  font-weight: 500;
  font-size: 28px;

  margin-bottom: 12px;
`;

export const ModalSubtitle = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 18px;
`;

export const CustomFormSelect = styled(Form.Select)<FormSelectProps>`
  height: 38px;
  width: 75%;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: ${props => props.theme.colors.black};
  border: 1px solid #d8d6de;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding: 0 32px;
`;

export const ModalButtonOutlined = styled.button`
  height: 42px;
  width: 48%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.red};
  background: transparent;
  border: 1px solid ${props => props.theme.colors.red};

  &:hover {
    background-color: ${props => props.theme.colors.danger + 20};
    transition: background-color 200ms linear;
  }
`;

export const CloseButtonModal = styled.button`
  height: 35px;
  width: 35px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: none;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.2);
  align-self: flex-end;
  margin-top: -5px;
  margin-right: -5px;
  margin-bottom: 11px;

  & > img {
    height: 20px;
    width: 20px;
  }

  &:hover {
    background: #f2f2f2;
    transition: background-color 200ms linear;
  }
`;

export const ModalButton = styled.button`
  height: 42px;
  width: 48%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background: linear-gradient(90deg, #f83600, #fe8c00);
  border: none;

  &:hover {
    background: linear-gradient(90deg, #e53c0d, #e53c0d);
    transition: background-color 200ms linear;
  }
`;
