import moment from 'moment';
import 'moment/locale/pt-br';

export function convertToDateAndHours(
  date: string | number | Date,
  isUTC = false
) {
  return moment(date).utc(isUTC).format('DD/MM/YYYY HH:mm');
  // let dt = new Date(date);
  // let calendar = moment(date).format("DD/MM/YYYY");
  // return `${calendar} ${dt.getUTCHours()}:${dt.getUTCMinutes()}`;
}

export function convertToDateTimeInput(date: string | number | Date) {
  return moment(date).utc(false).format('YYYY-MM-DDTHH:mm');
}

export function convertOfTimestampsToDateTime(date: string | number | Date) {
  return moment(date).utc(true).format('DD/MM/YYYY HH:mm');
}

//2022-05-03T13:45

export function convertDateTimeToInput(date: string | number | Date) {
  return moment(date).utc(false).format('YYYY-MM-DDTHH:mm');
}
