import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    
    * {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 24px;
        color: #6E6B7B;
    }

    img {
        background: none !important;
    }

    button {
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
    }

    .text-justify {
        text-align: justify !important;
        text-indent: 15px !important;
    }

    textarea {
        height: 145px;
    }

    h1, h2, h3, h4, h5, h6, p, b, strong, i {
        ::selection {
            background-color: ${props =>
              props.theme.colors.secondary} !important;
            color: ${props => props.theme.colors.white} !important;
        }
    }
`;
