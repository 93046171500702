import { Container } from 'react-bootstrap';
import styled from 'styled-components';

export const Block = styled(Container)`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 24px 20px;
  border-radius: 6px;

  p,
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #6e6b7b;
  }

  select,
  input {
    border: 1px solid #d8d6de !important;
    box-sizing: border-box !important;
  }

  select::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: red !important;
  }
`;

export const BlcokSB = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
  /* overflow: hidden; */

  > div {
    width: 48%;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
  }
`;

export const InputArea = styled.input`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  width: 350px;
  height: 125px;
  resize: none;
  outline: none;
  width: 100%;
`;

export const InputText = styled.input`
  background: ${props => (props.disabled ? '#DDDDDD' : '#ffffff')};
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 38px;
`;

export const InputSelect = styled.select`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 38px;
`;

export const ButtonAdd = styled.button`
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  padding: 12px 50px;
  border: none;
  border-radius: 6px;
  max-width: 200px;
`;

export const AddVtmContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const AddVtm = styled.button`
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.success};
  border: none;
`;

export const InputTextLatLng = styled.input`
  background: ${props => (props.disabled ? '#DDDDDD' : '#ffffff')};
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  resize: none;
  outline: none;
  display: block;
  width: 628px;
  height: 38px;
  margin-top: 5px;
`;

export const FormHeader = styled.h3`
  margin-top: 24px;
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #2c3941;
`;
