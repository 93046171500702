import React from 'react';
import { Container } from './index.style';
import downIcon from '../../assets/downIcon.png';

interface IProps {
  id: string;
  htmlFor: string;
  name: string;
  value?: any;
  onChange?: (e: any) => void;
  type?: string;
}

export const DataInput: React.FC<IProps> = props => {
  return (
    <Container>
      <input
        id={props.id}
        type={props.type != undefined ? props.type : 'date'}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <label htmlFor={props.htmlFor}>
        <img src={downIcon} alt="icon down" style={{ width: '18px' }} />
      </label>
    </Container>
  );
};

export default DataInput;
