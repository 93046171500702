import React, { useContext } from 'react';
import { IProps } from './index.types';

import {
  Body,
  Container,
  GeneralInfoAddressContainer,
  GeneralInfoAddressText,
  GeneralInfoSection,
  GeneralInfoHeader,
  GeneralInfoDateContainer,
  GeneralInfoDateStatusText,
  GeneralInfoDateText,
  GeneralInfoContainer,
  DataSection,
  DataOccurrenceNumber,
  DataItemContainer,
  DataItemTitle,
  DataItemInfoText,
  DataItemsContainer,
  DataItemsWoundedAndDeadContainer,
  OccurrenceDateInfo,
  OccurrenceDateContainer,
  ButtonsContainer,
  ConfirmButton,
  BackButton,
  ActionsSection
} from './index.styled';

import alertDark from '../../../../../../../assets/alertDark.png';
import alertRed from '../../../../../../../assets/redAlert2.png';
import localWhite from '../../../../../../../assets/localIcon2.png';
import agenteWhite from '../../../../../../../assets/policeIcon2.png';
import personWhite from '../../../../../../../assets/personIcon2.png';
import { Divider } from '../../../../../../../shared/styles';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';
import { EOccurrenceStatus } from '../../../../../../../shared/enums/ocurrenceStatus';
import { ERequestStatus } from '../../../../../../../shared/enums/requestStatus';
import Loading from '../../../../../../../components/Loading';

const CreateEditOccurrenceHeader: React.FC<IProps> = ({
  newOccurrence = false,
  onConfirm,
  onBack
}) => {
  const { createEditOccurrenceState } = useContext(OccurrenceContext);

  const formatedWoundedAndDeadInfo = (wounded: number, dead: number) =>
    `${wounded} - feridos / ${dead} - morte`;

  const withShot = () => createEditOccurrenceState.victimsCount.total > 0;

  const dataCy = (value: string) => `createEditOccurrenceHeader-${value}`;

  const getUserName = () => {
    if (createEditOccurrenceState?.editableOccurrenceData?.user) {
      return (
        createEditOccurrenceState?.editableOccurrenceData?.user?.name ||
        createEditOccurrenceState?.editableOccurrenceData?.user?.nickname ||
        'Desconhecido'
      );
    }
    return 'Moderador';
  };

  const isForApproval = () => {
    if (createEditOccurrenceState?.editableOccurrenceData?.status) {
      return (
        createEditOccurrenceState?.editableOccurrenceData?.status ===
        EOccurrenceStatus.Waiting
      );
    }
    return false;
  };

  const isLoading = () => {
    return (
      createEditOccurrenceState.editOccurrenceRequestStatus.status ===
        ERequestStatus.Loading ||
      createEditOccurrenceState.createOccurrenceRequestStatus.status ===
        ERequestStatus.Loading
    );
  };

  const getButtonLabel = () => {
    if (newOccurrence) {
      return 'Finalizar registro';
    } else if (isForApproval()) {
      return 'Finalizar aprovação';
    } else {
      return 'Finalizar edição';
    }
  };

  return (
    <Body>
      <Container>
        <GeneralInfoSection>
          <GeneralInfoHeader withShot={withShot()}>
            Registrado por<b>{getUserName()}</b>
          </GeneralInfoHeader>
          <GeneralInfoContainer>
            <GeneralInfoDateContainer>
              <img
                src={withShot() ? alertRed : alertDark}
                width={28}
                height={28}
              />
              <Divider width={12} />
              <div>
                <GeneralInfoDateStatusText withShot={withShot()}>
                  {withShot() ? ' Tiros com baleados' : ' Tiros sem baleados'}
                </GeneralInfoDateStatusText>
                <GeneralInfoDateText>
                  {' '}
                  {!createEditOccurrenceState.detailsForm.date
                    ? '--/--/---- --:--'
                    : new Date(
                        createEditOccurrenceState.detailsForm.date
                      ).toLocaleString('pt-BR')}
                </GeneralInfoDateText>
              </div>
            </GeneralInfoDateContainer>
            <Divider height={12} />
            <GeneralInfoAddressContainer>
              <img src={localWhite} width={28} height={28} />
              <Divider width={12} />
              <GeneralInfoAddressText>
                {!createEditOccurrenceState.detailsForm.address
                  ? '---'
                  : createEditOccurrenceState.detailsForm.address}
              </GeneralInfoAddressText>
            </GeneralInfoAddressContainer>
          </GeneralInfoContainer>
        </GeneralInfoSection>

        <DataSection>
          <DataOccurrenceNumber>
            #
            {!newOccurrence ? (
              createEditOccurrenceState?.editableOccurrenceData?.documentNumber
            ) : (
              <></>
            )}
          </DataOccurrenceNumber>

          <DataItemsContainer>
            <DataItemsWoundedAndDeadContainer>
              <DataItem
                title="Civis"
                info={formatedWoundedAndDeadInfo(
                  createEditOccurrenceState.victimsCount.civilWounded,
                  createEditOccurrenceState.victimsCount.civilDead
                )}
                icon={personWhite}
              />
              <Divider height={12} />
              <DataItem
                title="Agentes de segurança"
                info={formatedWoundedAndDeadInfo(
                  createEditOccurrenceState.victimsCount.agentWounded,
                  createEditOccurrenceState.victimsCount.agentDead
                )}
                icon={agenteWhite}
              />
            </DataItemsWoundedAndDeadContainer>
            <Divider width={32} />
            <div
              style={{
                maxWidth: '80%',
                overflow: 'auto'
              }}
            >
              <DataItem
                title="Descrição da ocorrência"
                info={
                  !createEditOccurrenceState.detailsForm.description
                    ? '---'
                    : createEditOccurrenceState.detailsForm.description
                }
              />
            </div>
          </DataItemsContainer>
        </DataSection>

        <ActionsSection newOccurrence={newOccurrence}>
          {!newOccurrence ? (
            <OccurrenceDateContainer>
              {createEditOccurrenceState.editableOccurrenceData?.createdAt ? (
                <OccurrenceDateInfo>
                  Data de cadastro:{' '}
                  <b style={{ fontSize: 12 }}>
                    {createEditOccurrenceState.editableOccurrenceData?.createdAt?.toLocaleString(
                      'pt-BR'
                    )}
                  </b>
                </OccurrenceDateInfo>
              ) : (
                <></>
              )}

              {createEditOccurrenceState.editableOccurrenceData?.createdAt ? (
                <OccurrenceDateInfo>
                  Última atualização:{' '}
                  <b style={{ fontSize: 12 }}>
                    {' '}
                    {createEditOccurrenceState.editableOccurrenceData?.updatedAt?.toLocaleString(
                      'pt-BR'
                    )}
                  </b>
                </OccurrenceDateInfo>
              ) : (
                <> </>
              )}
            </OccurrenceDateContainer>
          ) : (
            <></>
          )}

          <ButtonsContainer>
            <ConfirmButton
              data-cy={dataCy('confirmButton')}
              onClick={onConfirm}
              newOccurrence={newOccurrence}
              disabled={isLoading()}
            >
              {isLoading() ? (
                <>
                  <Loading size="sm" />
                  Processando...
                </>
              ) : (
                getButtonLabel()
              )}
            </ConfirmButton>
            <Divider height={12} />
            <BackButton onClick={onBack}>Voltar</BackButton>
          </ButtonsContainer>
        </ActionsSection>
      </Container>
    </Body>
  );
};

const DataItem: React.FC<{
  title: string;
  info: string;
  icon?: string;
}> = ({ title, info, icon }) => {
  return (
    <DataItemContainer>
      <DataItemTitle>{title}</DataItemTitle>
      <Divider height={8} />
      <div style={{ display: 'flex' }}>
        {icon ? (
          <>
            <img src={icon} width={28} height={28} />
            <Divider width={12} />
          </>
        ) : (
          <></>
        )}

        <DataItemInfoText>{info}</DataItemInfoText>
      </div>
    </DataItemContainer>
  );
};

export default CreateEditOccurrenceHeader;
