/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */

import Select from 'react-select';
import {
  Container,
  Header,
  DeleteButtonContainer,
  InputText,
  InputSelect,
  Title
} from './styles';
import { Radio } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { trashSvg } from '../../../../../assets/index';
import { IPropsVictim } from '../form-types';
import { RootState } from '../../../../../store';
import {
  Label,
  LabelContainer
} from '../../../../../components/RadioText/index.styled';
import CustomControllerSelect from '../../../../../components/CustomControllerSelect';
import { Controller } from 'react-hook-form';

const Victims: React.FC<IPropsVictim> = ({
  onDelete,
  idVictim,
  register,
  errors,
  control,
  watch,
  setValue
}) => {
  const {
    age_group,
    places,
    genres,
    qualifications,
    circumstances,
    status,
    positions,
    coorporations,
    parties,
    reasons
  } = useSelector((state: RootState) => state.clickState);

  const selected_qualifications: any[] =
    watch(`victims.${idVictim}.qualifications`) || [];

  const listaItems = Array.from(
    new Set(
      selected_qualifications.map(item => {
        return item?.type;
      })
    )
  );

  const [circunstancias, setCircunstancias] = useState<any>([]);

  useEffect(() => {
    if (circumstances != undefined || circumstances != null) {
      let aux: any[] = [];
      circumstances.forEach(id => {
        if (id.type != 'animal') {
          aux.push(id);
        }
      });
      setCircunstancias(aux);
    }
  }, [circumstances]);

  const wServiceStatus = watch(`victims.${idVictim}.service_status`);

  const wPoliticalStatus = watch(`victims.${idVictim}.political_status`);
  const wPoliticalPosition = watch(`victims.${idVictim}.political_position`);
  const wPoliticalPartie = watch(`victims.${idVictim}.partie`);

  const wAgentStatus = watch(`victims.${idVictim}.agent_status`);
  const wAgentPosition = watch(`victims.${idVictim}.agent_position`);
  const wAgentCorporation = watch(`victims.${idVictim}.coorporation`);

  useEffect(() => {
    if (wServiceStatus?.id != undefined) {
      setValue(`victims.${idVictim}.service_status`, wServiceStatus.id);
    } else {
      setValue(`victims.${idVictim}.service_status`, wServiceStatus);
    }
  }, [wServiceStatus]);

  useEffect(() => {
    if (wPoliticalStatus?.id != undefined) {
      setValue(`victims.${idVictim}.political_status`, wPoliticalStatus.id);
    } else {
      setValue(`victims.${idVictim}.political_status`, wPoliticalStatus);
    }
  }, [wPoliticalStatus]);

  useEffect(() => {
    if (wAgentStatus?.id != undefined) {
      setValue(`victims.${idVictim}.agent_status`, wAgentStatus.id);
    } else {
      setValue(`victims.${idVictim}.agent_status`, wAgentStatus);
    }
  }, [wAgentStatus]);

  useEffect(() => {
    if (wPoliticalPosition?.id != undefined) {
      setValue(`victims.${idVictim}.political_position`, wPoliticalPosition.id);
    } else {
      setValue(`victims.${idVictim}.political_position`, wPoliticalPosition);
    }
  }, [wPoliticalPosition]);

  useEffect(() => {
    if (wPoliticalPartie?.id != undefined) {
      setValue(`victims.${idVictim}.partie`, wPoliticalPartie.id);
    } else {
      setValue(`victims.${idVictim}.partie`, wPoliticalPartie);
    }
  }, [wPoliticalPartie]);

  useEffect(() => {
    if (wAgentPosition?.id != undefined) {
      setValue(`victims.${idVictim}.agent_position`, wAgentPosition.id);
    } else {
      setValue(`victims.${idVictim}.agent_position`, wAgentPosition);
    }
  }, [wAgentPosition]);

  useEffect(() => {
    if (wAgentCorporation?.id != undefined) {
      setValue(`victims.${idVictim}.coorporation`, wAgentCorporation.id);
    } else {
      setValue(`victims.${idVictim}.coorporation`, wAgentCorporation);
    }
  }, [wAgentCorporation]);

  function getCurrentQualifications() {
    return selected_qualifications?.filter(
      qualification => qualification?.id !== agentQualification?.id
    );
  }

  const agentQualification = qualifications.find(
    qualification => qualification.name === 'Agente de segurança'
  );

  const cQualificationsOptions = qualifications.filter(qualification => {
    if (
      watch(`victims.${idVictim}.type_person`) === 'Civilian' &&
      agentQualification
    ) {
      return qualification !== agentQualification;
    }
    return qualification;
  });

  const setAgentType = (v: any) => {
    setValue(`victims.${idVictim}.type_person`, v.target.value as any);
    if (agentQualification) {
      setValue(`victims.${idVictim}.qualifications`, [
        agentQualification,
        ...getCurrentQualifications()
      ]);
    }
  };

  const setCivilianType = (v: any) => {
    setValue(`victims.${idVictim}.type_person`, v.target.value as any);
    setValue(`victims.${idVictim}.qualifications`, [
      ...getCurrentQualifications()
    ]);
  };

  const childAgeGroup = age_group.find(group => group.name === 'Criança');
  const adolescentAgeGroup = age_group.find(
    group => group.name === 'Adolescente'
  );
  const adultAgeGroup = age_group.find(group => group.name === 'Adulto');
  const elderlyAgeGroup = age_group.find(group => group.name === 'Idoso');

  function onChangeAgeField(value: any) {
    if (value === null || value === undefined || value === '') {
      setValue(`victims.${idVictim}.age_group`, '');
    } else if (value >= 0 && value <= 11 && childAgeGroup) {
      setValue(`victims.${idVictim}.age_group`, childAgeGroup.id);
    } else if (value > 11 && value <= 17 && adolescentAgeGroup) {
      setValue(`victims.${idVictim}.age_group`, adolescentAgeGroup.id);
    } else if (value > 17 && value <= 59 && adultAgeGroup) {
      setValue(`victims.${idVictim}.age_group`, adultAgeGroup.id);
    } else if (value >= 60 && elderlyAgeGroup) {
      setValue(`victims.${idVictim}.age_group`, elderlyAgeGroup.id);
    }

    setValue(`victims.${idVictim}.age`, value);
  }

  const wCircumstancesField = watch(`victims.${idVictim}.circumstances`);
  const wMainReason = watch(`main_reason`);
  const wComplementaryReasons = watch(`complementary_reasons`);
  const suicideReason = reasons.find(reason => reason.name === 'Suicídio');
  useEffect(() => {
    if (
      wCircumstancesField &&
      wMainReason &&
      wComplementaryReasons &&
      suicideReason
    ) {
      wCircumstancesField.forEach(circumstance => {
        const hasSuicideInComplementaryReasons = wComplementaryReasons.find(
          reason => reason.id === suicideReason.id
        );
        if (
          circumstance.label === 'Suicídio' &&
          suicideReason.id !== wMainReason &&
          hasSuicideInComplementaryReasons === undefined
        ) {
          setValue(`complementary_reasons`, [
            ...wComplementaryReasons,
            suicideReason
          ]);
        }
      });
    }
  }, [wCircumstancesField]);

  return (
    <Container>
      <Header className="victim_header">
        <Row className="w-100">
          <Col style={{ flexDirection: 'column', alignItems: 'start' }} sm={5}>
            <div className="text-left">
              <Title>Vítima {idVictim + 1}#</Title>
            </div>

            <input type="hidden" {...register(`victims.${idVictim}.id`)} />
            <input type="hidden" {...register(`victims.${idVictim}._id`)} />

            <Row>
              <Col sm={6}>
                <div className="d-flex flex-row">
                  <LabelContainer color="#FF9F43">
                    <Controller
                      name={`victims.${idVictim}.situation`}
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <Radio
                          checked={value === 'Wounded'}
                          value={'Wounded'}
                          data-cy={`occurence-form-victims-${idVictim}-situation-wounded`}
                          {...field}
                        />
                      )}
                    />
                    <Label
                      checked={
                        watch(`victims.${idVictim}.situation`) === 'Wounded'
                      }
                      color={'#FF9F43'}
                    >
                      Ferido
                    </Label>
                  </LabelContainer>
                  <LabelContainer color={'#EA5455'}>
                    <Controller
                      name={`victims.${idVictim}.situation`}
                      control={control}
                      render={({ field: { value, ...field } }) => (
                        <Radio
                          checked={value === 'Dead'}
                          value={'Dead'}
                          data-cy={`occurence-form-victims-${idVictim}-situation-dead`}
                          {...field}
                        />
                      )}
                    />
                    <Label
                      checked={
                        watch(`victims.${idVictim}.situation`) === 'Dead'
                      }
                      color={'#EA5455'}
                    >
                      Morto
                    </Label>
                  </LabelContainer>
                </div>

                {errors?.situation && (
                  <p className="text-danger">
                    {(errors?.situation as any)?.message}
                  </p>
                )}
              </Col>

              <Col sm={6}>
                <div className="d-flex flex-row">
                  <LabelContainer color="#FF9F43">
                    <Radio
                      checked={
                        watch(`victims.${idVictim}.type_person`) === 'Civilian'
                      }
                      onChange={setCivilianType}
                      value={'Civilian'}
                      name={'Civilian'}
                      data-cy={`occurence-form-victims-${idVictim}-type-person-civilian`}
                    />
                    <Label
                      checked={
                        watch(`victims.${idVictim}.type_person`) === 'Civilian'
                      }
                      color={'#FF9F43'}
                    >
                      Civil
                    </Label>
                  </LabelContainer>
                  <LabelContainer color={'#EA5455'}>
                    <Radio
                      checked={
                        watch(`victims.${idVictim}.type_person`) === 'Agent'
                      }
                      onChange={setAgentType}
                      value={'Agent'}
                      name={'Agent'}
                      data-cy={`occurence-form-victims-${idVictim}-type-person-agent`}
                    />
                    <Label
                      checked={
                        watch(`victims.${idVictim}.type_person`) === 'Agent'
                      }
                      color={'#EA5455'}
                    >
                      Agent
                    </Label>
                  </LabelContainer>
                </div>

                {errors?.type_person && (
                  <p className="text-danger">
                    {(errors?.type_person as any)?.message}
                  </p>
                )}
              </Col>
            </Row>
          </Col>

          <Col style={{ display: 'flex', alignItems: 'center' }} sm={5}>
            <p className="me-2">Qualificação</p>

            <div className="w-100">
              <CustomControllerSelect
                control={control}
                name={`victims.${idVictim}.qualifications`}
                options={cQualificationsOptions}
                classNamePrefix={`occurence-form-victims-${idVictim}-qualifications`}
              />
              {errors?.qualifications && (
                <p className="text-danger">
                  {(errors?.qualifications as any)?.message}
                </p>
              )}
            </div>
          </Col>

          <Col
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            sm={2}
          >
            <DeleteButtonContainer>
              <Button
                variant="light"
                style={{
                  color: 'red',
                  backgroundColor: 'transparent',
                  border: 'none'
                }}
                onClick={onDelete}
              >
                Excluir Bloco
                <img src={trashSvg} style={{ marginLeft: '10px' }} />
              </Button>
            </DeleteButtonContainer>
          </Col>
        </Row>
      </Header>

      <Row>
        <Col>
          <Form.Group>
            <label>Nome/Apelido da vítima</label>
            <InputText type="text" {...register(`victims.${idVictim}.name`)} />

            {errors?.name && (
              <p className="text-danger">{(errors?.name as any)?.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Idade</label>
            <InputText
              type="number"
              placeholder=""
              min={0}
              max={120}
              onChange={e => onChangeAgeField(e.target.value)}
              value={watch(`victims.${idVictim}.age`)}
            />
            {errors?.age && (
              <p className="text-danger">{errors?.age?.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Faixa Etária*</label>
            <InputSelect {...register(`victims.${idVictim}.age_group`)}>
              <option value="">Selecione</option>
              {age_group?.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </InputSelect>
            {errors?.age_group && (
              <p className="text-danger">{errors?.age_group?.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Gênero*</label>
            <InputSelect {...register(`victims.${idVictim}.genre`)}>
              <option value="">Selecione</option>
              {genres?.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </InputSelect>
            {errors?.genre && (
              <p className="text-danger">{errors?.genre?.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Raça*</label>
            <InputSelect {...register(`victims.${idVictim}.race`)}>
              <option value="">Selecione</option>
              {[
                { id: 'Negra', name: 'Negra' },
                { id: 'Indígena', name: 'Indígena' },
                { id: 'Amarela', name: 'Amarela' },
                { id: 'Branca', name: 'Branca' },
                { id: 'Não identificado', name: 'Não identificado' }
              ]
                .sort((a, b) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                })
                ?.map(item => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </InputSelect>
            {errors?.race && (
              <p className="text-danger">{errors?.race?.message}</p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <label>Local*</label>
            <InputSelect {...register(`victims.${idVictim}.place`)}>
              <option value="">Selecione</option>
              {places?.map(item => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                );
              })}
            </InputSelect>
            {errors?.place && (
              <p className="text-danger">{errors?.place?.message}</p>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Circunstâncias</Form.Label>

            <CustomControllerSelect
              control={control}
              name={`victims.${idVictim}.circumstances`}
              options={circunstancias}
              classNamePrefix={`occurence-form-victims-${idVictim}-circumstances`}
            />
            {errors?.circumstances && (
              <p className="text-danger">
                {(errors?.circumstances as any)?.message}
              </p>
            )}
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Observações</Form.Label>
            <InputText
              type="text"
              placeholder=""
              {...register(`victims.${idVictim}.general_observation`)}
            />
            {errors?.general_observation && (
              <p className="text-danger">
                {errors?.general_observation?.message}
              </p>
            )}
          </Form.Group>
        </Col>

        {listaItems.includes('Servico') && (
          <Col>
            <Form.Group>
              <Form.Label>Status do serviço</Form.Label>
              <InputSelect {...register(`victims.${idVictim}.service_status`)}>
                <option value="">Selecione</option>
                {status?.map(item => {
                  if (item.type === 'Servico') {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  }
                })}
              </InputSelect>

              {errors?.service_status && (
                <p className="text-danger">{errors?.service_status?.message}</p>
              )}
            </Form.Group>
          </Col>
        )}
      </Row>

      {listaItems.includes('Politico') && (
        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Partido político*</Form.Label>
              <InputSelect {...register(`victims.${idVictim}.partie`)}>
                <option value="">Selecione</option>
                {parties?.map((item, index) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
              </InputSelect>
              {errors?.partie && (
                <p className="text-danger">{errors?.partie?.message}</p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Cargo*</Form.Label>
              <InputSelect
                {...register(`victims.${idVictim}.political_position`)}
              >
                <option value="">Selecione</option>
                {positions?.map(item => {
                  if (item.type === 'Politico') {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  }
                })}
              </InputSelect>
              {errors?.political_position && (
                <p className="text-danger">
                  {errors?.political_position?.message}
                </p>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Status do político*</Form.Label>
              <InputSelect
                {...register(`victims.${idVictim}.political_status`)}
              >
                <option value="">Selecione</option>
                {status?.map(item => {
                  if (item.type === 'Politico') {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  }
                })}
              </InputSelect>
              {errors?.political_status && (
                <p className="text-danger">
                  {errors?.political_status?.message}
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
      )}

      {listaItems.includes('Agente') && (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Coorporação do agente*</Form.Label>
                <InputSelect {...register(`victims.${idVictim}.coorporation`)}>
                  <option value="">Selecione</option>
                  {coorporations?.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </InputSelect>
                {errors?.coorporation && (
                  <p className="text-danger">{errors?.coorporation?.message}</p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Cargo*</Form.Label>
                <InputSelect
                  {...register(`victims.${idVictim}.agent_position`)}
                >
                  <option value="">Selecione</option>
                  {positions?.map(item => {
                    if (item.type === 'Agente') {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </InputSelect>
                {errors?.agent_position && (
                  <p className="text-danger">
                    {errors?.agent_position?.message}
                  </p>
                )}
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Status do agente*</Form.Label>
                <InputSelect {...register(`victims.${idVictim}.agent_status`)}>
                  <option value="">Selecione</option>
                  {status?.map(item => {
                    if (item.type === 'Agente') {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    }
                  })}
                </InputSelect>
                {errors?.agent_status && (
                  <p className="text-danger">{errors?.agent_status?.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Unidade</Form.Label>
                <InputText
                  type="text"
                  placeholder=""
                  {...register(`victims.${idVictim}.unit`)}
                />
                {errors?.unit && (
                  <p className="text-danger">{errors?.unit?.message}</p>
                )}
              </Form.Group>
            </Col>
          </Row>
        </>
      )}

      {watch(`victims.${idVictim}.situation`) === 'Dead' && (
        <Row>
          <Col sm={2}>
            <Form.Group>
              <Form.Label>Data da morte</Form.Label>
              <InputText
                type="datetime-local"
                {...register(`victims.${idVictim}.death_date`)}
                data-cy={`occurence-form-victims-${idVictim}-death-date`}
              />
              {errors?.death_date && (
                <p className="text-danger">{errors?.death_date?.message}</p>
              )}
            </Form.Group>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Victims;
