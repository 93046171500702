import { BasicModel } from '../../../../shared/domain/models/BasicModel';
import { QualificationType } from '../../data/entities/QualificationEntity';

export class QualificationModel extends BasicModel {
  type: QualificationType;

  constructor({
    id,
    name,
    type
  }: {
    id: string;
    name: string;
    type: QualificationType;
  }) {
    super({ id, name });
    this.type = type;
  }
}
