import FileForm from '../../../../shared/domain/forms/FileForm';
import { PostType } from '../../data/entities/PostEntity';

export class PostGroupItemForm {
  image: FileForm = new FileForm();
  description: string = '';
  content: string = '';

  constructor(data?: Partial<PostGroupItemForm>) {
    Object.assign(this, data);
  }
}

export class PostItemForm {
  group: string = 'bloco 1';
  group_items: Array<PostGroupItemForm> = [new PostGroupItemForm()];
  typeBlock: string = 'text';
  quantBlock: string = '1';

  constructor(data?: Partial<PostItemForm>) {
    Object.assign(this, data);
  }
}

export class PostForm {
  title: string = '';
  author: string = '';
  slug: string = '';
  region: string = '';
  authors: string = '';
  cover: FileForm = new FileForm();
  type: PostType = PostType.PUBLISHED;
  emphasis: boolean = false;
  date_post: string = '';
  description: string = '';
  items: Array<PostItemForm> = [new PostItemForm()];

  constructor(data?: Partial<PostForm>) {
    Object.assign(this, data);
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }
}
