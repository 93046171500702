import styled from 'styled-components';
import { PropsHeader } from './index.types';

export const CardStyled = styled.div`
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
`;

export const DivHeaderTitle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b,
  div {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;

export const HeaderStyled = styled.div<PropsHeader>`
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  border-radius: 6px 6px 0px 0px;
  padding: 20px 24px;
  display: flex;
  align-items: center;
  height: 61px;

  ${props => {
    if (props.btnEdit == true) {
      return `
                justify-content: space-between;
            `;
    } else {
      return `
                justify-content: center;
            `;
    }
  }}

  > h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
`;

export const BodyStyled = styled.div`
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
`;

export const BtnEdit = styled.button`
  background: none;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 6px;
  padding: 11px 33px;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: ${props => props.theme.colors.white};
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 500 !important;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: 0.4px;
  }
`;

export const BtnCancel = styled.button`
  height: 46px;
  width: 203px;
  border-radius: 6px;
  background: #ea5455;
  color: #fff;
  border: none;
`;

export const BtnSaveEdit = styled.button`
  height: 46px;
  width: 203px;
  border-radius: 6px;
  border: none;

  background: ${props => props.theme.colors.secondary};
  color: white;
`;

export const BtnEditSingle = styled.button`
  background: none;
  border: 2px solid ${props => props.theme.colors.white};
  border-radius: 6px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    width: 20px;
    height: 20px;
    margin-left: 7px;
  }
`;
