import * as Yup from 'yup';
import { BaseForm } from '../../../../shared/domain/forms/BaseForm';
import { EOccurrenceStatus } from '../../../../shared/enums/ocurrenceStatus';

export class CreateEditOccurrenceDetailsForm extends BaseForm<CreateEditOccurrenceDetailsForm> {
  id: string = '';
  documentNumber: string = '';
  status: EOccurrenceStatus = EOccurrenceStatus.Waiting;
  userId: string = '';
  adminId: string = '';
  address: string = '';
  latitude: string = '';
  longitude: string = '';
  country: string = '';
  stateId: string = '';
  regionId: string = '';
  cityId: string = '';
  neighborhoodId: string = '';
  subNeighborhoodId: string = '';
  localityId: string = '';
  date: string = '';
  sourceId: string = '';
  policeAction: boolean | undefined = undefined;
  agentPresence: boolean | undefined = undefined;
  description: string = '';
  relatedRecord: string = '';

  validationSchema = Yup.object().shape({
    address: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('endereço')),
    latitude: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('latitude')),
    longitude: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('longitude')),
    stateId: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('estado')),
    cityId: Yup.string().nullable().required(this.requiredDefaultMsg('cidade')),
    date: Yup.string().nullable().required(this.requiredDefaultMsg('data')),
    sourceId: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('fonte')),
    policeAction: Yup.boolean().required(
      this.requiredDefaultMsg('ação policial')
    ),
    agentPresence: Yup.boolean().required(
      this.requiredDefaultMsg('presença de agentes')
    ),
    description: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('descrição'))
  });

  constructor(data?: Partial<CreateEditOccurrenceDetailsForm>) {
    super();
    Object.assign(this, data);
  }
}
