import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { EHandlePostModalType, IProps } from './index.types';
import { RootState } from '../../../../../../store/index';
import { useSelector } from 'react-redux';

import {
  ButtonClear,
  ButtonDashed,
  ButtonDown,
  ButtonUp,
  CardStyled,
  CardStyledOne,
  Container,
  ContainerButtons,
  ContainerCard,
  ContainerTB,
  Footer,
  Header
} from './index.styled';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { arrowDown, arrowUp, trashRed } from '../../../../../../assets/index';
import { ImgUp } from '../../../../../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalPreview from './view/index';
import DatePicker from './date_picker/index';
import moment from 'moment';
import Editor from '@monaco-editor/react';
import * as urlSlug from 'url-slug';
import { PostEntity, PostType } from '../../../../data/entities/PostEntity';
import { BlogContext } from '../../../../providers/BlogProvider';
import { isDefined } from '../../../../../../utils/definedValue';
import { PostForm, PostItemForm } from '../../../../domain/forms/PostForm';
import { showAlert } from '../../../../../../shared/components/alert';
import ModalSucesso from '../../../../../../shared/components/modal_successo';

const ModalHandlePost: React.FC<IProps> = ({
  isModal,
  onHide,
  modalType,
  callBackPostAction,
  postContent
}) => {
  const { postActionsState, previousPostActionsState, postActionsUsecase } =
    useContext(BlogContext);

  const { user } = useSelector((state: RootState) => state.clickState);

  const [preview, setPreview] = useState<PostEntity>(new PostEntity());

  const [showPreview, setShowPreview] = useState(false);
  let [isModalDate, setModalDate] = useState(false);

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: new PostForm({ author: user.id }).toJson()
  });

  const { append, remove, move, fields } = useFieldArray({
    control,
    name: 'items'
  });

  let type = watch('type');

  async function onSubmit(data: any) {
    if (modalType === EHandlePostModalType.CREATE) {
      await postActionsUsecase.createPost({ data });
    } else {
      await postActionsUsecase.editPost({ id: data.id, data });
    }

    onHide();
  }

  async function requestRegions() {
    await postActionsUsecase.getRegions();
  }

  useEffect(() => {
    requestRegions();
  }, []);

  useEffect(() => {
    if (isDefined(postContent)) {
      let aux = {
        ...postContent,
        author: postContent?.author?.id,
        region: postContent?.region?.id
      };

      reset(aux);
    }
  }, [postContent]);

  function callSuccessAlert() {
    showAlert({
      onDismiss: () => {
        if (!!callBackPostAction) {
          callBackPostAction();
        }
      },
      children: (
        <ModalSucesso
          title={`Postagem ${
            modalType === EHandlePostModalType.CREATE ? 'criada' : 'editada'
          } com sucesso`}
          description={`A Postagem foi ${
            modalType === EHandlePostModalType.CREATE ? 'criada' : 'editada'
          } com sucesso.`}
        />
      )
    });
  }

  useEffect(() => {
    if (
      postActionsState.editPostRequestStatus.status !=
      previousPostActionsState?.editPostRequestStatus.status
    ) {
      postActionsState.editPostRequestStatus.maybeMap({
        failed: () => {},
        succeeded: () => {
          callSuccessAlert();
        }
      });
    }

    if (
      postActionsState.createPostRequestStatus.status !=
      previousPostActionsState?.createPostRequestStatus.status
    ) {
      postActionsState.createPostRequestStatus.maybeMap({
        failed: () => {},
        succeeded: () => {
          callSuccessAlert();
        }
      });
    }
  }, [
    postActionsState.editPostRequestStatus,
    postActionsState.createPostRequestStatus
  ]);

  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
      >
        <Container>
          <Header>
            <h3>
              {modalType === EHandlePostModalType.CREATE
                ? `Nova postagem`
                : `Editar postagem`}
            </h3>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 style={{ marginLeft: '38px' }}>Especificações</h3>

              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Região</Form.Label>
                      <Form.Select {...register('region', { required: true })}>
                        <option value="">Selecione</option>
                        {postActionsState.regionsRequestStatus.maybeMap({
                          loading: () => <option>Carregando...</option>,
                          succeeded(data) {
                            return data?.map((region: any) => {
                              if (region.enabled === true) {
                                return (
                                  <option value={region.id}>
                                    {region.region}
                                  </option>
                                );
                              }
                              return <></>;
                            });
                          }
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Escrito por</Form.Label>
                      <Form.Control
                        {...register('authors', { required: true })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Capa</Form.Label>
                      <ImgUp
                        getValues={getValues}
                        setValue={setValue}
                        index={`cover`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              <h3 style={{ marginLeft: '38px' }}>Descrição do post</h3>
              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        maxLength={151}
                        {...register('description', {
                          required: true,
                          maxLength: 151,
                          onChange: e => {
                            if (e.target.value.length < 150) {
                              setError('description', {
                                type: 'value',
                                message: `Quantidade de caracteres: ${e.target.value.length}`
                              });
                            } else if (e.target.value.length == 151) {
                              setError('description', {
                                type: 'value',
                                message: `Quantidade de máxima de caracteres alcançada: ${e.target.value.length}`
                              });
                            }
                          }
                        })}
                      />
                      {errors.description && (
                        <p> {errors.description.message}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              <h3 style={{ marginLeft: '38px' }}>Conteúdo</h3>

              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Titulo</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={61}
                        {...register('title', {
                          required: true,
                          maxLength: 61,
                          onChange: e => {
                            if (e.target.value.length < 60) {
                              setError('title', {
                                type: 'value',
                                message: `Quantidade de caracteres: ${e.target.value.length}`
                              });
                            } else if (e.target.value.length == 61) {
                              setError('title', {
                                type: 'value',
                                message: `Quantidade de máxima de caracteres alcançada: ${e.target.value.length}`
                              });
                            }
                          }
                        })}
                      />
                      {errors.title && <p> {errors.title.message}</p>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Slug</Form.Label>
                      <Controller
                        control={control}
                        name="slug"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (
                          <Form.Control
                            type="text"
                            maxLength={61}
                            onChange={(e: any) => {
                              let valor = urlSlug.convert(e.target.value, {
                                separator: '-',
                                transformer: urlSlug.LOWERCASE_TRANSFORMER
                              });
                              setValue('slug', valor);
                            }}
                            value={value}
                          />
                        )}
                      />

                      <p>São permitidos apenas letras, números e hífen.</p>
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              {fields.map((_, index) => {
                let tb = watch(`items.${index}.typeBlock`);
                let quant = watch(`items.${index}.quantBlock`);

                return (
                  <ContainerCard>
                    <ContainerButtons>
                      <ButtonUp
                        type="button"
                        onClick={() => {
                          let tmp = index - 1;
                          move(index, tmp);
                        }}
                      >
                        <img src={arrowUp} />
                      </ButtonUp>
                      <ButtonDown
                        type="button"
                        onClick={() => {
                          let tmp = index + 1;
                          move(index, tmp);
                        }}
                      >
                        <img src={arrowDown} />
                      </ButtonDown>
                    </ContainerButtons>

                    <CardStyled>
                      <ButtonClear
                        type="button"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Excluir bloco
                        <img src={trashRed} />
                      </ButtonClear>

                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Tipo de conteúdo do blog</Form.Label>
                            <Form.Select
                              {...register(`items.${index}.typeBlock`, {
                                required: true
                              })}
                            >
                              {[
                                { value: 'text', label: 'Texto' },
                                { value: 'image', label: 'Imagem' },
                                { value: 'code', label: 'Código' }
                              ].map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Quantidade de colunas</Form.Label>
                            <Form.Select
                              {...register(`items.${index}.quantBlock`, {
                                onChange: (e: any) => {
                                  setValue(
                                    `items.${index}.group`,
                                    `bloco ${e.target.value}`
                                  );
                                },
                                required: true
                              })}
                            >
                              {[
                                { value: '1', label: '1' },
                                { value: '2', label: '2' }
                              ].map((item, index) => {
                                return (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>

                      {tb == 'code' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map((chave, index) => {
                                  return (
                                    <Col key={index}>
                                      <Editor
                                        height="145px"
                                        defaultLanguage="html"
                                        defaultValue=""
                                        theme="vs-dark"
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                        options={{
                                          wordWrap: 'on'
                                        }}
                                      />
                                    </Col>
                                  );
                                })
                              : [0, 1].map((chave, index) => {
                                  return (
                                    <Col key={index} xs={6}>
                                      <Editor
                                        height="145px"
                                        defaultLanguage="html"
                                        defaultValue=""
                                        theme="vs-dark"
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                        options={{
                                          wordWrap: 'on'
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                      {tb == 'text' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map((chave, index) => {
                                  return (
                                    <Col key={index}>
                                      <ReactQuill
                                        theme="snow"
                                        formats={[
                                          'header',
                                          'bold',
                                          'italic',
                                          'underline',
                                          'strike',
                                          'blockquote',
                                          'list',
                                          'bullet',
                                          'indent',
                                          'code-block',
                                          'link'
                                        ]}
                                        modules={{
                                          toolbar: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false
                                                ]
                                              }
                                            ],
                                            [
                                              'bold',
                                              'italic',
                                              'underline',
                                              'strike',
                                              'blockquote'
                                            ],
                                            [
                                              { list: 'ordered' },
                                              { list: 'bullet' },
                                              { indent: '-1' },
                                              { indent: '+1' }
                                            ],
                                            ['code-block', 'link'],
                                            ['clean']
                                          ]
                                        }}
                                        value={getValues(
                                          `items.${index}.group_items.${chave}.content`
                                        )}
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                      />
                                    </Col>
                                  );
                                })
                              : [0, 1].map((chave, index) => {
                                  return (
                                    <Col key={index} xs={6}>
                                      <ReactQuill
                                        theme="snow"
                                        formats={[
                                          'header',
                                          'bold',
                                          'italic',
                                          'underline',
                                          'strike',
                                          'blockquote',
                                          'list',
                                          'bullet',
                                          'indent',
                                          'code-block',
                                          'link'
                                        ]}
                                        modules={{
                                          toolbar: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false
                                                ]
                                              }
                                            ],
                                            [
                                              'bold',
                                              'italic',
                                              'underline',
                                              'strike',
                                              'blockquote'
                                            ],
                                            [
                                              { list: 'ordered' },
                                              { list: 'bullet' },
                                              { indent: '-1' },
                                              { indent: '+1' }
                                            ],
                                            ['code-block', 'link'],
                                            ['clean']
                                          ]
                                        }}
                                        value={getValues(
                                          `items.${index}.group_items.${chave}.content`
                                        )}
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                      {tb == 'image' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map((chave, index) => {
                                  return (
                                    <Col key={index}>
                                      <Form.Group>
                                        <ImgUp
                                          getValues={getValues}
                                          setValue={setValue}
                                          index={`items.${index}.group_items.${chave}.image`}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>
                                          Descrição da foto
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          {...register(
                                            `items.${index}.group_items.${chave}.description`
                                          )}
                                        />
                                      </Form.Group>
                                    </Col>
                                  );
                                })
                              : [0, 1].map((chave, index) => {
                                  return (
                                    <Col key={index} xs={6}>
                                      <Form.Group>
                                        <ImgUp
                                          getValues={getValues}
                                          setValue={setValue}
                                          index={`items.${index}.group_items.${chave}.image`}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>
                                          Descrição da foto
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          {...register(
                                            `items.${index}.group_items.${chave}.description`
                                          )}
                                        />
                                      </Form.Group>
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                    </CardStyled>
                  </ContainerCard>
                );
              })}

              <ButtonDashed
                type="button"
                onClick={() => {
                  append(new PostItemForm());
                }}
              >
                Adicionar novo bloco de conteúdo
              </ButtonDashed>
            </div>
            <Footer>
              <button type="button" onClick={() => onHide()}>
                Cancelar
              </button>

              <button
                type="button"
                onClick={() => {
                  setPreview(PostEntity.fromJson(getValues()));
                  setShowPreview(true);
                }}
              >
                Preview
              </button>

              {modalType === EHandlePostModalType.CREATE ? (
                <button type="button" onClick={() => setModalDate(true)}>
                  Agendar
                </button>
              ) : (
                <></>
              )}

              <button
                type="submit"
                onClick={() => {
                  let dt = new Date();
                  let data = moment(dt).format('YYYY-MM-DDTHH:mm:ss.000Z');
                  setValue(`date_post`, data);
                }}
              >
                Finalizar
              </button>

              <fieldset>
                <label htmlFor="rascunho">Salvar como rascunho</label>
                <input
                  id="rascunho"
                  type="checkbox"
                  checked={type?.toLowerCase() == PostType.DRAFT.toLowerCase()}
                  onChange={_ => {
                    if (type?.toLowerCase() == PostType.DRAFT.toLowerCase()) {
                      setValue(`type`, PostType.PUBLISHED);
                    } else {
                      setValue(`type`, PostType.DRAFT);
                    }
                  }}
                />
              </fieldset>
            </Footer>
          </Form>
        </Container>
      </Modal>

      <ModalPreview
        content={preview?.toJson()}
        isModal={showPreview}
        onHide={() => setShowPreview(false)}
      />

      <DatePicker
        isModal={isModalDate}
        onHide={() => {
          setModalDate(false);
        }}
        setValue={setValue}
        index={`date_post`}
        index2={`type`}
        getValues={getValues}
        onHideAll={onHide}
      />
    </>
  );
};

export default ModalHandlePost;
