import * as Yup from 'yup';
import { BaseForm } from '../../../../shared/domain/forms/BaseForm';

export class CreateEditOccurrenceTransportForm extends BaseForm<CreateEditOccurrenceTransportForm> {
  id: string = '';
  occurrenceId: string = '';
  interruptedTransport: boolean | undefined = undefined;
  transportId: string = '';
  dateInterruption: string = '';
  releaseDate: string = '';
  transportDescription: string = '';

  validationSchema: Yup.SchemaOf<{
    interruptedTransport: boolean;
  }> = Yup.object().shape({
    interruptedTransport: Yup.boolean()
      .nullable()
      .required(this.requiredDefaultMsg('transporte interrompido')),
    transportId: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('tipo de transporte'))
  });

  constructor(data?: Partial<CreateEditOccurrenceTransportForm>) {
    super();
    Object.assign(this, data);
  }
}
