import React, { useState } from 'react';
import { IProps } from './index.types';
import {
  Card,
  Icon,
  Content,
  Banner,
  Tag,
  Container,
  LinkStyled,
  ContainerSwitch,
  TypePost,
  HeaderCover
} from './index.styled';
import { timePost, authorPost } from '../../assets/index';
import moment from 'moment';
import { SwitchGreen } from '..';
import { editPost, getPosts } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_BLOG_POSTS, RootState } from '../../store';
import Loading from '../Loading';
import { tempImg } from '../../constants/tempImg';

const CardPost: React.FC<IProps> = ({ content }) => {
  function convertData(data: string) {
    let dt = moment(data).format('DD/MM/YYYY HH:mm');
    return `${dt} `;
  }

  let { token } = useSelector((state: RootState) => state.clickState);
  let dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);

  function onClickSwitchEmphasis(value: boolean) {
    try {
      let aux = {
        ...content,
        author: content.author?.id,
        region: content.region?.id,
        emphasis: value
      };
      delete aux.comments;
      editPost(token, content.id, aux).then(r => {
        getPosts(token).then(resp =>
          dispatch({ type: ADD_BLOG_POSTS, blog_posts: resp.data })
        );
      });
    } catch (e) {
      alert('Erro ao editar o post!');
      console.error(e);
    }
  }

  const toPath =
    content.slug != null && content.slug != '' && content.slug != ' '
      ? `/blog/${content.slug}`
      : `/blog/id/${content.id}`;

  return (
    <Card>
      <HeaderCover to={toPath} style={{ margin: isLoading ? 'auto 0' : '' }}>
        <div
          style={{
            display: isLoading ? 'block' : 'none'
          }}
        >
          <Loading />
        </div>

        <Banner
          src={
            content.cover != null &&
            content.cover != undefined &&
            content.cover.file != ''
              ? content.cover.file
              : tempImg
          }
          style={{ display: !isLoading ? 'block' : 'none' }}
          onLoad={() => {
            setLoading(false);
          }}
        />
        <TypePost type={content.type}>{content.type}</TypePost>
      </HeaderCover>

      <ContainerSwitch>
        <label
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ marginRight: '10px' }}>Matéria em destaque</span>
          <span> </span>
          <SwitchGreen
            checked={content.emphasis}
            onClick={e => onClickSwitchEmphasis(e)}
          />
        </label>
      </ContainerSwitch>

      <Container to={toPath}>
        <Tag>{content.region?.state}</Tag>

        <LinkStyled>{content.title}</LinkStyled>

        <Content>
          <div>
            <div>
              <Icon src={authorPost} />
              <p>{content?.authors}</p>
            </div>
            <div>
              <Icon src={timePost} />
              <p>Atualizado em {convertData(content.updatedAt)}</p>
            </div>
          </div>
        </Content>
      </Container>
    </Card>
  );
};

export default CardPost;
