import React from 'react';
import './index.style.css';

interface IProps {
  checked: boolean;
  onClick: () => void;
}

const SwitchButton: React.FC<IProps> = props => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        id="togBtn"
        checked={props.checked}
        onClick={props.onClick}
      />
      <div className="slider round"></div>
    </label>
  );
};

export default SwitchButton;
