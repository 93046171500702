import { BasicModel } from '../../../../shared/domain/models/BasicModel';
import { CircumstanceType } from '../../data/entities/CircumstanceEntity';

export class CircumstanceModel extends BasicModel {
  type: CircumstanceType;

  constructor({
    id,
    name,
    type
  }: {
    id: string;
    name: string;
    type: CircumstanceType;
  }) {
    super({ id, name });
    this.type = type;
  }
}
