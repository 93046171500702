import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getArticles } from '../../services';
import { ADD_ARTICLES, RootState } from '../../store';
import {
  Container,
  BtnOrange,
  MenuRight,
  TitleMenu,
  MenuHeader,
  BtnMenu
} from './index.style';
import {
  ModalCreateAcademicResearch,
  ModalCreatePress,
  ModalCreatePublicPolicy,
  RenderPress,
  RenderAcademicResearch,
  RenderPublicPolicy
} from './Modals';

const Impacto: React.FC = () => {
  const [isModalCreatePress, setIsModalCreatePress] = useState(false);
  const [isModalCreateAcademicResearch, setIsModalCreateAcademicResearch] =
    useState(false);
  const [isModalCreatePublicPolicy, setIsModalCreatePublicPolicy] =
    useState(false);
  const [typeCreate, setTypeCreate] = useState('press');
  const { token, articles } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (articles != undefined) {
      console.log('artigos:', articles);
    }
  }, [articles]);

  const [typeOfMedia, setTypeOfMedia] = useState('');
  const [scope, setScope] = useState('');
  const [fieldKnowledge, setFieldKnowledge] = useState('');
  const [federativePower, setFederativePower] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [subjectArea, setSubjectArea] = useState('');
  const [year, setYear] = useState('');
  const [state, setState] = useState('');

  const [page, setPage] = useState('1');
  const [take, setTake] = useState('50');
  const [order, setOrder] = useState('DESC');

  const [data_maxima, setDataMaxima] = useState('');

  useEffect(() => {
    let dt = new Date();
    setDataMaxima(dt.getFullYear().toString());
  }, [data_maxima]);

  useEffect(() => {
    getArticles(
      token,
      typeOfMedia != '' ? typeOfMedia : undefined,
      scope != '' ? scope : undefined,
      state != '' ? state : undefined,
      year != '' ? year : undefined,
      fieldKnowledge != '' ? fieldKnowledge : undefined,
      federativePower != '' ? federativePower : undefined,
      documentType != '' ? documentType : undefined,
      subjectArea != '' ? subjectArea : undefined,
      page != '' ? page : undefined,
      take != '' ? take : undefined,
      order != '' ? order : undefined
    ).then(resp => {
      dispatch({ type: ADD_ARTICLES, articles: resp.data });
      console.log(resp.data, 'resp');
    });
  }, [
    typeOfMedia,
    scope,
    fieldKnowledge,
    federativePower,
    documentType,
    subjectArea,
    year,
    state,
    page,
    take,
    order
  ]);

  return (
    <Container>
      <MenuRight>
        <TitleMenu>Impacto</TitleMenu>
        <MenuHeader>
          <BtnMenu
            onClick={() => {
              setTypeCreate('press');
              setTypeOfMedia('');
              setScope('');
              setFieldKnowledge('');
              setFederativePower('');
              setDocumentType('');
              setSubjectArea('');
              setYear('');
              setState('');
            }}
            status={typeCreate == 'press' ? true : false}
          >
            Imprensa
          </BtnMenu>
          <BtnMenu
            onClick={() => {
              setTypeCreate('academic_research');
              setTypeOfMedia('');
              setScope('');
              setFieldKnowledge('');
              setFederativePower('');
              setDocumentType('');
              setSubjectArea('');
              setYear('');
              setState('');
            }}
            status={typeCreate == 'academic_research' ? true : false}
          >
            Pesquisas Acadêmicas
          </BtnMenu>
          <BtnMenu
            onClick={() => {
              setTypeCreate('public_policy');
              setTypeOfMedia('');
              setScope('');
              setFieldKnowledge('');
              setFederativePower('');
              setDocumentType('');
              setSubjectArea('');
              setYear('');
              setState('');
            }}
            status={typeCreate == 'public_policy' ? true : false}
          >
            Políticas Públicas
          </BtnMenu>
        </MenuHeader>
        <BtnOrange
          onClick={() => {
            switch (typeCreate) {
              case 'press':
                setIsModalCreatePress(true);
                break;
              case 'academic_research':
                setIsModalCreateAcademicResearch(true);
                break;
              case 'public_policy':
                setIsModalCreatePublicPolicy(true);
                break;
              default:
                setIsModalCreatePress(true);
                break;
            }
          }}
        >
          Incluir matéria
        </BtnOrange>
      </MenuRight>

      {typeCreate == 'press' ? (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de mídia</Form.Label>
                <Form.Select
                  onChange={e => {
                    setTypeOfMedia(e.target.value);
                  }}
                >
                  {[
                    'Impresso',
                    'TV',
                    'Rádio',
                    'Podcast',
                    'Vídeo em redes sociais',
                    'Outros'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Escopo</Form.Label>
                <Form.Select
                  onChange={e => {
                    setScope(e.target.value);
                  }}
                >
                  {['Internacional', 'Nacional', 'Regional', 'Local'].map(
                    chave => {
                      return <option value={chave}>{chave}</option>;
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  onChange={e => {
                    setState(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <br />

          {articles?.map(chave => {
            if (chave.type == 'Imprensa') {
              return <RenderPress content={chave} />;
            }
          })}
        </>
      ) : (
        <></>
      )}

      {typeCreate == 'academic_research' ? (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  min="1990"
                  max={data_maxima}
                  onChange={e => {
                    setYear(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Campo de conhecimento</Form.Label>
                <Form.Select
                  onChange={e => {
                    setFieldKnowledge(e.target.value);
                  }}
                >
                  {[
                    'Sociologia',
                    'Antropologia',
                    'Ciência política',
                    'Economia',
                    'Psicologia',
                    'Matemática',
                    'Educação',
                    'Relações internacionais',
                    'Administração',
                    'Geografia',
                    'Comunicação',
                    'Arquitetura e urbanismo',
                    'Serviço social',
                    'Gestão pública',
                    'Direito',
                    'Estatística'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de mídia</Form.Label>
                <Form.Select
                  onChange={e => {
                    setTypeOfMedia(e.target.value);
                  }}
                >
                  {[
                    'Artigo em periódicos',
                    'Capítulo de livro',
                    'Blog',
                    'Eventos',
                    'Entrevistas',
                    'Artigo eletrônico',
                    'Manual',
                    'Tese',
                    'Dissertação',
                    'Monografia/TCC',
                    'Relatório',
                    'Livro'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <br />

          {articles?.map(chave => {
            if (chave.type == 'Pesquisa Acadêmica') {
              return <RenderAcademicResearch content={chave} />;
            }
          })}
        </>
      ) : (
        <></>
      )}

      {typeCreate == 'public_policy' ? (
        <>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  min="1990"
                  max={data_maxima}
                  onChange={e => {
                    setYear(e.target.value);
                  }}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Poder Federativo</Form.Label>
                <Form.Select
                  onChange={e => {
                    setFederativePower(e.target.value);
                  }}
                >
                  {['Executivo', 'Legislativo', 'Judiciário', 'Nenhum'].map(
                    chave => {
                      return <option value={chave}>{chave}</option>;
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Escopo</Form.Label>
                <Form.Select
                  onChange={e => {
                    setScope(e.target.value);
                  }}
                >
                  {['Federal', 'Estadual', 'Municipal', 'Global'].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select
                  onChange={e => {
                    setDocumentType(e.target.value);
                  }}
                >
                  {[
                    'Lei',
                    'Ação Civil Pública',
                    'Arguição de Descumprimento de Preceito Fundamental',
                    'Manual',
                    'Recomendação Pública',
                    'Apresentação Pública',
                    'Projeto',
                    'Audiência Pública',
                    'Projeto de Lei'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Área temática</Form.Label>
                <Form.Select
                  onChange={e => {
                    setSubjectArea(e.target.value);
                  }}
                >
                  {[
                    'Educação',
                    'Segurança Pública',
                    'Combate à Pobreza',
                    'Crianças e Adolescentes'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <br />

          {articles?.map(chave => {
            if (chave.type == 'Politicas Públicas') {
              return <RenderPublicPolicy content={chave} />;
            }
          })}
        </>
      ) : (
        <></>
      )}

      <ModalCreatePress
        isModal={isModalCreatePress}
        onHide={() => {
          setIsModalCreatePress(false);
        }}
      />

      <ModalCreateAcademicResearch
        isModal={isModalCreateAcademicResearch}
        onHide={() => {
          setIsModalCreateAcademicResearch(false);
        }}
      />

      <ModalCreatePublicPolicy
        isModal={isModalCreatePublicPolicy}
        onHide={() => {
          setIsModalCreatePublicPolicy(false);
        }}
      />
    </Container>
  );
};

export default Impacto;
