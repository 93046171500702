/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import {
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalSubtitle,
  CustomFormSelect,
  ModalFooter,
  ModalButtonOutlined,
  ModalButton,
  CloseButtonModal,
  ModalContentSuccess
} from './styles';

import { Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { check, reproveIcon, close } from '../../../../../../assets/index';

import { Modal } from '@mui/material';
import { IOccurrence } from '../../../../../../shared/interface/occurrence';
import { ADD_OCCURRENCES, RootState } from '../../../../../../store';
import {
  editOccurrences,
  getOccurrencesByParams
} from '../../../../../../services';
import { editStatusOccurrenceById } from '../../../../../../services/functions/occurrences';
import { EOccurrenceStatus } from '../../../../../../shared/enums/ocurrenceStatus';

export interface IModalReprovarProps {
  onClose(): void;
  onHide: () => void;
  open: boolean;
  occurrence: IOccurrence;
  dataDt: typeDt;
  setOcorrencias: (e: any) => void;
}

export type typeDt = {
  order: any;
  page: any;
  take: any;
  typeOccurrences: any;
  initial_date: any;
  final_date: any;
};

const ModalReprovar: React.FC<IModalReprovarProps> = ({
  open,
  onClose,
  occurrence,
  dataDt,
  onHide,
  setOcorrencias
}) => {
  const [isOptionSelected, setOptionSelected] = useState('');
  const [isOpenSuccess, setOpenSuccess] = useState(false);

  const { token } = useSelector((state: RootState) => state.clickState);

  const handleChange = (event: any) => {
    console.log('Motivo da reprova:', event.target.value);
    setOptionSelected(event.target.value);
  };

  const handleCloseAll = () => {
    setOpenSuccess(false);
    requestAnimationFrame(onClose);
  };

  const dispatch = useDispatch();

  function isNumeric(val: any) {
    return /^-?\d+$/.test(val);
  }

  const onSubmit = async () => {
    try {
      const response = await editStatusOccurrenceById({
        token: token,
        id: occurrence['id'] || '',
        status: EOccurrenceStatus.Disapproved
      });
      if (response.status === 200) {
        setOpenSuccess(true);

        getOccurrencesByParams(
          token,
          dataDt.order,
          dataDt.page,
          dataDt.take,
          dataDt.typeOccurrences,
          `${dataDt.initial_date}T00:01:00.000Z`,
          `${dataDt.final_date}T23:59:00.000Z`
        ).then((r: any) => {
          setOcorrencias(r.data);
          dispatch({ type: ADD_OCCURRENCES, occurrences: r.data });
        });
      }
    } catch (error) {
      console.warn('(Reprovar ocorrencia) Erro:', error);
    }
  };

  useEffect(() => {
    console.log('open', open);
    if (open == true) {
      onHide();
    }
  }, [open]);

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={open}
        onClose={onClose}
      >
        <ModalContent>
          <ModalIcon src={reproveIcon} />

          <ModalTitle>Reprovar ocorrência</ModalTitle>
          <ModalSubtitle>
            Qual o motivo para a reprovação dessa ocorrência?
          </ModalSubtitle>

          <CustomFormSelect aria-label="" onChange={handleChange}>
            <option value="Duplicata">Duplicata</option>
            <option value="Não confirmada">Não Confirmada</option>
            <option value="Fora da área de cobertura">
              Fora da área de cobertura
            </option>
            <option value="Outros">Outros</option>
          </CustomFormSelect>

          <ModalFooter>
            <ModalButtonOutlined onClick={onClose}>
              Cancelar
            </ModalButtonOutlined>
            <ModalButton onClick={onSubmit}>Finalizar</ModalButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={isOpenSuccess}
        onClose={handleCloseAll}
      >
        <ModalContentSuccess>
          <CloseButtonModal onClick={handleCloseAll}>
            <img src={close} />
          </CloseButtonModal>

          <ModalIcon src={check} />

          <ModalTitle color="green">Ocorrência reprovada</ModalTitle>
          <ModalSubtitle>A ocorrência foi reprovada com sucesso.</ModalSubtitle>
        </ModalContentSuccess>
      </Modal>
    </>
  );
};

export default ModalReprovar;
