import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BlueComponent, ModalSucesso } from '../../../../../components';
import {
  createArticles,
  getArticles,
  getCities,
  getStates
} from '../../../../../services';
import {
  ADD_ARTICLES,
  ADD_CITIES,
  ADD_STATES,
  RootState
} from '../../../../../store';
import { Container } from './index.style';
import { IProps } from './index.types';

const ModalCreatePublicPolicy: React.FC<IProps> = ({ isModal, onHide }) => {
  const refBtn = useRef<any>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      publication_date: '',
      title: '',
      link: '',
      theme: '',
      indicators_fc: '',
      sub_identifiers_fc: '',
      keyword: '',
      media_type: '',
      vehicle_name: '',
      program_or_section: '',
      access_type: 'Livre',
      scope: '',
      continent: '',
      country: '',
      state: '',
      city: '',
      place: '',
      emphasis: '',
      spokesman: '',
      exclusive: true,
      hook: '',
      digital: true,
      type_of_contact: '',
      contact_channel: '',
      bait: '',
      axis_fc: '',
      year: 2022,
      federative_power: '',
      document_type: '',
      status: '',
      document_details: '',
      subject_area: '',
      public_reference: '',
      contact: '',
      fc_influence: '',
      type_material: '',
      field_knowledge: '',
      associated_theme_fc: '',
      inputs_used: '',
      author: '',
      institutional_binding: '',
      magazine_name: '',
      type: 'Politicas Públicas'
    }
  });

  const { token, states, cities } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
    getCities(token).then(resp => {
      dispatch({ type: ADD_CITIES, cities: resp });
    });
  }, []);

  function onSubmit(dados: any) {
    let aux = {
      ...dados,
      year: parseInt(dados.year)
    };

    console.log(aux);

    createArticles(token, aux).then(r => {
      getArticles(token).then(resp => {
        dispatch({ type: ADD_ARTICLES, articles: resp.data });
      });
      onHide();
      setVisible(true);
    });
  }

  const [listCities, setListCities] = useState([
    {
      id: '',
      name: ''
    }
  ]);

  const title = watch('title');

  return (
    <Container>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          refBtn.current.click();
        }}
        titleComponent={<div>Incluir matéria - Politica Pública</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Titulo</Form.Label>
                <Form.Control
                  maxLength={150}
                  {...register(`title`, {
                    required: true,
                    maxLength: 150,
                    onChange: () => {
                      if (title.length == 150) {
                        setError('title', {
                          type: 'maxLength',
                          message: 'Máximo de caracteres alcançado'
                        });
                      }
                    }
                  })}
                />
                {errors.title && <p>{errors.title.message}</p>}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  min="2000"
                  max="2022"
                  {...register(`year`)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Poder Federativo</Form.Label>
                <Form.Select
                  {...register(`federative_power`, { required: true })}
                >
                  {['Executivo', 'Legislativo', 'Judiciário', 'Nenhum'].map(
                    chave => {
                      return <option value={chave}>{chave}</option>;
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Escopo</Form.Label>
                <Form.Select {...register(`scope`)}>
                  {['Federal', 'Estadual', 'Municipal', 'Global'].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de documento</Form.Label>
                <Form.Select {...register(`document_type`)}>
                  {[
                    'Lei',
                    'Ação Civil Pública',
                    'Arguição de Descumprimento de Preceito Fundamental',
                    'Manual',
                    'Recomendação Pública',
                    'Apresentação Pública',
                    'Projeto',
                    'Audiência Pública',
                    'Projeto de Lei'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Form.Select {...register(`status`)}>
                  {[
                    'Ativo',
                    'Parcialmente concedido',
                    'Ativo',
                    'Em tramitação',
                    'Não informado'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Detalhes do documento</Form.Label>
                <Form.Control {...register(`document_details`)} />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Link do documento</Form.Label>
                <Form.Control type="url" {...register(`link`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Área temática</Form.Label>
                <Form.Select {...register(`subject_area`)}>
                  {[
                    'Educação',
                    'Segurança Pública',
                    'Combate à Pobreza',
                    'Crianças e Adolescentes'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Estado</Form.Label>
                <Form.Control {...register(`state`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Município</Form.Label>
                <Form.Control {...register(`city`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Referência pública</Form.Label>
                <Form.Control {...register(`public_reference`)} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Contato</Form.Label>
                <Form.Control {...register(`contact`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Influência Fogo Cruzado</Form.Label>
                <Form.Select>
                  {['Direta', 'Indireta'].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de material Fogo Cruzado</Form.Label>
                <Form.Select {...register(`type_material`)}>
                  {[
                    'Estudo e levantamentos especiais',
                    'Dados',
                    'Metodologia de dados e tecnologia'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Identificadores Fogo Cruzado</Form.Label>
                <Form.Select {...register(`indicators_fc`)}>
                  {[
                    'Ação/Operação policial',
                    'Agente de segurança vítima',
                    'Bala perdida',
                    'Baleados',
                    'Chacinas',
                    'Crianças e/ou adolescentes',
                    'Entorno de Unidades de ensino ou saúde',
                    'Gênero',
                    'Idosos',
                    'Motorista de app',
                    'Outros',
                    'Outros recortes',
                    'Projetos especiais',
                    'Residência',
                    'Tiros',
                    'Transporte/Via interrompida',
                    'Bar',
                    'Tiros de helicóptero',
                    'Ataque sob rodas',
                    'Político',
                    'Ex-detento',
                    'Presídio',
                    'Shopping',
                    'Tiroteiro contínuo',
                    'Vendedor ambulante',
                    'Mototaxista'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col></Col>
            <Col></Col>
          </Row>

          <br />
          <button type="submit" style={{ display: 'none' }} ref={refBtn}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Link criado"
        description="O link foi criado com sucesso."
      />
    </Container>
  );
};

export default ModalCreatePublicPolicy;
