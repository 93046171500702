import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { ClippingModel } from '../../domain/models/ClippingModel';

export class ClippingEntity extends BasicEntity {
  constructor(data?: Partial<ClippingEntity>) {
    super(data);
  }

  toDomain(): ClippingModel {
    return new ClippingModel({
      id: this.id,
      name: this.name
    });
  }
}
