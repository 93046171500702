import React, { useContext } from 'react';
import FormBody from '../../components/form_body';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';

import { Form, Row, Col } from 'react-bootstrap';
import {
  CustomForm,
  FormItem
} from '../../../../../../../shared/components/custom_form';
import FormCheckRadio from '../../../../../../../shared/components/form_check_radio';
import AddItemOnFormButton from '../../../../../../../shared/components/add_item_on_form_button';
import { Divider } from '../../../../../../../shared/styles';
import FormDateInput from '../../../../../../../shared/components/form_date_input';
import FormItemHeader from '../form_item_header';

const CreateEditOcurrenceTransportsFormComponent: React.FC = () => {
  const { createEditOccurrenceUsecase, createEditOccurrenceState } =
    useContext(OccurrenceContext);

  const dataCy = (field: string, index?: number) => {
    let prefix = 'transportForm';

    if (index !== null && index !== undefined) {
      return `${prefix}-${index}-${field}`;
    }
    return `${prefix}-${field}`;
  };

  return (
    <FormBody
      title="Transporte"
      description="Adicione transportes e informações sobre."
    >
      <Col md={2}>
        <AddItemOnFormButton
          dataCy={dataCy('addTransportButton')}
          name="Adicionar transporte"
          onClick={() => createEditOccurrenceUsecase.addNewTransportForm()}
        />
      </Col>
      {createEditOccurrenceState.transportsForm.map((transportForm, index) => (
        <div key={transportForm.uniqueKey}>
          <Divider height={24} />
          <FormItemHeader
            title="TRANSPORTE"
            index={index}
            onDelete={() =>
              createEditOccurrenceUsecase.removeTransportForm(index)
            }
          />
          <CustomForm
            validationSchema={transportForm.validationSchema}
            initialValues={transportForm}
            forwardedRef={transportForm.ref}
            onSubmit={() => {}}
            handleFormChange={(field, value) =>
              createEditOccurrenceUsecase.handleTransportFormChange(
                index,
                field,
                value
              )
            }
            component={({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              isInvalid
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Row>
                  <Col md={2}>
                    <FormItem
                      name="interruptedTransport"
                      label="O transporte foi interrompido?*"
                      component={field => (
                        <FormCheckRadio
                          field={field}
                          dataCy={dataCy(field.name, index)}
                          handleChange={(name, value) => {
                            handleChange(name, value);

                            if (value === false) {
                              handleChange('dateInterruption', '');
                              handleChange('releaseDate', '');
                            }
                          }}
                          isInvalid={isInvalid}
                        />
                      )}
                    />
                  </Col>
                  <Col md={2}>
                    <FormItem
                      name="transportId"
                      label="Tipo de transporte*"
                      failRequest={createEditOccurrenceState.transportsRequestStatus.maybeMap(
                        {
                          failed: () => true
                        }
                      )}
                      onPressedRefresh={() =>
                        createEditOccurrenceUsecase.getTransports()
                      }
                      component={field => (
                        <Form.Select
                          name={field.name}
                          data-cy={dataCy(field.name, index)}
                          value={values[field.name]}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                          onBlur={handleBlur}
                          isInvalid={isInvalid(field.name)}
                        >
                          {createEditOccurrenceState.transportsRequestStatus.maybeMap(
                            {
                              idle: () => (
                                <option>Carregando transportes...</option>
                              ),
                              loading: () => <option>Carregando...</option>,
                              failed: () => (
                                <option>
                                  Erro. Clique no botão acima para carregar as
                                  opções!
                                </option>
                              ),
                              succeeded(data) {
                                return (
                                  <>
                                    <option value="">Selecione</option>
                                    {data?.map(transport => (
                                      <option value={transport.id}>
                                        {' '}
                                        {transport.name}
                                      </option>
                                    ))}
                                  </>
                                );
                              }
                            }
                          )}
                        </Form.Select>
                      )}
                    />
                  </Col>
                  <Col md={2}>
                    <FormItem
                      name="dateInterruption"
                      label="Data da interrupção"
                      component={field => (
                        <FormDateInput
                          field={field}
                          dataCy={dataCy(field.name, index)}
                          value={values[field.name]}
                          handleChange={handleChange}
                          isInvalid={isInvalid}
                          handleBlur={handleBlur}
                          disabled={
                            values['interruptedTransport'] === false ||
                            !values['interruptedTransport']
                          }
                        />
                      )}
                    />
                  </Col>
                  <Col md={2}>
                    <FormItem
                      name="releaseDate"
                      label="Data da liberação"
                      component={field => (
                        <FormDateInput
                          field={field}
                          dataCy={dataCy(field.name, index)}
                          value={values[field.name]}
                          handleChange={handleChange}
                          isInvalid={isInvalid}
                          handleBlur={handleBlur}
                          disabled={!values['dateInterruption']}
                          min={values['dateInterruption']}
                        />
                      )}
                    />
                  </Col>
                  <Col md={4}>
                    <FormItem
                      name="transportDescription"
                      label="Descrição do transporte"
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name, index)}
                          as="textarea"
                          placeholder="Digite a descrição do transporte..."
                          rows={1}
                          style={{ maxHeight: '80px' }}
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Form>
            )}
          />
        </div>
      ))}
    </FormBody>
  );
};

export default CreateEditOcurrenceTransportsFormComponent;
