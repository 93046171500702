import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div`
  max-width: 720px;
  padding: 24px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
`;

export const Button = styled.button`
  border: none;
  width: 250px;
  margin: 20px auto;
  padding: 8px 12px;
  background-color: #ff9f43;
  color: #ffffff;
`;
