import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Content,
  Div,
  Section,
  Icon,
  LinkStyled,
  ImageHeader,
  DivNav,
  Post,
  ImgContent,
  ImgPost,
  TwoBlocks,
  ImgPostSolo
} from './index.styled';

import {
  paper,
  date,
  share,
  facebook,
  twitter,
  telegram,
  linkedin
} from '../../../../../../../assets';

import { convertData } from '../../../../../../../services';
import { Modal } from 'react-bootstrap';

import renderHTML from 'react-render-html';
import { IProps } from './index.types';
import { isDefined } from '../../../../../../../utils/definedValue';
import { PostEntity } from '../../../../../data/entities/PostEntity';

const ModalPreview: React.FC<IProps> = ({ onHide, isModal, content }) => {
  const [post, setPost] = useState<PostEntity>(new PostEntity());

  useEffect(() => {
    if (isDefined(content)) {
      setPost(content);
    }
  }, [content]);

  return (
    <Modal show={isModal} onHide={onHide} fullscreen>
      <Modal.Body>
        <Container>
          <>
            <DivNav>
              <LinkStyled
                onClick={() => {
                  onHide();
                }}
              >
                Voltar
              </LinkStyled>
              <div></div>
            </DivNav>

            <Header>
              <ImageHeader src={post?.cover?.file} />
            </Header>

            <Content>
              <h3>{post?.title}</h3>
              <div>
                <Div>
                  <div>
                    <Icon src={paper} />
                    <p>{post?.authors}</p>
                  </div>
                  <div>
                    <Icon src={date} />
                    <p>{convertData(post?.createdAt ?? '')}</p>
                  </div>
                </Div>
                <div>
                  <a href="">
                    {' '}
                    <img src={share} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={facebook} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={twitter} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={telegram} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={linkedin} alt="" />{' '}
                  </a>
                </div>
              </div>
            </Content>

            <Post>
              {post?.items?.map((items: any) => {
                if (items.group == 'bloco 1') {
                  return (
                    <Section>
                      {items?.group_items?.map((group_items: any) => {
                        return (
                          <>
                            <ImgContent>
                              {group_items?.image?.file != '' &&
                              group_items?.image?.file != undefined ? (
                                <ImgPost src={group_items?.image?.file} />
                              ) : (
                                <div style={{ display: 'none' }} />
                              )}
                              <div>
                                {group_items?.description?.length > 0
                                  ? renderHTML(group_items?.description)
                                  : ''}
                              </div>
                            </ImgContent>
                            <div className="app">
                              {group_items?.content?.length > 0
                                ? renderHTML(group_items?.content)
                                : ''}
                            </div>
                          </>
                        );
                      })}
                    </Section>
                  );
                } else if (
                  items.group == 'bloco 2' &&
                  items.quantBlock == '2' &&
                  items.typeBlock == 'image'
                ) {
                  return (
                    <TwoBlocks>
                      {items?.group_items?.map((group_items: any) => {
                        return (
                          <ImgPostSolo>
                            {group_items?.image?.file != '' &&
                            group_items?.image?.file != undefined ? (
                              <img src={group_items?.image?.file} />
                            ) : (
                              <div style={{ display: 'none' }} />
                            )}
                            <div>
                              {group_items?.description?.length > 0
                                ? renderHTML(group_items?.description)
                                : ''}
                            </div>
                          </ImgPostSolo>
                        );
                      })}
                    </TwoBlocks>
                  );
                } else if (
                  items.group == 'bloco 2' &&
                  items.quantBlock == '2' &&
                  items.typeBlock == 'text'
                ) {
                  return (
                    <TwoBlocks>
                      {items?.group_items?.map((group_items: any) => {
                        return (
                          <div className="app">
                            {group_items?.content?.length > 0
                              ? renderHTML(group_items?.content)
                              : ''}
                          </div>
                        );
                      })}
                    </TwoBlocks>
                  );
                } else if (
                  items.group == 'bloco 2' &&
                  items.quantBlock == '2' &&
                  items.typeBlock == 'code'
                ) {
                  return (
                    <TwoBlocks>
                      {items?.group_items?.map((group_items: any) => {
                        return (
                          <div className="app">
                            {group_items?.content?.length > 0
                              ? renderHTML(group_items?.content)
                              : ''}
                          </div>
                        );
                      })}
                    </TwoBlocks>
                  );
                } else if (
                  items.group == 'bloco 2' &&
                  items.quantBlock == '1' &&
                  items.typeBlock == 'image'
                ) {
                  return (
                    <ImgPostSolo>
                      {items?.group_items?.map((group_items: any) => {
                        return (
                          <>
                            {group_items?.image?.file != '' &&
                            group_items?.image?.file != undefined ? (
                              <img src={group_items?.image?.file} />
                            ) : (
                              <div style={{ display: 'none' }} />
                            )}
                            <div>
                              {group_items?.description?.length > 0
                                ? renderHTML(group_items?.description)
                                : ''}
                            </div>
                          </>
                        );
                      })}
                    </ImgPostSolo>
                  );
                }
              })}
            </Post>
          </>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreview;
