import React, { useContext, useState } from 'react';
import FormBody from '../../components/form_body';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';

import { Field } from 'formik';

import { Form, Row, Col } from 'react-bootstrap';
import { RefactorInputSearchMap } from '../../../../../../../components/InputSearchMap';
import { Divider } from '../../../../../../../shared/styles';
import { RefactorSelectWithSearch } from '../../../../../../../components/SelectWithSearch';
import MapSearch from '../../../../../../../components/MapSearch';
import {
  CustomForm,
  FormItem
} from '../../../../../../../shared/components/custom_form';
import FormCheckRadio from '../../../../../../../shared/components/form_check_radio';

const CreateEditOcurrenceDetailsFormComponent: React.FC = () => {
  const { createEditOccurrenceUsecase, createEditOccurrenceState } =
    useContext(OccurrenceContext);

  const [changePositionMap, setChangePositionMap] = useState(false);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);

  const dataCy = (field: string) => `detailsForm-${field}`;

  return (
    <FormBody
      title="Detalhes da ocorrência"
      description="Informações básicas e administrativas sobre os
        tiroteios/disparos de arma de fogo."
    >
      <CustomForm
        validationSchema={
          createEditOccurrenceState.detailsForm.validationSchema
        }
        initialValues={createEditOccurrenceState.detailsForm}
        forwardedRef={createEditOccurrenceState.detailsForm.ref}
        onSubmit={() => {}}
        handleFormChange={createEditOccurrenceUsecase.handleDetailsFormChange}
        component={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          isInvalid
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <FormItem
              name="address"
              label="Local*"
              component={field => (
                <div data-cy={dataCy(field.name)}>
                  <RefactorInputSearchMap
                    {...field}
                    type="text"
                    placeholder="Digite um endereço"
                    name={field.name}
                    value={values[field.name]}
                    disabled={!mapLoaded}
                    isInvalid={isInvalid(field.name)}
                    className={isInvalid(field.name) ? 'is-invalid' : ''}
                    onChange={(e: any) => handleChange(field.name, e)}
                    changePosition={changePositionMap}
                    latitude={values['latitude']}
                    longitude={values['longitude']}
                    onLocationChange={(location: any) => {
                      if (location) {
                        handleChange('latitude', location.lat);
                        handleChange('longitude', location.lng);
                      }
                    }}
                  />
                </div>
              )}
            />

            <Divider height={24} />

            <Row>
              <Col md={6}>
                <FormItem
                  name="latitude"
                  label="Latitude*"
                  component={field => (
                    <Form.Control
                      {...field}
                      data-cy={dataCy(field.name)}
                      type="text"
                      placeholder="Latitude"
                      isInvalid={isInvalid(field.name)}
                      onChange={(e: any) => {
                        setChangePositionMap(!changePositionMap);
                        handleChange(field.name, e.target.value);
                      }}
                    />
                  )}
                />
              </Col>
              <Col md={6}>
                <FormItem
                  name="longitude"
                  label="Longitude*"
                  component={field => (
                    <Form.Control
                      {...field}
                      data-cy={dataCy(field.name)}
                      type="text"
                      placeholder="Longitude"
                      isInvalid={isInvalid(field.name)}
                      onChange={(e: any) => {
                        setChangePositionMap(!changePositionMap);
                        handleChange(field.name, e.target.value);
                      }}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              <Col style={{ backgroundColor: 'transparent' }} sm={12}>
                <input type="hidden" name="latitude" />
                <input type="hidden" name="longitude" />

                <MapSearch
                  handleMapLoad={() => {
                    setMapLoaded(true);
                  }}
                  onPositionSelected={({ lat, lng }) => {
                    setChangePositionMap(!changePositionMap);
                    handleChange('latitude', String(lat));
                    handleChange('longitude', String(lng));
                  }}
                  selectedPosition={{
                    lng: Number(values['longitude']),
                    lat: Number(values['latitude'])
                  }}
                />
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <FormItem
                  name="stateId"
                  label="Estado*"
                  failRequest={createEditOccurrenceState.statesRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getStates()
                  }
                  component={field => (
                    <Form.Select
                      name={field.name}
                      data-cy={dataCy(field.name)}
                      value={values[field.name]}
                      onChange={(e: any) => {
                        const value = e.target.value;
                        handleChange('cityId', '');
                        handleChange('neighborhoodId', '');
                        handleChange('subNeighborhoodId', '');
                        handleChange('localityId', '');

                        if (value) {
                          createEditOccurrenceUsecase.getCitiesByIdState(value);
                        }

                        handleChange(field.name, value);
                      }}
                      onBlur={handleBlur}
                      isInvalid={isInvalid(field.name)}
                    >
                      {createEditOccurrenceState.statesRequestStatus.maybeMap({
                        idle: () => <option>Carregando estados...</option>,
                        loading: () => <option>Carregando...</option>,
                        failed: () => (
                          <option>
                            Erro. Clique no botão acima para carregar as opções!
                          </option>
                        ),
                        succeeded(data) {
                          return (
                            <>
                              <option value="">Selecione</option>
                              {data?.map(state => (
                                <option value={state.id}> {state.name}</option>
                              ))}
                            </>
                          );
                        }
                      })}
                    </Form.Select>
                  )}
                />
              </Col>
              <Col md={3}>
                <FormItem
                  name="cityId"
                  label="Cidade*"
                  failRequest={createEditOccurrenceState.citiesRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getCitiesByIdState(
                      values['stateId']
                    )
                  }
                  component={field => (
                    <Form.Select
                      name={field.name}
                      data-cy={dataCy(field.name)}
                      value={values[field.name]}
                      onChange={(e: any) => {
                        const value = e.target.value;

                        handleChange('neighborhoodId', '');
                        handleChange('subNeighborhoodId', '');
                        handleChange('localityId', '');

                        if (value) {
                          createEditOccurrenceUsecase.getNeighborhoodsByIdCity(
                            value
                          );
                          createEditOccurrenceUsecase.getSubNeighborhoodsByIdCity(
                            value
                          );
                          createEditOccurrenceUsecase.getLocalitiesByIdCity(
                            value
                          );
                        }

                        handleChange(field.name, value);
                      }}
                      onBlur={handleBlur}
                      disabled={!values['stateId']}
                      isInvalid={isInvalid(field.name)}
                    >
                      {createEditOccurrenceState.citiesRequestStatus.maybeMap({
                        idle: () => <option>Selecione</option>,
                        loading: () => <option>Carregando...</option>,
                        failed: () => (
                          <option>
                            Erro. Clique no botão acima para carregar as opções!
                          </option>
                        ),
                        succeeded(data) {
                          return (
                            <>
                              <option value="">Selecione</option>
                              {data?.map(city => (
                                <option value={city.id}> {city.name}</option>
                              ))}
                            </>
                          );
                        }
                      })}
                    </Form.Select>
                  )}
                />
              </Col>

              <Col md={2}>
                <FormItem
                  name="neighborhoodId"
                  label="Bairro"
                  failRequest={createEditOccurrenceState.neighborhoodsRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getNeighborhoodsByIdCity(
                      values['cityId']
                    )
                  }
                  component={field => (
                    <RefactorSelectWithSearch
                      dataCy={dataCy(field.name)}
                      options={createEditOccurrenceState.neighborhoodsRequestStatus.maybeMap(
                        {
                          idle: () => [],
                          loading: () => [],
                          failed: () => [],
                          succeeded(data) {
                            return data?.map(neighborhood => ({
                              label: neighborhood.name,
                              value: neighborhood.id
                            }));
                          }
                        }
                      )}
                      placeholder={createEditOccurrenceState.neighborhoodsRequestStatus.maybeMap(
                        {
                          idle: () => 'Selecione',
                          loading: () => 'Carregando...',
                          failed: () =>
                            'Erro. Clique no botão acima para carregar as opções!',
                          succeeded: () => 'Selecione'
                        }
                      )}
                      isDisabled={!values['cityId']}
                      onChange={option => handleChange(field.name, option)}
                      value={values[field.name]}
                    />
                  )}
                />
              </Col>

              <Col md={2}>
                <FormItem
                  name="subNeighborhoodId"
                  label="Sub-bairro"
                  failRequest={createEditOccurrenceState.subneighborhoodsRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getSubNeighborhoodsByIdCity(
                      values['cityId']
                    )
                  }
                  component={field => (
                    <RefactorSelectWithSearch
                      dataCy={dataCy(field.name)}
                      options={createEditOccurrenceState.subneighborhoodsRequestStatus.maybeMap(
                        {
                          idle: () => [],
                          loading: () => [],
                          failed: () => [],
                          succeeded(data) {
                            return data?.map(subneighborhood => ({
                              label: subneighborhood.name,
                              value: subneighborhood.id
                            }));
                          }
                        }
                      )}
                      placeholder={createEditOccurrenceState.neighborhoodsRequestStatus.maybeMap(
                        {
                          idle: () => 'Selecione',
                          loading: () => 'Carregando...',
                          failed: () =>
                            'Erro. Clique no botão acima para carregar as opções!',
                          succeeded: () => 'Selecione'
                        }
                      )}
                      isDisabled={!values['cityId']}
                      onChange={option => handleChange(field.name, option)}
                      value={values[field.name]}
                    />
                  )}
                />
              </Col>

              <Col md={2}>
                <FormItem
                  name="localityId"
                  label="Localidade"
                  failRequest={createEditOccurrenceState.localitiesRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getLocalitiesByIdCity(
                      values['cityId']
                    )
                  }
                  component={field => (
                    <RefactorSelectWithSearch
                      dataCy={dataCy(field.name)}
                      options={createEditOccurrenceState.localitiesRequestStatus.maybeMap(
                        {
                          idle: () => [],
                          loading: () => [],
                          failed: () => [],
                          succeeded(data) {
                            return data?.map(locality => ({
                              label: locality.name,
                              value: locality.id
                            }));
                          }
                        }
                      )}
                      placeholder={createEditOccurrenceState.localitiesRequestStatus.maybeMap(
                        {
                          idle: () => 'Selecione',
                          loading: () => 'Carregando...',
                          failed: () =>
                            'Erro. Clique no botão acima para carregar as opções!',
                          succeeded: () => 'Selecione'
                        }
                      )}
                      isDisabled={!values['cityId']}
                      onChange={option => handleChange(field.name, option)}
                      value={values[field.name]}
                    />
                  )}
                />
              </Col>
            </Row>
            <Divider height={24} />
            <Row>
              <Col md={6}>
                <Row>
                  <Col>
                    <FormItem
                      name="date"
                      label="Data da ocorrência*"
                      component={field => (
                        <Field
                          as="input"
                          type="datetime-local"
                          name={field.name}
                          data-cy={dataCy(field.name)}
                          value={values[field.name]}
                          max={new Date().toISOString().slice(0, 16)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                          onBlur={handleBlur}
                          className={
                            isInvalid(field.name)
                              ? 'form-control is-invalid'
                              : 'form-control'
                          }
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <FormItem
                      name="sourceId"
                      label="Fonte de registro*"
                      failRequest={createEditOccurrenceState.sourcesRequestStatus.maybeMap(
                        {
                          failed: () => true
                        }
                      )}
                      onPressedRefresh={() =>
                        createEditOccurrenceUsecase.getSources()
                      }
                      component={field => (
                        <Form.Select
                          name={field.name}
                          data-cy={dataCy(field.name)}
                          value={values[field.name]}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                          onBlur={handleBlur}
                          isInvalid={isInvalid(field.name)}
                        >
                          {createEditOccurrenceState.sourcesRequestStatus.maybeMap(
                            {
                              idle: () => <option>Carregando...</option>,
                              loading: () => <option>Carregando...</option>,
                              failed: () => (
                                <option>
                                  Erro. Clique no botão acima para carregar as
                                  opções!
                                </option>
                              ),
                              succeeded(data) {
                                return (
                                  <>
                                    <option value="">Selecione</option>
                                    {data?.map(source => (
                                      <option value={source.id}>
                                        {}
                                        {source.name}
                                      </option>
                                    ))}
                                  </>
                                );
                              }
                            }
                          )}
                        </Form.Select>
                      )}
                    />
                  </Col>
                </Row>
                <Divider height={24} />
                <Row>
                  <Col>
                    <FormItem
                      name="policeAction"
                      label="Houve ação policial*"
                      component={field => (
                        <FormCheckRadio
                          field={field}
                          dataCy={dataCy(field.name)}
                          handleChange={(name, value) => {
                            handleChange(name, value);

                            if (value) {
                              const mainReasonId =
                                createEditOccurrenceState.reasonActionPolice.id;
                              createEditOccurrenceState.contextInfoForm.mainReasonId =
                                mainReasonId;
                              createEditOccurrenceState.contextInfoForm.ref.current?.setFieldValue(
                                'mainReasonId',
                                mainReasonId
                              );
                              handleChange('agentPresence', value);
                            }
                          }}
                          isInvalid={isInvalid}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <FormItem
                      name="agentPresence"
                      label="Presença de agentes*"
                      component={field => (
                        <FormCheckRadio
                          field={field}
                          dataCy={dataCy(field.name)}
                          handleChange={handleChange}
                          isInvalid={isInvalid}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md={6}>
                    <FormItem
                      name="description"
                      label="Descrição da ocorrência*"
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name)}
                          as="textarea"
                          placeholder="Digite a descrição da ocorrência..."
                          rows={3}
                          style={{ minHeight: '125px' }}
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>

                  <Col md={6}>
                    <FormItem
                      name="relatedRecord"
                      label="Registro relacionado"
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name)}
                          as="textarea"
                          placeholder="Digite o registro relacionado..."
                          rows={3}
                          style={{ minHeight: '125px' }}
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      />
    </FormBody>
  );
};

export default CreateEditOcurrenceDetailsFormComponent;
