import * as Yup from 'yup';

const DEFAULT_STRING_FIELD = Yup.string().required('Campo é obrigatório');
const DEFAULT_BOOLEAN_FIELD = Yup.boolean()
  .required('Campo é obrigatório')
  .nullable();
const DEFAULT_ARRAY_FIELD = Yup.array().required('Campo é obrigatório');
const DEFAULT_AGENT_FIELD = Yup.string()
  .nullable()
  .when(['type_person'], {
    is: (type: any) => type === 'Agent',
    then: Yup.string()
      .nullable()
      .required(
        'Campo é obrigatório. Em caso de informação faltante, preencher com "Não identificado".'
      )
  });
const DEFAULT_POLITICAL_FIELD = Yup.string()
  .nullable()
  .when(['qualifications'], {
    is: (qualifications: any) => {
      return qualifications.find(
        (qualification: { label: string }) => qualification.label === 'Político'
      );
    },
    then: Yup.string()
      .nullable()
      .required(
        'Campo é obrigatório. Em caso de informação faltante, preencher com "Não identificado".'
      )
  });

export const schemaOccurrence = (pressSourceId: any) =>
  Yup.object().shape({
    address: DEFAULT_STRING_FIELD,
    state: DEFAULT_STRING_FIELD,
    city: DEFAULT_STRING_FIELD,
    date: DEFAULT_STRING_FIELD,
    source: DEFAULT_STRING_FIELD,
    police_action: DEFAULT_BOOLEAN_FIELD,
    agent_presence: DEFAULT_BOOLEAN_FIELD,
    main_reason: DEFAULT_STRING_FIELD,
    massacre: DEFAULT_BOOLEAN_FIELD,
    description: DEFAULT_STRING_FIELD,
    related_news: Yup.string()
      .nullable()
      .when(['source'], {
        is: (source: any) => source === pressSourceId,
        then: DEFAULT_STRING_FIELD
      }),
    occurrenceTransportsTransport: Yup.array().of(
      Yup.object().shape({
        transportId: DEFAULT_STRING_FIELD
      })
    ),
    victims: Yup.array().of(
      Yup.object().shape({
        situation: DEFAULT_STRING_FIELD,
        type_person: DEFAULT_STRING_FIELD,
        qualifications: DEFAULT_ARRAY_FIELD,
        circumstances: DEFAULT_ARRAY_FIELD,
        age_group: DEFAULT_STRING_FIELD,
        genre: DEFAULT_STRING_FIELD,
        race: DEFAULT_STRING_FIELD,
        place: DEFAULT_STRING_FIELD,
        death_date: Yup.string()
          .nullable()
          .when(['situation'], {
            is: (situation: any) => situation === 'Dead',
            then: Yup.string().nullable().required('Campo é obrigatório')
          }),
        coorporation: DEFAULT_AGENT_FIELD,
        agent_position: DEFAULT_AGENT_FIELD,
        agent_status: DEFAULT_AGENT_FIELD,
        partie: DEFAULT_POLITICAL_FIELD,
        political_status: DEFAULT_POLITICAL_FIELD,
        political_position: DEFAULT_POLITICAL_FIELD
      })
    ),
    victims_animal: Yup.array().of(
      Yup.object().shape({
        situation: DEFAULT_STRING_FIELD,
        animal_type: DEFAULT_STRING_FIELD,
        circumstances: DEFAULT_ARRAY_FIELD
      })
    )
  });
