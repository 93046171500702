import { AxiosError } from 'axios';
import { AppError } from './AppError';

abstract class HttpError extends AppError {
  public readonly statusCode: number;

  protected constructor(
    slug: string,
    message: string,
    stackTrace: string,
    statusCode: number = -1
  ) {
    super(slug, message, stackTrace);
    this.statusCode = statusCode;
  }

  toString = (): string =>
    `[${this.constructor.name}]: \nslug: ${this.slug}, \nmessage: ${this.message}, \nstackTrace: ${this.stackTrace}, \nstatusCode: ${this.statusCode}`;

  // TODO: Handle error message
  static parseHttpError(error: AxiosError) {
    switch (error.response?.status) {
      case 400:
        return new HttpBadRequestError();
      case 401:
        return new HttpUnauthorizedError();
      case 403:
        return new HttpForbiddenError();
      case 404:
        return new HttpNotFoundError();
      case 410:
        return new HttpGoneError();
      case 500:
        return new HttpInternalServerError();
      default:
        return new HttpUnknownError();
    }
  }
}

class HttpUnknownError extends HttpError {
  constructor(
    slug: string = 'unknown',
    message: string = 'Unknown error',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace);
  }
}

class HttpBadRequestError extends HttpError {
  constructor(
    slug: string = 'bad_request',
    message: string = 'Bad request',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 400);
  }
}

class HttpUnauthorizedError extends HttpError {
  constructor(
    slug: string = 'unauthorized',
    message: string = 'Unauthorized',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 401);
  }
}

class HttpForbiddenError extends HttpError {
  constructor(
    slug: string = 'forbidden',
    message: string = 'Forbidden',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 403);
  }
}

class HttpNotFoundError extends HttpError {
  constructor(
    slug: string = 'not_found',
    message: string = 'Not found',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 404);
  }
}

class HttpGoneError extends HttpError {
  constructor(
    slug: string = 'gone',
    message: string = 'Gone',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 410);
  }
}

class HttpInternalServerError extends HttpError {
  constructor(
    slug: string = 'internal_server_error',
    message: string = 'Internal server error',
    stackTrace: string = ''
  ) {
    super(slug, message, stackTrace, 500);
  }
}

export {
  HttpError,
  HttpUnknownError,
  HttpBadRequestError,
  HttpUnauthorizedError,
  HttpForbiddenError,
  HttpNotFoundError,
  HttpGoneError,
  HttpInternalServerError
};
