import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

export const ibge = axios.create({
  baseURL: process.env.REACT_APP_IBGE_URL
});

export const file_api = axios.create({
  baseURL: process.env.REACT_APP_FILE_API_URL
});

export const temp = axios.create({
  baseURL: 'http://banco-cms.herokuapp.com/'
});

export const imgs = axios.create({
  baseURL: 'https://gustavoleaonogueira.com.br/api-upload'
});

export const apiteste = axios.create({
  baseURL: 'https://orcamento-cria.herokuapp.com'
});
