export class UseContextNotifier<T> {
  public state: T;
  public setState: React.Dispatch<React.SetStateAction<T>>;
  private _listener: ((value: T) => void) | undefined;

  constructor(state: T, setState: React.Dispatch<React.SetStateAction<T>>) {
    this.state = state;
    this.setState = setState;
  }

  public updateState(newState: T) {
    this.state = newState;
    this.setState(newState);
    if (this._listener) {
      this._listener(newState);
    }
  }

  seedState(newState: T) {
    this.state = newState;
    this.setState(newState);
  }

  setListener(listener: (value: T) => void) {
    this._listener = listener;
  }
}
