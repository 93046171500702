import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export interface PropsDiv {
  type: string;
}

export const HeaderCover = styled(NavLink)`
  position: relative;
`;

export const TypePost = styled.div<PropsDiv>`
  ${props => {
    switch (props.type) {
      case 'Rascunho':
        return `background-color: #7367F0;`;
      case 'Agendada':
        return `background-color: #FE8C00;`;
      case 'Publicada':
        return `background-color: #28C76F;`;
      default:
        return ` background-color: #28C76F;`;
    }
  }}

  padding: 4px;
  color: #fff;
  border: none;
  border-radius: 6px;
  position: absolute;
  top: 16px;
  right: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const LinkStyled = styled.h3`
  text-decoration: none;
  color: #2c394190;
  font-size: 18px;
  line-height: 25px;
  margin-bottom: 16px;
  transition: 0.3s;
`;

export const Container = styled(NavLink)`
  text-decoration: none;
  padding-left: 20px;
  padding-top: 20px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 360px;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  object-fit: cover;
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #dfdfdf;
  filter: drop-shadow(rgb(0 0 0 / 15%) 0px 0px 30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 413px;
  width: 100%;
  min-height: 413px;

  cursor: pointer !important;
  text-decoration: none !important;
  overflow: hidden !important;

  :hover {
    filter: drop-shadow(rgb(0 0 0 / 35%) 0px 0px 30px);
  }

  :hover ${LinkStyled} {
    color: ${props => props.theme.colors.tertiary};
    transition: 0.3s;
  }

  :hover ${Image} {
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

export const ContainerSwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 20px;
`;

export const ImageIcon = styled.img`
  width: 20px;
  height: auto;
  margin-right: 10px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tag = styled.p`
  color: ${props => props.theme.colors.black};
  background-color: ${props => props.theme.colors.gray};
  padding: 4px 8px;
  border-radius: 50px;
  margin-bottom: 8px;
  width: max-content;
  margin: 0 0 10px 0;
`;

export const Content = styled.div`
  padding: 24px 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    color: #5a5a5a;
    margin: 0 !important;
  }

  > div:nth-child(1) {
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
`;
