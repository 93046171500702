import { api } from '../index';

export async function getArticles(
  token: string,
  type_of_media?: string,
  scope?: string,
  state?: string,
  year?: string,
  field_knowledge?: string,
  federative_power?: string,
  document_type?: string,
  subject_area?: string,
  page?: string,
  take?: string,
  order?: string
) {
  try {
    let params = new URLSearchParams();

    if (type_of_media != undefined && type_of_media != '') {
      params.append('type_of_media', type_of_media);
    }
    if (scope != undefined && scope != '') {
      params.append('scope', scope);
    }
    if (state != undefined && state != '') {
      params.append('state', state);
    }
    if (year != undefined && year != '') {
      params.append('year', year);
    }
    if (field_knowledge != undefined && field_knowledge != '') {
      params.append('field_knowledge', field_knowledge);
    }
    if (federative_power != undefined && federative_power != '') {
      params.append('federative_power', federative_power);
    }
    if (document_type != undefined && document_type != '') {
      params.append('document_type', document_type);
    }
    if (subject_area != undefined && subject_area != '') {
      params.append('subject_area', subject_area);
    }
    if (order != undefined && order != '') {
      params.append('order', order);
    }
    if (take != undefined && take != '') {
      params.append('take', take);
    }
    if (page != undefined && page != '') {
      params.append('page', page);
    }

    const resposta = await api.get('/articles', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Artigos) erro: ', erro);
    return null;
  }
}

export async function editArticles(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/articles/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Artigos) erro: ', erro);
    return null;
  }
}

export async function deleteArticles(token: string, id: string) {
  try {
    const resposta = await api.delete(`/articles/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Artigos) erro: ', erro);
    return null;
  }
}

export async function createArticles(token: string, dados: any) {
  try {
    const resposta = await api.post(`/articles`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Artigos) erro: ', erro);
    return null;
  }
}

export async function getByParams(token: string) {}
