import styled from 'styled-components';

export const Container = styled.div``;

export const ImageOpen = styled.img`
  width: 192px;
  height: 141px;
  object-fit: cover;
`;

export const Icon = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const ContainerCard = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 46px;
`;

export const Card = styled.div`
  width: 239px;
  height: 170px;
  background: #f8f8f8;
  border: 1px solid #ebe9f1;

  > h3 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #2c3941;
    margin: 14px 8px 0 12px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    margin: 0 12px;
  }
`;

export const Data = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #6e6b7b;
`;

export const Content = styled.div`
  > div {
    display: flex;
    align-items: flex-start;

    > div {
      display: flex;
      flex-direction: column;
      align-items: left;
      margin-left: 10px;
      > p {
        margin: 0 !important;
        font-family: 'Montserrat' !important;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        font-style: normal;
      }
    }
  }

  > section {
    display: flex;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: flex-start;
      > div {
        display: flex;
        flex-direction: column;
        align-items: left;
        margin-left: 10px;
        > p {
          margin: 0 !important;
          font-family: 'Montserrat' !important;
          font-weight: 400;
          font-size: 12px;
          line-height: 21px;
          font-style: normal;
        }
      }
    }
  }
`;

export const NoStyle = styled.div`
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 21px;
    color: #2c3941;
  }
`;

export const NoStyleTwo = styled.div`
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #2c3941;
  }
`;

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const CardValues = styled.div`
  width: 220px;

  > div {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;

    > img {
      background: white !important;
      margin-right: 10px;
    }

    > div {
      > p {
        margin: 0 !important;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 21px;
        color: #6e6b7b;
      }
    }
  }

  > div:nth-child(3) {
    width: 110px;
    height: 71px;
    background: #ffffff;
    border: 1px solid #ebe9f1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const TitleTab = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #6e6b7b;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Partners = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 20px 20px;

  > div {
    text-decoration: none;
    width: 200px !important;
    height: 75px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));

    > img {
      width: 158.38px;
      height: 25.69px;
    }
  }
`;
