/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from 'react';
import {
  Header,
  Nav,
  ShowOccurence,
  Content,
  List,
  Search,
  SearchGroup,
  Container,
  ContainerBtn,
  PrevNext,
  AtualPage,
  LoadingContainer
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootState,
  ADD_OCCURRENCES,
  ADD_STATES,
  ADD_REGIONS,
  ADD_CITIES,
  ADD_SOURCES,
  ADD_REASONS,
  ADD_CLIPPINGS,
  ADD_TRANSPORTS,
  ADD_NEIGHBORHOODS,
  ADD_QUALIFICATIONS,
  ADD_AGE_GROUP,
  ADD_GENRES,
  ADD_CIRCUMSTANCES,
  ADD_PLACES,
  ADD_ANIMALS,
  ADD_STATUS,
  ADD_POSITIONS,
  ADD_COORPORATIONS,
  ADD_SUBNEIGHBORHOODS,
  ADD_LOCALITIES
} from '../../store';
import {
  getStates,
  getRegions,
  getCities,
  getSourcers,
  getReasons,
  getClippings,
  getTransports,
  getNeighborhoods,
  getQualifications,
  getAgeGroup,
  getGenres,
  getCircumstances,
  getPositions,
  getCoorporations,
  getStatus,
  getAnimals,
  getPlaces,
  getOccurrencesByParams,
  getSubNeighborhoods,
  getLocalities
} from '../../services';
import { ButtonSubline, CardOccurrence, InputData } from '../../components';
import { RegisterForm, ModalReprovar } from './Modals/index';

import { lupa, learnMore } from '../../assets/index';
import RadioButon from '../../components/RadioButton';
import {
  convertOfTimestampsToDateTime,
  convertToDateAndHours
} from '../../utils/date';
import SocketContext from '../../context/socket';
import Loading from '../../components/Loading';
import { isDefined } from '../../utils/definedValue';
import { useNavigate } from 'react-router-dom';

enum OccurrenceScreenType {
  approveOccurrences,
  allOccurrences
}

interface IResultMetadata {
  total: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const Occurrences: React.FC = ({}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    user,
    token,
    occurrences,
    states,
    cities,
    circumstances,
    qualifications,
    places
  } = useSelector((state: RootState) => state.clickState);

  const { listOfOccurrences } = useContext(SocketContext);
  const [occurrenceScreenType, setOccurrenceScreenType] =
    useState<OccurrenceScreenType>(OccurrenceScreenType.approveOccurrences);

  const [isRegisterModal, setRegisterModal] = useState(false);
  const [estado, setEstado] = useState<any>(undefined);
  const [regiao, setRegiao] = useState<any>(undefined);
  const [cidade, setCidade] = useState<any>(undefined);

  const [order, setOrder] = useState('DESC');
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);

  const [initial_date, setInitialDate] = useState('2000-01-01');
  const [final_date, setFinalDate] = useState('2999-12-31');
  const [search, setSearch] = useState('');
  const [idOccorencia, setIdOcorrencia] = useState('');
  const [isAlterarStatusOcorrencia, setModalAlterarStatusOcorrencia] =
    useState(false);
  const [isAprove, setIsAprove] = useState(false);
  const [isReprovarOccorrencia, setModalReprovarOccorrencia] = useState(false);
  const [ocorrenciaTemp, setOccorenciaTemp] = useState<any>(null);
  const [ordenacao, setOrdenacao] = useState('dtc');

  const [circumstance, setCircumstance] = useState('');
  const [qualification, setQualification] = useState('');
  const [place, setPlace] = useState('');

  const [typeOccurrences, setTypeOccurrences] = useState<any>('Waiting');

  const [resultMetada, setResultMetada] = useState<IResultMetadata>({
    total: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  const [isLoading, setIsLoading] = useState(false);

  function isNumeric(val: any) {
    return /^-?\d+$/.test(val);
  }

  function getOcorrencesByParams() {
    setIsLoading(true);

    let dt1 = undefined;
    let dt2 = undefined;

    if (initial_date !== '') {
      dt1 = initial_date + 'T00:00:00.000';
      dt2 = final_date + 'T23:59:59.000';
    } else {
      dt1 = '2000-01-01T00:00:00.000';
      dt2 = '2999-12-31T23:59:59.000';
    }

    let dt1_dtoc = undefined;
    let dt2_dtoc = undefined;
    let dt1_c = undefined;
    let dt2_c = undefined;

    if (ordenacao === 'dtoc') {
      dt1_dtoc = dt1;
      dt2_dtoc = dt2;
    } else if (ordenacao == 'dtc') {
      dt1_c = dt1;
      dt2_c = dt2;
    }

    getOccurrencesByParams(
      token,
      order,
      page,
      take,
      typeOccurrences,
      dt1_dtoc,
      dt2_dtoc,
      cidade?.length > 0 ? cidade : undefined,
      !isDefined(estado) ? (user as any)?.region?.id : undefined,
      isNumeric(search) === false && search?.length > 0 ? search : undefined,
      isNumeric(search) === true ? search : undefined,
      estado?.length > 1 ? estado : undefined,
      dt1_c,
      dt2_c,
      undefined,
      circumstance,
      qualification,
      place
    ).then(resp => {
      dispatch({
        type: ADD_OCCURRENCES,
        occurrences: resp.data
      });
      setResultMetada({
        total: resp.meta.itemCount,
        hasNextPage: resp.meta.hasNextPage,
        hasPreviousPage: resp.meta.hasPreviousPage
      });
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getOcorrencesByParams();
  }, [
    typeOccurrences,
    initial_date,
    final_date,
    page,
    cidade,
    regiao,
    isRegisterModal,
    isReprovarOccorrencia,
    token,
    order,
    take,
    occurrenceScreenType,
    estado,
    dispatch,
    ordenacao,
    listOfOccurrences,
    circumstance,
    place,
    qualification
  ]);

  const list = ['Aprovar ocorrências', 'Todas as ocorrências'];

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
    getCities(token).then(resp => {
      dispatch({ type: ADD_CITIES, cities: resp });
    });
    getSourcers(token).then(resp => {
      dispatch({ type: ADD_SOURCES, sources: resp });
    });
    getReasons(token).then(resp => {
      dispatch({ type: ADD_REASONS, reasons: resp });
    });
    getCircumstances(token).then(resp => {
      dispatch({ type: ADD_CIRCUMSTANCES, circumstances: resp });
    });
    getClippings(token).then(resp => {
      dispatch({ type: ADD_CLIPPINGS, clippings: resp });
    });
    getNeighborhoods(token).then(resp => {
      dispatch({ type: ADD_NEIGHBORHOODS, neighborhoods: resp });
    });
    getSubNeighborhoods(token).then(resp => {
      dispatch({ type: ADD_SUBNEIGHBORHOODS, subneighborhoods: resp });
    });
    getLocalities(token).then(resp => {
      dispatch({ type: ADD_LOCALITIES, localities: resp });
    });
    getStatus(token).then(resp => {
      dispatch({ type: ADD_STATUS, status: resp });
    });
    getQualifications(token).then(resp => {
      dispatch({ type: ADD_QUALIFICATIONS, qualifications: resp });
    });
    getTransports(token).then(resp => {
      dispatch({ type: ADD_TRANSPORTS, transports: resp });
    });
    getAnimals(token).then(resp => {
      dispatch({ type: ADD_ANIMALS, animals: resp });
    });
    getAgeGroup(token).then(resp => {
      dispatch({ type: ADD_AGE_GROUP, age_group: resp });
    });
    getGenres(token).then(resp => {
      dispatch({ type: ADD_GENRES, genres: resp });
    });
    getPlaces(token).then(resp => {
      dispatch({ type: ADD_PLACES, places: resp });
    });
    getPositions(token).then(resp => {
      dispatch({ type: ADD_POSITIONS, positions: resp });
    });
    getCoorporations(token).then(resp => {
      dispatch({ type: ADD_COORPORATIONS, coorporations: resp });
    });
  }, []);

  const getOccurrenceUserName = (occurrence: any) => {
    const defaultName = 'Desconhecido';
    let userName = occurrence?.user?.name || occurrence?.user?.nickname || '';

    return userName ? userName : defaultName;
  };

  const getOccurrenceAdminName = (occurrence: any) => {
    const defaultName = 'Desconhecido';
    let adminName =
      occurrence?.admin?.name || occurrence?.admin?.nickname || '';

    return adminName ? adminName : defaultName;
  };

  return (
    <Container>
      <Header>
        <Nav>
          <ButtonSubline
            list={list}
            onClick={() => {
              if (
                occurrenceScreenType === OccurrenceScreenType.allOccurrences
              ) {
                setOccurrenceScreenType(
                  OccurrenceScreenType.approveOccurrences
                );
                setTypeOccurrences('Waiting');
              } else {
                setOccurrenceScreenType(OccurrenceScreenType.allOccurrences);
                setTypeOccurrences(undefined);
              }
            }}
          />
        </Nav>
        <ShowOccurence
          data-cy="btn-register-occurence"
          onClick={() => navigate('/occurrences/create')}
        >
          Registrar ocorrências
        </ShowOccurence>
      </Header>
      <Content>
        <Search>
          <label htmlFor="search">
            <img src={lupa} width={15} />
          </label>
          <input
            type="search"
            name="search"
            value={search}
            onKeyDown={e => {
              if (e.key === 'Enter') getOcorrencesByParams();
            }}
            onChange={(e: any) => setSearch(e?.target?.value)}
            id="search"
            placeholder="Pesquisar"
          />
        </Search>

        <div>
          <label>Data Inicial</label>
          <InputData
            id="data"
            htmlFor="data"
            name="data"
            type="date"
            value={initial_date}
            onChange={(e: any) => {
              setInitialDate(e.target.value);
              setFinalDate(e.target.value);
            }}
          />
        </div>

        <div>
          <label>Data Final</label>
          <InputData
            id="data"
            htmlFor="data"
            name="data"
            type="date"
            value={final_date}
            onChange={(e: any) => {
              setFinalDate(e.target.value);
            }}
          />
        </div>
        <select
          value={estado}
          onChange={(e: any) => {
            setEstado(e?.target?.value);
          }}
        >
          <option value="">Selecione o estado</option>
          <option value=" ">Institucional</option>
          {states?.map((chave: any) => {
            return <option value={chave?.id}>{chave?.name}</option>;
          })}
        </select>
        <select
          value={cidade}
          onChange={e => {
            setCidade(e.target.value);
          }}
          id="cidade"
          disabled={
            estado != undefined ? (estado?.length <= 0 ? true : false) : false
          }
        >
          <option value="">Selecione a cidade</option>
          {cities?.map((chave: any, valor: any) => {
            if (chave.state != null && chave.state.id === estado) {
              return <option value={chave.id}>{chave.name}</option>;
            }
          })}
        </select>
      </Content>

      <Content>
        <select
          value={circumstance}
          onChange={(e: any) => {
            setCircumstance(e?.target?.value);
          }}
        >
          <option value="">Circunstância</option>
          {circumstances?.map((chave: any) => {
            return <option value={chave?.id}>{chave?.name}</option>;
          })}
        </select>

        <select
          value={qualification}
          onChange={(e: any) => {
            setQualification(e?.target?.value);
          }}
        >
          <option value="">Qualificação</option>
          {qualifications?.map((chave: any) => {
            return <option value={chave?.id}>{chave?.name}</option>;
          })}
        </select>

        <select
          value={place}
          onChange={(e: any) => {
            setPlace(e?.target?.value);
          }}
        >
          <option value="">Local</option>
          {places?.map((chave: any) => {
            return <option value={chave?.id}>{chave?.name}</option>;
          })}
        </select>
      </Content>

      {occurrenceScreenType == OccurrenceScreenType.allOccurrences && (
        <SearchGroup>
          <div>
            <div>
              <RadioButon
                id="todas"
                labelText="Todas"
                name="type_occurrences"
                value=""
                onChange={() => {
                  setTypeOccurrences(undefined);
                }}
                checked={typeOccurrences === undefined}
              />
            </div>
            <div>
              <RadioButon
                id="Waiting"
                labelText="Apenas aguardando aprovação"
                name="type_occurrences"
                value="Waiting"
                onChange={() => {
                  setTypeOccurrences('Waiting');
                }}
                checked={typeOccurrences === 'Waiting'}
              />
            </div>
            <div>
              <RadioButon
                id="Approved"
                labelText="Apenas aprovadas"
                name="type_occurrences"
                value="Approved"
                onChange={() => {
                  setTypeOccurrences('Approved');
                }}
                checked={typeOccurrences === 'Approved'}
              />
            </div>
            <div>
              <RadioButon
                id="Disapproved"
                labelText="Apenas reprovadas"
                name="type_occurrences"
                value="Disapproved"
                onChange={() => {
                  setTypeOccurrences('Disapproved');
                }}
                checked={typeOccurrences === 'Disapproved'}
              />
            </div>
          </div>
          <div>
            <label>Ordenar por:</label>
            <select
              onChange={(e: any) => {
                setOrdenacao(e.target.value);
              }}
            >
              <option value="dtc">Data da criação</option>
              <option value="dtoc">Data da ocorrência</option>
            </select>
          </div>
          <div>
            <select
              onChange={(e: any) => {
                setOrder(e.target.value);
              }}
            >
              <option value="DESC">Descendente</option>
              <option value="ASC">Ascendente</option>
            </select>
          </div>
        </SearchGroup>
      )}

      {isLoading ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <List>
            <div>
              <p>Total encontrado: {resultMetada.total}</p>
              {!isDefined(estado) ? (
                <>
                  <h5>
                    Você está visualizando as ocorrências da sua região, caso
                    queira ver as ocorrências de outro estado selecione-o no
                    filtro.
                  </h5>
                  <br />
                </>
              ) : (
                <></>
              )}
            </div>
            {occurrenceScreenType == OccurrenceScreenType.allOccurrences ? (
              <>
                {occurrences?.map((occurrence: any, value) => {
                  return (
                    <CardOccurrence
                      id={occurrence.id}
                      document_number={occurrence.document_number}
                      status={occurrence.status}
                      user={getOccurrenceUserName(occurrence)}
                      admin={getOccurrenceAdminName(occurrence)}
                      date={convertToDateAndHours(occurrence.date)}
                      createdAt={convertOfTimestampsToDateTime(
                        occurrence.createdAt
                      )}
                      address={occurrence.address}
                      civiliansWounded={occurrence.number_civilians_wounded}
                      civiliansDead={occurrence.number_civilians_dead}
                      agentDead={occurrence.number_agent_dead}
                      agentWounded={occurrence.number_agent_wounded}
                      description={occurrence.description}
                      state={
                        occurrence.state === null ? '' : occurrence.state.name
                      }
                      aprove={() => {
                        switch (occurrence.status) {
                          case 'Waiting':
                            setRegisterModal(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                            break;
                          case 'Approved':
                            setRegisterModal(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                            break;
                          case 'Disapproved':
                            setRegisterModal(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                            break;
                          default:
                            setRegisterModal(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                        }
                      }}
                      reprove={() => {
                        if (occurrence.status === 'Waiting') {
                          setModalAlterarStatusOcorrencia(true);
                          setIdOcorrencia(occurrence.id);
                          setOccorenciaTemp(occurrence);
                        } else if (occurrence.status === 'Approved') {
                          setModalAlterarStatusOcorrencia(true);
                          setIdOcorrencia(occurrence.id);
                          setOccorenciaTemp(occurrence);
                        }
                      }}
                      changeStatus={() => {
                        setIsAprove(true);
                        setModalAlterarStatusOcorrencia(true);
                        setIdOcorrencia(occurrence.id);
                        setOccorenciaTemp(occurrence);
                      }}
                    />
                  );
                })}
              </>
            ) : (
              <>
                {occurrences?.map((occurrence: any, value) => {
                  if (occurrence.status === 'Waiting') {
                    return (
                      <CardOccurrence
                        key={occurrence.id}
                        id={occurrence.id}
                        document_number={occurrence.document_number}
                        status={occurrence.status}
                        user={getOccurrenceUserName(occurrence)}
                        admin={getOccurrenceAdminName(occurrence)}
                        date={convertToDateAndHours(occurrence.date)}
                        createdAt={convertOfTimestampsToDateTime(
                          occurrence.createdAt
                        )}
                        address={occurrence.address}
                        civiliansWounded={occurrence.number_civilians_wounded}
                        civiliansDead={occurrence.number_civilians_dead}
                        agentDead={occurrence.number_agent_dead}
                        agentWounded={occurrence.number_agent_wounded}
                        description={occurrence.description}
                        state={
                          occurrence.state === null ? '' : occurrence.state.name
                        }
                        aprove={() => {
                          switch (occurrence.status) {
                            case 'Waiting':
                              setRegisterModal(true);
                              setIdOcorrencia(occurrence.id);
                              setOccorenciaTemp(occurrence);
                              break;
                            case 'Approved':
                              setRegisterModal(true);
                              setIdOcorrencia(occurrence.id);
                              setOccorenciaTemp(occurrence);
                              break;
                            case 'Disapproved':
                              setRegisterModal(true);
                              setIdOcorrencia(occurrence.id);
                              setOccorenciaTemp(occurrence);
                              break;
                            default:
                              setRegisterModal(true);
                              setIdOcorrencia(occurrence.id);
                              setOccorenciaTemp(occurrence);
                          }
                        }}
                        reprove={() => {
                          if (occurrence.status === 'Waiting') {
                            setModalAlterarStatusOcorrencia(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                          } else if (occurrence.status === 'Approved') {
                            setModalAlterarStatusOcorrencia(true);
                            setIdOcorrencia(occurrence.id);
                            setOccorenciaTemp(occurrence);
                          }
                        }}
                        changeStatus={() => {
                          setIsAprove(true);
                          setModalAlterarStatusOcorrencia(true);
                          setIdOcorrencia(occurrence.id);
                          setOccorenciaTemp(occurrence);
                        }}
                      />
                    );
                  }
                })}
              </>
            )}
          </List>
        </>
      )}

      <ContainerBtn>
        <PrevNext
          to="prev"
          onClick={() => {
            if (resultMetada.hasPreviousPage && page > 1) {
              let cont = page - 1;
              setPage(cont);
            }
          }}
          disabled={!resultMetada.hasPreviousPage}
        >
          <img src={learnMore} alt="" />
        </PrevNext>
        <AtualPage>{page}</AtualPage>
        <PrevNext
          to="next"
          onClick={() => {
            if (resultMetada.hasNextPage) {
              let cont = page + 1;
              setPage(cont);
            }
          }}
          disabled={!resultMetada.hasNextPage}
        >
          <img src={learnMore} alt="" />
        </PrevNext>
      </ContainerBtn>

      <ModalReprovar
        open={isAlterarStatusOcorrencia}
        onClose={() => {
          setOccorenciaTemp(null);
          setModalAlterarStatusOcorrencia(false);
          setIsAprove(false);
        }}
        occurrence={ocorrenciaTemp}
        dataDt={{
          typeOccurrences: typeOccurrences,
          initial_date: initial_date,
          final_date: final_date,
          search: search,
          page: page,
          cidade: cidade,
          regiao: regiao,
          isRegisterModal: isRegisterModal,
          isReprovarOccorrencia: isReprovarOccorrencia,
          order: order,
          take: take,
          estado: estado,
          ordenacao: ordenacao
        }}
        isAproovedModel={isAprove}
      />

      <RegisterForm
        isModal={isRegisterModal}
        onHide={() => {
          setRegisterModal(false);
          setOccorenciaTemp(null);
        }}
        itemEdit={ocorrenciaTemp}
      />
    </Container>
  );
};

export default Occurrences;
