import React from 'react';
import { Spinner } from 'react-bootstrap';

import { Container } from './index.style';

interface IProps {
  size?: 'sm' | undefined;
}

const Loading: React.FC<IProps> = props => {
  return (
    <Container>
      <Spinner animation="border" size={props.size} />
    </Container>
  );
};

export default Loading;
