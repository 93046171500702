import styled from 'styled-components';

export const ContainerStyled = styled.div`
  height: 100%;
  min-height: 100vh;
  padding: 16px;
`;

export const BtnOrange = styled.button`
  padding: 12px;
  color: #fff;
  background: ${props => props.theme.colors.secondary};
  border-radius: 6px;
  text-align: center;
  border: none;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleMenu = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2c3941;
`;

export const N = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 16px;
`;

export const NHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 9px;
  background-color: #e4e5e6;

  height: 61px;

  border-radius: 6px 6px 0 0;
`;

export const NBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 20px;

  background-color: #ffffff;

  border-radius: 0 0 6px 6px;

  & > .left {
    & > h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #2c3941;
    }
    & > p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #6e6b7b;

      text-align: justify;
    }
  }

  & > .right {
    margin: 24px;
  }
  & > .right > div {
    display: flex;
    flex-direction: row;
    margin-top: 12px;
  }
`;

export const ButtonSend = styled.button`
  width: 203px;
  height: 42px;

  ${props => {
    if (props.disabled == false) {
      return `
                background: #E0733D;
                color: #fff;
                border: none;
                border-radius: 6px;

                & > span {
                    color: #fff;
                }
            `;
    } else {
      return `
                background: #A8A6B0;
                color: #fff;
                border: none;
                border-radius: 6px;


                & > span {
                    color: #fff;
                }
            `;
    }
  }}
`;
export const ButtonEdit = styled.button`
  width: 138px;
  height: 42px;
  margin-right: 12px;

  ${props => {
    if (props.disabled == false) {
      return `
                background: #fff;
                color: #000;
                border: 1px solid #000;
                border-radius: 6px;


                & > span {
                    color: #fff;
                }
            `;
    } else {
      return `
                background: #fff;
                color: #A8A6B0;
                border: 1px solid #A8A6B0;
                border-radius: 6px;


                & > span {
                    color: #fff;
                }
            `;
    }
  }}
`;

export const ButtonDelte = styled.button`
  width: 53px;
  height: 42px;

  ${props => {
    if (props.disabled == false) {
      return `
                background: #fff;
                color: red;
                border-radius: 6px;
                border: 1px solid red;
            `;
    } else {
      return `
                background: #fff;
                color: #A8A6B0;
                border: 1px solid #A8A6B0;
                border-radius: 6px;


                & > span {
                    color: #fff;
                }
            `;
    }
  }}
`;

export const NHeaderCreatedAt = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    margin-right: 12px;
    & > p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      color: #2c3941;
    }

    & > h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #6e6b7b;
      margin-top: 11px;
      margin-bottom: 1px !important;
    }
  }
`;
export const NHeaderName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    margin-right: 12px;
    & > p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      color: #2c3941;
    }

    & > h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #6e6b7b;
      margin-top: 11px;
      margin-bottom: 1px !important;
    }
  }
`;
export const NHeaderScheduling = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: center;

  & > div {
    margin-right: 12px;

    & > p {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 21px;
      color: #2c3941;
    }

    & > h4 {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 21px;
      color: #6e6b7b;
      margin-top: 11px;
      margin-bottom: 1px !important;
    }
  }
`;
export const NHeaderTags = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const NHeaderTag = styled.div`
  background: rgba(115, 103, 240, 0.15);
  padding: 9px;
  border-radius: 17px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #7367f0;

  margin-left: 12px;
`;

export const NLeftHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NRightHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ImageNotification = styled.img`
  width: 32px;
  height: 32px;
`;
