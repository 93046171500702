import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  editicon,
  editIconOrange,
  editWhiteIcon,
  redTrash
} from '../../assets';
import {
  deleteImage,
  editImage,
  getImageById,
  postImage
} from '../../services';
import { RootState } from '../../store';
import {
  CardImage,
  ButtonDelete,
  ImageStyled,
  ButtonUpload,
  ButtonEdit
} from './index.styled';
import { IProps } from './index.types';

import base64 from 'base-64';

const ImgUpEdit: React.FC<IProps> = ({ setValue, getValues, id, file }) => {
  const referencia = useRef<HTMLInputElement>(null);
  const [img, setImg] = useState('');

  useEffect(() => {
    console.log('Valores:', getValues(id), getValues(file));
    if (getValues(id) != '') {
      getImageById(token, getValues(id)).then(resp => {
        setImg(resp.file);
      });
    }
  }, [id, file]);

  const { token } = useSelector((state: RootState) => state.clickState);

  const [tempId, setTempId] = useState('');

  return (
    <>
      <Form.Control
        type="file"
        ref={referencia}
        accept="image/png,image/gif,image/jpeg"
        onChange={(e: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          reader.onloadend = () => {
            let base64data = reader.result;
            let dados = { file: base64data };

            editImage(token, getValues(id), dados).then(r => {
              getImageById(token, getValues(id)).then(resp => {
                setValue(file, resp.file);
                setImg(resp.file);

                console.log(resp, getValues(id), getValues(file));
              });
            });
          };
        }}
        multiple={false}
        style={{ display: 'none' }}
      />

      {img == '' || img == null || img == undefined ? (
        <ButtonUpload
          type="button"
          onClick={() => {
            referencia?.current?.click();
          }}
        >
          Fazer upload da imagem
        </ButtonUpload>
      ) : (
        <CardImage>
          <ImageStyled src={img} />

          <ButtonDelete
            type="button"
            onClick={() => {
              setValue(file, '');
              setImg('');

              referencia?.current?.click();
            }}
          >
            <img src={redTrash} />
          </ButtonDelete>
        </CardImage>
      )}
    </>
  );
};

export default ImgUpEdit;
