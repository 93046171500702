import { ApiRepository } from '../../../../shared/domain/repositories/ApiRepository';
import { OccurrenceEndpoint } from '../../../../shared/endpoint';
import { IResult } from '../../../../shared/interface/result';
import { AppUnknownError } from '../../../../shared/types/AppError';
import { Result } from '../../../../shared/types/Result';
import { AgeGroupModel } from '../../domain/models/AgeGroupModel';
import { AnimalModel } from '../../domain/models/AnimalModel';
import { CircumstanceModel } from '../../domain/models/CircumstanceModel';
import { ClippingModel } from '../../domain/models/ClippingModel';
import { CoorporationModel } from '../../domain/models/CoorporationModel';
import { GenreModel } from '../../domain/models/GenreModel';
import { PartieModel } from '../../domain/models/PartieModel';
import { PlaceModel } from '../../domain/models/PlaceModel';
import { PositionModel } from '../../domain/models/PositionModel';
import { QualificationModel } from '../../domain/models/QualificationModel';
import { RaceModel } from '../../domain/models/RaceModel';
import { ReasonModel } from '../../domain/models/ReasonModel';
import { SourceModel } from '../../domain/models/SourceModel';
import { StatusModel } from '../../domain/models/StatusModel';
import { TransportModel } from '../../domain/models/TransportModel';
import { OccurrenceRepository } from '../../domain/repositories/OccurrenceRepository';
import { AgeGroupEntity } from '../entities/AgeGroupEntity';
import { AnimalEntity } from '../entities/AnimalEntity';
import { CircumstanceEntity } from '../entities/CircumstanceEntity';
import { ClippingEntity } from '../entities/ClippingEntity';
import { CreateEditOccurrenceEntity } from '../entities/CreateEditOccurrenceEntity';
import { CoorporationEntity } from '../entities/CoorporationEntity';
import { GenreEntity } from '../entities/GenreEntity';
import { PartieEntity } from '../entities/PartieEntity';
import { PlaceEntity } from '../entities/PlaceEntity';
import { PositionEntity } from '../entities/PositionEntity';
import { QualificationEntity } from '../entities/QualificationEntity';
import { RaceEntity } from '../entities/RaceEntity';
import { ReasonEntity } from '../entities/ReasonEntity';
import { SourceEntity } from '../entities/SourceEntity';
import { StatusEntity } from '../entities/StatusEntity';
import { TransportEntity } from '../entities/TransportEntity';
import { CreateEditOccurrenceModel } from '../../domain/models/CreateEditOccurrenceModel';

export class OccurrenceRepositoryImpl implements OccurrenceRepository {
  apiRepository: ApiRepository;

  constructor(apiRepository: ApiRepository) {
    this.apiRepository = apiRepository;
  }

  public async getOccurrenceById(
    id: string
  ): Promise<IResult<CreateEditOccurrenceModel>> {
    try {
      let response = await this.apiRepository.get(
        OccurrenceEndpoint.occurrenceById(id)
      );

      return response.handle({
        onSuccess(data) {
          const entity = new CreateEditOccurrenceEntity(data);
          const model = entity.toDomain();

          return Result.success(model);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async editOccurrenceById(
    id: string,
    data: CreateEditOccurrenceEntity
  ): Promise<IResult<any>> {
    try {
      let response = await this.apiRepository.update(
        OccurrenceEndpoint.occurrenceById(id),
        data
      );
      return response.handle({
        onSuccess(data) {
          return Result.success(data);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async createOccurrence(
    data: CreateEditOccurrenceEntity
  ): Promise<IResult<any>> {
    try {
      let response = await this.apiRepository.post(
        OccurrenceEndpoint.createOccurrence,
        data
      );
      return response.handle({
        onSuccess(data) {
          return Result.success(data);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getSources(): Promise<IResult<SourceModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.sources);

      return response.handle({
        onSuccess(data) {
          let entities: SourceEntity[] = data.map(
            (item: any) => new SourceEntity(item)
          );
          let models: SourceModel[] = entities.map((item: SourceEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getReasons(): Promise<IResult<ReasonModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.reasons);

      return response.handle({
        onSuccess(data) {
          let entities: ReasonEntity[] = data.map(
            (item: any) => new ReasonEntity(item)
          );
          let models: ReasonModel[] = entities.map((item: ReasonEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getClippings(): Promise<IResult<ClippingModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.clippings);

      return response.handle({
        onSuccess(data) {
          let entities: ClippingEntity[] = data.map(
            (item: any) => new ClippingEntity(item)
          );
          let models: ClippingModel[] = entities.map((item: ClippingEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getTransports(): Promise<IResult<TransportModel[]>> {
    try {
      let response = await this.apiRepository.get(
        OccurrenceEndpoint.transports
      );

      return response.handle({
        onSuccess(data) {
          let entities: TransportEntity[] = data.map(
            (item: any) => new TransportEntity(item)
          );
          let models: TransportModel[] = entities.map((item: TransportEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getQualifications(): Promise<IResult<QualificationModel[]>> {
    try {
      let response = await this.apiRepository.get(
        OccurrenceEndpoint.qualifications
      );

      return response.handle({
        onSuccess(data) {
          let entities: QualificationEntity[] = data.map(
            (item: any) => new QualificationEntity(item)
          );
          let models: QualificationModel[] = entities.map(
            (item: QualificationEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getAgeGroups(): Promise<IResult<AgeGroupModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.ageGroups);

      return response.handle({
        onSuccess(data) {
          let entities: AgeGroupEntity[] = data.map(
            (item: any) => new AgeGroupEntity(item)
          );
          let models: AgeGroupModel[] = entities.map((item: AgeGroupEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getGenres(): Promise<IResult<GenreModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.genres);

      return response.handle({
        onSuccess(data) {
          let entities: GenreEntity[] = data.map(
            (item: any) => new GenreEntity(item)
          );
          let models: GenreModel[] = entities.map((item: GenreEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getRaces(): Promise<IResult<RaceModel>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.races);

      return response.handle({
        onSuccess(data) {
          let entitie: RaceEntity = new RaceEntity(data);

          let model: RaceModel = entitie.toDomain();

          return Result.success(model);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getPlaces(): Promise<IResult<PlaceModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.places);

      return response.handle({
        onSuccess(data) {
          let entities: PlaceEntity[] = data.map(
            (item: any) => new PlaceEntity(item)
          );
          let models: PlaceModel[] = entities.map((item: PlaceEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getCircumstances(): Promise<IResult<CircumstanceModel[]>> {
    try {
      let response = await this.apiRepository.get(
        OccurrenceEndpoint.circumstances
      );

      return response.handle({
        onSuccess(data) {
          let entities: CircumstanceEntity[] = data.map(
            (item: any) => new CircumstanceEntity(item)
          );
          let models: CircumstanceModel[] = entities.map(
            (item: CircumstanceEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getStatus(): Promise<IResult<StatusModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.status);

      return response.handle({
        onSuccess(data) {
          let entities: StatusEntity[] = data.map(
            (item: any) => new StatusEntity(item)
          );
          let models: StatusModel[] = entities.map((item: StatusEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getPositions(): Promise<IResult<PositionModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.positions);

      return response.handle({
        onSuccess(data) {
          let entities: PositionEntity[] = data.map(
            (item: any) => new PositionEntity(item)
          );
          let models: PositionModel[] = entities.map((item: PositionEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getCoorporations(): Promise<IResult<CoorporationModel[]>> {
    try {
      let response = await this.apiRepository.get(
        OccurrenceEndpoint.coorporations
      );

      return response.handle({
        onSuccess(data) {
          let entities: CoorporationEntity[] = data.map(
            (item: any) => new CoorporationEntity(item)
          );
          let models: CoorporationModel[] = entities.map(
            (item: CoorporationEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getParties(): Promise<IResult<PartieModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.parties);

      return response.handle({
        onSuccess(data) {
          let entities: PartieEntity[] = data.map(
            (item: any) => new PartieEntity(item)
          );
          let models: PartieModel[] = entities.map((item: PartieEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getAnimals(): Promise<IResult<AnimalModel[]>> {
    try {
      let response = await this.apiRepository.get(OccurrenceEndpoint.animals);

      return response.handle({
        onSuccess(data) {
          let entities: AnimalEntity[] = data.map(
            (item: any) => new AnimalEntity(item)
          );
          let models: AnimalModel[] = entities.map((item: AnimalEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }
}
