import { BasicModel } from '../../../../shared/domain/models/BasicModel';
import { PositionType } from '../../data/entities/PositionEntity';

export class PositionModel extends BasicModel {
  type: PositionType;

  constructor({
    id,
    name,
    type
  }: {
    id: string;
    name: string;
    type: PositionType;
  }) {
    super({ id, name });
    this.type = type;
  }
}
