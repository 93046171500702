import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { redTrash } from '../../assets';
import { deleteImage, getImageById, postImage } from '../../services';
import { RootState } from '../../store';
import {
  CardImage,
  ButtonDelete,
  ImageStyled,
  ButtonUpload
} from './index.styled';
import { IProps } from './index.types';

import Loading from '../Loading';

const ImgUp: React.FC<IProps> = ({
  isDisabled,
  setValue,
  index,
  getValues,
  height,
  width,
  imgWidth,
  imgHeight,
  containerWidth,
  containerHeight
}) => {
  const referencia = useRef<HTMLInputElement>(null);
  const [imgRequestLoading, setImgRequestLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(true);
  const [img, setImg] = useState('');

  async function upNewImg(e: any) {
    try {
      setImgRequestLoading(true);

      let arq = e.target.files[0];
      let reader = new FileReader();
      reader.readAsDataURL(arq);

      reader.onloadend = () => {
        let base64data = reader.result;
        let dados = { file: base64data };

        postImage(token, dados)
          .then((resp: any) => {
            setValue(index, resp);
            setImg(resp?.file);
          })
          .finally(() => {
            setImgRequestLoading(false);
          });
      };
    } catch (e) {
      alert('Erro ao tentar salvar imagem!');
      console.error(e);
    }
  }

  async function removeImg() {
    try {
      let valores: any = getValues(index);
      let id = valores.id;

      referencia.current?.value && (referencia.current.value = '');
      deleteImage(token, id).then(_ => {
        setValue(index, '');
        setImg('');
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function getAlreadyExistedImg() {
    try {
      let value = getValues(index);

      if (value != '') {
        let obj = Object(value).id;
        if (obj === undefined || obj === null || obj === '') return;
        setImgRequestLoading(true);

        getImageById(token, obj)
          .then(resp => {
            setImg(resp.file);
          })
          .finally(() => {
            setImgRequestLoading(false);
          });
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getAlreadyExistedImg();
  }, []);

  const { token } = useSelector((state: RootState) => state.clickState);

  return (
    <>
      <Form.Control
        type="file"
        ref={referencia}
        accept="image/png,image/gif,image/jpeg"
        onChange={(e: any) => upNewImg(e)}
        multiple={false}
        style={{ display: 'none' }}
      />

      {img == '' || img == null || img == undefined ? (
        <ButtonUpload
          type="button"
          onClick={() => {
            referencia?.current?.click();
          }}
          disabled={isDisabled}
          width={containerWidth}
          height={containerHeight}
        >
          {imgRequestLoading ? <Loading /> : 'Fazer upload da imagem'}
        </ButtonUpload>
      ) : (
        <CardImage width={width} height={height}>
          <div style={{ display: imgLoading ? 'block' : 'none' }}>
            <Loading />
          </div>

          <ImageStyled
            src={img}
            width={imgWidth}
            height={imgHeight}
            style={{ display: !imgLoading ? 'block' : 'none' }}
            onLoad={() => {
              setImgLoading(false);
            }}
            onError={() => {
              setImgLoading(false);
            }}
          />

          <ButtonDelete
            type="button"
            onClick={() => removeImg()}
            disabled={isDisabled}
            width={width}
            height={height}
          >
            <img src={redTrash} />
          </ButtonDelete>
        </CardImage>
      )}
    </>
  );
};

export default ImgUp;
