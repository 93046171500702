import axios, { AxiosError, AxiosInstance } from 'axios';
import { ApiRepository } from '../../domain/repositories/ApiRepository';
import { IResult } from '../../interface/result';
import { AppUnknownError } from '../../types/AppError';
import { HttpError } from '../../types/HttpError';
import { Result } from '../../types/Result';

export class ApiRepositoryImpl implements ApiRepository {
  private httpClient: AxiosInstance;
  private token: string;

  constructor(httpClient: AxiosInstance, token: string) {
    this.httpClient = httpClient;
    this.token = token;
  }

  get headers() {
    return {
      Authorization: `Bearer ${this.token}`
    };
  }

  public async get(path: string, queryParams?: any): Promise<IResult<any>> {
    try {
      const response = await this.httpClient.get(path, {
        headers: this.headers,
        params: queryParams
      });

      return Result.success(response.data);
    } catch (e) {
      const error = e as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        let httpError: HttpError = HttpError.parseHttpError(error);

        return Result.failure(httpError);
      }

      return Result.failure(new AppUnknownError());
    }
  }

  public async post(path: string, body: any): Promise<IResult<any>> {
    try {
      const response = await this.httpClient.post(path, body, {
        headers: this.headers
      });

      return Result.success(response.data);
    } catch (e) {
      const error = e as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        let httpError: HttpError = HttpError.parseHttpError(error);

        return Result.failure(httpError);
      }

      return Result.failure(new AppUnknownError());
    }
  }

  public async update(path: string, body: any): Promise<IResult<any>> {
    try {
      const response = await this.httpClient.put(path, body, {
        headers: this.headers
      });

      return Result.success(response.data);
    } catch (e) {
      const error = e as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        let httpError: HttpError = HttpError.parseHttpError(error);

        return Result.failure(httpError);
      }

      return Result.failure(new AppUnknownError());
    }
  }

  public async delete(path: string, queryParams?: any): Promise<IResult<any>> {
    try {
      const response = await this.httpClient.delete(path, {
        headers: this.headers,
        params: queryParams
      });

      return Result.success(response.data);
    } catch (e) {
      const error = e as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        let httpError: HttpError = HttpError.parseHttpError(error);

        return Result.failure(httpError);
      }

      return Result.failure(new AppUnknownError());
    }
  }

  public async refreshToken(token: string): Promise<IResult<any>> {
    try {
      const response = await this.httpClient.post('/refresh-token/cms', {
        refresh_token: token
      });

      return Result.success(response.data);
    } catch (e) {
      const error = e as Error | AxiosError;

      if (axios.isAxiosError(error)) {
        let httpError: HttpError = HttpError.parseHttpError(error);

        return Result.failure(httpError);
      }

      return Result.failure(new AppUnknownError());
    }
  }
}
