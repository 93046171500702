import { AnimalModel } from '../../domain/models/AnimalModel';

export class AnimalEntity {
  id: string = '';
  type: string = '';

  constructor(data?: Partial<AnimalEntity>) {
    Object.assign(this, data);
  }

  toDomain(): AnimalModel {
    return new AnimalModel({
      id: this.id,
      type: this.type
    });
  }
}
