import React from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { trashSvg } from '../../../../../../../assets';
import { RootState } from '../../../../../../../store';
import { IPropsTransports } from '../form-types';
import {
  Container,
  DeleteButtonContainer,
  InputArea,
  InputSelect,
  InputText
} from './styles';

const Transports: React.FC<IPropsTransports> = ({
  onDelete,
  idTransports,
  register,
  errors,
  control,
  watch,
  setValue
}) => {
  const { transports } = useSelector((state: RootState) => state.clickState);
  const interruptedTransport =
    watch(
      `occurrenceTransportsTransport.${idTransports}.interrupted_transport`
    )?.toString() == 'false';

  return (
    <Container>
      <Row>
        <Col sm={2}>
          <Form.Group>
            <p>O transporte foi interrompido?*</p>
            <div>
              <Controller
                name={`occurrenceTransportsTransport.${idTransports}.interrupted_transport`}
                control={control}
                render={({ field: { value, ...field } }) => (
                  <Form.Check
                    inline
                    label="Sim"
                    value="true"
                    type="radio"
                    checked={value?.toString() === 'true'}
                    data-cy="occurence-form-have-interrupted-transport"
                    {...field}
                  />
                )}
              />
              <Controller
                name={`occurrenceTransportsTransport.${idTransports}.interrupted_transport`}
                control={control}
                render={({ field: { value, ...field } }) => (
                  <Form.Check
                    inline
                    label="Não"
                    value="false"
                    type="radio"
                    checked={value?.toString() === 'false'}
                    data-cy="occurence-form-not-have-interrupted-transport"
                    {...field}
                  />
                )}
              />
            </div>
            {errors?.interrupted_transport && (
              <p className="text-danger">Campo obrigatório</p>
            )}
          </Form.Group>
        </Col>
        <Col sm={2}>
          <Form.Group>
            <label>Tipo de transporte</label>

            <Controller
              control={control}
              name={`occurrenceTransportsTransport.${idTransports}.transportId`}
              defaultValue=""
              render={({ field: { onBlur, onChange, value } }) => (
                <InputSelect
                  data-cy="idtransport-form-state"
                  onBlur={onBlur}
                  onChange={event => {
                    onChange(event?.target?.value);
                  }}
                  value={value}
                >
                  <option value="">Selecione</option>
                  {transports?.map(item => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </InputSelect>
              )}
            />
            {errors?.transportId && (
              <p className="text-danger">
                {(errors?.transportId as any)?.message}
              </p>
            )}
          </Form.Group>
        </Col>

        <Col sm={2}>
          <Form.Group>
            <label htmlFor="date_interruption">Data da interrupção</label>
            <InputText
              disabled={interruptedTransport}
              type="datetime-local"
              {...register(
                `occurrenceTransportsTransport.${idTransports}.date_interruption`
              )}
            />
          </Form.Group>
        </Col>

        <Col sm={2}>
          <Form.Group>
            <label htmlFor="release_date">Data da liberação</label>
            <InputText
              disabled={interruptedTransport}
              type="datetime-local"
              {...register(
                `occurrenceTransportsTransport.${idTransports}.release_date`
              )}
            />
          </Form.Group>
        </Col>

        <Col sm={3}>
          <Form.Group>
            <label htmlFor="transport_description">
              Descrição do transporte
            </label>
            <InputArea
              as="textarea"
              {...register(
                `occurrenceTransportsTransport.${idTransports}.transport_description`
              )}
            />
          </Form.Group>
        </Col>

        <Col
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          sm={1}
        >
          <DeleteButtonContainer>
            <Button
              variant="light"
              style={{
                color: 'red',
                backgroundColor: '#E8E8E8',
                alignItems: 'center',
                height: '60px',
                width: '60px',
                borderRadius: '60px'
              }}
              onClick={onDelete}
            >
              <img src={trashSvg} />
            </Button>
          </DeleteButtonContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default Transports;
