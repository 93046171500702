import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { IProps } from './index.types';

import { BtnCancel, ContainerFoooter, LinkStyled } from './index.styled';
import 'react-quill/dist/quill.snow.css';
import { BlogContext } from '../../../../providers/BlogProvider';
import { isDefined } from '../../../../../../utils/definedValue';

const ModalApagar: React.FC<IProps> = ({
  isModal,
  onHide,
  content,
  callBackPostAction
}) => {
  const { postActionsUsecase } = useContext(BlogContext);

  async function onSubmit() {
    await postActionsUsecase.deletePost({ id: content?.id });

    if (isDefined(callBackPostAction)) {
      callBackPostAction!();
    }
    onHide();
  }
  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ backgroundColor: '#E5E5E5' }}>
          <h1 style={{ textAlign: 'center' }}>Apagar post</h1>
          <p style={{ textAlign: 'center' }}>Deseja apagar o post?</p>

          <ContainerFoooter>
            <BtnCancel onClick={() => onHide()}>Cancelar</BtnCancel>
            <LinkStyled onClick={onSubmit} to="/blog">
              Apagar
            </LinkStyled>
          </ContainerFoooter>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalApagar;
