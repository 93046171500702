import { ApiRepository } from '../../../../shared/domain/repositories/ApiRepository';
import { PostEndpoint } from '../../../../shared/endpoint';
import { IResult } from '../../../../shared/interface/result';
import { AppUnknownError } from '../../../../shared/types/AppError';
import { Result } from '../../../../shared/types/Result';
import { isDefined } from '../../../../utils/definedValue';
import { PostModel } from '../../domain/models/PostModel';
import { PostRepository } from '../../domain/repositories/PostRepository';
import { PostListQueryParams } from '../../domain/usecases/post_list/PostListState';
import { PostEntity } from '../entities/PostEntity';

export class PostRepositoryImpl implements PostRepository {
  apiRepository: ApiRepository;

  constructor(apiRepository: ApiRepository) {
    this.apiRepository = apiRepository;
  }

  public async getPosts(
    postListQueryParams: PostListQueryParams
  ): Promise<IResult<PostModel[]>> {
    try {
      let params = new URLSearchParams();
      const { order, page, take, date, search, region, authors } =
        postListQueryParams;

      if (isDefined(order)) {
        params.append('oder', order);
      }

      if (isDefined(page)) {
        params.append('page', page);
      }

      if (isDefined(take)) {
        params.append('take', take);
      }

      if (isDefined(date)) {
        params.append('initialdate', `${date}T00:01:00.000Z`);
        params.append('finaldate', `${date}T23:59:00.000Z`);
      }

      if (isDefined(search)) {
        params.append('title', search);
      }

      if (isDefined(region)) {
        params.append('region', region);
      }

      if (isDefined(authors)) {
        params.append('authors', authors);
      }

      let response = await this.apiRepository.get(PostEndpoint.posts, params);

      return response.handle({
        onSuccess(body) {
          let entities: Array<PostEntity> = body.data?.map(
            (item: any) => new PostEntity(item)
          );

          let models: Array<PostModel> = entities.map(entity =>
            entity.toDomain()
          );
          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getPostById(id: string): Promise<IResult<PostModel>> {
    try {
      let response = await this.apiRepository.get(PostEndpoint.postById(id));

      return response.handle({
        onSuccess(data) {
          let entity = new PostEntity(data);

          return Result.success(entity.toDomain());
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getPostBySlug(slug: string): Promise<IResult<PostModel>> {
    try {
      let response = await this.apiRepository.get(
        PostEndpoint.postBySlug(slug)
      );

      return response.handle({
        onSuccess(data) {
          let entity = new PostEntity(data);

          return Result.success(entity.toDomain());
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      console.warn(e);
      return Result.failure(new AppUnknownError());
    }
  }

  public async createPost(data: any): Promise<IResult<any>> {
    try {
      let response = await this.apiRepository.post(PostEndpoint.posts, data);

      return response.handle({
        onSuccess(data) {
          return Result.success(data);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async editPost(id: string, data: any): Promise<IResult<any>> {
    try {
      let response = await this.apiRepository.update(
        PostEndpoint.postById(id),
        data
      );

      return response.handle({
        onSuccess(data) {
          return Result.success(data);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async deletePost(id: string): Promise<IResult<any>> {
    try {
      let response = await this.apiRepository.delete(PostEndpoint.postById(id));

      return response.handle({
        onSuccess(data) {
          return Result.success(data);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }
}
