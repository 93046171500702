import { Button } from 'react-bootstrap';
import { Container } from './styled';

interface IPropsReloadRequestComponent {
  onClick: () => void;
}

const ReloadRequestComponent: React.FC<IPropsReloadRequestComponent> = ({
  onClick
}) => {
  return (
    <Container>
      <h5>Erro ao carregar os dados.</h5>
      <Button
        style={{
          marginLeft: '10px',
          marginBottom: '10px',
          padding: '10px',
          backgroundColor: '#e0733d',
          borderColor: 'transparent'
        }}
        onClick={onClick}
      >
        Recarregar
      </Button>
    </Container>
  );
};

export default ReloadRequestComponent;
