export const ADD_TOKEN = 'ADD_TOKEN';
export const ADD_STATES = 'ADD_STATES';
export const ADD_USERS = 'ADD_USERS';
export const ADD_ALL_USERS = 'ADD_ALL_USERS';
export const ADD_OCCURRENCES = 'ADD_OCCURRENCES';
export const ADD_VICTIMS = 'ADD_VICTIMS';

export const ADD_STATISTICS = 'ADD_STATISTICS';
export const ADD_ANNUAL_STATISTICS = 'ADD_ANNUAL_STATISTICS';

export const ADD_POSTS = 'ADD_POSTS';
export const ADD_QUESTIONS = 'ADD_QUESTIONS';
export const ADD_POLICIES = 'ADD_POLICIES';
export const ADD_SOURCES = 'ADD_SOURCES';
export const ADD_REASONS = 'ADD_REASONS';
export const ADD_CLIPPINGS = 'ADD_CLIPPINGS';
export const ADD_TRANSPORTS = 'ADD_TRANSPORTS';
export const ADD_QUALIFICATIONS = 'ADD_QUALIFICATIONS';
export const ADD_STATUS = 'ADD_STATUS';
export const ADD_CIRCUMSTANCES = 'ADD_CIRCUMSTANCES';
export const ADD_CITIES = 'ADD_CITIES';
export const ADD_NEIGHBORHOODS = 'ADD_NEIGHBORHOODS';
export const ADD_SUBNEIGHBORHOODS = 'ADD_SUBNEIGHBORHOODS';
export const ADD_LOCALITIES = 'ADD_LOCALITIES';
export const ADD_AGE_GROUP = 'ADD_AGE_GROUP';
export const ADD_GENRES = 'ADD_GENRES';
export const ADD_PLACES = 'ADD_PLACES';
export const ADD_POSITIONS = 'ADD_POSITIONS';
export const ADD_COORPORATIONS = 'ADD_COORPORATIONS';
export const ADD_PARTIES = 'ADD_PARTIES';
export const ADD_ANIMALS = 'ADD_ANIMALS';
export const ADD_REGIONS = 'ADD_REGIONS';
export const ADD_DESTROY_VICTIMS = 'ADD_DESTROY_VICTIMS';
export const ADD_LOGIN_USER = 'ADD_USER_LOGIN';
export const ADD_SOCIAL_NETWORKS = 'ADD_SOCIAL_NETWORKS';
export const ADD_VICTMS_LIST = 'ADD_VICTMS_LIST';
export const ADD_ANIMALS_LIST = 'ADD_ANIMALS_LIST';

export const LIST_VICTMNS = 'LIST_VICTMINS';
export const LIST_ANIMALS = 'LIST_ANIMALS';

export const ADD_CONT_VITIMAS_CIVIS_MORTOS = 'ADD_CONT_VITIMAS_CIVIS_MORTOS';
export const ADD_CONT_VITIMAS_CIVIS_FERIDOS = 'ADD_CONT_VITIMAS_CIVIS_FERIDOS';
export const ADD_CONT_VITIMAS_AGENTES_MORTOS =
  'ADD_CONT_VITIMAS_AGENTES_MORTOS';
export const ADD_CONT_VITIMAS_AGENTES_FERIDOS =
  'ADD_CONT_VITIMAS_AGENTES_FERIDOS';
export const ADD_CONT_ANIMAIS_MORTOS = 'ADD_CONT_ANIMAIS_MORTOS';
export const ADD_CONT_ANIMAIS_FERIDOS = 'ADD_CONT_ANIMAIS_FERIDOS';

export const CREATE_ANIMALS_LIST = 'CREATE_ANIMALS_LIST';
export const CREATE_VICTIMS_LIST = 'CREATE_VICTIMS_LIST';

export const ADD_LIST_OF_ANIMALS = 'ADD_LIST_OF_ANIMALS';
export const ADD_CONT_OF_ANIMALS = 'ADD_CONT_OF_ANIMALS';
export const ADD_LIST_OF_VICTIMS = 'ADD_LIST_OF_VICTIMS';
export const ADD_CONT_OF_VICTIMS = 'ADD_CONT_OF_VICTIMS';

export const ADD_CONT_VICTIMS_NUMBER = 'ADD_CONT_VICTIMS_NUMBER';

export const ADD_HOME = 'ADD_HOME';

export const ADD_BLOG_POSTS = 'ADD_BLOG_POSTS';

export const ADD_ABOUT = 'ADD_ABOUT';
export const ADD_ABOUT_API = 'ADD_ABOUT_API';

export const ADD_TEAMS = 'ADD_TEAMS';
export const ADD_AWARDS = 'ADD_AWARDS';
export const ADD_PARTNERS = 'ADD_PARTNERS';

export const ADD_TRANSPARENCIES = 'ADD_TRANSPARENCIES';
export const ADD_ARTICLES = 'ADD_ARTICLES';

export const ADD_NOTIFICATIONS = 'ADD_NOTIFICATIONS';

export const ADD_REPORTS = 'ADD_REPORTS';

export const GET_IMAGE_SOCIAL_MEDIA = 'GET_IMAGE_SOCIAL_MEDIA';

export const ADD_ALERT = 'ADD_ALERT';
