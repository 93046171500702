import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { PositionModel } from '../../domain/models/PositionModel';

export enum PositionType {
  UNKNOWN = '',
  AGENT = 'Agente',
  POLITICAL = 'Politico'
}

export class PositionEntity extends BasicEntity {
  type: PositionType;

  constructor(data?: Partial<PositionEntity>) {
    super(data);
    this.type = data?.type || PositionType.UNKNOWN;
  }

  toDomain(): PositionModel {
    return new PositionModel({
      id: this.id,
      name: this.name,
      type: this.type
    });
  }
}
