import { FormikProps } from 'formik';
import { createRef } from 'react';
import * as Yup from 'yup';
import { getUniqueKey } from '../../../utils/shared';

export abstract class BaseForm<T extends Object> {
  uniqueKey: string = getUniqueKey();
  ref: React.RefObject<FormikProps<T>> = createRef<FormikProps<T>>();
  [key: string]: string | Yup.SchemaOf<any, object> | any;

  requiredDefaultMsg(field: string) {
    return `Campo ${field} é obrigatório`;
  }

  toJson() {
    return JSON.parse(
      JSON.stringify(this, (key, value) => {
        return key === 'ref' ||
          key === 'validationSchema' ||
          key === 'uniqueKey'
          ? undefined
          : value;
      })
    );
  }
}
