import AuthorEntity from '../../../../shared/data/entities/AuthorEntity';
import FileEntity from '../../../../shared/data/entities/FileEntity';
import RegionEntity from '../../../../shared/data/entities/RegionEntity';
import {
  PostGroupItemModel,
  PostItemModel,
  PostModel
} from '../../domain/models/PostModel';

export enum PostType {
  ALL = 'Todas',
  PUBLISHED = 'Publicada',
  DRAFT = 'Rascunho',
  SCHEDULED = 'Agendada'
}

export class PostGroupItemEntity {
  image: FileEntity = new FileEntity();
  description: string = '';
  content: string = '';

  constructor(data?: Partial<PostGroupItemEntity>) {
    Object.assign(this, {
      ...data,
      image: new FileEntity(data?.image)
    });
  }

  toDomain(): PostGroupItemModel {
    return new PostGroupItemModel({
      image: this.image.toDomain(),
      description: this.description,
      content: this.content
    });
  }
}

export class PostItemEntity {
  group: string = 'bloco 1';
  group_items: Array<PostGroupItemEntity> = [new PostGroupItemEntity()];
  typeBlock: string = 'text';
  quantBlock: string = '1';

  constructor(data?: Partial<PostItemEntity>) {
    Object.assign(this, {
      ...data,
      group_items: data?.group_items?.map(item => new PostGroupItemEntity(item))
    });
  }

  toDomain(): PostItemModel {
    return new PostItemModel({
      group: this.group,
      group_items: this.group_items.map(item => item.toDomain()),
      typeBlock: this.typeBlock,
      quantBlock: this.quantBlock
    });
  }
}

export class PostEntity {
  id: string = '';
  title: string = '';
  author: AuthorEntity = new AuthorEntity();
  slug: string = '';
  region: RegionEntity = new RegionEntity();
  authors: string = '';
  cover: FileEntity = new FileEntity();
  thumbnail: FileEntity = new FileEntity();
  type: PostType = PostType.PUBLISHED;
  emphasis: boolean = false;
  date_post: string = '';
  description: string = '';
  items: Array<PostItemEntity> = [new PostItemEntity()];
  createdAt: string = '';
  updatedAt: string = '';

  constructor(data?: Partial<PostEntity>) {
    Object.assign(this, {
      ...data,
      author: new AuthorEntity(data?.author),
      region: new RegionEntity(data?.region),
      cover: new FileEntity(data?.cover),
      thumbnail: new FileEntity(data?.thumbnail),
      items: data?.items?.map((item: any) => new PostItemEntity(item))
    });
  }

  static fromJson(jsonData: any): PostEntity {
    return Object.assign(new PostEntity(), {
      ...jsonData,
      author: new AuthorEntity(jsonData.author),
      region: new RegionEntity(jsonData.region),
      cover: new FileEntity(jsonData.cover),
      thumbnail: new FileEntity(jsonData.thumbnail),
      items: jsonData.items.map((item: any) => new PostItemEntity(item))
    });
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  toDomain(): PostModel {
    return new PostModel({
      id: this.id,
      title: this.title,
      author: this.author.toDomain(),
      slug: this.slug,
      region: this.region.toDomain(),
      authors: this.authors,
      cover: this.cover.toDomain(),
      thumbnail: this.thumbnail.toDomain(),
      type: this.type,
      emphasis: this.emphasis,
      date_post: this.date_post,
      description: this.description,
      items: this.items.map(item => item.toDomain()),
      createdAt: this.createdAt,
      updatedAt: this.updatedAt
    });
  }
}
