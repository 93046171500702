import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { editBlack, trashSvg } from '../../../assets';
import { RootState } from '../../../store';
import { Variable } from '../form-types';
import {
  Body,
  ActionButton,
  Header,
  Container,
  ContainerActionButtons,
  TitleVariable,
  AddButton,
  InputSelect,
  ContainerOptions
} from './styles';

interface IProps {
  values: any;
  title: string;
  titleTable: string;
  variable: Variable;
  onClickButtonAdd: (value?: any) => void;
  onClickButtonDelete: (value: any) => void;
  onClickButtonEdit: (value: any) => void;
  options?: any;
}

const VariableTable: React.FC<IProps> = props => {
  const {
    circumstances,
    positions,
    qualifications,
    neighborhoods,
    subneighborhoods,
    localities
  } = useSelector((state: RootState) => state.clickState);

  const [type, setType] = useState(' ');
  const [filterVariableByType, setFilterVariableByType] = useState<any>([]);

  useEffect(() => {
    if (props.values != undefined || props.values != null) {
      let auxFilterVariableByType: any[] = [];
      props.values.forEach((item: { type: string; city: any }) => {
        if (
          [
            Variable.NEIGHBORHOODS,
            Variable.SUBNEIGHBORHOODS,
            Variable.LOCALITIES
          ].includes(props.variable)
        ) {
          if (type !== ' ' && item.city !== null && item.city.id === type) {
            auxFilterVariableByType.push(item);
          }
        } else if (item.type !== null && item.type === type) {
          auxFilterVariableByType.push(item);
        }
      });
      setFilterVariableByType(auxFilterVariableByType);
    }
  }, [
    type,
    circumstances,
    positions,
    qualifications,
    neighborhoods,
    subneighborhoods,
    localities
  ]);

  function hasTypeInVariable(variable: Variable) {
    return [
      Variable.CIRCUMSTANCE,
      Variable.POSITIONS,
      Variable.QUALIFICATIONS,
      Variable.NEIGHBORHOODS,
      Variable.SUBNEIGHBORHOODS,
      Variable.LOCALITIES
    ].includes(variable);
  }

  function getLabel(variable: Variable) {
    if (variable === Variable.NEIGHBORHOODS) {
      return 'Adicionar/visualizar bairro em:';
    } else if (variable === Variable.SUBNEIGHBORHOODS) {
      return 'Adicionar/visualizar sub bairro em:';
    } else if (variable === Variable.LOCALITIES) {
      return 'Adicionar/visualizar localidade em:';
    } else {
      return 'Adicionar do tipo:';
    }
  }

  return (
    <Container>
      <TitleVariable>{props.title}</TitleVariable>

      <ContainerOptions>
        {hasTypeInVariable(props.variable) ? (
          <div>
            <label>{getLabel(props.variable)}</label>
            <InputSelect
              onChange={async event => {
                setType(event?.target?.value);
              }}
              value={type}
            >
              <option value=" ">Selecione</option>
              {props.options?.map((item: { value: string; name: string }) => (
                <option value={item.value}>{item.name}</option>
              ))}
            </InputSelect>
            {type === ' ' ? null : (
              <AddButton
                onClick={() => {
                  props.onClickButtonAdd({
                    type: type
                  });
                }}
              >
                Adicionar
              </AddButton>
            )}
          </div>
        ) : (
          <AddButton onClick={props.onClickButtonAdd}>Adicionar</AddButton>
        )}
      </ContainerOptions>

      <Header>
        {hasTypeInVariable(props.variable) ? (
          type === ' ' ? null : (
            <tr>
              <td>{props.titleTable}</td>
              <td>AÇÕES</td>
            </tr>
          )
        ) : (
          <tr>
            <td>{props.titleTable}</td>
            <td>AÇÕES</td>
          </tr>
        )}
      </Header>
      <Body>
        {hasTypeInVariable(props.variable)
          ? filterVariableByType.map(
              (item: {
                name?: string;
                id?: string;
                type?: string;
                city?: string;
              }) => {
                return (
                  <tr>
                    <td>{item.name || item.type}</td>
                    <td>
                      <ContainerActionButtons>
                        <ActionButton
                          onClick={() => {
                            props.onClickButtonEdit({
                              id: item.id,
                              name: item.name,
                              type: item.type
                            });
                          }}
                        >
                          <img src={editBlack} />
                        </ActionButton>
                        <ActionButton
                          onClick={() => {
                            props.onClickButtonDelete({
                              id: item.id,
                              name: item.name,
                              type: item.type
                            });
                          }}
                        >
                          <img src={trashSvg} />
                        </ActionButton>
                      </ContainerActionButtons>
                    </td>
                  </tr>
                );
              }
            )
          : props.values.map(
              (item: { name: string; id: string; type: string }) => {
                return (
                  <tr>
                    <td>{item.name || item.type}</td>
                    <td>
                      <ContainerActionButtons>
                        <ActionButton
                          onClick={() => {
                            props.onClickButtonEdit({
                              id: item.id,
                              name: item.name,
                              type: item.type
                            });
                          }}
                        >
                          <img src={editBlack} />
                        </ActionButton>
                        <ActionButton
                          onClick={() => {
                            props.onClickButtonDelete({
                              id: item.id,
                              name: item.name,
                              type: item.type
                            });
                          }}
                        >
                          <img src={trashSvg} />
                        </ActionButton>
                      </ContainerActionButtons>
                    </td>
                  </tr>
                );
              }
            )}
      </Body>
    </Container>
  );
};

export default VariableTable;
