import { Container, Input } from './index.styled';

export interface IProps {
  id: string;
  htmlFor: string;
  name: string;
  value?: any;
  onChange?: (e: any) => void;
  type?: string;
}

const InputData: React.FC<IProps> = ({
  id,
  htmlFor,
  name,
  value,
  onChange,
  type
}) => {
  return (
    <Container>
      <Input
        id={id}
        type={type != undefined ? type : 'data'}
        name={name}
        value={value}
        onChange={onChange}
      />
    </Container>
  );
};

export default InputData;
