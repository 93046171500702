import styled from 'styled-components';
import { Container as C } from 'react-bootstrap';

export const Body = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.1);
  background-color: ${props => props.theme.colors.white};
  padding: 16px;
`;

export const Container = styled(C)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-width: 80% !important;

  padding: 0;
  border-radius: 6px;
  min-height: 210px;
  max-height: 250px;

  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
`;

export const GeneralInfoSection = styled.div`
  flex: 1;
  max-width: 25%;
  border-right: 1px solid #ebe9f1;
`;

export const GeneralInfoHeader = styled.header<{
  withShot: boolean;
}>`
  height: 43px;
  display: flex;
  align-items: center;
  padding: 16px 20px;
  background-color: ${props =>
    props.withShot ? '#ea5455' : props.theme.colors.tertiary};
  color: ${props => props.theme.colors.white};

  > b {
    margin-left: 8px;
    color: ${props => props.theme.colors.white};
    font-size: 12px;
    line-height: 23px;
  }

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
`;

export const GeneralInfoContainer = styled.div`
  padding: 16px 16px;
`;

export const GeneralInfoDateContainer = styled.div`
  display: flex;
`;

export const GeneralInfoDateStatusText = styled.p<{
  withShot: boolean;
}>`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: ${props => (props.withShot ? '#ea5455' : '')};
  margin: 0 !important;
`;

const InfoText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 21px;
  margin: 0 !important;
  padding: 0 !important;
  color: #5e5873;
`;

export const GeneralInfoDateText = styled(InfoText)``;

export const GeneralInfoAddressContainer = styled.div`
  display: flex;
`;

export const GeneralInfoAddressText = styled(InfoText)``;

export const DataSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px 32px;
`;

export const DataOccurrenceNumber = styled.p`
  font-style: normal;
  font-weight: bold;
  padding: 0 16px;
`;

export const DataItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  word-break: break-all;
  overflow: auto;
`;

export const DataItemsWoundedAndDeadContainer = styled.div`
  display: flex;
  padding: 0 16px;
  flex-direction: column;
`;

export const DataItemContainer = styled.div``;

export const DataItemTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 23px;
  color: #5e5873;
`;

export const DataItemInfoText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.6;
  margin: 0 !important;
`;

export const ActionsSection = styled.div<{
  newOccurrence: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.newOccurrence ? 'flex-end' : 'space-between'};

  align-items: flex-end;

  padding: 16px 16px;
`;

export const OccurrenceDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const OccurrenceDateInfo = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.6;
  margin: 0 !important;
`;

export const ButtonsContainer = styled.div`
  width: 220px;
`;

export const ConfirmButton = styled.button<{
  newOccurrence: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  width: 100%;

  padding: 12px 38px;
  border-radius: 6px;

  color: ${props => props.theme.colors.white};
  background: ${props =>
    props.newOccurrence
      ? 'linear-gradient(90deg, #f83600, #fe8c00)'
      : props.theme.colors.success};
  border: none;

  &:hover {
    background: ${props =>
      props.newOccurrence
        ? 'linear-gradient(90deg, #e53c0d, #e53c0d)'
        : '#27b263'};

    transition: background-color 200ms linear;
  }

  & > div {
    margin-right: 8px;
    color: white;
  }
`;

export const BackButton = styled.button`
  height: 42px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.black};
  background: transparent;
  border: 1px solid #2c3941;

  &:hover {
    background: #f2f2f2;
    transition: background-color 200ms linear;
  }
`;
