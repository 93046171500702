import { useState, useEffect } from 'react';
import SocketService from '../../services/socket';
import SocketContext from './index';
import SocketState from './state';

const SocketProvider = ({ children }: any) => {
  let socketService: SocketService;

  const [value, setValue] = useState<SocketState>(new SocketState());

  useEffect(() => {
    socketService = new SocketService(setValue);
  }, []);

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};

export default SocketProvider;
