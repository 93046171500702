import { api } from '../index';

export function createStates(token: string, dados: any) {
  return api
    .post(`/states`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Estados) Erro:', erro);
      return erro;
    });
}

export function createUsers(token: string, dados: any) {
  return api
    .post(`/users`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Usuários) Erro:', erro);
      return erro;
    });
}

export function createVictims(token: string, { id, ...dados }: any) {
  return api.post(`/victims`, dados, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function createQuestions(token: string, dados: any) {
  return api
    .post(`/questions`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Questões) Erro:', erro);
      return erro;
    });
}

export function createPolicies(token: string, dados: any) {
  return api
    .post(`/policies`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Políticas) Erro:', erro);
      return erro;
    });
}

export function createSourcers(token: string, dados: any) {
  return api
    .post(`/sources`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Fontes) Erro:', erro);
      return erro;
    });
}

export function createReasons(token: string, dados: any) {
  return api
    .post(`/reasons`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Motívo) Erro:', erro);
      return erro;
    });
}

export function createClippings(token: string, dados: any) {
  return api
    .post(`/clippings`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Recorte) Erro:', erro);
      return erro;
    });
}

export function createTransports(token: string, dados: any) {
  return api
    .post(`/transports`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Transportes) Erro:', erro);
      return erro;
    });
}

export function createQualifications(token: string, dados: any) {
  return api
    .post(`/qualifications`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Qualificações) Erro:', erro);
      return erro;
    });
}

export function createStatus(token: string, dados: any) {
  return api
    .post(`/status`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Status) Erro:', erro);
      return erro;
    });
}

export function createRegions(token: string, dados: any) {
  return api
    .post(`/regions`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Regiões) Erro:', erro);
      return erro;
    });
}

export function createCircumstances(token: string, dados: any) {
  return api
    .post(`/circumstances`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Circuunstancias) Erro:', erro);
      return erro;
    });
}

export function createCities(token: string, dados: any) {
  return api
    .post(`/cities`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Cidades) Erro:', erro);
      return erro;
    });
}

export function createNeighborhoods(token: string, dados: any) {
  return api
    .post(`/neighborhoods`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Bairro) Erro:', erro);
      return erro;
    });
}
export function createSubNeighborhoods(token: string, dados: any) {
  return api
    .post(`/subneighborhoods`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Bairro) Erro:', erro);
      return erro;
    });
}
export function createLocalities(token: string, dados: any) {
  return api
    .post(`/localities`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Bairro) Erro:', erro);
      return erro;
    });
}

export function createAgeGroup(token: string, dados: any) {
  return api
    .post(`/age-group`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Faixa etária) Erro:', erro);
      return erro;
    });
}

export function createGenres(token: string, dados: any) {
  return api
    .post(`/genres`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Generos) Erro:', erro);
      return erro;
    });
}

export function createPlaces(token: string, dados: any) {
  return api
    .post(`/places`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Lugar/Local) Erro:', erro);
      return erro;
    });
}

export function createPositions(token: string, dados: any) {
  return api
    .post(`/positions`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Posições) Erro:', erro);
      return erro;
    });
}

export function createCoorporations(token: string, dados: any) {
  return api
    .post(`/coorporations`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Corporações) Erro:', erro);
      return erro;
    });
}

export function createAnimals(token: string, dados: any) {
  return api
    .post(`/animals`, dados, { headers: { Authorization: `Bearer ${token}` } })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Animais) Erro:', erro);
      return erro;
    });
}

export function createSocialNetworks(token: string, dados: any) {
  return api
    .post(`/social-networks`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Redes sociais) Erro:', erro);
      return erro;
    });
}

export function createParties(token: string, dados: any) {
  return api
    .post(`/parties`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(resposta => {
      let resp = resposta.data;
      return resp;
    })
    .catch(erro => {
      console.warn('(Parties) Erro:', erro);
      return erro;
    });
}
