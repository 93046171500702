import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_BLOG_POSTS,
  ADD_CIRCUMSTANCES,
  ADD_CITIES,
  ADD_COORPORATIONS,
  ADD_NEIGHBORHOODS,
  ADD_POSITIONS,
  ADD_QUALIFICATIONS,
  ADD_REGIONS,
  ADD_STATES,
  ADD_STATUS,
  RootState
} from '../../store';
import { IProps } from './index.types';
import {
  getCities,
  getCircumstances,
  createCities,
  deleteCities,
  editCities,
  getNeighborhoods,
  editNeighborhoods,
  createNeighborhoods,
  deleteNeighborhoods,
  getQualifications,
  editCircumstances,
  createCircumstances,
  deleteCircumstances,
  editRegions,
  getRegions,
  deleteRegions,
  createRegions,
  getStates,
  deleteStates,
  createStates,
  getState,
  getLocalities,
  deleteLocalities,
  getPositions,
  deletePositions,
  getStatus,
  deleteStatus,
  createStatus,
  createPositions,
  createCoorporations,
  getCoorporations,
  deleteCoorporations,
  getOccurrences,
  deleteOccurrences,
  getOccurrencesByParams,
  getEstimativas,
  createHome,
  getPosts,
  getPostsById
} from '../../services';
import { Button, Form, Table } from 'react-bootstrap';
import Leitor from 'react-csv-reader';
import { ImgUp } from '../../components';
import { useForm, useFormState } from 'react-hook-form';

import { CreateHome } from './Components/index';

import Editor from '@monaco-editor/react';
import { useParams } from 'react-router-dom';

const Dash: React.FC<IProps> = ({}) => {
  return <></>;
};

export default Dash;
