import React, { useContext, useEffect, useState } from 'react';
import { IProps } from './index.types';

import { Body, FormsSection, Title, Container } from './index.styled';
import CreateEditOccurrenceHeader from './components/header';
import { useNavigate, useParams } from 'react-router-dom';
import { Divider } from '../../../../../shared/styles';
import { OccurrenceContext } from '../../../providers/OccurrenceProvider';
import CreateEditOcurrenceDetailsFormComponent from './components/details_form';
import CreateEditOcurrenceContextInfoFormComponent from './components/context_info_form';
import CreateEditOcurrenceTransportsFormComponent from './components/transports_form';
import CreateEditOcurrenceAnimalVictimsFormComponent from './components/animal_victims_form';
import CreateEditOcurrenceVictimsFormComponent from './components/victims_form';
import Loading from '../../../../../components/Loading';
import { reportErrorHandler } from '../../../../../utils/GlobalErrorHandler';
import { showAlert } from '../../../../../shared/components/alert';
import ModalError from '../../../../../shared/components/modal_error';
import ModalSucesso from '../../../../../shared/components/modal_successo';
import SuccessOccurrenceModal from '../../../../../components/SuccessOccurrenceModal';
import { EOccurrenceStatus } from '../../../../../shared/enums/ocurrenceStatus';

const CreateEditOccurrence: React.FC<IProps> = () => {
  let { id } = useParams();
  const navigate = useNavigate();

  const [isVisibleModal, setVisibleModal] = useState<boolean>(false);
  const [occurrenceId, setOccurrenceId] = useState<string>('');
  const [successOccurrenceModalTitle, setSuccessOccurrenceModalTitle] =
    useState<string>('');
  const [successOccurrenceModalSubTitle, setSuccessOccurrenceModalSubTitle] =
    useState<string>('');

  const {
    createEditOccurrenceUsecase,
    createEditOccurrenceState,
    previousCreateEditOccurrenceState
  } = useContext(OccurrenceContext);

  const onConfirm = async () => {
    createEditOccurrenceState.detailsForm.ref.current?.submitForm();
    createEditOccurrenceState.contextInfoForm.ref.current?.submitForm();
    createEditOccurrenceState.transportsForm.forEach(transportForm => {
      transportForm.ref.current?.submitForm();
    });
    createEditOccurrenceState.victimsForm.forEach(victim => {
      victim.ref.current?.submitForm();
    });
    createEditOccurrenceState.animalVictimsForm.forEach(animalVictim => {
      animalVictim.ref.current?.submitForm();
    });

    await createEditOccurrenceUsecase.handleFormSubmit();
  };

  useEffect(() => {
    createEditOccurrenceUsecase.init(id);

    return () => {
      createEditOccurrenceUsecase.resetState();
    };
  }, []);

  useEffect(() => {
    createEditOccurrenceUsecase.countVictims();
  }, [createEditOccurrenceState.victimsForm]);

  useEffect(() => {
    createEditOccurrenceUsecase.countAnimalVictims();
  }, [createEditOccurrenceState.animalVictimsForm]);

  useEffect(() => {
    if (
      createEditOccurrenceState.occurrenceRequestStatus.status !=
      previousCreateEditOccurrenceState?.occurrenceRequestStatus.status
    ) {
      createEditOccurrenceState.occurrenceRequestStatus.maybeMap({
        failed: error => {
          reportErrorHandler({
            error,
            context: {
              reportLocation: {
                filePath:
                  '/pages/RefactorOccurrences/presentation/views/create_edit/index.tsx',
                lineNumber: 0,
                functionName: 'CreateEditOccurrence'
              }
            },
            msgContext: `Error on getOccurrenceForEdit | OccurrenceId: ${id}`
          });

          showAlert({
            onDismiss: () => {
              navigate('/occurrences');
            },
            children: (
              <ModalError
                title="Erro ao carregar ocorrência"
                description="Ocorreu um erro ao carregar a ocorrência, tente novamente mais tarde!"
              />
            )
          });
        }
      });
    }
  }, [createEditOccurrenceState.occurrenceRequestStatus]);

  useEffect(() => {
    if (
      createEditOccurrenceState.editOccurrenceRequestStatus.status !=
      previousCreateEditOccurrenceState?.editOccurrenceRequestStatus.status
    ) {
      createEditOccurrenceState.editOccurrenceRequestStatus.maybeMap({
        failed: error => {
          reportErrorHandler({
            error,
            context: {
              reportLocation: {
                filePath:
                  '/pages/RefactorOccurrences/presentation/views/create_edit/index.tsx',
                lineNumber: 0,
                functionName: 'CreateEditOccurrence'
              }
            },
            msgContext: `Error on editOccurrenceById | OccurrenceId: ${id}`
          });

          showAlert({
            onDismiss: () => {},
            children: (
              <ModalError
                title="Erro ao editar ocorrência"
                description="Verifique se todos os campos obrigatórios foram preechidos e tente novamente!"
              />
            )
          });
        },
        succeeded: _ => {
          createEditOccurrenceState.occurrenceRequestStatus.maybeMap({
            succeeded(data) {
              if (data.status === EOccurrenceStatus.Waiting) {
                setOccurrenceId(data.id);
                setSuccessOccurrenceModalTitle('Ocorrência aprovada');
                setSuccessOccurrenceModalSubTitle(
                  'A ocorrência foi aprovada com sucesso.'
                );
                setVisibleModal(true);
              } else {
                showAlert({
                  onDismiss: () => {
                    navigate('/occurrences');
                  },
                  children: (
                    <ModalSucesso
                      title="Sucesso ao editar ocorrência"
                      description="Ocorrência editada com sucesso!"
                    />
                  )
                });
              }
            }
          });
        }
      });
    }
  }, [createEditOccurrenceState.editOccurrenceRequestStatus]);

  useEffect(() => {
    if (
      createEditOccurrenceState.createOccurrenceRequestStatus.status !=
      previousCreateEditOccurrenceState?.createOccurrenceRequestStatus.status
    ) {
      createEditOccurrenceState.createOccurrenceRequestStatus.maybeMap({
        failed: error => {
          reportErrorHandler({
            error,
            context: {
              reportLocation: {
                filePath:
                  '/pages/RefactorOccurrences/presentation/views/create_edit/index.tsx',
                lineNumber: 0,
                functionName: 'CreateEditOccurrence'
              }
            },
            msgContext: `Error on createOccurence | OccurrenceId: ${id}`
          });

          showAlert({
            onDismiss: () => {},
            children: (
              <ModalError
                title="Erro ao criar ocorrência"
                description="Ocorreu um erro ao criar a ocorrência, tente novamente!"
              />
            )
          });
        },
        succeeded: data => {
          setOccurrenceId(data.id);
          setSuccessOccurrenceModalTitle('Ocorrência registrada');
          setSuccessOccurrenceModalSubTitle(
            'A ocorrência foi registrada com sucesso.'
          );
          setVisibleModal(true);
        }
      });
    }
  }, [createEditOccurrenceState.createOccurrenceRequestStatus]);

  const formBody = () => (
    <>
      <CreateEditOccurrenceHeader
        newOccurrence={!id}
        onConfirm={onConfirm}
        onBack={() => {
          navigate('/occurrences');
        }}
      />
      <Divider height={48} />
      <Container>
        <Title>Registrar ocorrência</Title>
        <FormsSection>
          <CreateEditOcurrenceDetailsFormComponent />
        </FormsSection>
        <FormsSection>
          <CreateEditOcurrenceContextInfoFormComponent />
        </FormsSection>
        <FormsSection>
          <CreateEditOcurrenceTransportsFormComponent />
        </FormsSection>
        <FormsSection>
          <CreateEditOcurrenceVictimsFormComponent />
        </FormsSection>
        <FormsSection>
          <CreateEditOcurrenceAnimalVictimsFormComponent />
        </FormsSection>
      </Container>

      <SuccessOccurrenceModal
        title={successOccurrenceModalTitle}
        subtitle={successOccurrenceModalSubTitle}
        open={isVisibleModal}
        occurrenceId={occurrenceId}
        onClose={() => {
          setVisibleModal(false);
          navigate('/occurrences');
        }}
      />
    </>
  );

  const renderLoadingScreen = (message: string) => (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <h2>{message}</h2>
      <Divider width={16} />
      <Loading />
    </div>
  );

  return (
    <Body>
      {id
        ? createEditOccurrenceState.occurrenceRequestStatus.maybeMap({
            loading: () =>
              renderLoadingScreen('Carregando ocorrência, aguarde...'),
            succeeded() {
              return formBody();
            }
          })
        : createEditOccurrenceState.preparingDataRequestStatus.maybeMap({
            loading: () => renderLoadingScreen('Preparando dados, aguarde...'),
            succeeded(_) {
              return formBody();
            }
          })}
    </Body>
  );
};

export default CreateEditOccurrence;
