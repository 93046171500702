import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const Button = styled.button`
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background-color: ${props => props.theme.colors.success};
  border: none;
`;
