import { CityModel } from '../../../../../shared/domain/models/CityModel';
import { LocalityModel } from '../../../../../shared/domain/models/LocalityModel';
import { NeighborhoodModel } from '../../../../../shared/domain/models/NeighborhoodModel';
import { StateModel } from '../../../../../shared/domain/models/StateModel';
import { SubNeighborhoodModel } from '../../../../../shared/domain/models/SubNeighborhoodModel';
import { IRequestStatus } from '../../../../../shared/interface/requestStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { QualificationType } from '../../../data/entities/QualificationEntity';
import { CreateEditOccurrenceAnimalVictimsForm } from '../../forms/CreateEditOccurrenceAnimalVictimsForm';
import { CreateEditOccurrenceContextInfoForm } from '../../forms/CreateEditOccurrenceContextInfoForm';
import { CreateEditOccurrenceDetailsForm } from '../../forms/CreateEditOccurrenceDetailsForm';
import { CreateEditOccurrenceTransportForm } from '../../forms/CreateEditOccurrenceTransportForm';
import { CreateEditOccurrenceVictimsForm } from '../../forms/CreateEditOccurrenceVictimsForm';
import { AgeGroupModel } from '../../models/AgeGroupModel';
import { AnimalModel } from '../../models/AnimalModel';
import { CircumstanceModel } from '../../models/CircumstanceModel';
import { ClippingModel } from '../../models/ClippingModel';
import { CreateEditOccurrenceModel } from '../../models/CreateEditOccurrenceModel';
import { CoorporationModel } from '../../models/CoorporationModel';
import { GenreModel } from '../../models/GenreModel';
import { PartieModel } from '../../models/PartieModel';
import { PlaceModel } from '../../models/PlaceModel';
import { PositionModel } from '../../models/PositionModel';
import { QualificationModel } from '../../models/QualificationModel';
import { RaceModel } from '../../models/RaceModel';
import { ReasonModel } from '../../models/ReasonModel';
import { SourceModel } from '../../models/SourceModel';
import { StatusModel } from '../../models/StatusModel';
import { TransportModel } from '../../models/TransportModel';

interface ICreateEditOccurrenceVictimsCount {
  total: number;
  civilWounded: number;
  civilDead: number;
  agentWounded: number;
  agentDead: number;
}

interface ICreateEditOccurrenceAnimalVictimsCount {
  total: number;
  wounded: number;
  dead: number;
}

interface IFieldsForValidation {
  agentQualification?: QualificationModel;
  politicalQualification?: QualificationModel;
  servicesQualifications?: QualificationModel[];
  pressSource?: SourceModel;
}

export default class CreateEditOccurrenceState {
  preparingDataRequestStatus: IRequestStatus<any>;
  editableOccurrenceData?: CreateEditOccurrenceModel;
  editOccurrenceRequestStatus: IRequestStatus<any>;
  createOccurrenceRequestStatus: IRequestStatus<any>;
  detailsForm: CreateEditOccurrenceDetailsForm;
  contextInfoForm: CreateEditOccurrenceContextInfoForm;
  transportsForm: CreateEditOccurrenceTransportForm[];
  victimsForm: CreateEditOccurrenceVictimsForm[];
  animalVictimsForm: CreateEditOccurrenceAnimalVictimsForm[];
  victimsCount: ICreateEditOccurrenceVictimsCount;
  animalVictimsCount: ICreateEditOccurrenceAnimalVictimsCount;
  occurrenceRequestStatus: IRequestStatus<CreateEditOccurrenceModel>;
  statesRequestStatus: IRequestStatus<StateModel[]>;
  citiesRequestStatus: IRequestStatus<CityModel[]>;
  neighborhoodsRequestStatus: IRequestStatus<NeighborhoodModel[]>;
  subneighborhoodsRequestStatus: IRequestStatus<SubNeighborhoodModel[]>;
  localitiesRequestStatus: IRequestStatus<LocalityModel[]>;
  sourcesRequestStatus: IRequestStatus<SourceModel[]>;
  reasonsRequestStatus: IRequestStatus<ReasonModel[]>;
  clippingsRequestStatus: IRequestStatus<ClippingModel[]>;
  transportsRequestStatus: IRequestStatus<TransportModel[]>;
  reasonActionPolice: ReasonModel;
  reasonOperationPolice: ReasonModel;
  qualificationsRequestStatus: IRequestStatus<QualificationModel[]>;
  fieldsForValidation: IFieldsForValidation;
  agentQualification: QualificationModel;
  ageGroupsRequestStatus: IRequestStatus<AgeGroupModel[]>;
  genresRequestStatus: IRequestStatus<GenreModel[]>;
  racesRequestStatus: IRequestStatus<RaceModel>;
  placesRequestStatus: IRequestStatus<PlaceModel[]>;
  circumstancesRequestStatus: IRequestStatus<CircumstanceModel[]>;
  statusRequestStatus: IRequestStatus<StatusModel[]>;
  positionsRequestStatus: IRequestStatus<PositionModel[]>;
  coorporationsRequestStatus: IRequestStatus<CoorporationModel[]>;
  partiesRequestStatus: IRequestStatus<PartieModel[]>;
  animalsRequestStatus: IRequestStatus<AnimalModel[]>;

  constructor(
    preparingDataRequestStatus: IRequestStatus<any> = RequestStatus.idle(),
    detailsForm: CreateEditOccurrenceDetailsForm = new CreateEditOccurrenceDetailsForm(),
    editOccurrenceRequestStatus: IRequestStatus<any> = RequestStatus.idle(),
    createOccurrenceRequestStatus: IRequestStatus<any> = RequestStatus.idle(),
    contextInfoForm: CreateEditOccurrenceContextInfoForm = new CreateEditOccurrenceContextInfoForm(),
    transportsForm: CreateEditOccurrenceTransportForm[] = [],
    victimsForm: CreateEditOccurrenceVictimsForm[] = [],
    animalVictimsForm: CreateEditOccurrenceAnimalVictimsForm[] = [],
    victimsCount: ICreateEditOccurrenceVictimsCount = {
      total: 0,
      civilWounded: 0,
      civilDead: 0,
      agentWounded: 0,
      agentDead: 0
    },
    animalVictimsCount: ICreateEditOccurrenceAnimalVictimsCount = {
      total: 0,
      wounded: 0,
      dead: 0
    },
    occurrenceRequestStatus: IRequestStatus<CreateEditOccurrenceModel> = RequestStatus.idle(),
    statesRequestStatus: IRequestStatus<StateModel[]> = RequestStatus.idle(),
    citiesRequestStatus: IRequestStatus<CityModel[]> = RequestStatus.idle(),
    neighborhoodsRequestStatus: IRequestStatus<
      NeighborhoodModel[]
    > = RequestStatus.idle(),
    subNeighborhoodsRequestStatus: IRequestStatus<
      SubNeighborhoodModel[]
    > = RequestStatus.idle(),
    localitiesRequestStatus: IRequestStatus<
      LocalityModel[]
    > = RequestStatus.idle(),
    sourcesRequestStatus: IRequestStatus<SourceModel[]> = RequestStatus.idle(),
    reasonsRequestStatus: IRequestStatus<ReasonModel[]> = RequestStatus.idle(),
    clippingsRequestStatus: IRequestStatus<
      ClippingModel[]
    > = RequestStatus.idle(),
    transportsRequestStatus: IRequestStatus<
      TransportModel[]
    > = RequestStatus.idle(),
    reasonActionPolice: ReasonModel = { id: '', name: '' },
    reasonOperationPolice: ReasonModel = { id: '', name: '' },
    qualificationsRequestStatus: IRequestStatus<
      QualificationModel[]
    > = RequestStatus.idle(),
    fieldsForValidation: IFieldsForValidation = {} as IFieldsForValidation,
    agentQualification: QualificationModel = {
      id: '',
      name: '',
      type: QualificationType.AGENT
    },
    ageGroupsRequestStatus: IRequestStatus<
      AgeGroupModel[]
    > = RequestStatus.idle(),
    genresRequestStatus: IRequestStatus<GenreModel[]> = RequestStatus.idle(),
    racesRequestStatus: IRequestStatus<RaceModel> = RequestStatus.idle(),
    placesRequestStatus: IRequestStatus<PlaceModel[]> = RequestStatus.idle(),
    circumstancesRequestStatus: IRequestStatus<
      CircumstanceModel[]
    > = RequestStatus.idle(),
    statusRequestStatus: IRequestStatus<StatusModel[]> = RequestStatus.idle(),
    positionsRequestStatus: IRequestStatus<
      PositionModel[]
    > = RequestStatus.idle(),
    coorporationsRequestStatus: IRequestStatus<
      CoorporationModel[]
    > = RequestStatus.idle(),
    partiesRequestStatus: IRequestStatus<PartieModel[]> = RequestStatus.idle(),
    animalsRequestStatus: IRequestStatus<AnimalModel[]> = RequestStatus.idle()
  ) {
    this.preparingDataRequestStatus = preparingDataRequestStatus
    this.detailsForm = detailsForm;
    this.editOccurrenceRequestStatus = editOccurrenceRequestStatus;
    this.createOccurrenceRequestStatus = createOccurrenceRequestStatus;
    this.contextInfoForm = contextInfoForm;
    this.transportsForm = transportsForm;
    this.victimsForm = victimsForm;
    this.animalVictimsForm = animalVictimsForm;
    this.victimsCount = victimsCount;
    this.animalVictimsCount = animalVictimsCount;
    this.occurrenceRequestStatus = occurrenceRequestStatus;
    this.statesRequestStatus = statesRequestStatus;
    this.citiesRequestStatus = citiesRequestStatus;
    this.neighborhoodsRequestStatus = neighborhoodsRequestStatus;
    this.subneighborhoodsRequestStatus = subNeighborhoodsRequestStatus;
    this.localitiesRequestStatus = localitiesRequestStatus;
    this.sourcesRequestStatus = sourcesRequestStatus;
    this.reasonsRequestStatus = reasonsRequestStatus;
    this.clippingsRequestStatus = clippingsRequestStatus;
    this.transportsRequestStatus = transportsRequestStatus;
    this.reasonActionPolice = reasonActionPolice;
    this.reasonOperationPolice = reasonOperationPolice;
    this.qualificationsRequestStatus = qualificationsRequestStatus;
    this.fieldsForValidation = fieldsForValidation;
    this.agentQualification = agentQualification;
    this.ageGroupsRequestStatus = ageGroupsRequestStatus;
    this.genresRequestStatus = genresRequestStatus;
    this.racesRequestStatus = racesRequestStatus;
    this.placesRequestStatus = placesRequestStatus;
    this.circumstancesRequestStatus = circumstancesRequestStatus;
    this.statusRequestStatus = statusRequestStatus;
    this.positionsRequestStatus = positionsRequestStatus;
    this.coorporationsRequestStatus = coorporationsRequestStatus;
    this.partiesRequestStatus = partiesRequestStatus;
    this.animalsRequestStatus = animalsRequestStatus;
  }
}
