import React, { useEffect, useState } from 'react';
import { Container, Form, Fieldset } from './index.style';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  api,
  editUsers,
  editUsersPassword,
  getRegions,
  getStates,
  getUsers
} from '../../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_REGIONS,
  ADD_STATES,
  ADD_USERS,
  RootState
} from '../../../../../store';
import { IProps } from './index.types';
import {
  ModalEditTrue,
  ModalSucesso,
  RadioGroup,
  SwitchButtonSecondary
} from '../../../../../components';

const ModalAlterarSenha: React.FC<IProps> = ({ isModal, onHide, user }) => {
  const [isModalErrorEdit, setModalErrorEdit] = useState(false);
  const [isModalSucessoEdit, setModalSucessoEdit] = useState(false);
  const dispatch = useDispatch();
  const { token, regions, states } = useSelector(
    (state: RootState) => state.clickState
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue,
    setError,
    watch
  } = useForm({
    defaultValues: {
      id: '',
      password: '',
      new_password: '',
      new_password1: ''
    }
  });

  const onSubmit = (dados: any) => {
    let aux = Object.assign(user, {
      password: dados.new_password,
      region: user.region != null ? user.region.id : user.region,
      id: user.id
    });

    delete aux.password_hash;

    editUsers(token, aux.id, aux)
      .catch(erro => {
        console.log(erro);
        console.warn('(Usuário) Erro:', erro.response.data);
        onHide();
        setModalErrorEdit(true);
      })
      .then(resp => {
        if (resp != null) {
          getUsers(token).then(resp => {
            dispatch({ type: ADD_USERS, users: resp.data });
            onHide();
            setModalSucessoEdit(true);
            console.log(resp);
          });
        } else {
          console.log('rrr', resp);
        }
      });
  };

  useEffect(() => {
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
  }, []);

  let password = watch('new_password');

  return (
    <>
      <Offcanvas show={isModal} onHide={onHide} placement="end">
        <Container>
          <header>
            <h3>Editar senha do usuário</h3>
          </header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {/* <Fieldset>
            <label>Senha atual</label>
            <input
                type="password"
                placeholder="Digite a senha atual"
                {...register("password", { required: true })}
            />
        </Fieldset> */}
            <Fieldset>
              <label>Nova senha</label>
              <input
                type="password"
                placeholder="Digite a nova senha"
                {...register('new_password', { required: true })}
              />
            </Fieldset>

            <Fieldset>
              <label>Confirmar as senhas</label>
              <input
                type="password"
                placeholder="Redigite a nova senha"
                {...register('new_password1', {
                  required: true,
                  onChange: (e: any) => {
                    let valor = e.target.value;
                    if (valor != password) {
                      setError('new_password1', {
                        type: 'value',
                        message: 'Senhas não combinam'
                      });
                    } else {
                      setError('new_password1', { type: 'value', message: '' });
                    }
                  }
                })}
              />
              {errors.new_password1 && (
                <p style={{ color: 'red' }}> {errors.new_password1.message}</p>
              )}
            </Fieldset>

            <footer>
              <button
                onClick={() => {
                  onHide();
                  reset({
                    id: '',
                    password: '',
                    new_password: '',
                    new_password1: ''
                  });
                }}
              >
                Cancelar
              </button>
              <button type="submit">Finalizar</button>
            </footer>
          </Form>
        </Container>
      </Offcanvas>

      <ModalSucesso
        isModal={isModalSucessoEdit}
        onHide={() => setModalSucessoEdit(false)}
        title="Usuário editado"
        description="O usuário foi editado com sucesso."
      />

      <Modal
        show={isModalErrorEdit}
        onHide={() => {
          setModalErrorEdit(false);
        }}
      >
        <div>
          <h1>Erro ao editar</h1>
          <p>Ocorreu um erro ao editar o usuário!</p>
        </div>
      </Modal>
    </>
  );
};

export default ModalAlterarSenha;
