export function login(token: string) {
  try {
    localStorage.setItem('@cms/token', token);

    console.log('Dados salvos!');
  } catch (e) {
    console.log('Dados não salvos!');
  }
}

export function logout() {
  try {
    localStorage.removeItem('@cms/token');

    console.log('Dados removidos!');
  } catch (e) {
    console.log('Erro ao excluir os dados!');
  }
}

export function getCookie() {
  try {
    return localStorage.getItem('@cms/token');
  } catch (e) {
    console.log('Erro ao retornar os dados!');
  }
}
