/* eslint-disable jsx-a11y/alt-text */
import { Modal } from '@mui/material';
import React from 'react';

import {
  ModalContent,
  ModalIcon,
  ModalCloseIcon,
  ModalTitle,
  ModalSubtitle,
  ModalCloseButton
} from './styles';

interface PropsModal {
  title?: string;
  message?: string;
  open: boolean;
  onClose(): void;
}

const ErrorSimpleModal: React.FC<PropsModal> = ({
  open,
  onClose,
  title,
  message
}) => {
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <ModalCloseButton onClick={onClose}>
          <ModalCloseIcon />
        </ModalCloseButton>

        <ModalIcon />

        <ModalTitle>{title}</ModalTitle>
        <ModalSubtitle>{message}</ModalSubtitle>
      </ModalContent>
    </Modal>
  );
};

export default ErrorSimpleModal;
