import React, { useEffect, useState } from 'react';
import { Container, Fieldset, Form } from './index.style';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { editUsers, getRegions, getUsers } from '../../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_REGIONS, ADD_USERS, RootState } from '../../../../../store';
import { IProps } from './index.types';
import { ModalSucesso, SwitchButtonSecondary } from '../../../../../components';
import { isDefined } from '../../../../../utils/definedValue';
import Loading from '../../../../../components/Loading';

const ModalEditar: React.FC<IProps> = ({ isModal, onHide, user }) => {
  const [isModalErrorEdit, setModalErrorEdit] = useState(false);
  const [isModalSucessoEdit, setModalSucessoEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const { token, regions } = useSelector(
    (state: RootState) => state.clickState
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues
  } = useForm({
    defaultValues: {
      role: 'Selecione',
      name: '',
      email: '',
      phone_number: '',
      active: true,
      city: '',
      id: '',
      nickname: '',
      region: '',
      security_answer: '',
      security_question: '',
      trusted: true,
      password: ''
    }
  });

  const onSubmit = async (dados: any) => {
    if (dados.password == '') {
      delete dados.password;
    }
    let aux = Object.assign(user, dados);

    setIsLoading(true);
    await editUsers(token, aux.id, aux)
      .catch(_ => {
        onHide();
        setModalErrorEdit(true);
      })
      .then(async resp => {
        if (resp != null) {
          await getUsers(token)
            .then(resp => {
              dispatch({ type: ADD_USERS, users: resp.data });
              onHide();
              setModalSucessoEdit(true);
            })
            .finally(() => {
              resetForm();
            });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function resetForm() {
    reset({
      role: 'Selecione',
      name: '',
      email: '',
      phone_number: '',
      active: true,
      city: '',
      id: '',
      nickname: '',
      region: '',
      security_answer: '',
      security_question: '',
      trusted: true,
      password: ''
    });
  }

  useEffect(() => {
    getRegions(token, {
      enabled: true
    }).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  useEffect(() => {
    if (isDefined(user)) {
      let aux = {
        ...user,
        region: user.region != null ? user.region.id : user.region
      };
      reset(aux);
    }
  }, [user, isModal]);

  return (
    <>
      <Offcanvas show={isModal} onHide={onHide} placement="end">
        <Container>
          <header>
            <h3>Editar administrador</h3>
          </header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <label>Tipo de acesso*</label>
              <select {...register('role', { required: true })}>
                <option>Selecione</option>
                <option value="Administrador">Administrador</option>
                <option value="Coordenador">Coordenador</option>
                <option value="Regional">Regional</option>
                <option value="Mobile">Mobile</option>
              </select>
              {errors.role && (
                <p style={{ color: 'red' }}> Selecione um tipo de acesso </p>
              )}
            </Fieldset>
            <Fieldset>
              <label>Nome</label>
              <input
                type="text"
                placeholder="Digite o nome do moderador"
                {...register('name', { required: true })}
              />
              {errors.name && (
                <p style={{ color: 'red' }}> Digite o nome do moderador</p>
              )}
            </Fieldset>
            <Fieldset>
              <label>E-mail</label>
              <input
                type="text"
                placeholder="Digite o e-mail do moderador"
                {...register('email', { required: true })}
              />
              {errors.email && (
                <p style={{ color: 'red' }}> Digite o e-mail do moderador</p>
              )}
            </Fieldset>
            <Fieldset>
              <label>Telefone</label>
              <InputMask
                type="text"
                style={{ width: '100%' }}
                mask={'(99) 99999-9999'}
                placeholder="Digite o telefone do moderador"
                {...register('phone_number', {
                  required: true,
                  pattern: /\(\d{2}\)\s\d{5}\-\d{4}/i
                })}
              />
              {errors.phone_number && (
                <p style={{ color: 'red' }}> Digite o telefone do moderador</p>
              )}
            </Fieldset>
            <Fieldset>
              <label>Estado</label>
              <select {...register('region')}>
                <option>Selecione</option>
                {regions?.map(value => {
                  if (value.region.toLowerCase() === 'geral') return <></>;
                  return <option value={value.id}>{value.state}</option>;
                })}
              </select>

              {errors.region && (
                <p style={{ color: 'red' }}>
                  Selecione um estado para o moderador
                </p>
              )}
            </Fieldset>
            <Fieldset>
              <label>Status</label>
              <div>
                <p>Inativo</p>
                <SwitchButtonSecondary
                  onClick={e => {
                    setValue('active', !e);
                  }}
                  checked={getValues('active')}
                />
                <p>Ativo</p>
              </div>
              {errors.active && (
                <p style={{ color: 'red' }}> Selecione o status do moderador</p>
              )}
            </Fieldset>

            <footer>
              <button
                type="button"
                onClick={() => {
                  onHide();
                  resetForm();
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <>{isLoading ? <Loading size="sm" /> : <></>}</>
                Finalizar
              </button>
            </footer>
          </Form>
        </Container>
      </Offcanvas>

      <ModalSucesso
        isModal={isModalSucessoEdit}
        onHide={() => setModalSucessoEdit(false)}
        title="Moderador editado"
        description="O moderador foi editado com sucesso."
      />

      <Modal
        show={isModalErrorEdit}
        onHide={() => {
          setModalErrorEdit(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1>Erro ao editar</h1>
          <p>Ocorreu um erro ao editar o usuário!</p>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalEditar;
