import React from 'react';
import { Container } from './index.style';

import close from '../../assets/close.png';
import success from '../../assets/success.png';

interface IProps {
  close: () => void;
  title: any;
  subTitle: any;
}

const ModalEditTrue: React.FC<IProps> = props => {
  return (
    <Container>
      <button onClick={props.close}>
        <img src={close} alt="" />
      </button>
      <div>
        <img src={success} alt="" />
        <h3>{props.title}</h3>
        <p>{props.subTitle}</p>
      </div>
    </Container>
  );
};

export default ModalEditTrue;
