import PopupState from 'material-ui-popup-state';
import styled from 'styled-components';
import { IProps } from './index.types';

export const Container = styled.table`
  width: 100%;
  margin: 20px auto;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);

  > thead {
    background-color: #f3f2f7;

    > tr {
      > th {
        padding: 7px 24px !important;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #5e5873;
      }
    }
  }

  > tbody {
    > tr {
      > td {
        padding: 7px 24px !important;
        border-bottom: 1px solid ${props => props.theme.colors.grayLight};
      }
    }
  }
`;

export const Item = styled.th`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #5e5873;
    background: none;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;

      > button {
        height: 14px;
        background: none;
        border: none;
        display: flex;
        align-items: center;

        > img {
          width: 10.18px;
          height: 6.67px;
        }
      }
    }
  }
`;

export const Role = styled.td<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  > p {
    border-radius: 17px;
    padding: 1px 9px;
    margin: 0 !important;

    ${text => {
      if (text.access == 'Administrador') {
        return `
                    background-color: rgba(254, 140, 0, 0.2);
                    color: #FE8C00;
                `;
      } else if (text.access == 'Coordenador') {
        return `
                        background-color: rgba(0, 207, 232, 0.2);
                        color: #00CFE8;
                `;
      } else if (text.access == 'Regional') {
        return `
                        background-color: rgba(115, 103, 240, 0.2);
                        color: #7367F0;
                `;
      } else {
        return ``;
      }
    }}
  }
`;

export const Name = styled.td`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
`;

export const Active = styled.td<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;

  > p {
    border-radius: 17px;
    padding: 1px 9px;
    margin: 0 !important;
    ${text => {
      if (text.active == true) {
        return `
                    background-color: rgba(40, 199, 111, 0.2);
                    color: #28C76F;
                `;
      } else if (text.active == false) {
        return `
                    background-color: rgba(110, 107, 123, 0.2);
                    color: #6E6B7B;
                `;
      }
    }}
  }
`;

export const Action = styled.td`
  text-align: center;

  > span {
    > button {
      background: none;
      border: none;
      > img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const Card = styled.div`
  margin-right: 50px;

  > button {
    border: none;
    border-radius: 6px;
    background-color: ${props => props.theme.colors.white};
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    margin-left: 100px;
  }

  div {
    background-color: ${props => props.theme.colors.white};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding: 19px 30px 19px 16px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);

    > button {
      background: none;
      border: none;
      display: flex;

      > img {
        margin-right: 10px;
      }
    }
  }
`;

export const Trusted = styled.td<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;

  > p {
    border-radius: 17px;
    padding: 1px 9px;
    margin: 0 !important;
    ${text => {
      if (text.active == true) {
        return `
                    background-color: rgba(40, 199, 111, 0.2);
                    color: #28C76F;
                `;
      } else if (text.active == false) {
        return `
                    background-color: #f8e9e9;
                    color: #ea5455;
                `;
      }
    }}
  }
`;
