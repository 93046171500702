import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlueComponent,
  ModalSucesso,
  RadioText
} from '../../../../../components';
import {
  createArticles,
  editArticles,
  getArticles
} from '../../../../../services';
import { ADD_ARTICLES, RootState } from '../../../../../store';
import { Container } from './index.style';
import { IProps, PropI } from './index.types';

const ModalCreateAcademicResearch: React.FC<IProps> = ({ isModal, onHide }) => {
  const refBtn = useRef<any>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      publication_date: '',
      title: '',
      link: '',
      theme: '',
      indicators_fc: '',
      sub_identifiers_fc: '',
      keyword: '',
      media_type: '',
      vehicle_name: '',
      program_or_section: '',
      access_type: 'Livre',
      scope: '',
      continent: '',
      country: '',
      state: '',
      city: '',
      place: '',
      emphasis: '',
      spokesman: '',
      exclusive: false,
      hook: '',
      digital: false,
      type_of_contact: '',
      contact_channel: '',
      bait: '',
      axis_fc: '',
      year: 2022,
      federative_power: '',
      document_type: '',
      status: '',
      document_details: '',
      subject_area: '',
      public_reference: '',
      contact: '',
      fc_influence: '',
      type_material: '',
      field_knowledge: '',
      associated_theme_fc: '',
      inputs_used: '',
      author: '',
      institutional_binding: '',
      magazine_name: '',
      type: 'Pesquisa Acadêmica'
    }
  });

  const { token } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false);

  function onSubmit(dados: any) {
    let aux = {
      ...dados,
      year: parseInt(dados.year)
    };

    console.log(aux);

    createArticles(token, aux).then(r => {
      getArticles(token).then(resp => {
        dispatch({ type: ADD_ARTICLES, articles: resp.data });
      });
      onHide();
      setVisible(true);
    });
  }

  const title = watch('title');

  return (
    <Container>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          refBtn.current.click();
        }}
        titleComponent={<div>Incluir matéria - Pesquisa Acadêmica</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Ano</Form.Label>
                <Form.Control
                  type="number"
                  min="2000"
                  max="2022"
                  {...register(`year`)}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Título</Form.Label>

                <Form.Control
                  maxLength={150}
                  {...register(`title`, {
                    required: true,
                    maxLength: 150,
                    onChange: () => {
                      if (title.length == 150) {
                        setError('title', {
                          type: 'maxLength',
                          message: 'Máximo de caracteres alcançado'
                        });
                      }
                    }
                  })}
                />
                {errors.title && <p>{errors.title.message}</p>}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Link</Form.Label>

                <Form.Control type="url" {...register(`link`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Campo de conhecimento</Form.Label>
                <Form.Select {...register(`field_knowledge`)}>
                  {[
                    'Sociologia',
                    'Antropologia',
                    'Ciência política',
                    'Economia',
                    'Psicologia',
                    'Matemática',
                    'Educação',
                    'Relações internacionais',
                    'Administração',
                    'Geografia',
                    'Comunicação',
                    'Arquitetura e urbanismo',
                    'Serviço social',
                    'Gestão pública',
                    'Direito',
                    'Estatística'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Tema associado ao uso do Fogo Cruzado</Form.Label>
                <Form.Select {...register(`associated_theme_fc`)}>
                  {[
                    'Segurança pública',
                    'Sociologia urbana',
                    'Gestão/Serviços públicos',
                    'Tecnologia',
                    'Organizações',
                    'Jornalismo',
                    'Participação social',
                    'Estatística'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Label>Eixo do Fogo Cruzado</Form.Label>
              <Form.Select {...register(`axis_fc`)}>
                {[
                  'Praça RJ',
                  'Praça PE',
                  'Instituto/Metodologia de dados',
                  'Instituto/Comunicação',
                  'Instituto/Tecnologia'
                ].map(chave => {
                  return <option value={chave}>{chave}</option>;
                })}
              </Form.Select>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Insumos utilizados</Form.Label>
                <Form.Select {...register(`inputs_used`)}>
                  {[
                    'Dados',
                    'Metodologia',
                    'Entrevista',
                    'Mapa dos grupos armados'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Autor</Form.Label>
                <Form.Control {...register(`author`, { required: true })} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Vinculação Institucional</Form.Label>
                <Form.Control {...register(`institutional_binding`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de acesso</Form.Label>

                <Form.Select>
                  {['Livre', 'Assinatura', 'Bibliotéca'].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Tipo de mídia</Form.Label>
                <Form.Select {...register(`media_type`)}>
                  {[
                    'Artigo em periódicos',
                    'Capítulo de livro',
                    'Blog',
                    'Eventos',
                    'Entrevistas',
                    'Artigo eletrônico',
                    'Manual',
                    'Tese',
                    'Dissertação',
                    'Monografia/TCC',
                    'Relatório',
                    'Livro'
                  ].map(chave => {
                    return <option value={chave}>{chave}</option>;
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Nome da revista/livro</Form.Label>
                <Form.Control {...register(`magazine_name`)} />
              </Form.Group>
            </Col>
            <Col> </Col>
          </Row>

          <br />
          <button type="submit" style={{ display: 'none' }} ref={refBtn}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Link criado"
        description="O link foi criado com sucesso."
      />
    </Container>
  );
};

export default ModalCreateAcademicResearch;
