import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  ButtonNav,
  CardWrap,
  CenterContainer,
  Container,
  HeaderTitle,
  NavBtn,
  NavHeader,
  NavIcons
} from './index.style';
import { CardPost } from '../../../../../components/index';
import Loading from '../../../../../components/Loading';
import ModalHandlePost from '../../components/modals/handle_post';
import { BlogContext } from '../../../providers/BlogProvider';

import { EHandlePostModalType } from '../../components/modals/handle_post/index.types';
import { PostType } from '../../../data/entities/PostEntity';

const PostList: React.FC = () => {
  const { postListState, postListUsecase } = useContext(BlogContext);

  const [isModalCadastrar, setModalCadastrar] = useState(false);
  const [menu, setMenu] = useState<PostType>(PostType.ALL);

  async function requestRegions() {
    await postListUsecase.getRegions();
  }

  async function requestPosts() {
    await postListUsecase.getPostList();
  }

  useEffect(() => {
    requestRegions();
  }, []);

  useEffect(() => {
    requestPosts();
  }, [
    postListState.queryParams.order,
    postListState.queryParams.page,
    postListState.queryParams.take,
    postListState.queryParams.search,
    postListState.queryParams.region,
    postListState.queryParams.authors,
    postListState.queryParams.date
  ]);

  return (
    <Container>
      <NavHeader>
        <HeaderTitle>Informe-se (Blog)</HeaderTitle>

        <NavIcons>
          <NavBtn
            enabled={menu === PostType.ALL}
            onClick={() => {
              setMenu(PostType.ALL);
            }}
          >
            Todas
          </NavBtn>
          <NavBtn
            enabled={menu === PostType.PUBLISHED}
            onClick={() => {
              setMenu(PostType.PUBLISHED);
            }}
          >
            Publicadas
          </NavBtn>
          <NavBtn
            enabled={menu === PostType.SCHEDULED}
            onClick={() => {
              setMenu(PostType.SCHEDULED);
            }}
          >
            Agendadas
          </NavBtn>
          <NavBtn
            enabled={menu === PostType.DRAFT}
            onClick={() => {
              setMenu(PostType.DRAFT);
            }}
          >
            Rascunho
          </NavBtn>
        </NavIcons>

        <ButtonNav
          onClick={() => {
            setModalCadastrar(true);
          }}
        >
          Nova postagem
        </ButtonNav>
      </NavHeader>

      <br />

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Pesquisar</Form.Label>
            <Form.Control
              placeholder="Pesquisar"
              onChange={e => {
                postListUsecase.setSearch(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <br />

      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Região</Form.Label>
            <Form.Select
              onChange={e => {
                postListUsecase.setRegion(e.target.value);
              }}
            >
              <option value="">Selecione</option>
              {postListState.regionsRequestStatus.maybeMap({
                loading: () => <option>Carregando...</option>,
                succeeded(data) {
                  return data?.map(region => {
                    if (region.enabled === true) {
                      return <option value={region.id}>{region.region}</option>;
                    }
                    return <></>;
                  });
                }
              })}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Criador do conteúdo</Form.Label>
            <Form.Control
              placeholder="Nome do criado de conteúdo"
              onChange={e => {
                postListUsecase.setAuthors(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label>Selecione a data</Form.Label>
            <Form.Control
              type="date"
              onChange={e => {
                postListUsecase.setDate(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <br />

      <CenterContainer>
        <CardWrap>
          {postListState.postsRequestStatus.maybeMap({
            loading() {
              return <Loading />;
            },
            succeeded(data) {
              return data?.map(post => {
                if (menu === post.type && menu !== PostType.ALL) {
                  return <CardPost content={post} />;
                } else if (menu === PostType.ALL) {
                  return <CardPost content={post} />;
                }
                return <></>;
              });
            }
          })}
        </CardWrap>
      </CenterContainer>

      <ModalHandlePost
        isModal={isModalCadastrar}
        onHide={() => setModalCadastrar(false)}
        modalType={EHandlePostModalType.CREATE}
        callBackPostAction={requestPosts}
      />
    </Container>
  );
};

export default PostList;
