import React, { useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteArticles,
  deletePost,
  getArticles,
  getPosts
} from '../../../../services';
import { ADD_ARTICLES, ADD_POSTS, RootState } from '../../../../store';
import { IProps } from './index.types';

const Delete: React.FC<IProps> = ({ content, isModal, onHide }) => {
  const { token } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  function deleteItems() {
    if (content != undefined) {
      deleteArticles(token, content.id).then(resp => {
        getArticles(token).then(r => {
          dispatch({ type: ADD_ARTICLES, articles: r.data });
          onHide();
          setVisible(true);
        });
      });
    }
  }

  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal show={isModal} onHide={onHide}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1 style={{ textAlign: 'center' }}>Apagar a matéria</h1>
          <p style={{ textAlign: 'center' }}>
            Deseja apagar a matéria selecionada?
          </p>
          <br />

          <Row>
            <Col xs={3}>
              <Button
                variant="info"
                onClick={() => {
                  onHide();
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col xs={6}></Col>
            <Col xs={3}>
              <Button variant="danger" onClick={() => deleteItems()}>
                Apagar
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>

      <Modal show={visible} onHide={() => setVisible(false)}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1 style={{ textAlign: 'center' }}>Matéria apagada</h1>
          <p style={{ textAlign: 'center' }}>
            A matéria foi apagada com sucesso!
          </p>
          <br />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Delete;
