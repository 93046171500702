import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { StatusModel } from '../../domain/models/StatusModel';

export enum StatusType {
  UNKNOWN = '',
  SERVICE = 'Servico',
  AGENT = 'Agente',
  POLITICAL = 'Politico'
}

export class StatusEntity extends BasicEntity {
  type: StatusType;

  constructor(data?: Partial<StatusEntity>) {
    super(data);
    this.type = data?.type || StatusType.UNKNOWN;
  }

  toDomain(): StatusModel {
    return new StatusModel({
      id: this.id,
      name: this.name,
      type: this.type
    });
  }
}
