import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 20px;
  padding-top: 13px;
`;

export const Title = styled.h6`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5e5873;
  margin: 0;
  margin-right: 10px;
`;

export const Header = styled.header`
  background: #f3f2f7 !important;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-bottom: 20px;

  > div {
    background: #f3f2f7 !important;
  }
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

export const BlcokSB = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;

  > div {
    width: 48%;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
  }
`;

export const InputArea = styled.input`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  width: 350px;
  height: 125px;
  resize: none;
  outline: none;
`;

export const InputText = styled.input`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 38px;
`;

export const InputSelect = styled.select`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 38px;
`;

export const CadVtm = styled.button`
  background: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.white};
  border: none;
  border-radius: 6px;
  padding: 12px 50px;
`;

export const EditVtm = styled.button`
  background: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.tertiary};
  border: 3px solid ${props => props.theme.colors.tertiary};
  border-radius: 6px;
  padding: 12px 50px;
`;
