import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkStyled = styled(Link)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: #2c3941;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus,
  &:disabled {
    color: #2c3941;
  }
`;

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  max-width: 100%;
  padding: 16px;
`;

export const Back = styled(Link)`
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  z-index: 2;
  margin-bottom: -90px;
  margin-left: 30px;

  > img {
    transform: scaleX(-1);
    rotate: right;
    width: 32px;
    height: 32px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 46px;

  position: relative;
`;

export const ButtonTop = styled.a`
  position: absolute;
  top: 10px;
  right: 10px;
  text-decoration: none;
  color: #294150;
  padding: 10px;
  width: 177px;
  text-align: center;
  background-color: #fff;
  border: 1px solid black;
`;

export const DivNAv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const DivNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonNAv = styled.button`
  margin-left: 20px;
  width: 200px;
  height: 46px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.4px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  border: none;
  background: ${props => props.theme.colors.red};

  > img {
    margin-right: 10px;
  }
`;

export const ImageTrash = styled.img`
  width: 32px;
  height: 32px;
`;

export const ButtonNav = styled.button`
  width: 190px;
  height: 46px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.4px;
  border-radius: 6px;
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  background: #ffffff;

  > img {
    margin-right: 10px;
  }
`;

export const Content = styled.div`
  padding: 40px 0;

  > div:nth-child(1) {
    color: ${props => props.theme.colors.black};
    background-color: ${props => props.theme.colors.gray};
    padding: 4px 8px;
    border-radius: 50px;
    margin-bottom: 8px;
    width: max-content;
    margin-bottom: 20px;
  }

  > h3 {
    font-size: 28px;
    line-height: 27px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;

    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      width: 200px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
`;

export const Div = styled.div`
  display: flex;
  width: 520px;

  > div {
    display: flex;
    align-items: center;
    :nth-child(2) {
      margin-left: 80px;
    }
  }

  @media (max-width: 575.98px) {
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      :nth-child(2) {
        margin-left: 0;
        margin-top: 8px;
      }
    }
  }
`;

export const Section = styled.div`
  padding: 20px 120px;

  > p {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  > img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 575.98px) {
    padding: 0;
  }

  @media (min-width: 768px) and (max-width: 991.98px) {
    padding: 0;
  }
`;

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const ImageContent = styled.img`
  width: 1066px !important;
  height: 315px !important;
  max-width: 1066px !important;
  max-height: 315px !important;
`;

export const ImageHeader = styled.img`
  width: 100%;
  max-height: 320px;
  object-fit: cover;
`;

export const DivImage = styled.div`
  text-align: center;
`;

export const ImagesContent = styled.img`
  width: 522px !important;
  height: 315px !important;
  max-width: 522px !important;
  max-height: 315px !important;
`;

export const DivImages = styled.div`
  text-align: center;
`;

export const ContainerCenter = styled.div``;

export const ContentHTML = styled.div`
  & > p {
    text-indent: 20px;
    text-align: justify;
  }

  & > blockquote {
    border-left: 3px solid #000;
    padding: 10px 20px;
    text-indent: 20px;
    text-align: justify;
  }
`;
