import { Field, FieldInputProps } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

const FormCheckRadio: React.FC<{
  field: FieldInputProps<any>;
  dataCy?: string;
  handleChange: (fieldName: string, fieldValue: any) => void;
  isInvalid: (fieldName: string) => boolean | undefined;
}> = ({ field, dataCy, handleChange, isInvalid }) => {
  const onChange = (e: any) =>
    handleChange(field.name, e.target.value === 'true');

  return (
    <>
      <Form.Check inline>
        <Field
          data-cy={`${dataCy}-true`}
          as={Form.Check.Input}
          type="radio"
          id={field.name}
          name={field.name}
          value={true}
          onChange={onChange}
          isInvalid={isInvalid(field.name)}
        />
        <Form.Check.Label htmlFor={field.name}>Sim</Form.Check.Label>
      </Form.Check>

      <Form.Check inline>
        <Field
          data-cy={`${dataCy}-false`}
          as={Form.Check.Input}
          type="radio"
          id={field.name}
          name={field.name}
          value={false}
          onChange={onChange}
          isInvalid={isInvalid(field.name)}
        />
        <Form.Check.Label htmlFor={field.name}>Não</Form.Check.Label>
      </Form.Check>
    </>
  );
};

export default FormCheckRadio;
