import axios from 'axios';
import { createContext, useEffect, useRef, useState } from 'react';
import { ApiRepositoryImpl } from '../data/repositories_impl/ApiRepositoryImpl';
import { AdressSharedRepositoryImpl } from '../data/repositories_impl/AdressSharedRepositoryImpl';
import { ApiRepository } from '../domain/repositories/ApiRepository';
import { AdressSharedRepository } from '../domain/repositories/AdressSharedRepository';
import AppState from '../domain/usecases/app/AppState';
import AppUsecase from '../domain/usecases/app/AppUsecase';

interface IAppContext {
  state: AppState;
  appUsecase: AppUsecase;
  apiRepository: ApiRepository;
  adressSharedRepository: AdressSharedRepository;
}

export const AppContext = createContext<IAppContext>({} as IAppContext);

export const AppProvider = ({ children }: any) => {
  const createAxiosInstance = () =>
    axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL
    });

  const [apiRepository, setApiRepository] = useState<ApiRepository>(
    new ApiRepositoryImpl(createAxiosInstance(), '')
  );
  const [adressSharedRepository, setSharedRepository] =
    useState<AdressSharedRepository>(
      new AdressSharedRepositoryImpl(apiRepository)
    );
  const [state, setState] = useState<AppState>(new AppState());

  const appUsecase: AppUsecase = new AppUsecase(state, setState);

  useEffect(() => {
    setApiRepository(new ApiRepositoryImpl(createAxiosInstance(), state.token));
  }, [state.token]);

  useEffect(() => {
    setSharedRepository(new AdressSharedRepositoryImpl(apiRepository));
  }, [apiRepository]);

  return (
    <AppContext.Provider
      value={{
        state,
        appUsecase,
        apiRepository: apiRepository,
        adressSharedRepository: adressSharedRepository
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
