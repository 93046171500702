import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin-bottom: 60px;
  width: 100%;

  > h3 {
    padding: 24px;
  }
`;

export const Header = styled.thead`
  background-color: #f3f2f7;
  width: 100%;
  > tr {
    padding: 0 !important;
    margin: 0 !important;
    > th {
      width: 33.333%;
      padding: 24px 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      letter-spacing: 1px;
    }
  }
`;

export const Body = styled.tbody`
  > tr {
    padding: 0 !important;
    margin: 0 !important;
    > td {
      padding: 9px 13px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
`;
