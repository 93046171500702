import React, { useRef, useEffect, ReactNode, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

interface CustomAlertProps {
  message?: string;
  backgroundColor?: string;
  textColor?: string;
  children?: ReactNode;
  onHide: () => void;
  onDismiss?: () => void;
}

function CustomAlert({
  backgroundColor,
  children,
  onHide,
  onDismiss
}: CustomAlertProps) {
  const [isVisible, setIsVisible] = useState(false);
  const alertRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 250);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        alertRef.current &&
        !alertRef.current.contains(event.target as Node)
      ) {
        onHide();
        if (onDismiss) {
          onDismiss();
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onHide, onDismiss]);

  const childrenWithProps: React.ReactElement[] | undefined =
    children &&
    (React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          onDismiss: () => {
            onHide();
            if (onDismiss) {
              onDismiss();
            }
          }
        } as CustomAlertProps);
      }
      return child;
    })?.filter(Boolean) as React.ReactElement[] as any);

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: backgroundColor ?? 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        opacity: isVisible ? 1 : 0,
        transition: 'opacity 0.25s ease-in-out'
      }}
    >
      <div
        ref={alertRef}
        style={{
          padding: '20px',
          borderRadius: '5px',
          maxWidth: '80%',
          textAlign: 'center'
        }}
      >
        {childrenWithProps}
      </div>
    </div>,
    document.getElementById('alert-root') as Element
  );
}

export interface AlertComponentProps {
  onDismiss?: () => void;
}

interface AlertOptions {
  backgroundColor?: string;
  onDismiss?: () => void;
  children?: ReactNode;
}

export function showAlert({
  backgroundColor,
  onDismiss,
  children
}: AlertOptions) {
  let alertRoot = document.getElementById('alert-root');

  if (!alertRoot) {
    alertRoot = document.createElement('div');
    alertRoot.setAttribute('id', 'alert-root');
    document.body.appendChild(alertRoot);
  }

  let root = createRoot(alertRoot);

  const onHide = () => {
    root.unmount();
  };

  root.render(
    <CustomAlert
      backgroundColor={backgroundColor}
      onDismiss={onDismiss}
      onHide={onHide}
    >
      {children}
    </CustomAlert>
  );
}
