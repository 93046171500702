import { DashboardRepository } from '../../domain/repositories/DashboardRepository';
import { ApiRepository } from '../../../../shared/domain/repositories/ApiRepository';
import { IResult } from '../../../../shared/interface/result';
import { AppUnknownError } from '../../../../shared/types/AppError';
import { Result } from '../../../../shared/types/Result';
import { DashboardEndpoint } from '../../../../shared/endpoint';
import {
  AnnualStatisticsEntity,
  StatisticsEntity
} from '../entities/DashboardEntity';
import {
  AnnualStatisticsModel,
  StatisticsModel
} from '../../domain/models/DashboardModel';
import {
  AnnualStatisticQueryParams,
  StatisticQueryParams
} from '../../domain/usecases/statistics/StatisticsState';
import { isDefined } from '../../../../utils/definedValue';

export class DashboardRepositoryImpl implements DashboardRepository {
  apiRepository: ApiRepository;

  constructor(apiRepository: ApiRepository) {
    this.apiRepository = apiRepository;
  }

  public async getStatistics(
    statisticQueryParams: StatisticQueryParams
  ): Promise<IResult<StatisticsModel>> {
    try {
      let params = new URLSearchParams();

      const { initialdate, finaldate, region, cities, neighborhoods } =
        statisticQueryParams;

      if (isDefined(initialdate)) {
        params.append('initialdate', `${initialdate}T00:00:00.000Z`);
      }

      if (isDefined(finaldate)) {
        params.append('finaldate', `${finaldate}T23:59:59.000Z`);
      }

      if (isDefined(region)) {
        params.append('region', region);
      }

      if (isDefined(cities)) {
        cities.forEach(city => params.append('cities', city.value));
      }

      if (isDefined(neighborhoods)) {
        neighborhoods.forEach(neighborhood =>
          params.append('neighborhoods', neighborhood.value)
        );
      }

      params.append('status', 'Approved');

      let response = await this.apiRepository.get(
        DashboardEndpoint.statistics,
        params
      );

      return response.handle({
        onSuccess(body) {
          let entity: StatisticsEntity = new StatisticsEntity(body);
          let model: StatisticsModel = entity.toDomain();

          return Result.success(model);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getAnnualStatistics(
    annualStatisticQueryParams: AnnualStatisticQueryParams
  ): Promise<IResult<AnnualStatisticsModel>> {
    try {
      let params = new URLSearchParams();

      const year = annualStatisticQueryParams.year;

      params.append('initialdate', `${year}-01-01T00:00:00.000Z`);
      params.append('finaldate', `${year}-12-31T23:59:59.000Z`);

      params.append('status', 'Approved');

      let response = await this.apiRepository.get(
        DashboardEndpoint.annualStatistics,
        params
      );

      return response.handle({
        onSuccess(body) {
          let entity: AnnualStatisticsEntity = new AnnualStatisticsEntity(body);
          let model: AnnualStatisticsModel = new AnnualStatisticsModel(
            entity.toDomain()
          );

          return Result.success(model);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }
}
