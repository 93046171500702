import React, { useContext } from 'react';
import FormBody from '../../components/form_body';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';

import { Form, Row, Col } from 'react-bootstrap';
import {
  CustomForm,
  FormItem
} from '../../../../../../../shared/components/custom_form';
import AddItemOnFormButton from '../../../../../../../shared/components/add_item_on_form_button';
import { Divider } from '../../../../../../../shared/styles';
import FormItemCount from '../form_item_count';
import FormItemHeader from '../form_item_header';
import { Field } from 'formik';
import FormDateInput from '../../../../../../../shared/components/form_date_input';
import { RefactorSelectWithSearch } from '../../../../../../../components/SelectWithSearch';
import { QualificationType } from '../../../../../data/entities/QualificationEntity';
import { StatusType } from '../../../../../data/entities/StatusEntity';
import { PositionType } from '../../../../../data/entities/PositionEntity';
import { CircumstanceType } from '../../../../../data/entities/CircumstanceEntity';
import { EVictimsTypePerson } from '../../../../../../../shared/enums/victimsTypePerson';

const CreateEditOcurrenceVictimsFormComponent: React.FC = () => {
  const { createEditOccurrenceUsecase, createEditOccurrenceState } =
    useContext(OccurrenceContext);

  function enableFieldsByTypeQualification(
    idsQualification: string[],
    type: QualificationType
  ) {
    return (
      createEditOccurrenceState.qualificationsRequestStatus.maybeMap({
        succeeded(data) {
          return idsQualification.some(id => {
            const qualification = data.find(data => data.id === id);
            return qualification?.type === type;
          });
        },
        failed: () => false
      }) || false
    );
  }

  const dataCy = (field: string, index?: number) => {
    let prefix = 'victimForm';

    if (index !== null && index !== undefined) {
      return `${prefix}-${index}-${field}`;
    }
    return `${prefix}-${field}`;
  };

  return (
    <FormBody
      title="Vítimas"
      description="Complementação com informações sobre pessoas baleadas em cada registro (apenas administradores)."
    >
      <Row>
        <Col md={2}>
          <AddItemOnFormButton
            name="Incluir vítima"
            dataCy={dataCy('addVictimButton')}
            onClick={() => createEditOccurrenceUsecase.addNewVictimsForm()}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Total de vítimas"
            value={createEditOccurrenceState.victimsCount.total}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Civis mortos"
            value={createEditOccurrenceState.victimsCount.civilDead}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Civis feridos"
            value={createEditOccurrenceState.victimsCount.civilWounded}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Agentes mortos"
            value={createEditOccurrenceState.victimsCount.agentDead}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Agentes feridos"
            value={createEditOccurrenceState.victimsCount.agentWounded}
          />
        </Col>
      </Row>

      {createEditOccurrenceState.victimsForm.map((victimForm, index) => (
        <div key={victimForm.uniqueKey}>
          <Divider height={24} />

          <CustomForm
            validationSchema={victimForm.validationSchema({
              politicalQualificationId:
                createEditOccurrenceState.fieldsForValidation
                  .politicalQualification?.id,
              agentQualificationId:
                createEditOccurrenceState.fieldsForValidation.agentQualification
                  ?.id,
              servicesQualificationsIds:
                createEditOccurrenceState.fieldsForValidation.servicesQualifications?.map(
                  q => q.id
                )
            })}
            initialValues={victimForm}
            forwardedRef={victimForm.ref}
            onSubmit={() => {}}
            handleFormChange={(field, value) =>
              createEditOccurrenceUsecase.handleVictimsFormChange(
                index,
                field,
                value
              )
            }
            component={({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              isInvalid
            }) => {
              const clearAgentFields = () => {
                handleChange('coorporationId', '');
                handleChange('agentPositionId', '');
                handleChange('agentStatusId', '');
                handleChange('unit', '');
              };

              const clearPoliticalFields = () => {
                handleChange('partieId', '');
                handleChange('politicalPositionId', '');
                handleChange('politicalStatusId', '');
              };

              const clearServiceFields = () => {
                handleChange('serviceStatusId', '');
              };

              function onChangeQualificationField(options: any) {
                handleChange(
                  'qualifications',
                  options.map((option: any) => option.value)
                );

                const types = options.map((option: any) => option.type);

                if (!types.includes(QualificationType.POLITICAL)) {
                  clearPoliticalFields();
                }

                if (!types.includes(QualificationType.AGENT)) {
                  clearAgentFields();
                  handleChange('personType', EVictimsTypePerson.Civilian);
                }

                if (!types.includes(QualificationType.SERVICE)) {
                  clearServiceFields();
                }
              }

              function onChangeAgentRadio(e: any) {
                handleChange('personType', e.target.value);

                handleChange('qualifications', [
                  ...values['qualifications'],
                  createEditOccurrenceState.agentQualification.id
                ]);

                clearAgentFields();
              }

              function onChangeAgeField(e: any) {
                const age = e.target.value;
                let ageGroupName = '';
                if (age) {
                  if (age >= 0 && age <= 11) {
                    ageGroupName = 'criança';
                  } else if (age > 11 && age <= 17) {
                    ageGroupName = 'adolescente';
                  } else if (age > 17 && age <= 59) {
                    ageGroupName = 'adulto';
                  } else if (age >= 60) {
                    ageGroupName = 'idoso';
                  }
                }

                const ageGroupId =
                  createEditOccurrenceUsecase.getAgeGroupByName(
                    ageGroupName
                  ).id;

                handleChange('ageGroupId', ageGroupId);

                if (age < 0) {
                  handleChange('age', '0');
                } else {
                  handleChange('age', e.target.value);
                }
              }

              return (
                <Form noValidate onSubmit={handleSubmit}>
                  <FormItemHeader
                    title="VÍTIMA"
                    index={index}
                    onDelete={() =>
                      createEditOccurrenceUsecase.removeVictimsForm(index)
                    }
                    children={
                      <>
                        <Row>
                          <Col md={4}>
                            <Row>
                              <Col>
                                <FormItem
                                  name="situation"
                                  label="Situação da vítima*"
                                  component={field => (
                                    <>
                                      <Form.Check>
                                        <Field
                                          as={Form.Check.Input}
                                          type="radio"
                                          id={field.name}
                                          name={field.name}
                                          data-cy={
                                            dataCy(field.name, index) +
                                            '-wounded'
                                          }
                                          value="Wounded"
                                          onChange={(e: any) => {
                                            handleChange(
                                              field.name,
                                              e.target.value
                                            );

                                            handleChange('deathDate', '');
                                          }}
                                          isInvalid={isInvalid(field.name)}
                                        />
                                        <Form.Check.Label
                                          htmlFor={field.name}
                                          style={{ color: '#FF9F43' }}
                                        >
                                          Ferido
                                        </Form.Check.Label>
                                      </Form.Check>

                                      <Form.Check inline>
                                        <Field
                                          as={Form.Check.Input}
                                          type="radio"
                                          id={field.name}
                                          name={field.name}
                                          data-cy={
                                            dataCy(field.name, index) + '-dead'
                                          }
                                          value="Dead"
                                          onChange={(e: any) =>
                                            handleChange(
                                              field.name,
                                              e.target.value
                                            )
                                          }
                                          isInvalid={isInvalid(field.name)}
                                        />
                                        <Form.Check.Label
                                          htmlFor={field.name}
                                          style={{ color: '#EA5455' }}
                                        >
                                          Morto
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </>
                                  )}
                                />
                              </Col>
                              <Col>
                                <FormItem
                                  name="personType"
                                  label="Tipo de vítima*"
                                  component={field => (
                                    <>
                                      <Form.Check>
                                        <Field
                                          as={Form.Check.Input}
                                          type="radio"
                                          id={field.name}
                                          name={field.name}
                                          data-cy={
                                            dataCy(field.name, index) + '-civil'
                                          }
                                          value="Civilian"
                                          onChange={(e: any) => {
                                            handleChange(
                                              field.name,
                                              e.target.value
                                            );

                                            const filterQualifications = values[
                                              'qualifications'
                                            ].filter(
                                              qualification =>
                                                qualification !==
                                                createEditOccurrenceState
                                                  .agentQualification.id
                                            );

                                            clearAgentFields();

                                            handleChange(
                                              'qualifications',
                                              filterQualifications
                                            );
                                          }}
                                          isInvalid={isInvalid(field.name)}
                                        />
                                        <Form.Check.Label htmlFor={field.name}>
                                          Civil
                                        </Form.Check.Label>
                                      </Form.Check>

                                      <Form.Check inline>
                                        <Field
                                          as={Form.Check.Input}
                                          type="radio"
                                          id={field.name}
                                          name={field.name}
                                          data-cy={
                                            dataCy(field.name, index) + '-agent'
                                          }
                                          value="Agent"
                                          onChange={(e: any) =>
                                            onChangeAgentRadio(e)
                                          }
                                          isInvalid={isInvalid(field.name)}
                                        />
                                        <Form.Check.Label htmlFor={field.name}>
                                          Agente
                                        </Form.Check.Label>
                                      </Form.Check>
                                    </>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col md={6}>
                            <FormItem
                              name="qualifications"
                              label="Qualificações*"
                              failRequest={createEditOccurrenceState.qualificationsRequestStatus.maybeMap(
                                {
                                  failed: () => true
                                }
                              )}
                              onPressedRefresh={() =>
                                createEditOccurrenceUsecase.getQualifications()
                              }
                              component={field => (
                                <RefactorSelectWithSearch
                                  dataCy={dataCy(field.name, index)}
                                  options={createEditOccurrenceState.qualificationsRequestStatus.maybeMap(
                                    {
                                      idle: () => [],
                                      loading: () => [],
                                      failed: () => [],
                                      succeeded(data) {
                                        const qualifications = data.filter(
                                          qualification =>
                                            values['personType'] === 'Civilian'
                                              ? qualification !==
                                                createEditOccurrenceState.agentQualification
                                              : true
                                        );

                                        return qualifications?.map(
                                          qualification => ({
                                            label: qualification.name,
                                            value: qualification.id,
                                            type: qualification.type
                                          })
                                        );
                                      }
                                    }
                                  )}
                                  placeholder={createEditOccurrenceState.qualificationsRequestStatus.maybeMap(
                                    {
                                      idle: () => 'Selecione',
                                      loading: () => 'Carregando...',
                                      failed: () =>
                                        'Erro. Clique no botão acima para carregar as opções!',
                                      succeeded: () => 'Selecione'
                                    }
                                  )}
                                  onChange={options =>
                                    onChangeQualificationField(options)
                                  }
                                  value={values[field.name]}
                                  isDisabled={false}
                                  isMulti={true}
                                  isInvalid={isInvalid(field.name)}
                                  fieldName="qualificações"
                                />
                              )}
                            />
                          </Col>
                        </Row>
                      </>
                    }
                  />
                  <Row>
                    <Col md={3}>
                      <FormItem
                        name="name"
                        label="Nome/Apelido da vítima"
                        component={field => (
                          <Form.Control
                            {...field}
                            data-cy={dataCy(field.name, index)}
                            type="text"
                            placeholder="Digite o nome da vítima..."
                            isInvalid={isInvalid(field.name)}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                          />
                        )}
                      />
                    </Col>
                    <Col md={1}>
                      <FormItem
                        name="age"
                        label="Idade"
                        component={field => (
                          <Form.Control
                            {...field}
                            data-cy={dataCy(field.name, index)}
                            type="number"
                            placeholder="Digite..."
                            isInvalid={isInvalid(field.name)}
                            onChange={(e: any) => onChangeAgeField(e)}
                          />
                        )}
                      />
                    </Col>
                    <Col md={2}>
                      <FormItem
                        name="ageGroupId"
                        label="Faixa Etária*"
                        failRequest={createEditOccurrenceState.ageGroupsRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getAgeGroups()
                        }
                        component={field => (
                          <Form.Select
                            name={field.name}
                            data-cy={dataCy(field.name, index)}
                            value={values[field.name]}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                            onBlur={handleBlur}
                            isInvalid={isInvalid(field.name)}
                          >
                            {createEditOccurrenceState.ageGroupsRequestStatus.maybeMap(
                              {
                                idle: () => (
                                  <option>Carregando faixa etária...</option>
                                ),
                                loading: () => <option>Carregando...</option>,
                                failed: () => (
                                  <option>
                                    Erro. Clique no botão acima para carregar as
                                    opções!
                                  </option>
                                ),
                                succeeded(data) {
                                  return (
                                    <>
                                      <option value="">Selecione</option>
                                      {data?.map(agegroup => (
                                        <option value={agegroup.id}>
                                          {agegroup.name}
                                        </option>
                                      ))}
                                    </>
                                  );
                                }
                              }
                            )}
                          </Form.Select>
                        )}
                      />
                    </Col>
                    <Col md={2}>
                      <FormItem
                        name="genreId"
                        label="Gênero*"
                        failRequest={createEditOccurrenceState.genresRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getGenres()
                        }
                        component={field => (
                          <Form.Select
                            name={field.name}
                            data-cy={dataCy(field.name, index)}
                            value={values[field.name]}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                            onBlur={handleBlur}
                            isInvalid={isInvalid(field.name)}
                          >
                            {createEditOccurrenceState.genresRequestStatus.maybeMap(
                              {
                                idle: () => <option>Carregando...</option>,
                                loading: () => <option>Carregando...</option>,
                                failed: () => (
                                  <option>
                                    Erro. Clique no botão acima para carregar as
                                    opções!
                                  </option>
                                ),
                                succeeded(data) {
                                  return (
                                    <>
                                      <option value="">Selecione</option>
                                      {data?.map(genre => (
                                        <option value={genre.id}>
                                          {genre.name}
                                        </option>
                                      ))}
                                    </>
                                  );
                                }
                              }
                            )}
                          </Form.Select>
                        )}
                      />
                    </Col>
                    <Col md={2}>
                      <FormItem
                        name="race"
                        label="Raça*"
                        failRequest={createEditOccurrenceState.racesRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getRaces()
                        }
                        component={field => (
                          <Form.Select
                            name={field.name}
                            data-cy={dataCy(field.name, index)}
                            value={values[field.name]}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                            onBlur={handleBlur}
                            isInvalid={isInvalid(field.name)}
                          >
                            {createEditOccurrenceState.racesRequestStatus.maybeMap(
                              {
                                idle: () => <option>Carregando...</option>,
                                loading: () => <option>Carregando...</option>,
                                failed: () => (
                                  <option>
                                    Erro. Clique no botão acima para carregar as
                                    opções!
                                  </option>
                                ),
                                succeeded(data) {
                                  return (
                                    <>
                                      <option value="">Selecione</option>
                                      {data.raceList.map(race => (
                                        <option value={race}>{race}</option>
                                      ))}
                                    </>
                                  );
                                }
                              }
                            )}
                          </Form.Select>
                        )}
                      />
                    </Col>
                    <Col md={2}>
                      <FormItem
                        name="placeId"
                        label="Local*"
                        failRequest={createEditOccurrenceState.placesRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getPlaces()
                        }
                        component={field => (
                          <Form.Select
                            name={field.name}
                            data-cy={dataCy(field.name, index)}
                            value={values[field.name]}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                            onBlur={handleBlur}
                            isInvalid={isInvalid(field.name)}
                          >
                            {createEditOccurrenceState.placesRequestStatus.maybeMap(
                              {
                                idle: () => <option>Carregando...</option>,
                                loading: () => <option>Carregando...</option>,
                                failed: () => (
                                  <option>
                                    Erro. Clique no botão acima para carregar as
                                    opções!
                                  </option>
                                ),
                                succeeded(data) {
                                  return (
                                    <>
                                      <option value="">Selecione</option>
                                      {data?.map(place => (
                                        <option value={place.id}>
                                          {place.name}
                                        </option>
                                      ))}
                                    </>
                                  );
                                }
                              }
                            )}
                          </Form.Select>
                        )}
                      />
                    </Col>
                  </Row>
                  <Divider height={24} />
                  <Row>
                    <Col>
                      <FormItem
                        name="circumstances"
                        label="Circunstâncias*"
                        failRequest={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getCircumstances()
                        }
                        component={field => (
                          <RefactorSelectWithSearch
                            dataCy={dataCy(field.name, index)}
                            options={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                              {
                                idle: () => [],
                                loading: () => [],
                                failed: () => [],
                                succeeded(data) {
                                  return data
                                    ?.filter(
                                      circumstance =>
                                        circumstance.type !==
                                        CircumstanceType.ANIMAL
                                    )
                                    .map(circumstance => ({
                                      label: circumstance.name,
                                      value: circumstance.id
                                    }));
                                }
                              }
                            )}
                            placeholder={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                              {
                                idle: () => 'Selecione',
                                loading: () => 'Carregando...',
                                failed: () =>
                                  'Erro. Clique no botão acima para carregar as opções!',
                                succeeded: () => 'Selecione'
                              }
                            )}
                            onChange={options =>
                              handleChange(
                                field.name,
                                options.map((option: any) => option.value)
                              )
                            }
                            value={values[field.name]}
                            isDisabled={false}
                            isMulti={true}
                            isInvalid={isInvalid(field.name)}
                            fieldName="circunstâncias"
                          />
                        )}
                      />
                    </Col>

                    <Col>
                      <FormItem
                        name="generalObservation"
                        label="Observações"
                        component={field => (
                          <Form.Control
                            {...field}
                            data-cy={dataCy(field.name, index)}
                            type="text"
                            placeholder="Digite as observações..."
                            isInvalid={isInvalid(field.name)}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                          />
                        )}
                      />
                    </Col>
                    {enableFieldsByTypeQualification(
                      values['qualifications'],
                      QualificationType.SERVICE
                    ) && (
                      <Col>
                        <FormItem
                          name="serviceStatusId"
                          label="Status do serviço*"
                          failRequest={createEditOccurrenceState.statusRequestStatus.maybeMap(
                            {
                              failed: () => true
                            }
                          )}
                          onPressedRefresh={() =>
                            createEditOccurrenceUsecase.getStatus()
                          }
                          component={field => (
                            <Form.Select
                              name={field.name}
                              data-cy={dataCy(field.name, index)}
                              value={values[field.name]}
                              onChange={(e: any) =>
                                handleChange(field.name, e.target.value)
                              }
                              onBlur={handleBlur}
                              isInvalid={isInvalid(field.name)}
                            >
                              {createEditOccurrenceState.statusRequestStatus.maybeMap(
                                {
                                  idle: () => <option>Carregando...</option>,
                                  loading: () => <option>Carregando...</option>,
                                  failed: () => (
                                    <option>
                                      Erro. Clique no botão acima para carregar
                                      as opções!
                                    </option>
                                  ),
                                  succeeded(data) {
                                    return (
                                      <>
                                        <option value="">Selecione</option>
                                        {data?.map(
                                          status =>
                                            status.type ===
                                              StatusType.SERVICE && (
                                              <option
                                                key={status.id}
                                                value={status.id}
                                              >
                                                {status.name}
                                              </option>
                                            )
                                        )}
                                      </>
                                    );
                                  }
                                }
                              )}
                            </Form.Select>
                          )}
                        />
                      </Col>
                    )}
                  </Row>
                  {enableFieldsByTypeQualification(
                    values['qualifications'],
                    QualificationType.POLITICAL
                  ) && (
                    <>
                      <Divider height={24} />
                      <Row>
                        <Col>
                          <FormItem
                            name="partieId"
                            label="Partido político*"
                            failRequest={createEditOccurrenceState.partiesRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getParties()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.partiesRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(partie => (
                                            <option value={partie.id}>
                                              {partie.name}
                                            </option>
                                          ))}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                        <Col>
                          <FormItem
                            name="politicalPositionId"
                            label="Cargo*"
                            failRequest={createEditOccurrenceState.positionsRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getPositions()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.positionsRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(
                                            position =>
                                              position.type ===
                                                PositionType.POLITICAL && (
                                                <option
                                                  key={position.id}
                                                  value={position.id}
                                                >
                                                  {position.name}
                                                </option>
                                              )
                                          )}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                        <Col>
                          <FormItem
                            name="politicalStatusId"
                            label="Status do político*"
                            failRequest={createEditOccurrenceState.statusRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getStatus()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.statusRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(
                                            status =>
                                              status.type ===
                                                StatusType.POLITICAL && (
                                                <option
                                                  key={status.id}
                                                  value={status.id}
                                                >
                                                  {status.name}
                                                </option>
                                              )
                                          )}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                  {enableFieldsByTypeQualification(
                    values['qualifications'],
                    QualificationType.AGENT
                  ) && (
                    <>
                      <Divider height={24} />

                      <Row>
                        <Col>
                          <FormItem
                            name="coorporationId"
                            label="Corporação do agente*"
                            failRequest={createEditOccurrenceState.coorporationsRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getCoorporations()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.coorporationsRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(coorporation => (
                                            <option value={coorporation.id}>
                                              {coorporation.name}
                                            </option>
                                          ))}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                        <Col>
                          <FormItem
                            name="agentPositionId"
                            label="Posição do agente*"
                            failRequest={createEditOccurrenceState.positionsRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getPositions()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.positionsRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(
                                            position =>
                                              position.type ===
                                                PositionType.AGENT && (
                                                <option
                                                  key={position.id}
                                                  value={position.id}
                                                >
                                                  {position.name}
                                                </option>
                                              )
                                          )}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                        <Col>
                          <FormItem
                            name="agentStatusId"
                            label="Status do agente*"
                            failRequest={createEditOccurrenceState.statusRequestStatus.maybeMap(
                              {
                                failed: () => true
                              }
                            )}
                            onPressedRefresh={() =>
                              createEditOccurrenceUsecase.getStatus()
                            }
                            component={field => (
                              <Form.Select
                                name={field.name}
                                data-cy={dataCy(field.name, index)}
                                value={values[field.name]}
                                onChange={(e: any) =>
                                  handleChange(field.name, e.target.value)
                                }
                                onBlur={handleBlur}
                                isInvalid={isInvalid(field.name)}
                              >
                                {createEditOccurrenceState.statusRequestStatus.maybeMap(
                                  {
                                    idle: () => <option>Carregando...</option>,
                                    loading: () => (
                                      <option>Carregando...</option>
                                    ),
                                    failed: () => (
                                      <option>
                                        Erro. Clique no botão acima para
                                        carregar as opções!
                                      </option>
                                    ),
                                    succeeded(data) {
                                      return (
                                        <>
                                          <option value="">Selecione</option>
                                          {data?.map(
                                            status =>
                                              status.type ===
                                                StatusType.AGENT && (
                                                <option
                                                  key={status.id}
                                                  value={status.id}
                                                >
                                                  {status.name}
                                                </option>
                                              )
                                          )}
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </Form.Select>
                            )}
                          />
                        </Col>
                      </Row>

                      <Divider height={24} />

                      <Col>
                        <FormItem
                          name="unit"
                          label="Unidade do agente"
                          component={field => (
                            <Form.Control
                              {...field}
                              data-cy={dataCy(field.name, index)}
                              type="text"
                              placeholder="Digite a unidade do agente..."
                              isInvalid={isInvalid(field.name)}
                              onChange={(e: any) =>
                                handleChange(field.name, e.target.value)
                              }
                            />
                          )}
                        />
                      </Col>
                    </>
                  )}
                  {values['situation'].toLowerCase() === 'dead' && (
                    <>
                      <Divider height={24} />
                      <Col md={2}>
                        <FormItem
                          name="deathDate"
                          label="Data da morte"
                          component={field => (
                            <FormDateInput
                              field={field}
                              dataCy={dataCy(field.name, index)}
                              value={values[field.name]}
                              handleChange={handleChange}
                              isInvalid={isInvalid}
                              handleBlur={handleBlur}
                              disabled={
                                values['situation'].toLowerCase() !== 'dead'
                              }
                            />
                          )}
                        />
                      </Col>
                    </>
                  )}
                </Form>
              );
            }}
          />
        </div>
      ))}
      <Divider height={40} />
      <h2 style={{ fontSize: '11px' }}>
        * - Campo é obrigatório. Em caso de informação faltante, preencher com
        "Não identificado" ou "Não informado".
      </h2>
    </FormBody>
  );
};

export default CreateEditOcurrenceVictimsFormComponent;
