import React from 'react';

import { trashSvg } from '../../../../../../../assets';
import {
  Container,
  DeleteButton,
  DeleteButtonContainer,
  Title
} from './index.styled';

const FormItemHeader: React.FC<{
  title: string;
  index: number;
  children?: React.ReactNode;
  onDelete: () => void;
}> = ({ title, index, children, onDelete }) => {
  return (
    <Container>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '90%'
        }}
      >
        <Title>
          {title} {index + 1}#
        </Title>

        <div style={{ flex: 1 }}>{children}</div>
      </div>

      <DeleteButtonContainer>
        <DeleteButton onClick={onDelete}>
          Excluir Bloco
          <img src={trashSvg} style={{ marginLeft: '10px' }} />
        </DeleteButton>
      </DeleteButtonContainer>
    </Container>
  );
};

export default FormItemHeader;
