import styled from 'styled-components';
import { IBtn } from './index.types';

export const MenuHeader = styled.div``;
export const BtnMenu = styled.button<IBtn>`
  margin-right: 24px;
  border: none;
  background: transparent;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.4px;
  color: #6e6b7b;

  padding-bottom: 4px;

  &:hover {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.4px;
    color: #2c3941;

    border-bottom: 2px solid orange;
  }

  ${props => {
    if (props.status == true) {
      return `
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px; 
                letter-spacing: 0.4px;  
                color: #2C3941;
                
                border-bottom: 2px solid orange;
            `;
    } else {
      return `
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px; 
                letter-spacing: 0.4px; 
                color: #6E6B7B;

                padding-bottom: 4px;
            `;
    }
  }}
`;

export const Container = styled.div``;

export const BtnOrange = styled.button`
  padding: 12px;
  color: #fff;
  background: ${props => props.theme.colors.secondary};
  border-radius: 6px;
  text-align: center;
  border: none;
`;

export const MenuRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const TitleMenu = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2c3941;
`;
