/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import {
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalSubtitle,
  CustomFormSelect,
  ModalFooter,
  ModalButtonOutlined,
  ModalButton,
  CloseButtonModal,
  ModalContentSuccess
} from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getOccurrencesByParams } from '../../../../services';
import { ADD_OCCURRENCES, RootState } from '../../../../store';

import reproveIcon from '../../../../assets/reproveIcon.png';
import { check } from '../../../../assets/index';
import close from '../../../../assets/close.png';
import { Modal } from '@mui/material';

import { IOccurrence } from '../../../../shared/interface/occurrence';
import success from '../../../../assets/success.png';
import SuccessOccurrenceModal from '../../../../components/SuccessOccurrenceModal';
import Loading from '../../../../components/Loading';
import { editStatusOccurrenceById } from '../../../../services/functions/occurrences';
import { EOccurrenceStatus } from '../../../../shared/enums/ocurrenceStatus';
export interface IModalReprovarProps {
  onClose(): void;
  open: boolean;
  occurrence: IOccurrence;
  dataDt: typeDt;
  isAproovedModel: boolean;
}

export type typeDt = {
  typeOccurrences: any;
  initial_date: any;
  final_date: any;
  search: any;
  page: any;
  cidade: any;
  regiao: any;
  isRegisterModal: any;
  isReprovarOccorrencia: any;
  order: any;
  take: any;
  estado: any;
  ordenacao: any;
};

const ModalReprovar: React.FC<IModalReprovarProps> = ({
  open,
  onClose,
  occurrence,
  dataDt,
  isAproovedModel
}) => {
  const [isOptionSelected, setOptionSelected] = useState('');
  const [isOpenSuccess, setOpenSuccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { token } = useSelector((state: RootState) => state.clickState);

  const handleChange = (event: any) => {
    setOptionSelected(event.target.value);
  };

  const handleCloseAll = () => {
    setOpenSuccess(false);
    requestAnimationFrame(onClose);
  };

  const dispatch = useDispatch();

  function isNumeric(val: any) {
    return /^-?\d+$/.test(val);
  }

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await editStatusOccurrenceById({
        token: token,
        id: occurrence['id'] || '',
        status: isAproovedModel
          ? EOccurrenceStatus.Approved
          : EOccurrenceStatus.Disapproved
      });

      if (response.status === 200) {
        setOpenSuccess(true);

        let dt1 = undefined;
        let dt2 = undefined;

        if (dataDt.initial_date !== '') {
          dt1 = dataDt.initial_date + 'T00:01:00.000';
          dt2 = dataDt.final_date + 'T23:59:00.000';
        } else {
          dt1 = '2022-01-01T00:01:00.000';
          dt2 = '2022-12-31T23:59:00.000';
        }

        let dt1_dtoc = undefined;
        let dt2_dtoc = undefined;
        let dt1_c = undefined;
        let dt2_c = undefined;

        if (dataDt.ordenacao === 'dtoc') {
          dt1_dtoc = dt1;
          dt2_dtoc = dt2;
        } else if (dataDt.ordenacao == 'dtc') {
          dt1_c = dt1;
          dt2_c = dt2;
        }

        getOccurrencesByParams(
          token,
          dataDt.order,
          dataDt.page,
          dataDt.take,
          dataDt.typeOccurrences,
          dt1_dtoc,
          dt2_dtoc,
          dataDt.cidade?.lenght > 0 ? dataDt.cidade : undefined,
          undefined,
          isNumeric(dataDt.search) === false && dataDt.search?.length > 0
            ? dataDt.search
            : undefined,
          isNumeric(dataDt.search) === true ? dataDt.search : undefined,
          dataDt.estado,
          dt1_c,
          dt2_c
        ).then(r => {
          dispatch({ type: ADD_OCCURRENCES, occurrences: r.data });
        });
      }
    } catch (error) {
      console.warn('(Reprovar ocorrencia) Erro:', error);
    }

    setIsLoading(false);
  };

  return (
    <>
      {isAproovedModel ? (
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={open}
          onClose={onClose}
        >
          <ModalContent>
            <ModalIcon src={success} />
            <ModalTitle color={'green'}>Aprovar ocorrência</ModalTitle>
            <ModalSubtitle>
              Tem certeza que deseja aprovar a ocorrência?
            </ModalSubtitle>
            <ModalFooter>
              <ModalButtonOutlined onClick={onClose}>
                Cancelar
              </ModalButtonOutlined>
              <ModalButton onClick={onSubmit}>
                {isLoading ? (
                  <>
                    <Loading size="sm" />
                    Processando...
                  </>
                ) : (
                  'Confirmar'
                )}
              </ModalButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={open}
          onClose={onClose}
        >
          <ModalContent>
            <ModalIcon src={reproveIcon} />

            <ModalTitle>Reprovar ocorrência</ModalTitle>
            <ModalSubtitle>
              Qual o motivo para a reprovação dessa ocorrência?
            </ModalSubtitle>

            <CustomFormSelect aria-label="" onChange={handleChange}>
              <option value="Duplicata">Duplicata</option>
              <option value="Não confirmada">Não Confirmada</option>
              <option value="Fora da área de cobertura">
                Fora da área de cobertura
              </option>
              <option value="Outros">Outros</option>
            </CustomFormSelect>

            <ModalFooter>
              <ModalButtonOutlined onClick={onClose}>
                Cancelar
              </ModalButtonOutlined>
              <ModalButton onClick={onSubmit}>
                {isLoading ? (
                  <>
                    <Loading size="sm" />
                    Processando...
                  </>
                ) : (
                  'Finalizar'
                )}
              </ModalButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {isAproovedModel ? (
        <SuccessOccurrenceModal
          title={'Ocorrência aprovada'}
          subtitle={'A ocorrência foi aprovada com sucesso.'}
          open={isOpenSuccess}
          occurrenceId={occurrence.id}
          onClose={handleCloseAll}
        />
      ) : (
        <Modal
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={isOpenSuccess}
          onClose={handleCloseAll}
        >
          <ModalContentSuccess>
            <CloseButtonModal onClick={handleCloseAll}>
              <img src={close} />
            </CloseButtonModal>
            <ModalIcon src={check} />
            <ModalTitle color="green">Ocorrência reprovada</ModalTitle>
            <ModalSubtitle>
              A ocorrência foi reprovada com sucesso.
            </ModalSubtitle>
          </ModalContentSuccess>
        </Modal>
      )}
    </>
  );
};

export default ModalReprovar;
