import styled from 'styled-components';

export const FormContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  padding: 24px 20px;
  border-radius: 6px;
`;

export const ParagraphAndLabelStyles = `
   font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #6e6b7b;
`;

export const FormHeader = styled.div`
  p {
    ${ParagraphAndLabelStyles}
  }
`;
