import styled from 'styled-components';

export const Container = styled.div`
  > header {
    width: 100%;
    padding: 16px 32px;
    background-color: ${props => props.theme.colors.grayLight};

    > h3 {
      color: ${props => props.theme.colors.black};
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    button {
      padding: 10px 22px;
      border-radius: 6px;
    }

    > button:nth-child(1) {
      background: none;
      border: 1px solid ${props => props.theme.colors.red};
      color: ${props => props.theme.colors.red};
    }

    > button:nth-child(2) {
      background: ${props => props.theme.colors.secondary};
      border: none;
      color: ${props => props.theme.colors.white};
    }
  }
`;

export const Form = styled.form`
  padding: 16px 32px;
`;

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > label {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #6e6b7b;
    margin-bottom: 6px;
  }

  > input[type='text'],
  input[type='email'],
  input[type='password'],
  select {
    background: ${props => props.theme.colors.white};
    border-radius: 5px;
    padding: 8px 15px;
    border: 1px solid ${props => props.theme.colors.gray};
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 180px;
  }
`;
