import styled from 'styled-components';
import { IProps } from './index.types';

export const RowStyle = styled.div<IProps>`
  ${props => {
    if (props.spaceBetween === true) {
      return `
                justify-content: space-between;
            `;
    } else {
      return `
                justify-content: center;
            `;
    }
  }}

  display: flex;
  flex-direction: row;
`;
