import {
  AnnualStatisticsModel,
  MonthStatisticsModel,
  OccurrencePercentStatisticsModel,
  OccurrenceStatisticsModel,
  StatisticsModel,
  VictimStatisticsModel
} from '../../domain/models/DashboardModel';

export class StatisticsEntity {
  occurence: OccurrenceStatisticsEntity = new OccurrenceStatisticsEntity();
  occurrence_percent: OccurrencePercentStatisticsEntity =
    new OccurrencePercentStatisticsEntity();
  victim: VictimStatisticsEntity = new VictimStatisticsEntity();
  victim_percent: VictimStatisticsEntity = new VictimStatisticsEntity();

  constructor(data?: Partial<StatisticsEntity>) {
    Object.assign(this, {
      occurence: new OccurrenceStatisticsEntity(data?.occurence),
      occurrence_percent: new OccurrencePercentStatisticsEntity(
        data?.occurrence_percent
      ),
      victim: new VictimStatisticsEntity(data?.victim),
      victim_percent: new VictimStatisticsEntity(data?.victim_percent)
    });
  }

  static fromJson(jsonData: any): StatisticsEntity {
    return Object.assign(new StatisticsEntity(), {
      occurence: new OccurrenceStatisticsEntity(jsonData.occurence),
      occurrence_percent: new OccurrencePercentStatisticsEntity(
        jsonData.occurrence_percent
      ),
      victim: new VictimStatisticsEntity(jsonData.victim),
      victim_percent: new VictimStatisticsEntity(jsonData.victim_percent)
    });
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  toDomain(): StatisticsModel {
    return new StatisticsModel({
      occurence: this.occurence.toDomain(),
      occurrence_percent: this.occurrence_percent.toDomain(),
      victim: this.victim.toDomain(),
      victim_percent: this.victim_percent.toDomain()
    });
  }
}

export class OccurrenceStatisticsEntity {
  total: number = 0;
  with_dead: number = 0;
  with_wounded: number = 0;
  no_wounded: number = 0;
  police_presence: number = 0;
  with_police_action: number = 0;
  without_shot: number = 0;
  with_shot: number = 0;

  constructor(data?: Partial<OccurrenceStatisticsEntity>) {
    Object.assign(this, {
      ...data
    });
  }

  toDomain(): OccurrenceStatisticsModel {
    return new OccurrenceStatisticsModel({
      total: this.total,
      with_dead: this.with_dead,
      with_wounded: this.with_wounded,
      no_wounded: this.no_wounded,
      police_presence: this.police_presence,
      with_police_action: this.with_police_action,
      without_shot: this.without_shot,
      with_shot: this.with_shot
    });
  }
}

export class OccurrencePercentStatisticsEntity {
  total: number = 0;
  with_dead: number = 0;
  with_wounded: number = 0;
  no_wounded: number = 0;
  police_presence: number = 0;

  constructor(data?: Partial<OccurrencePercentStatisticsEntity>) {
    Object.assign(this, {
      ...data
    });
  }

  toDomain(): OccurrencePercentStatisticsModel {
    return new OccurrencePercentStatisticsModel({
      total: this.total,
      with_dead: this.with_dead,
      with_wounded: this.with_wounded,
      no_wounded: this.no_wounded,
      police_presence: this.police_presence
    });
  }
}

export class VictimStatisticsEntity {
  total_dead: number = 0;
  total_wounded: number = 0;
  dead_agents: number = 0;
  dead_civilians: number = 0;
  wounded_agents: number = 0;
  wounded_civilians: number = 0;

  constructor(data?: Partial<VictimStatisticsEntity>) {
    Object.assign(this, {
      ...data
    });
  }

  toDomain(): VictimStatisticsModel {
    return new VictimStatisticsModel({
      total_dead: this.total_dead,
      total_wounded: this.total_wounded,
      dead_agents: this.dead_agents,
      dead_civilians: this.dead_civilians,
      wounded_agents: this.wounded_agents,
      wounded_civilians: this.wounded_civilians
    });
  }
}

export class AnnualStatisticsEntity {
  total_occurrences: number = 0;
  total_dead: number = 0;
  total_wounded: number = 0;
  months: Array<MonthStatisticsEntity> = [new MonthStatisticsEntity()];

  constructor(data?: Partial<AnnualStatisticsEntity>) {
    Object.assign(this, {
      ...data,
      months: data?.months?.map(
        (month: any) => new MonthStatisticsEntity(month)
      )
    });
  }

  static fromJson(jsonData: any): AnnualStatisticsEntity {
    return Object.assign(new AnnualStatisticsEntity(), {
      ...jsonData,
      months: jsonData.months.map(
        (month: any) => new MonthStatisticsEntity(month)
      )
    });
  }

  toJson() {
    return JSON.parse(JSON.stringify(this));
  }

  toDomain(): AnnualStatisticsModel {
    return new AnnualStatisticsModel({
      total_occurrences: this.total_occurrences,
      total_dead: this.total_dead,
      total_wounded: this.total_wounded,
      months: this.months.map(month => month.toDomain())
    });
  }
}

export class MonthStatisticsEntity {
  month: string = '';
  total: number = 0;
  dead_agents: number = 0;
  dead_civilians: number = 0;
  wounded_agents: number = 0;
  wounded_civilians: number = 0;

  constructor(data?: Partial<MonthStatisticsEntity>) {
    Object.assign(this, {
      ...data
    });
  }

  toDomain(): MonthStatisticsModel {
    return new MonthStatisticsModel({
      month: this.month,
      total: this.total,
      dead_agents: this.dead_agents,
      dead_civilians: this.dead_civilians,
      wounded_agents: this.wounded_agents,
      wounded_civilians: this.wounded_civilians
    });
  }
}
