import { api } from '../index';

export async function getTransparencies(token: string) {
  try {
    const resposta = await api.get('/transparencies', {
      headers: { Authorization: `Bearer ${token}` }
    });
    return resposta.data;
  } catch (erro) {
    console.warn('(Transparencias) erro:', erro);
    return null;
  }
}

export async function editTransparencies(
  token: string,
  id: string,
  dados: any
) {
  try {
    const resposta = await api.put(`/transparencies/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return resposta.data;
  } catch (erro) {
    console.warn('(Transparencias) erro:', erro);
    return null;
  }
}
