import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const CardStyled = styled.div`
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 32px;
  position: relative;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  width: 100%;
`;

export const CardStyledOne = styled.div`
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  position: relative;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 0 70px 32px 38px !important;

  width: 98%;
`;

export const LineDashed = styled.hr`
  border-top: 1px dashed #2c3941;
`;

export const ButtonDashed = styled.button`
  background-color: #f8f8f8;
  height: 92px;
  border: 1px dashed black;
  border-radius: 5px;
  margin: 0 70px 70px 38px !important;
  width: 98%;
`;

export const ButtonClear = styled.button`
  border: none;
  background-color: transparent;
  color: #ea5455;

  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ButtonUp = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
`;

export const ButtonDown = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
`;

export const ContainerButtons = styled.div`
  margin-right: 8px;
  max-width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerTB = styled.div`
  margin-top: 20px;
`;

export const DivEditor = styled.div`
  border: 1px solid #ccc;
`;

export const Container = styled.div`
  background: ${props => props.theme.colors.grayLight};
  width: 100% !important;

  > form {
    > div {
      padding: 32px 70px 32px 38px;
    }
  }
`;

export const Header = styled.header`
  width: 100%;
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  > h3 {
    color: ${props => props.theme.colors.white};
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
  }
`;

export const Footer = styled.footer`
  background: ${props => props.theme.colors.white};
  position: sticky;
  bottom: 0;
  left: 0;

  padding: 20px 80px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    padding: 15px 69px;
    border-radius: 6px;
    border: none;
  }

  > button:nth-child(1) {
    background: ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.white};
  }

  > button:nth-child(2) {
    background: none;
    border: 1px solid ${props => props.theme.colors.red};
    color: ${props => props.theme.colors.red};
  }

  > button:nth-child(3) {
    background: none;
    border: 1px solid ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondary};
  }

  > button:nth-child(4) {
    background: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.white};
  }

  > fieldset {
    > label {
      margin-right: 10px;
      font-size: 16px;
    }

    > input {
      width: 18px;
      height: 18px;
    }
  }
`;
