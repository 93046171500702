import React, { useEffect, useState } from 'react';
import { Container, Header, Body } from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import { getOccurrences, getRegions } from '../../services';
import { ADD_OCCURRENCES, ADD_REGIONS, RootState } from '../../store';
import ModalAlterar from './Modals/Alterar';

import arow from '../../assets/arow.png';
import action from '../../assets/action.png';
import close from '../../assets/close.png';
import trash from '../../assets/trash.png';
import editIcon from '../../assets/editIcon.png';
import { SwitchButton } from '../../components';

const Regions: React.FC = ({ children }) => {
  const { token, regions, user } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();
  const [checkedAlterar, setCheckedAlterar] = useState(false);
  const [idAlterar, setIdAlterar] = useState('');
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
    getOccurrences(token).then(resp =>
      dispatch({ type: ADD_OCCURRENCES, occurrences: resp.data })
    );
  }, []);

  return (
    <>
      <Container>
        <h3>Configurar regiões</h3>
        <Header>
          <tr>
            <th>REGIÃO</th>
            <th>ESTADO</th>
            <th>HABILITADO</th>
          </tr>
        </Header>
        <Body>
          {regions.map((chave, valor) => {
            return (
              <tr>
                <td>{chave.region}</td>
                <td>{chave.state}</td>
                <td>
                  <SwitchButton
                    checked={chave.enabled}
                    onClick={() => {
                      setCheckedAlterar(chave.enabled);
                      setIdAlterar(chave.id);
                      setModal(true);
                    }}
                  />
                </td>
                <td></td>
              </tr>
            );
          })}
        </Body>
      </Container>
      <ModalAlterar
        isModal={modal}
        onHide={() => setModal(false)}
        checked={checkedAlterar}
        id={idAlterar}
      />
    </>
  );
};

export default Regions;
