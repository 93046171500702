import React, { useState } from 'react';
import './index.style.css';

interface IProps {
  onClick: (e: any) => void;
  checked: boolean;
}

const SwitchButtonSecondary: React.FC<IProps> = ({ onClick, checked }) => {
  const [valueSwitch, setValueSwitch] = useState(checked);

  console.error(valueSwitch);
  return (
    <label className="switch">
      <input
        type="checkbox"
        id="togBtn"
        value="true"
        checked={valueSwitch}
        onClick={() => {
          setValueSwitch(!valueSwitch);
          onClick(valueSwitch);
        }}
      />
      <div className="slider round"></div>
    </label>
  );
};

export default SwitchButtonSecondary;
