import styled from 'styled-components';
import { check } from '../../assets';

export const ModalContent = styled.div`
  width: 586px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 60px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.white};
`;

export const ModalCheckIcon = styled.img.attrs({
  src: check
})`
  height: 60px;
  width: 60px;
  margin-bottom: 24px;
`;

export const ModalTitle = styled.h3`
  color: ${props => props.theme.colors.success};
  font-weight: 500;
  font-size: 28px;

  margin-bottom: 12px;
`;

export const ModalSubtitle = styled.p`
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 18px;
  text-align: center;
`;

export const ModalDescription = styled.strong`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 32px;
  padding: 0 32px;
`;

export const ModalButtonOutlined = styled.button`
  height: 42px;
  width: 48%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.black};
  background: transparent;
  border: 1px solid #2c3941;

  &:hover {
    background-color: #f2f2f2;
    transition: background-color 200ms linear;
  }
`;

export const ModalButton = styled.button`
  height: 42px;
  width: 48%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 38px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background: linear-gradient(90deg, #f83600, #fe8c00);
  border: none;

  &:hover {
    background: linear-gradient(90deg, #e53c0d, #e53c0d);
    transition: background-color 200ms linear;
  }
  & > div {
    color: ${props => props.theme.colors.white};
    margin-right: 8px;
  }

  & > img {
    height: 20px;
    width: 20px;
    margin-right: 8px;
  }
`;
