import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 6px;
  margin: 60px 40px;
`;

export const TitleVariable = styled.h4`
  background-color: ${props => props.theme.colors.secondary};
  text-align: center;
  color: white;
  padding: 10px;
`;

export const ContainerOptions = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const InputSelect = styled.select`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  width: 200px;
  height: 38px;
  margin-left: 10px;
`;

export const Header = styled.thead`
  width: 100%;
  > tr {
    padding: 0 !important;
    margin: 0 !important;
    > td {
      width: 100%;
      padding: 24px 13px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 15px;
      letter-spacing: 1px;
      text-align: center;
      color: black;
    }
  }
`;

export const Body = styled.tbody`
  > tr {
    padding: 0 !important;
    margin: 0 !important;

    > td {
      padding: 9px 13px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
    }
  }
`;

export const ContainerActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: ${props => props.theme.colors.black};
  background: transparent;
  border: none;

  &:hover {
    background: ${props => props.theme.colors.grayLight};
    transition: background-color 200ms linear;
  }
`;

export const AddButton = styled.button`
  background: ${props => props.theme.colors.success};
  border: none;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  padding: 0px 26px;
  margin-left: 20px;
  font-size: 16px;
  height: 38px;
`;
