import moment from 'moment';
import { api } from '../index';

export async function getPosts(
  token: any,
  order?: string,
  page?: string,
  take?: string,
  initial_date?: string,
  final_date?: string,
  title?: string,
  region?: string,
  authors?: string
) {
  try {
    let params = new URLSearchParams();

    if (order != undefined && order != '') {
      params.append('oder', order);
    }
    if (page != undefined && page != '') {
      params.append('page', page);
    }
    if (take != undefined && take != '') {
      params.append('take', take);
    }
    if (initial_date != undefined && initial_date != '') {
      params.append('initialdate', initial_date);
    }
    if (final_date != undefined && final_date != '') {
      params.append('finaldate', final_date);
    }
    if (title != undefined && title != '') {
      params.append('title', title);
    }
    if (region != undefined && region != '') {
      params.append('region', region);
    }
    if (authors != undefined && authors != '') {
      params.append('authors', authors);
    }

    const resposta = await api.get('/posts', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}

export async function getPostsById(token: string, id?: string) {
  try {
    const resposta = await api.get(`/posts/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}

export async function getPostsBySlug(token: string, slug?: string) {
  try {
    const resposta = await api.get(`/posts/slug/${slug}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return resposta.data;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}

export async function createPost(token: string, dados: any) {
  try {
    const resposta = await api.post(`/posts`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}

export function convertData(data: string) {
  let date = moment(data).format('DD/MM/YYYY');
  return date;
}

export async function deletePost(token: string, id: string) {
  try {
    const resposta = await api.delete(`/posts/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}

export async function editPost(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/posts/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posts) Erro: ', erro);
    return null;
  }
}
