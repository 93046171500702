import React, { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { IProps } from './index.types';
import { ADD_REGIONS, ADD_REPORTS, RootState } from '../../../../store/index';
import { useSelector, useDispatch } from 'react-redux';

import { getRegions, createReports, getReports } from '../../../../services';
import {
  ButtonClear,
  ButtonDashed,
  ButtonDown,
  ButtonUp,
  CardStyled,
  CardStyledOne,
  Container,
  ContainerButtons,
  ContainerCard,
  ContainerTB,
  Footer,
  Header
} from './index.styled';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { arrowDown, arrowUp, trashRed } from '../../../../assets/index';
import { FileUploader, ImgUp, ModalSucesso } from '../../../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ModalPreview from './View/index';
import Editor from '@monaco-editor/react';
import * as urlSlug from 'url-slug';

const ModalCriar: React.FC<IProps> = ({ isModal, onHide, setReps }) => {
  const [isVisible, setVisible] = useState(false);

  const dispatch = useDispatch();
  const { token, regions, user } = useSelector(
    (state: RootState) => state.clickState
  );

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  const [preview, setPreview] = useState<any>({
    title: '',
    author: '',
    region: '',
    file: {
      id: '',
      file: ''
    },
    slug: '',
    cover: {
      id: '',
      file: ''
    },
    file_width: '',
    file_name: '',
    thumbnail: {
      id: '',
      file: ''
    },
    date_post: '',
    authors: '',
    description: '',
    type: '',
    emphasis: true,
    items: [
      {
        group: 'bloco 1',
        group_items: [
          {
            image: {
              id: '',
              file: ''
            },
            description: '',
            content: ''
          }
        ],
        typeBlock: 'text',
        quantBlock: '1'
      }
    ]
  });

  const [showPreview, setShowPreview] = useState(false);

  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    control,
    reset,
    watch,
    formState: { errors },
    setError
  } = useForm({
    defaultValues: {
      title: '',
      author: '',
      region: '',
      file: {
        id: '',
        file: ''
      },
      slug: '',
      cover: {
        id: '',
        file: ''
      },
      file_width: '',
      file_name: '',
      thumbnail: {
        id: '',
        file: ''
      },
      date_post: '',
      authors: '',
      description: '',
      type: '',
      emphasis: true,
      items: [
        {
          group: 'bloco 1',
          group_items: [
            {
              image: {
                id: '',
                file: ''
              },
              description: '',
              content: ''
            }
          ],
          typeBlock: 'text',
          quantBlock: '1'
        }
      ]
    }
  });

  const { append, remove, move, fields } = useFieldArray({
    control,
    name: 'items'
  });

  function onSubmit(dados: any) {
    let aux = {
      ...dados,
      author: user?.id,
      date_post: new Date()
    };

    if (aux.file_name == '' && aux.file_width == '') {
      delete aux.file;
      delete aux.file_name;
      delete aux.file_width;
    } else if (aux.file == '') {
      delete aux.file;
      delete aux.file_name;
      delete aux.file_width;
    }

    createReports(token, aux).then(resp => {
      getReports(token).then(resp => {
        dispatch({ type: ADD_REPORTS, reports: resp.data });
        setReps(resp.data);
        onHide();
        setVisible(true);
      });
    });
  }

  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
      >
        <Container>
          <Header>
            <h3>Novo relatório</h3>
          </Header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <h3 style={{ marginLeft: '38px' }}>Especificações</h3>

              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Link do Relatório</Form.Label>
                      <FileUploader
                        getValues={getValues}
                        setValue={setValue}
                        index={`file`}
                        index1={`file_width`}
                        index2={`file_name`}
                        imgWidth={270}
                        imgHeight={86}
                        width={461}
                        height={35}
                        containerWidth={461}
                        containerHeight={35}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Região</Form.Label>
                      <Form.Select {...register('region', { required: true })}>
                        <option>Selecione</option>
                        {regions?.map(chave => {
                          if (chave.enabled == true) {
                            return (
                              <option value={chave.id}>{chave.state}</option>
                            );
                          }
                        })}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Escrito por</Form.Label>
                      <Form.Control
                        {...register('authors', { required: true })}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Capa</Form.Label>
                      <ImgUp
                        getValues={getValues}
                        setValue={setValue}
                        index={`cover`}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              <h3 style={{ marginLeft: '38px' }}>Descrição do post</h3>
              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        maxLength={151}
                        {...register('description', {
                          required: true,
                          maxLength: 151,
                          onChange: e => {
                            if (e.target.value.length < 150) {
                              setError('description', {
                                type: 'value',
                                message: `Quantidade de caracteres: ${e.target.value.length}`
                              });
                            } else if (e.target.value.length == 151) {
                              setError('description', {
                                type: 'value',
                                message: `Quantidade de máxima de caracteres alcançada: ${e.target.value.length}`
                              });
                            }
                          }
                        })}
                      />
                      {errors.description && (
                        <p> {errors.description.message}</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              <h3 style={{ marginLeft: '38px' }}>Conteúdo</h3>

              <CardStyledOne>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Titulo</Form.Label>
                      <Form.Control
                        type="text"
                        maxLength={61}
                        {...register('title', {
                          required: true,
                          maxLength: 61,
                          onChange: e => {
                            if (e.target.value.length < 60) {
                              setError('title', {
                                type: 'value',
                                message: `Quantidade de caracteres: ${e.target.value.length}`
                              });
                            } else if (e.target.value.length == 61) {
                              setError('title', {
                                type: 'value',
                                message: `Quantidade de máxima de caracteres alcançada: ${e.target.value.length}`
                              });
                            }
                          }
                        })}
                      />
                      {errors.title && <p> {errors.title.message}</p>}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Slug</Form.Label>
                      <Controller
                        control={control}
                        name="slug"
                        render={({
                          field: { onChange, onBlur, value, name, ref },
                          fieldState: { invalid, isTouched, isDirty, error },
                          formState
                        }) => (
                          <Form.Control
                            type="text"
                            maxLength={61}
                            onChange={(e: any) => {
                              let valor = urlSlug.convert(e.target.value, {
                                separator: '-',
                                transformer: urlSlug.LOWERCASE_TRANSFORMER
                              });
                              setValue('slug', valor);
                            }} // send value to hook form
                            value={value}
                          />
                        )}
                      />
                      <p>São permitidos apenas letras, números e hífen.</p>
                    </Form.Group>
                  </Col>
                </Row>
              </CardStyledOne>

              {fields.map((item, index) => {
                let tb = watch(`items.${index}.typeBlock`);
                let quant = watch(`items.${index}.quantBlock`);

                return (
                  <ContainerCard>
                    <ContainerButtons>
                      <ButtonUp
                        type="button"
                        onClick={() => {
                          let tmp = index - 1;
                          move(index, tmp);
                        }}
                      >
                        <img src={arrowUp} />
                      </ButtonUp>
                      <ButtonDown
                        type="button"
                        onClick={() => {
                          let tmp = index + 1;
                          move(index, tmp);
                        }}
                      >
                        <img src={arrowDown} />
                      </ButtonDown>
                    </ContainerButtons>

                    <CardStyled>
                      <ButtonClear
                        type="button"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        Excluir bloco
                        <img src={trashRed} />
                      </ButtonClear>

                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Tipo de conteúdo do blog</Form.Label>
                            <Form.Select
                              {...register(`items.${index}.typeBlock`, {
                                required: true
                              })}
                            >
                              {[
                                { value: 'text', label: 'Texto' },
                                { value: 'image', label: 'Imagem' },
                                { value: 'code', label: 'Código' }
                              ].map(item => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Quantidade de colunas</Form.Label>
                            <Form.Select
                              {...register(`items.${index}.quantBlock`, {
                                onChange: (e: any) => {
                                  setValue(
                                    `items.${index}.group`,
                                    `bloco ${e.target.value}`
                                  );
                                },
                                required: true
                              })}
                            >
                              {[
                                { value: '1', label: '1' },
                                { value: '2', label: '2' }
                              ].map(item => {
                                return (
                                  <option value={item.value}>
                                    {item.label}
                                  </option>
                                );
                              })}
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>

                      {tb == 'code' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map(chave => {
                                  return (
                                    <Col>
                                      <Editor
                                        height="145px"
                                        defaultLanguage="html"
                                        defaultValue=""
                                        theme="vs-dark"
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                        options={{
                                          wordWrap: 'on'
                                        }}
                                      />
                                    </Col>
                                  );
                                })
                              : [0, 1].map(chave => {
                                  return (
                                    <Col xs={6}>
                                      <Editor
                                        height="145px"
                                        defaultLanguage="html"
                                        defaultValue=""
                                        theme="vs-dark"
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                        options={{
                                          wordWrap: 'on'
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                      {tb == 'text' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map(chave => {
                                  return (
                                    <Col>
                                      <ReactQuill
                                        theme="snow"
                                        formats={[
                                          'header',
                                          'bold',
                                          'italic',
                                          'underline',
                                          'strike',
                                          'blockquote',
                                          'list',
                                          'bullet',
                                          'indent',
                                          'code-block',
                                          'link'
                                        ]}
                                        modules={{
                                          toolbar: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false
                                                ]
                                              }
                                            ],
                                            [
                                              'bold',
                                              'italic',
                                              'underline',
                                              'strike',
                                              'blockquote'
                                            ],
                                            [
                                              { list: 'ordered' },
                                              { list: 'bullet' },
                                              { indent: '-1' },
                                              { indent: '+1' }
                                            ],
                                            ['code-block', 'link'],
                                            ['clean']
                                          ]
                                        }}
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                      />
                                    </Col>
                                  );
                                })
                              : [0, 1].map(chave => {
                                  return (
                                    <Col xs={6}>
                                      <ReactQuill
                                        theme="snow"
                                        formats={[
                                          'header',
                                          'bold',
                                          'italic',
                                          'underline',
                                          'strike',
                                          'blockquote',
                                          'list',
                                          'bullet',
                                          'indent',
                                          'code-block',
                                          'link'
                                        ]}
                                        modules={{
                                          toolbar: [
                                            [
                                              {
                                                header: [
                                                  1,
                                                  2,
                                                  3,
                                                  4,
                                                  5,
                                                  6,
                                                  false
                                                ]
                                              }
                                            ],
                                            [
                                              'bold',
                                              'italic',
                                              'underline',
                                              'strike',
                                              'blockquote'
                                            ],
                                            [
                                              { list: 'ordered' },
                                              { list: 'bullet' },
                                              { indent: '-1' },
                                              { indent: '+1' }
                                            ],
                                            ['code-block', 'link'],
                                            ['clean']
                                          ]
                                        }}
                                        onChange={(e: any) => {
                                          setValue(
                                            `items.${index}.group_items.${chave}.content`,
                                            e
                                          );
                                        }}
                                      />
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                      {tb == 'image' && (
                        <ContainerTB>
                          <Row>
                            {quant == '1'
                              ? [0].map(chave => {
                                  return (
                                    <Col>
                                      <Form.Group>
                                        <ImgUp
                                          getValues={getValues}
                                          setValue={setValue}
                                          index={`items.${index}.group_items.${chave}.image`}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>
                                          Descrição da foto
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          {...register(
                                            `items.${index}.group_items.${chave}.description`
                                          )}
                                        />
                                      </Form.Group>
                                    </Col>
                                  );
                                })
                              : [0, 1].map(chave => {
                                  return (
                                    <Col xs={6}>
                                      <Form.Group>
                                        <ImgUp
                                          getValues={getValues}
                                          setValue={setValue}
                                          index={`items.${index}.group_items.${chave}.image`}
                                        />
                                      </Form.Group>
                                      <Form.Group>
                                        <Form.Label>
                                          Descrição da foto
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          {...register(
                                            `items.${index}.group_items.${chave}.description`
                                          )}
                                        />
                                      </Form.Group>
                                    </Col>
                                  );
                                })}
                          </Row>
                        </ContainerTB>
                      )}
                    </CardStyled>
                  </ContainerCard>
                );
              })}

              <ButtonDashed
                type="button"
                onClick={() => {
                  append({
                    group: 'bloco 1',
                    group_items: [
                      {
                        image: {
                          id: '',
                          file: ''
                        },
                        description: '',
                        content: ''
                      }
                    ],
                    typeBlock: 'text',
                    quantBlock: '1'
                  });
                }}
              >
                Adicionar novo bloco de conteúdo
              </ButtonDashed>
            </div>
            <Footer>
              <button type="button" onClick={() => onHide()}>
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => {
                  setPreview(getValues());
                  setShowPreview(true);
                }}
              >
                Preview
              </button>
              <button type="submit">Finalizar</button>
            </Footer>
          </Form>
        </Container>
      </Modal>

      <ModalPreview
        content={preview}
        isModal={showPreview}
        onHide={() => setShowPreview(false)}
      />

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Relatório criado"
        description="O relatório criado com sucesso."
      />
    </>
  );
};

export default ModalCriar;
