import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  contentIcon,
  fileBlack,
  fileRed,
  imgIcon,
  titleIcon
} from '../../assets';
import { BlueComponent } from '../../components';
import { getTransparencies } from '../../services';
import { ADD_TRANSPARENCIES, RootState } from '../../store';
import {
  CardFile,
  Col1,
  Col2,
  Col3,
  Container,
  DivCardFile,
  IconeFile,
  ImageB3,
  ImageFinance,
  Partners,
  ImageIcon,
  ImageOpen,
  ImageT1,
  Line1,
  NoStyle,
  Content,
  NoStyleTwo,
  ContainerCard,
  Card,
  Data
} from './index.style';
import renderHTML from 'react-render-html';
import moment from 'moment';
import { ModalEditar } from './Modals';
import { TitleTab } from '../Sobre/index.styled';

const Transparencia: React.FC = () => {
  const { token, transparencies } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getTransparencies(token).then(resp => {
      dispatch({ type: ADD_TRANSPARENCIES, transparencies: resp });
    });
  }, []);

  useEffect(() => {
    console.log(transparencies, 'transparencias');
  }, [transparencies]);

  function convertData(data: string) {
    let dt = moment(data).format('DD/MM/YYYY - HH:mm');
    return `${dt} `;
  }

  const [isModalEditar, setIsModalEditar] = useState(false);
  const [contentEditar, setContentEditar] = useState({
    id: '',
    name: '',
    title: '',
    description: '',
    image: {
      id: '',
      file: ''
    },
    items: [
      {
        title: '',
        reports: [
          {
            file: {
              id: '',
              file: ''
            },
            image: {
              id: '',
              file: ''
            },
            title: '',
            createAt: '',
            updateAt: '',
            file_width: ''
          }
        ],
        description: '',
        image: {
          id: '',
          file: ' '
        }
      }
    ]
  });

  const [id, setId] = useState();

  useEffect(() => {
    console.log(transparencies, 'transparencias');
  }, [transparencies]);

  return (
    <Container>
      <Tabs defaultActiveKey={'Abertura'} className="mb-3">
        {transparencies?.map((chave: any, index: any) => {
          return (
            <Tab
              eventKey={chave.name}
              title={<TitleTab>{chave.name}</TitleTab>}
            >
              <BlueComponent
                btnEdit={true}
                isModal={false}
                isShow={false}
                onHide={() => {}}
                onClickCancel={() => {}}
                onClickSave={() => {}}
                onClickEdit={() => {
                  setIsModalEditar(true);
                  setContentEditar(chave);
                  setId(index);
                }}
                titleComponent={<div>{chave.name}</div>}
              >
                {chave.name == 'Abertura' && (
                  <>
                    <Row>
                      <Content>
                        <div>
                          <ImageIcon src={titleIcon} />
                          <div>
                            <p>Títutlo</p>
                            <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                          </div>
                        </div>
                        <div>
                          <ImageIcon src={contentIcon} />
                          <div>
                            <p>Texto</p>
                            <NoStyle>{renderHTML(chave.description)}</NoStyle>
                          </div>
                        </div>
                      </Content>
                    </Row>
                  </>
                )}
                {chave.name == 'Bloco 1' && (
                  <>
                    <Row>
                      <Content>
                        <div>
                          <ImageIcon src={titleIcon} />
                          <div>
                            <p>Títutlo</p>
                            <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                          </div>
                        </div>
                        <div>
                          <ImageIcon src={contentIcon} />
                          <div>
                            <p>Texto</p>
                            <NoStyle>{renderHTML(chave.description)}</NoStyle>
                          </div>
                        </div>
                      </Content>
                    </Row>
                  </>
                )}
                {chave.name == 'Bloco 2' && (
                  <Row>
                    {chave.items.map((item: any) => {
                      return (
                        <>
                          <Row>
                            <Content>
                              {item.title != undefined && (
                                <div>
                                  <ImageIcon src={titleIcon} />
                                  <div>
                                    <p>Títutlo</p>
                                    <NoStyleTwo>
                                      {renderHTML(item?.title)}
                                    </NoStyleTwo>
                                  </div>
                                </div>
                              )}
                              {item.description != undefined && (
                                <div>
                                  <ImageIcon src={contentIcon} />
                                  <div>
                                    <p>Texto</p>
                                    <NoStyle>
                                      {renderHTML(item?.description)}
                                    </NoStyle>
                                  </div>
                                </div>
                              )}
                              {item.image != undefined && (
                                <div>
                                  <ImageIcon src={imgIcon} />
                                  <Col>
                                    <ImageB3 src={item?.image.file} />
                                  </Col>
                                </div>
                              )}
                            </Content>
                          </Row>
                        </>
                      );
                    })}
                  </Row>
                )}
                {chave.name == 'Bloco 3' && (
                  <>
                    <Row>
                      <Content>
                        <div>
                          <ImageIcon src={titleIcon} />
                          <div>
                            <p>Títutlo</p>
                            <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                          </div>
                        </div>
                        <div>
                          <ImageIcon src={contentIcon} />
                          <div>
                            <p>Texto</p>
                            <NoStyle>{renderHTML(chave.description)}</NoStyle>
                          </div>
                        </div>
                      </Content>
                    </Row>
                  </>
                )}
                {chave.name == 'Bloco 4' && (
                  <>
                    <Row>
                      <Content>
                        <div>
                          <ImageIcon src={titleIcon} />
                          <div>
                            <p>Títutlo</p>
                            <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                          </div>
                        </div>
                        <div>
                          <ImageIcon src={contentIcon} />
                          <div>
                            <p>Texto</p>
                            <NoStyle>{renderHTML(chave.description)}</NoStyle>
                          </div>
                        </div>
                      </Content>
                    </Row>
                  </>
                )}
                {chave.name == 'Bloco 5' && (
                  <>
                    {chave.items.map((item: any) => {
                      return (
                        <>
                          <Row>
                            <Content>
                              <div>
                                <ImageIcon src={titleIcon} />
                                <div>
                                  <p>Títutlo</p>
                                  <NoStyleTwo>
                                    {renderHTML(item.title)}
                                  </NoStyleTwo>
                                </div>
                              </div>
                              <div>
                                <ImageIcon src={contentIcon} />
                                <div>
                                  <p>Texto</p>
                                  <NoStyle>
                                    {renderHTML(item.description)}
                                  </NoStyle>
                                </div>
                              </div>
                            </Content>
                          </Row>

                          <ContainerCard>
                            {item.reports.map((id: any) => {
                              console.log(id, 'ids');
                              return (
                                <Card>
                                  <ImageT1 src={id.image.file} />
                                  <h3>{id.title}</h3>
                                  <div>
                                    {id.file != '' ? (
                                      <IconeFile src={fileRed} />
                                    ) : (
                                      <IconeFile src={fileBlack} />
                                    )}
                                    <Data>
                                      {id.updateAt != '' ? (
                                        <p>{convertData(id.updateAt)}</p>
                                      ) : (
                                        <p>--/--/---- - --:--</p>
                                      )}
                                    </Data>
                                    <p>{id.file_width}mb</p>
                                  </div>
                                </Card>
                              );
                            })}
                          </ContainerCard>
                        </>
                      );
                    })}
                  </>
                )}
                {chave.name == 'Bloco 6' && (
                  <>
                    <Row>
                      <Content>
                        <div>
                          <ImageIcon src={titleIcon} />
                          <div>
                            <p>Títutlo</p>
                            <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                          </div>
                        </div>
                      </Content>
                    </Row>
                    <br />
                    <Partners>
                      {chave.items.map((item: any) => {
                        return (
                          <a href={item.description} target="_blank">
                            <img src={item.image.file} />
                          </a>
                        );
                      })}
                    </Partners>
                  </>
                )}
              </BlueComponent>
            </Tab>
          );
        })}
      </Tabs>

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setIsModalEditar(false)}
        content={contentEditar}
        id={id}
      />
    </Container>
  );
};

export default Transparencia;
