import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Content,
  Div,
  Icon,
  ImageContent,
  DivImages,
  ImagesContent,
  DivImage,
  LinkStyled,
  ImageHeader,
  DivNAv,
  ContainerCenter,
  ContentHTML,
  ButtonTop
} from './index.styled';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_REGIONS, RootState } from '../../../../../store';

import {
  paper,
  date,
  share,
  facebook,
  twitter,
  telegram,
  linkedin,
  downloadBlack
} from '../../../../../assets';

import { convertData, getRegions } from '../../../../../services';
import { Col, Modal, Row } from 'react-bootstrap';

import renderHTML from 'react-render-html';
import { IProps } from './index.types';

const ModalPreview: React.FC<IProps> = ({ onHide, isModal, content }) => {
  const dispatch = useDispatch();
  const { token, regions } = useSelector(
    (state: RootState) => state.clickState
  );
  const [post, setPost] = useState<any>({
    title: '',
    author: {
      id: '',
      name: '',
      nickname: null,
      email: ''
    },
    region: '',
    thumbnail: {
      id: '',
      file: ''
    },
    cover: {
      id: '',
      file: ''
    },
    emphasis: '',
    type: '',
    items: [
      {
        image: {
          id: '',
          file: ''
        },
        description: '',
        group: ''
      }
    ],
    createdAt: '',
    updatedAt: '',
    id: ''
  });

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  useEffect(() => {
    if (content != undefined) {
      regions?.forEach(id => {
        if (id.id == content.region) {
          let aux = {
            ...content,
            region: id.region
          };
          setPost(aux);
        }
      });
    }
  }, [content]);

  return (
    <Modal show={isModal} onHide={onHide} fullscreen>
      <Modal.Body>
        <Container>
          <>
            <DivNAv>
              <LinkStyled
                onClick={() => {
                  onHide();
                }}
              >
                Voltar
              </LinkStyled>
              <div></div>
            </DivNAv>

            <Header>
              <ImageHeader src={post.cover?.file} />

              <ButtonTop href={post.file?.file} target="_blank">
                <img src={downloadBlack} />
                Baixar Relatórios
              </ButtonTop>
            </Header>

            <Content>
              <p>{post.region?.state}</p>
              <h3>{post.title}</h3>
              <div>
                <Div>
                  <div>
                    <Icon src={paper} />
                    <p>{post.authors}</p>
                  </div>
                  <div>
                    <Icon src={date} />
                    <p>{convertData(post.createdAt)}</p>
                  </div>
                </Div>
                <div>
                  <a href="">
                    {' '}
                    <img src={share} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={facebook} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={twitter} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={telegram} alt="" />{' '}
                  </a>
                  <a href="">
                    {' '}
                    <img src={linkedin} alt="" />{' '}
                  </a>
                </div>
              </div>
            </Content>

            <ContainerCenter>
              <div>
                {post.items?.map((item: any) => {
                  if (item.group == 'bloco 1') {
                    return (
                      <Row>
                        {item.group_items?.map((chave: any) => {
                          return (
                            <Col xs={12}>
                              {chave.content != undefined &&
                                chave.content != '' && (
                                  <>
                                    <ContentHTML>
                                      {renderHTML(chave.content)}
                                    </ContentHTML>
                                  </>
                                )}
                              {chave.description != undefined &&
                                chave.description != '' && (
                                  <DivImage>
                                    <ImageContent src={chave.image.file} />
                                    <p>{chave.description}</p>
                                  </DivImage>
                                )}
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  } else {
                    return (
                      <Row>
                        {item.group_items?.map((chave: any) => {
                          return (
                            <Col xs={6}>
                              {chave.content != undefined &&
                                chave.content != '' && (
                                  <>
                                    <ContentHTML>
                                      {renderHTML(chave.content)}
                                    </ContentHTML>
                                  </>
                                )}
                              {chave.description != undefined &&
                                chave.description != '' && (
                                  <DivImages>
                                    <ImagesContent src={chave.image.file} />
                                    <p>{chave.description}</p>
                                  </DivImages>
                                )}
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  }
                })}
              </div>
            </ContainerCenter>
          </>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreview;
