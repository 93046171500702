/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { IProps, FormData } from './form-types';
import {
  Block,
  BlcokSB,
  InputArea,
  InputText,
  InputSelect,
  AddVtm,
  AddVtmContainer,
  InputTextLatLng,
  FormHeader
} from './styles';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import {
  RootState,
  ADD_OCCURRENCES,
  ADD_AGE_GROUP,
  ADD_ANIMALS,
  ADD_CIRCUMSTANCES,
  ADD_CITIES,
  ADD_CLIPPINGS,
  ADD_COORPORATIONS,
  ADD_GENRES,
  ADD_LOCALITIES,
  ADD_NEIGHBORHOODS,
  ADD_PARTIES,
  ADD_PLACES,
  ADD_POSITIONS,
  ADD_QUALIFICATIONS,
  ADD_REASONS,
  ADD_REGIONS,
  ADD_SOURCES,
  ADD_STATES,
  ADD_STATUS,
  ADD_SUBNEIGHBORHOODS,
  ADD_TRANSPORTS
} from '../../../../../../store';
import {
  createOccurrences,
  getOccurrencesByParams,
  createVictims,
  deleteVictims,
  editOccurrences,
  editVictims,
  getAgeGroup,
  getAnimals,
  getCircumstances,
  getCities,
  getClippings,
  getCoorporations,
  getGenres,
  getLocalities,
  getNeighborhoods,
  getParties,
  getPlaces,
  getPositions,
  getQualifications,
  getReasons,
  getRegions,
  getSourcers,
  getStates,
  getStatus,
  getSubNeighborhoods,
  getTransports
} from '../../../../../../services';
import VictimsForm from './Victims/index';
import AnimalsForm from './Animals/index';
import TransportsForm from './Transports';

import {
  Controller,
  useController,
  useFieldArray,
  UseFieldArrayReturn,
  useForm
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOccurrence } from '../../../../../../shared/interface/occurrence';
import { AxiosResponse } from 'axios';
import { schemaOccurrence } from './validation-schema';
import CustomControllerSelect from '../../../../../CustomControllerSelect';
import { IVictim } from '../../../../../../shared/interface/victim';
import { IVictimAnimal } from '../../../../../../shared/interface/victim_animal';
import SuccessOccurrenceModal from '../../../../../SuccessOccurrenceModal';
import OccurrenceCreationHeader from '../../../../../OccurrenceCreationHeader';
import OccurrenceEditHeader from '../../../../../OccurrenceEditHeader';
import ModalConfirm from '../../../../../ModalConfirm';
import MapSearch from '../../../../../MapSearch';
import InputSearchMap from '../../../../../InputSearchMap';
import SuccessSimpleModal from '../../../../../SuccessSimpleModal';
import ErrorSimpleModal from '../../../../../ErrorSimpleModal';
import { convertToDateTimeInput } from '../../../../../../utils/date';
import SelectWithSearch from '../../../../../SelectWithSearch';
import { ITransport } from '../../../../../../shared/interface/transport';

const RegisterForm: React.FC<IProps> = ({
  isModal,
  onHide,
  itemEdit,
  dataDt,
  setOcorrencias
}) => {
  const selector = useSelector((state: RootState) => state.clickState);

  const {
    token,
    regions,
    states,
    cities,
    sources,
    reasons,
    clippings,
    neighborhoods,
    subneighborhoods,
    localities,
    status,
    positions,
    coorporations,
    parties
  } = selector;

  useEffect(() => {
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
    getCities(token).then(resp => {
      dispatch({ type: ADD_CITIES, cities: resp });
    });
    getSourcers(token).then(resp => {
      dispatch({ type: ADD_SOURCES, sources: resp });
    });
    getReasons(token).then(resp => {
      dispatch({ type: ADD_REASONS, reasons: resp });
    });
    getCircumstances(token).then(resp => {
      dispatch({ type: ADD_CIRCUMSTANCES, circumstances: resp });
    });
    getClippings(token).then(resp => {
      dispatch({ type: ADD_CLIPPINGS, clippings: resp });
    });
    getNeighborhoods(token).then(resp => {
      dispatch({ type: ADD_NEIGHBORHOODS, neighborhoods: resp });
    });
    getSubNeighborhoods(token).then(resp => {
      dispatch({ type: ADD_SUBNEIGHBORHOODS, subneighborhoods: resp });
    });
    getLocalities(token).then(resp => {
      dispatch({ type: ADD_LOCALITIES, localities: resp });
    });
    getStatus(token).then(resp => {
      dispatch({ type: ADD_STATUS, status: resp });
    });
    getQualifications(token).then(resp => {
      dispatch({ type: ADD_QUALIFICATIONS, qualifications: resp });
    });
    getTransports(token).then(resp => {
      dispatch({ type: ADD_TRANSPORTS, transports: resp });
    });
    getAnimals(token).then(resp => {
      dispatch({ type: ADD_ANIMALS, animals: resp });
    });
    getAgeGroup(token).then(resp => {
      dispatch({ type: ADD_AGE_GROUP, age_group: resp });
    });
    getGenres(token).then(resp => {
      dispatch({ type: ADD_GENRES, genres: resp });
    });
    getPlaces(token).then(resp => {
      dispatch({ type: ADD_PLACES, places: resp });
    });
    getPositions(token).then(resp => {
      dispatch({ type: ADD_POSITIONS, positions: resp });
    });
    getCoorporations(token).then(resp => {
      dispatch({ type: ADD_COORPORATIONS, coorporations: resp });
    });
    getParties(token).then(resp =>
      dispatch({ type: ADD_PARTIES, parties: resp })
    );
  }, []);

  const [isVictimConfirm, setVictimConfirm] = useState(false);
  const [occurrence, setOccurrence] = useState<IOccurrence>();
  const [isVictimSelected, setVictimSelected] = useState<{
    name: string;
    // fieldArray: UseFieldArrayReturn;
    index: number;
  }>();

  const pressSourceId = sources.find(item => item.name === 'Imprensa')?.id;

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    getValues,
    formState: { errors, isSubmitting }
  } = useForm<FormData>({
    resolver: yupResolver(schemaOccurrence(pressSourceId))
  });

  const victimFieldArray = useFieldArray({
    control,
    name: 'victims'
  });

  const transportFieldArray = useFieldArray({
    control,
    name: 'occurrenceTransportsTransport'
  });

  const victimAnimalFieldArray = useFieldArray({
    control,
    name: 'victims_animal'
  });

  const addField = (field: UseFieldArrayReturn, values?: any) => {
    field.append(values || {});
  };

  const removeField = (field: UseFieldArrayReturn, index: number) => {
    field.remove(index);
  };

  const [isVisibleModal, setVisibleModal] = useState<string | false>(false);

  const isWaitingMode = !!itemEdit && itemEdit?.status === 'Waiting';

  useEffect(() => {
    if (!itemEdit) return;

    Object.keys(itemEdit).map(keys => {
      let key = keys as keyof unknown;

      if (!!itemEdit?.[key]?.['id'] && key !== 'victims')
        setValue(key as any, itemEdit?.[key]?.['id'] as any);
      else if (
        (key === 'date' && itemEdit?.[key]) ||
        (key === 'release_date' && itemEdit?.[key]) ||
        (key === 'date_interruption' && itemEdit?.[key])
      )
        setValue(key as any, convertToDateTimeInput(itemEdit?.[key]));
      else if (key === 'occurrenceTransportsTransport') {
        setValue(
          'occurrenceTransportsTransport',
          itemEdit?.[key]?.map((item: ITransport) => ({
            ...item,
            transportId: item?.transportId,
            interrupted_transport: item?.interrupted_transport,
            date_interruption: item.date_interruption
              ? convertToDateTimeInput(item?.date_interruption)
              : null,
            release_date: item.release_date
              ? convertToDateTimeInput(item?.release_date)
              : null,
            transport_description: item?.transport_description
          }))
        );
      } else if (key === 'victims') {
        setValue(
          'victims',
          itemEdit?.[key]
            ?.filter((v: any) => v.type === 'People')
            .map((item: IVictim) => ({
              ...item,
              genre: item?.genre?.id,
              place: item?.place?.id,
              age_group: item?.age_group?.id,
              death_date: item.death_date
                ? convertToDateTimeInput(item?.death_date)
                : null
            }))
        );
        setValue(
          'victims_animal',
          itemEdit?.[key]
            ?.filter((v: any) => v.type === 'Animal')
            .map((item: IVictimAnimal) => ({
              ...item,
              animal_type: item?.animal_type?.id,
              death_date: item.death_date
                ? convertToDateTimeInput(item?.death_date)
                : null
            })) as any
        );
      } else setValue(key as any, itemEdit[key] as any);
    });
  }, [itemEdit, selector, setValue]);

  setValue(
    'number_civilians_dead',
    getValues('victims')?.filter(
      item => item.situation === 'Dead' && item.type_person === 'Civilian'
    ).length || 0
  );

  setValue(
    'number_civilians_wounded',
    getValues('victims')?.filter(
      item => item.situation === 'Wounded' && item.type_person === 'Civilian'
    ).length || 0
  );

  setValue(
    'number_agent_dead',
    getValues('victims')?.filter(
      item => item.situation === 'Dead' && item.type_person === 'Agent'
    ).length || 0
  );

  setValue(
    'number_agent_wounded',
    getValues('victims')?.filter(
      item => item.situation === 'Wounded' && item.type_person === 'Agent'
    ).length || 0
  );

  function actionPoliceOnChange() {
    setValue('agent_presence', true);
    setValue('police_action', true);

    const main_reason = reasons.find(item => item.name === 'Ação policial');

    if (main_reason !== undefined) {
      setValue('main_reason', main_reason.id);
    }
  }

  async function registerOccurrence(values: FormData) {
    const { victims, victims_animal, ...item } = values;

    const data: any = {};

    Object.keys(item).map(keys => {
      let key = keys as keyof unknown;
      if (item[key] !== '') data[key] = item[key];
      else data[key] = null;
    });

    if (Object.entries(data).length === 0) return;

    const method: Promise<AxiosResponse<any, any>> = !!data.id
      ? editOccurrences(token, data.id, data)
      : createOccurrences(token, data);

    return await method
      .then(response => {
        return response.data;
      })
      .catch(error => {
        setVisibleModal('error');
        return error;
      });
  }

  const agentStatusNotApplicable = status.find(
    item => item.name === 'Não se aplica' && item.type === 'Agente'
  );
  const agentPositionNotApplicable = positions.find(
    item => item.name === 'Não se aplica' && item.type === 'Agente'
  );
  const corporationNotApplicable = coorporations.find(
    item => item.name === 'Não se aplica'
  );
  const politialPartyNotApplicable = parties.find(
    item => item.name === 'Não se aplica'
  );
  const politicalStatusNotApplicable = status.find(
    item => item.name === 'Não se aplica' && item.type === 'Politico'
  );
  const politicalPositionNotApplicable = positions.find(
    item => item.name === 'Não se aplica' && item.type === 'Politico'
  );
  async function handleOnSubmit(values: FormData) {
    try {
      const _occurrence: IOccurrence = await registerOccurrence(values);
      setOccurrence(_occurrence);

      if (!_occurrence.id) return;

      let arrayVictims = [
        ...(values?.victims || []),
        ...(values?.victims_animal || [])
      ];

      for await (let item of arrayVictims) {
        let data: any = {
          occurrence: _occurrence.id
        };

        Object.keys(item).map(keys => {
          let key = keys as keyof unknown;

          if (item[key] !== '') data[key] = item[key];
          else data[key] = null;

          if (data[key] === null || data[key] === undefined) {
            if (key === 'agent_status') {
              data[key] = agentStatusNotApplicable;
            } else if (key === 'agent_position') {
              data[key] = agentPositionNotApplicable;
            } else if (key === 'coorporation') {
              data[key] = corporationNotApplicable;
            } else if (key === 'partie') {
              data[key] = politialPartyNotApplicable;
            } else if (key === 'political_status') {
              data[key] = politicalStatusNotApplicable;
            } else if (key === 'political_position') {
              data[key] = politicalPositionNotApplicable;
            }
          }
        });

        if (!!data) {
          const method = !!data?.id
            ? editVictims(token, data?.id, data)
            : createVictims(token, data);

          await method
            .then(response => {
              return response.data;
            })
            .catch(error => {
              return error;
            });
        }
      }

      setVisibleModal(
        !isWaitingMode && !!itemEdit ? 'success-edit' : 'success'
      );
    } catch (error) {
      setVisibleModal('error');
    }
  }

  async function handleDeleteVictim() {
    if (!isVictimSelected) return;

    const { name, index } = isVictimSelected;
    const fieldArray: any =
      name === 'victims' ? victimFieldArray : victimAnimalFieldArray;
    const id = watch(`${name}.${index}.id` as any);

    if (!id) return removeField(fieldArray, index);

    try {
      await deleteVictims(token, id);
      removeField(fieldArray, index);
      const _data: any = {
        ...itemEdit,
        number_civilians_wounded: getValues('number_civilians_wounded'),
        number_civilians_dead: getValues('number_civilians_dead'),
        number_agent_wounded: getValues('number_agent_wounded'),
        number_agent_dead: getValues('number_agent_dead')
      };

      for (const key of Object.keys(_data)) {
        if (Array.isArray(_data[key]) && _data[key]?.[0]?.id) {
          _data[key] = _data[key].map((v: any) => ({ id: v.id }));
        } else if (_data[key]?.id) {
          _data[key] = _data[key]?.id;
        }
      }

      await editOccurrences(token, _data?.id, _data);
    } catch (error) {
      setVisibleModal('error');
    }
  }

  function latitudeLongitudeFormat(value: string) {
    return value.replaceAll(' ', '').replaceAll(',', '.');
  }

  const dispatch = useDispatch();

  function handleCloseSuccessModal() {
    setVisibleModal(false);
    getOccurrencesByParams(
      token,
      dataDt.order,
      dataDt.page,
      dataDt.take,
      dataDt.typeOccurrences,
      `${dataDt.initial_date}T00:01:00.000Z`,
      `${dataDt.final_date}T23:59:00.000Z`
    ).then((r: any) => {
      setOcorrencias(r.data);
      dispatch({ type: ADD_OCCURRENCES, occurrences: r.data });
    });
    onHide();
  }

  function handleSetRegionByState(stateId: string) {
    const _state = states?.find(({ id }) => id === stateId);
    if (!_state) return;

    const _region = regions?.find(
      ({ state }) =>
        state.toLowerCase().indexOf(_state?.name.toLocaleLowerCase()) > -1
    )?.id;

    if (!_region) return;
    setValue('region', _region);
  }

  useEffect(() => {
    if (!isModal) {
      reset();
    }
  }, [isModal, reset]);

  const latitude = watch('latitude');
  const longitude = watch('longitude');

  const dateAtual = new Date().toISOString().split('T')[0];

  const stateWatch = watch('state');
  const cityWatch = watch('city');

  const mainReason = watch('main_reason');
  const complementary_reasons = watch('complementary_reasons');

  useEffect(() => {
    setValue('complementary_reasons', []);
  }, [mainReason]);

  const policeActionReason = reasons.find(
    reason => reason.name === 'Ação policial'
  );
  const policeOperationReason = reasons.find(
    reason => reason.name === 'Operação policial'
  );

  const cReasonsOptions = reasons.filter(reason => {
    const policeActionFilter =
      policeActionReason &&
      mainReason === policeActionReason?.id &&
      [policeOperationReason?.id, policeActionReason?.id].includes(reason?.id);
    const policeOperationFilter =
      policeOperationReason &&
      mainReason === policeOperationReason?.id &&
      [policeOperationReason?.id, policeActionReason?.id].includes(reason?.id);

    return (
      mainReason !== reason?.id &&
      !(policeActionFilter || policeOperationFilter)
    );
  });

  let wId = watch('id');

  useEffect(() => {
    if (itemEdit == null || itemEdit == undefined) {
      setValue('id', '');
    } else {
      if (itemEdit?.id == undefined) {
        setValue('id', '');
      }
      if (itemEdit?.id == null) {
        setValue('id', '');
      }
      if (
        itemEdit?.id != undefined &&
        itemEdit.id != null &&
        itemEdit.id != ''
      ) {
        setValue('id', itemEdit?.id);
      }
    }
  }, [itemEdit, wId]);

  const [listNeighborhoods, setListNeighborhoods] = useState<any[]>([]);
  const [listSubNeighborhoods, setListSubNeighborhoods] = useState<any[]>([]);
  const [listLocalities, setListLocalities] = useState<any[]>([]);

  useEffect(() => {
    let neighborhoods_aux: any[] = [];
    let sub_neighborhoods_aux: any[] = [];
    let localities_aux: any[] = [];

    setValue('subneighborhood', '');
    setValue('neighborhood', '');
    setValue('locality', '');

    if (cityWatch !== undefined && cityWatch !== null) {
      if (neighborhoods !== undefined) {
        neighborhoods.forEach(id => {
          if (id.city != undefined && cityWatch.includes(id.city.id)) {
            neighborhoods_aux.push(id);
          }
        });
      }

      if (subneighborhoods !== undefined && subneighborhoods !== null) {
        subneighborhoods.forEach(id => {
          if (id.city != undefined && cityWatch.includes(id.city.id)) {
            sub_neighborhoods_aux.push(id);
          }
        });
      }

      if (localities !== undefined && localities !== null) {
        localities.forEach(id => {
          if (id.city != undefined && cityWatch.includes(id.city.id)) {
            localities_aux.push(id);
          }
        });
      }
    }

    setListNeighborhoods([...neighborhoods_aux]);
    setListSubNeighborhoods([...sub_neighborhoods_aux]);
    setListLocalities([...localities_aux]);
  }, [cityWatch]);

  function getLabelNeighborhoodsByValue(list: any[], value: string) {
    var item = { label: '', value: '' };
    list.forEach(element => {
      if (value === element.id) {
        item = { label: element.name, value: value };
      }
    });

    return item;
  }

  function getFormHeader() {
    if (!itemEdit) {
      return 'Registrar ocorrência';
    } else if (itemEdit && itemEdit.status === 'Approved') {
      return 'Editar ocorrência';
    }
    return 'Quando terminar de preencher as informações que forem relevantes, finalize o registro para aprovar a ocorrência';
  }

  return (
    <>
      <Modal
        show={isModal}
        onHide={() => {
          onHide();
          reset();
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        fullscreen
      >
        <Modal.Header style={{ border: 'none', padding: 0, margin: 0 }}>
          {!itemEdit && (
            <OccurrenceCreationHeader
              onClickFinish={handleSubmit(handleOnSubmit, console.warn)}
              onClickGoBack={() => {
                onHide();
                reset();
              }}
              isLoading={isSubmitting}
            />
          )}

          {!!itemEdit && (
            <OccurrenceEditHeader
              onClickFinish={handleSubmit(handleOnSubmit, console.warn)}
              onClickGoBack={() => {
                onHide();
                reset();
              }}
              onApproved={status => !!status && setValue('status', status)}
              isLoading={isSubmitting}
              occurrence={itemEdit}
            />
          )}
        </Modal.Header>

        <Modal.Body>
          <FormHeader>{getFormHeader()}</FormHeader>
          <form>
            <input type="hidden" {...register(`id`)} />

            <Block>
              <h3>Detalhes da ocorrência</h3>
              <p>
                Informações básicas e administrativas sobre os
                tiroteios/disparos de arma de fogo.
              </p>

              <Form.Group>
                <label htmlFor="address">Local*</label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <>
                      <InputSearchMap
                        type="text"
                        placeholder="Digite..."
                        data-cy="occurence-form-address"
                        value={value}
                        latitude={watch('latitude')}
                        longitude={watch('longitude')}
                        changePosition={watch('changePositionSelected')}
                        onLocationChange={({ lat, lng }: any) => {
                          if (lat && lng) {
                            setValue('latitude', String(lat));
                            setValue('longitude', String(lng));
                          }
                        }}
                        {...field}
                      />
                      {errors?.address && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </>
                  )}
                />
              </Form.Group>

              <BlcokSB>
                <Row style={{ flex: 1 }}>
                  <Col>
                    <Form.Group>
                      <label htmlFor="latitude">Latitude</label>
                      <Controller
                        name="latitude"
                        control={control}
                        render={({ field: { value, onChange, ...field } }) => (
                          <>
                            <InputTextLatLng
                              type="text"
                              value={value}
                              onChange={e => {
                                onChange(
                                  latitudeLongitudeFormat(e.target.value)
                                );
                                setValue('latitude', e.target.value);
                                setValue(
                                  'changePositionSelected',
                                  e.target.value
                                );
                              }}
                            />
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <label htmlFor="longitude">Longitude</label>
                      <Controller
                        name="longitude"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <>
                            <InputTextLatLng
                              type="text"
                              value={value}
                              onChange={e => {
                                setValue('longitude', e.target.value);
                                onChange(
                                  latitudeLongitudeFormat(e.target.value)
                                );
                                setValue(
                                  'changePositionSelected',
                                  e.target.value
                                );
                              }}
                            />
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </BlcokSB>

              <Row>
                <Col style={{ backgroundColor: 'transparent' }} sm={12}>
                  <input type="hidden" name="latitude" />
                  <input type="hidden" name="longitude" />

                  <MapSearch
                    onPositionSelected={({ lat, lng }) => {
                      setValue('latitude', String(lat));
                      setValue('longitude', String(lng));
                      setValue('changePositionSelected', String(lng - lat));
                    }}
                    selectedPosition={{
                      lng: Number(longitude),
                      lat: Number(latitude)
                    }}
                  />
                </Col>
              </Row>

              <BlcokSB>
                <Row style={{ flex: 1 }}>
                  <Col>
                    <Form.Group>
                      <label htmlFor="state">Estado*</label>
                      <Controller
                        control={control}
                        name="state"
                        defaultValue=""
                        render={({ field: { onBlur, onChange, value } }) => (
                          <InputSelect
                            data-cy="occurence-form-state"
                            onBlur={onBlur}
                            onChange={event => {
                              onChange(event?.target?.value);
                              setValue('city', '');
                              handleSetRegionByState(event?.target?.value);
                            }}
                            value={value}
                          >
                            <option value="">Selecione</option>
                            {states?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </InputSelect>
                        )}
                      />

                      {errors?.state && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <label htmlFor="city">Cidade*</label>
                      <InputSelect
                        {...register('city')}
                        disabled={watch('state') === ''}
                        data-cy="occurence-form-city"
                      >
                        <option value="">Selecione</option>
                        {cities?.map(item => {
                          if (item?.state?.id == stateWatch)
                            return <option value={item.id}>{item.name}</option>;
                        })}
                      </InputSelect>

                      {errors?.city && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <label htmlFor="neighborhood">Bairro</label>
                      <SelectWithSearch
                        options={listNeighborhoods.map(chave => {
                          return { label: chave.name, value: chave.id };
                        })}
                        placeholder="Selecione"
                        isDisabled={watch('city') === ''}
                        onChange={value => setValue('neighborhood', value)}
                        value={getLabelNeighborhoodsByValue(
                          listNeighborhoods,
                          watch('neighborhood')
                        )}
                      />
                      {errors?.neighborhood && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <label htmlFor="subneighborhood">Sub-bairro</label>
                      <SelectWithSearch
                        options={listSubNeighborhoods.map(chave => {
                          return { label: chave.name, value: chave.id };
                        })}
                        placeholder="Selecione"
                        isDisabled={watch('city') === ''}
                        onChange={value => setValue('subneighborhood', value)}
                        value={getLabelNeighborhoodsByValue(
                          listSubNeighborhoods,
                          watch('subneighborhood')
                        )}
                      />
                      {errors?.subneighborhood && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group>
                      <label htmlFor="location">Localidade</label>
                      <SelectWithSearch
                        options={listLocalities.map(chave => {
                          return { label: chave.name, value: chave.id };
                        })}
                        placeholder="Selecione"
                        isDisabled={watch('city') === ''}
                        onChange={value => setValue('locality', value)}
                        value={getLabelNeighborhoodsByValue(
                          listLocalities,
                          watch('locality')
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </BlcokSB>

              <BlcokSB>
                <Row style={{ flex: 1 }}>
                  <Col style={{ flex: 0.8 }}>
                    <Row>
                      <Col>
                        <Form.Group>
                          <label>Data da ocorrência*</label>
                          <InputText
                            type="datetime-local"
                            {...register('date')}
                            data-cy="occurence-form-date"
                            max={dateAtual}
                          />
                          {errors?.date && (
                            <p className="text-danger">Campo obrigatório</p>
                          )}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <label htmlFor="source">Fonte de registro*</label>
                          <InputSelect
                            {...register('source')}
                            data-cy="occurence-form-source"
                          >
                            <option value="">Selecione</option>
                            {sources?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </InputSelect>
                          {errors?.source && (
                            <p className="text-danger">Campo obrigatório</p>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>

                    <BlcokSB>
                      <Row style={{ flex: 1 }}>
                        <Col>
                          <p>Houve ação policial*</p>
                          <div
                            style={{ display: 'flex', flexDirection: 'row' }}
                          >
                            <div style={{ marginRight: '10px' }}>
                              <Controller
                                name="police_action"
                                control={control}
                                render={({
                                  field: { value, onChange, ...field }
                                }) => (
                                  <Form.Check
                                    inline
                                    label="Sim"
                                    value="true"
                                    type="radio"
                                    data-cy="occurence-form-have-police-action"
                                    checked={value?.toString() === 'true'}
                                    onChange={actionPoliceOnChange}
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="police_action"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                  <Form.Check
                                    inline
                                    label="Não"
                                    value="false"
                                    type="radio"
                                    checked={value?.toString() === 'false'}
                                    data-cy="occurence-form-not-have-police-action"
                                    {...field}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          {errors?.police_action && (
                            <p className="text-danger">Campo obrigatório</p>
                          )}
                        </Col>
                        <Col>
                          <Form.Group>
                            <p>Presença de agentes*</p>
                            <div style={{ marginRight: '10px' }}>
                              <Controller
                                name="agent_presence"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                  <Form.Check
                                    inline
                                    label="Sim"
                                    value="true"
                                    type="radio"
                                    checked={value?.toString() === 'true'}
                                    data-cy="occurence-form-have-agent-presence"
                                    {...field}
                                  />
                                )}
                              />
                              <Controller
                                name="agent_presence"
                                control={control}
                                render={({ field: { value, ...field } }) => (
                                  <Form.Check
                                    inline
                                    label="Não"
                                    value="false"
                                    type="radio"
                                    checked={value?.toString() === 'false'}
                                    data-cy="occurence-form-not-have-agent-presence"
                                    {...field}
                                  />
                                )}
                              />
                            </div>

                            {errors?.agent_presence && (
                              <p className="text-danger">Campo obrigatório</p>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </BlcokSB>
                  </Col>

                  <Row style={{ flex: 1 }}>
                    <Col>
                      <Form.Group>
                        <label htmlFor="description">
                          Descrição da ocorrência*
                        </label>
                        <InputArea
                          as="textarea"
                          placeholder="Digite..."
                          {...register('description')}
                          data-cy="occurence-form-description"
                        />
                        {errors?.description && (
                          <p className="text-danger">Campo obrigatório</p>
                        )}
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <label htmlFor="related_record">
                          Registro relacionado
                        </label>

                        <InputArea
                          as="textarea"
                          placeholder="Digite..."
                          {...register('related_record')}
                          data-cy="occurence-related-record"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </BlcokSB>
            </Block>
            <br />

            <Block>
              <Row>
                <h3>Informações de contexto</h3>
                <p>
                  Complementação com informações de contexto dos
                  tiroteios/disparos (apenas administradores).
                </p>
              </Row>

              <Row>
                <Col sm={4}>
                  <Form.Group>
                    <label htmlFor="main_reason">Motivo principal *</label>
                    <InputSelect
                      {...register('main_reason')}
                      data-cy="occurence-form-main-reason"
                    >
                      <option value="">Selecione</option>
                      {reasons?.map(item => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </InputSelect>
                    {errors?.main_reason && (
                      <p className="text-danger">Campo obrigatório</p>
                    )}
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group>
                    <label htmlFor="complementary_reasons">
                      Motivos complementares
                    </label>
                    <CustomControllerSelect
                      control={control}
                      name="complementary_reasons"
                      options={cReasonsOptions}
                      classNamePrefix="occurence-form-complementary-reasons"
                    />

                    {errors?.complementary_reasons?.[0]?.id && (
                      <p className="text-danger">Campo obrigatório</p>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <p>Chacina*</p>
                    <div style={{ marginRight: '10px' }}>
                      <Controller
                        name="massacre"
                        control={control}
                        render={({ field: { value, ...field } }) => (
                          <Form.Check
                            inline
                            label="Sim"
                            value="true"
                            type="radio"
                            checked={value?.toString() === 'true'}
                            data-cy="occurence-form-have-massacre"
                            {...field}
                          />
                        )}
                      />
                      <Controller
                        name="massacre"
                        control={control}
                        render={({ field: { value, ...field } }) => (
                          <Form.Check
                            inline
                            label="Não"
                            value="false"
                            type="radio"
                            checked={value?.toString() === 'false'}
                            data-cy="occurence-form-not-have-massacre"
                            {...field}
                          />
                        )}
                      />
                    </div>
                    {errors?.massacre && (
                      <p className="text-danger">Campo obrigatório</p>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <label htmlFor="police_unit">
                      Unidades policiais presentes
                    </label>
                    <InputText
                      type="text"
                      {...register('police_unit')}
                      data-cy="occurence-form-police-unit"
                    />
                    {errors?.police_unit && (
                      <p className="text-danger">Campo obrigatório</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <BlcokSB>
                <Row style={{ flex: 1 }}>
                  <Col>
                    <Form.Group>
                      <label>Links de notícias relacionadas</label>
                      <InputArea as="textarea" {...register('related_news')} />
                      {errors?.related_news && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <label htmlFor="clippings">Outros recortes</label>
                      <CustomControllerSelect
                        control={control}
                        name="clippings"
                        options={clippings}
                        classNamePrefix="occurence-form-clippings"
                      />
                      {errors?.clippings && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <label>Observação</label>
                      <InputArea as="textarea" {...register('observations')} />
                      {errors?.observations && (
                        <p className="text-danger">Campo obrigatório</p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </BlcokSB>

              <BlcokSB>
                <Row>
                  <Row>
                    <h5>Transporte</h5>
                    <p>Adicione transportes e informações sobre.</p>
                  </Row>
                  <Col sm={6}>
                    <AddVtmContainer>
                      <AddVtm
                        type="button"
                        onClick={() => addField(transportFieldArray as any)}
                        data-cy="occurence-form-btn-add-transport"
                      >
                        Adicionar transporte
                      </AddVtm>
                    </AddVtmContainer>
                  </Col>
                </Row>
              </BlcokSB>
              {transportFieldArray?.fields?.map(
                (field: { id: any }, index: number) => {
                  return (
                    <TransportsForm
                      key={`${field.id}`}
                      register={register}
                      errors={errors?.occurrenceTransportsTransport?.[index]}
                      watch={watch}
                      setValue={setValue}
                      control={control}
                      idTransports={index}
                      onDelete={() => {
                        removeField(transportFieldArray as any, index);
                      }}
                    />
                  );
                }
              )}
            </Block>
            <br />

            <Block>
              <Row>
                <Col>
                  <h4> Vitimas </h4>
                  <p>
                    {' '}
                    Complementação com informações sobre pessoas baleadas em
                    cada registro (apenas administradores).{' '}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>
                  <AddVtmContainer>
                    <AddVtm
                      type="button"
                      onClick={() =>
                        addField(victimFieldArray as any, { type: 'People' })
                      }
                      data-cy="occurence-form-btn-add-victim"
                    >
                      Incluir vitima
                    </AddVtm>
                  </AddVtmContainer>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Total de vitimas</Form.Label>
                    <InputText
                      value={victimFieldArray.fields.length || 0}
                      type="number"
                      min={0}
                      disabled={true}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Civis mortos</Form.Label>
                    <InputText
                      type="number"
                      min={0}
                      disabled={true}
                      {...register('number_civilians_dead')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Civis feridos</Form.Label>
                    <InputText
                      type="number"
                      min={0}
                      disabled={true}
                      {...register('number_civilians_wounded')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Agentes mortos</Form.Label>
                    <InputText
                      type="number"
                      min={0}
                      disabled={true}
                      {...register('number_agent_dead')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Agentes feridos</Form.Label>
                    <InputText
                      type="number"
                      min={0}
                      disabled={true}
                      {...register('number_agent_wounded')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {victimFieldArray?.fields?.map((field, index) => {
                return (
                  <VictimsForm
                    key={`${field.id}`}
                    register={register}
                    errors={errors?.victims?.[index]}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    idVictim={index}
                    onDelete={() => {
                      if (!watch(`victims.${index}.id`)) {
                        removeField(victimFieldArray as any, index);
                      } else {
                        setVictimConfirm(true);
                        setVictimSelected({
                          name: 'victims',
                          // fieldArray: victimFieldArray,
                          index
                        });
                      }
                    }}
                  />
                );
              })}
            </Block>
          </form>
          <br />

          <Block>
            <Row>
              <Col>
                <h4> Vitimas animais</h4>
                <p>
                  {' '}
                  Complementação caso haja animais baleados (apenas
                  administradores){' '}
                </p>
              </Col>
            </Row>

            <Row>
              <Col sm={2}>
                <AddVtmContainer>
                  <AddVtm
                    type="button"
                    onClick={() =>
                      addField(victimAnimalFieldArray as any, {
                        type: 'Animal'
                      })
                    }
                    data-cy="occurence-form-btn-add-animal-victim"
                  >
                    Incluir animal
                  </AddVtm>
                </AddVtmContainer>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Total de vitimas animais</Form.Label>
                  <InputText
                    value={victimAnimalFieldArray.fields.length || 0}
                    type="number"
                    min={0}
                    disabled={true}
                  />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Animais mortos</Form.Label>
                  <InputText
                    value={
                      watch('victims_animal')?.filter(
                        item =>
                          item.situation === 'Dead' && item.type === 'Animal'
                      ).length || 0
                    }
                    type="number"
                    min={0}
                    disabled={true}
                  />
                </Form.Group>
              </Col>

              <Col sm={2}>
                <Form.Group>
                  <Form.Label>Animais feridos</Form.Label>
                  <InputText
                    value={
                      watch('victims_animal')?.filter(
                        item =>
                          item.situation === 'Wounded' && item.type === 'Animal'
                      ).length || 0
                    }
                    type="number"
                    min={0}
                    disabled={true}
                  />
                </Form.Group>
              </Col>
            </Row>

            {victimAnimalFieldArray.fields.map((field, index) => (
              <AnimalsForm
                key={field.id}
                register={register}
                errors={errors?.victims_animal?.[index]}
                watch={watch}
                setValue={setValue}
                control={control}
                idVictim={index}
                onDelete={() => {
                  if (!watch(`victims_animal.${index}.id`)) {
                    removeField(victimAnimalFieldArray as any, index);
                  } else {
                    setVictimConfirm(true);
                    setVictimSelected({
                      name: 'victims_animal',
                      // fieldArray: victimAnimalFieldArray,
                      index
                    });
                  }
                }}
              />
            ))}
          </Block>
        </Modal.Body>
      </Modal>

      <SuccessOccurrenceModal
        title={isWaitingMode ? 'Ocorrência aprovada' : 'Ocorrência registrada'}
        subtitle={
          isWaitingMode
            ? 'A ocorrência foi aprovada com sucesso.'
            : 'A ocorrência foi registrada com sucesso.'
        }
        open={isVisibleModal === 'success'}
        occurrenceId={occurrence?.id || ''}
        onClose={handleCloseSuccessModal}
      />

      <SuccessSimpleModal
        title="Ocorrência salva"
        message="A ocorrência foi salva com sucesso."
        open={isVisibleModal === 'success-edit'}
        onClose={handleCloseSuccessModal}
      />

      <ErrorSimpleModal
        title="Falha na ação"
        message="Houve uma falha na ação executada."
        open={isVisibleModal === 'error'}
        onClose={() => setVisibleModal(false)}
      />

      <ModalConfirm
        open={isVictimConfirm}
        onClose={() => setVictimConfirm(false)}
        onClickConfirm={() => {
          handleDeleteVictim();
          // setVictimConfirm(false);
        }}
        title="Excluir vítima"
        messageBold="Você tem certeza que deseja excluir esta vítima?"
        message="Essa ação não poderá ser alterada."
        textConfirm="Sim, excluir vítima"
      />
    </>
  );
};

export default RegisterForm;
