export class StatisticsModel {
  occurence: OccurrenceStatisticsModel;
  occurrence_percent: OccurrencePercentStatisticsModel;
  victim: VictimStatisticsModel;
  victim_percent: VictimStatisticsModel;

  constructor({
    occurence,
    occurrence_percent,
    victim,
    victim_percent
  }: {
    occurence: OccurrenceStatisticsModel;
    occurrence_percent: OccurrencePercentStatisticsModel;
    victim: VictimStatisticsModel;
    victim_percent: VictimStatisticsModel;
  }) {
    this.occurence = occurence;
    this.occurrence_percent = occurrence_percent;
    this.victim = victim;
    this.victim_percent = victim_percent;
  }
}

export class OccurrenceStatisticsModel {
  total: number;
  with_dead: number;
  with_wounded: number;
  no_wounded: number;
  police_presence: number;
  with_police_action: number;
  without_shot: number;
  with_shot: number;

  constructor({
    total,
    with_dead,
    with_wounded,
    no_wounded,
    police_presence,
    with_police_action,
    without_shot,
    with_shot
  }: {
    total: number;
    with_dead: number;
    with_wounded: number;
    no_wounded: number;
    police_presence: number;
    with_police_action: number;
    without_shot: number;
    with_shot: number;
  }) {
    this.total = total;
    this.with_dead = with_dead;
    this.with_wounded = with_wounded;
    this.no_wounded = no_wounded;
    this.police_presence = police_presence;
    this.with_police_action = with_police_action;
    this.without_shot = without_shot;
    this.with_shot = with_shot;
  }
}

export class OccurrencePercentStatisticsModel {
  total: number;
  with_dead: number;
  with_wounded: number;
  no_wounded: number;
  police_presence: number;

  constructor({
    total,
    with_dead,
    with_wounded,
    no_wounded,
    police_presence
  }: {
    total: number;
    with_dead: number;
    with_wounded: number;
    no_wounded: number;
    police_presence: number;
  }) {
    this.total = total;
    this.with_dead = with_dead;
    this.with_wounded = with_wounded;
    this.no_wounded = no_wounded;
    this.police_presence = police_presence;
  }
}

export class VictimStatisticsModel {
  total_dead: number;
  total_wounded: number;
  dead_agents: number;
  dead_civilians: number;
  wounded_agents: number;
  wounded_civilians: number;

  constructor({
    total_dead,
    total_wounded,
    dead_agents,
    dead_civilians,
    wounded_agents,
    wounded_civilians
  }: {
    total_dead: number;
    total_wounded: number;
    dead_agents: number;
    dead_civilians: number;
    wounded_agents: number;
    wounded_civilians: number;
  }) {
    this.total_dead = total_dead;
    this.total_wounded = total_wounded;
    this.dead_agents = dead_agents;
    this.dead_civilians = dead_civilians;
    this.wounded_agents = wounded_agents;
    this.wounded_civilians = wounded_civilians;
  }
}

export class MonthStatisticsModel {
  month: string;
  total: number;
  dead_agents: number;
  dead_civilians: number;
  wounded_agents: number;
  wounded_civilians: number;

  constructor({
    month,
    total,
    dead_agents,
    dead_civilians,
    wounded_agents,
    wounded_civilians
  }: {
    month: string;
    total: number;
    dead_agents: number;
    dead_civilians: number;
    wounded_agents: number;
    wounded_civilians: number;
  }) {
    this.month = month;
    this.total = total;
    this.dead_agents = dead_agents;
    this.dead_civilians = dead_civilians;
    this.wounded_agents = wounded_agents;
    this.wounded_civilians = wounded_civilians;
  }
}

export class AnnualStatisticsModel {
  total_occurrences: number;
  total_dead: number;
  total_wounded: number;
  months: Array<MonthStatisticsModel>;

  constructor({
    total_occurrences,
    total_dead,
    total_wounded,
    months
  }: {
    total_occurrences: number;
    total_dead: number;
    total_wounded: number;
    months: Array<MonthStatisticsModel>;
  }) {
    this.total_occurrences = total_occurrences;
    this.total_dead = total_dead;
    this.total_wounded = total_wounded;
    this.months = months;
  }
}
