import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerFoooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LinkStyled = styled(Link)`
  background-color: #e0733d;
  padding: 6px;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  color: #fff;
`;
export const BtnCancel = styled.button`
  background-color: #ea5455;
  padding: 4px;
  border: none;
  border-radius: 6px;
  text-decoration: none;
  color: #fff;
`;

export const CardStyled = styled.div`
  padding: 20px;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 32px;
  position: relative;

  width: 100%;
`;

export const LineDashed = styled.hr`
  border-top: 1px dashed #2c3941;
`;

export const ButtonDashed = styled.button`
  background-color: #f8f8f8;
  width: 100%;
  height: 92px;
  border: 1px dashed black;
  border-radius: 5px;
`;

export const ButtonClear = styled.button`
  border: none;
  background-color: transparent;
  color: #ea5455;

  position: absolute;
  top: 20px;
  right: 20px;
`;

export const ButtonUp = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
`;
export const ButtonDown = styled.button`
  text-decoration: none;
  background-color: transparent;
  border: none;
`;

export const ContainerButtons = styled.div`
  margin-right: 8px;
  max-width: 52px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerCard = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerTB = styled.div`
  margin-top: 20px;
`;
