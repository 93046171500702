import {
  CREATE_VICTIMS_LIST,
  CREATE_ANIMALS_LIST,
  ADD_CONT_VITIMAS_CIVIS_MORTOS,
  ADD_CONT_VITIMAS_CIVIS_FERIDOS,
  ADD_CONT_VITIMAS_AGENTES_MORTOS,
  ADD_CONT_VITIMAS_AGENTES_FERIDOS,
  ADD_CONT_ANIMAIS_MORTOS,
  ADD_CONT_ANIMAIS_FERIDOS,
  ADD_SUBNEIGHBORHOODS,
  ADD_LOCALITIES,
  ADD_ANIMALS_LIST,
  ADD_VICTMS_LIST,
  ADD_AGE_GROUP,
  ADD_ANIMALS,
  ADD_CIRCUMSTANCES,
  ADD_CITIES,
  ADD_CLIPPINGS,
  ADD_COORPORATIONS,
  ADD_PARTIES,
  ADD_DESTROY_VICTIMS,
  ADD_GENRES,
  ADD_LOGIN_USER,
  ADD_NEIGHBORHOODS,
  ADD_OCCURRENCES,
  ADD_PLACES,
  ADD_POLICIES,
  ADD_POSITIONS,
  ADD_POSTS,
  ADD_QUALIFICATIONS,
  ADD_QUESTIONS,
  ADD_REASONS,
  ADD_REGIONS,
  ADD_SOCIAL_NETWORKS,
  ADD_SOURCES,
  ADD_STATES,
  ADD_STATISTICS,
  ADD_STATUS,
  ADD_TOKEN,
  ADD_TRANSPORTS,
  ADD_USERS,
  ADD_VICTIMS,
  ADD_ALL_USERS,
  LIST_VICTMNS,
  LIST_ANIMALS,
  ADD_CONT_OF_ANIMALS,
  ADD_CONT_OF_VICTIMS,
  ADD_LIST_OF_ANIMALS,
  ADD_LIST_OF_VICTIMS,
  ADD_CONT_VICTIMS_NUMBER,
  ADD_ANNUAL_STATISTICS,
  ADD_HOME,
  ADD_BLOG_POSTS,
  ADD_ABOUT,
  ADD_ABOUT_API,
  ADD_TEAMS,
  ADD_PARTNERS,
  ADD_AWARDS,
  ADD_TRANSPARENCIES,
  ADD_ARTICLES,
  ADD_NOTIFICATIONS,
  ADD_REPORTS,
  GET_IMAGE_SOCIAL_MEDIA,
  ADD_ALERT
} from '../index';
import { initialState } from '../../shared/state/index';

export const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ADD_ABOUT:
      return { ...state, about: action.about };

    case ADD_NOTIFICATIONS:
      return { ...state, notifications: action.notifications };

    case ADD_TEAMS:
      return { ...state, teams: action.teams };

    case ADD_ALERT:
      return { ...state, alert: action.alert };

    case GET_IMAGE_SOCIAL_MEDIA:
      return { ...state, image_sm: action.image };

    case ADD_PARTNERS:
      return { ...state, partners: action.partners };

    case ADD_AWARDS:
      return { ...state, awards: action.awards };

    case ADD_REPORTS:
      return { ...state, reports: action.awards };

    case ADD_TRANSPARENCIES:
      return { ...state, transparencies: action.transparencies };

    case ADD_LOGIN_USER:
      return { ...state, user: action.user };

    case ADD_TOKEN:
      return { ...state, token: action.token };
    case ADD_STATES:
      return { ...state, states: action.states };
    case ADD_USERS:
      return { ...state, users: action.users };
    case ADD_HOME:
      return { ...state, home: action.home };
    case ADD_ALL_USERS:
      return { ...state, users: action.users };
    case ADD_OCCURRENCES:
      return { ...state, occurrences: action.occurrences };
    case ADD_VICTIMS:
      return { ...state, victims: action.victims };
    case ADD_STATISTICS:
      return { ...state, statistic: action.statistics };
    case ADD_ANNUAL_STATISTICS:
      return { ...state, annual_statistics: action.annual_statistics };

    case ADD_POSTS:
      return { ...state, posts: action.posts };

    case ADD_BLOG_POSTS:
      return { ...state, blog_posts: action.blog_posts };

    case ADD_ARTICLES:
      return { ...state, articles: action.articles };

    case ADD_ABOUT_API:
      return { ...state, about_api: action.about_api };

    case ADD_QUESTIONS:
      return { ...state, questions: action.questions };
    case ADD_POLICIES:
      return { ...state, policies: action.policies };
    case ADD_SOURCES:
      return { ...state, sources: action.sources };
    case ADD_REASONS:
      return { ...state, reasons: action.reasons };
    case ADD_CLIPPINGS:
      return { ...state, clippings: action.clippings };
    case ADD_TRANSPORTS:
      return { ...state, transports: action.transports };
    case ADD_QUALIFICATIONS:
      return { ...state, qualifications: action.qualifications };
    case ADD_STATUS:
      return { ...state, status: action.status };
    case ADD_CIRCUMSTANCES:
      return { ...state, circumstances: action.circumstances };
    case ADD_CITIES:
      return { ...state, cities: action.cities };
    case ADD_NEIGHBORHOODS:
      return { ...state, neighborhoods: action.neighborhoods };
    case ADD_SUBNEIGHBORHOODS:
      return { ...state, subneighborhoods: action.subneighborhoods };
    case ADD_LOCALITIES:
      return { ...state, localities: action.localities };
    case ADD_AGE_GROUP:
      return { ...state, age_group: action.age_group };
    case ADD_GENRES:
      return { ...state, genres: action.genres };
    case ADD_PLACES:
      return { ...state, places: action.places };
    case ADD_POSITIONS:
      return { ...state, positions: action.positions };
    case ADD_COORPORATIONS:
      return { ...state, coorporations: action.coorporations };
    case ADD_PARTIES:
      return { ...state, parties: action.parties };
    case ADD_CONT_VITIMAS_CIVIS_MORTOS:
      return { ...state, contCivisMortos: action.contCivisMortos };

    case ADD_CONT_OF_ANIMALS:
      return { ...state, contOfAnimals: action.contOfAnimals };
    case ADD_LIST_OF_ANIMALS:
      return { ...state, listOfAnimals: action.listOfAnimals };
    case ADD_CONT_OF_VICTIMS:
      return { ...state, contOfVictims: action.contOfVictims };
    case ADD_LIST_OF_VICTIMS:
      return { ...state, listOfVictims: action.listOfVictims };

    case ADD_CONT_VICTIMS_NUMBER:
      return { ...state, contVictimsNumber: action.contVictimsNumber };

    case ADD_CONT_VITIMAS_CIVIS_FERIDOS:
      return { ...state, contCivisFeridos: action.contCivisFeridos };
    case ADD_CONT_VITIMAS_AGENTES_MORTOS:
      return { ...state, contAgentesMortos: action.contAgentesMortos };
    case ADD_CONT_VITIMAS_AGENTES_FERIDOS:
      return { ...state, contAgentesFeridos: action.contAgentesFeridos };
    case ADD_CONT_ANIMAIS_MORTOS:
      return { ...state, contAnimaisMortos: action.contAnimaisMortos };
    case ADD_CONT_ANIMAIS_FERIDOS:
      return { ...state, contAnimaisFeridos: action.contAnimaisFeridos };
    case CREATE_ANIMALS_LIST:
      return { ...state, listAnimals: action.listAnimals };
    case CREATE_VICTIMS_LIST:
      return { ...state, listVictims: action.listVictims };
    case ADD_ANIMALS:
      return { ...state, animals: action.animals };
    case ADD_REGIONS:
      return { ...state, regions: action.regions };
    case ADD_SOCIAL_NETWORKS:
      return { ...state, social_networks: action.social_networks };
    case ADD_DESTROY_VICTIMS:
      return { ...state, idVictims: action.idVictims };
    case ADD_VICTMS_LIST:
      return { ...state, victimsList: action.victimsList };
    case ADD_ANIMALS_LIST:
      return { ...state, animalsList: action.animalsList };
    case LIST_VICTMNS:
      return { ...state };
    case LIST_ANIMALS:
      return { ...state };
    default:
      return { ...state };
  }
};
