import { EOccurrenceStatus } from '../../../../shared/enums/ocurrenceStatus';
import { EVictimsRace } from '../../../../shared/enums/victimsRace';
import { EVictimsSituation } from '../../../../shared/enums/victimsSituation';
import { EVictimsType } from '../../../../shared/enums/victimsType';
import { EVictimsTypePerson } from '../../../../shared/enums/victimsTypePerson';

export class CreateEditOccurrenceModel {
  id: string;
  documentNumber: number;
  status: EOccurrenceStatus;
  userId?: string;
  user?: OccurrenceUserModel;
  adminId?: string;
  admin?: OccurrenceUserModel;
  address: string;
  country?: string;
  stateId: string;
  regionId?: string;
  cityId?: string;
  neighborhoodId?: string;
  subNeighborhoodId?: string;
  localityId?: string;
  latitude: string;
  longitude: string;
  date: Date;
  sourceId: string;
  policeAction: boolean;
  agentPresence: boolean;
  description: string;
  relatedRecord?: string;
  numberCiviliansDead: number;
  numberCiviliansWounded: number;
  numberAgentDead: number;
  numberAgentWounded: number;
  createdAt?: Date;
  updatedAt?: Date;
  contextInfo: OccurrenceContextInfoModel;
  transports: OccurrenceTransportModel[];
  victims: OccurrenceHumanVictimsModel[];
  animalVictims: OccurrenceAnimalVictimsModel[];

  constructor({
    id,
    documentNumber,
    status,
    userId,
    user,
    adminId,
    admin,
    address,
    country,
    stateId,
    regionId,
    cityId,
    neighborhoodId,
    subNeighborhoodId,
    localityId,
    latitude,
    longitude,
    date,
    sourceId,
    policeAction,
    agentPresence,
    description,
    relatedRecord,
    numberCiviliansDead,
    numberCiviliansWounded,
    numberAgentDead,
    numberAgentWounded,
    createdAt,
    updatedAt,
    contextInfo,
    transports,
    victims,
    animalVictims
  }: CreateEditOccurrenceModel) {
    this.id = id;
    this.documentNumber = documentNumber;
    this.status = status;
    this.userId = userId;
    this.user = user;
    this.adminId = adminId;
    this.admin = admin;
    this.address = address;
    this.country = country;
    this.stateId = stateId;
    this.regionId = regionId;
    this.cityId = cityId;
    this.neighborhoodId = neighborhoodId;
    this.subNeighborhoodId = subNeighborhoodId;
    this.localityId = localityId;
    this.latitude = latitude;
    this.longitude = longitude;
    this.date = date;
    this.sourceId = sourceId;
    this.policeAction = policeAction;
    this.agentPresence = agentPresence;
    this.description = description;
    this.relatedRecord = relatedRecord;
    this.numberCiviliansDead = numberCiviliansDead;
    this.numberCiviliansWounded = numberCiviliansWounded;
    this.numberAgentDead = numberAgentDead;
    this.numberAgentWounded = numberAgentWounded;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.contextInfo = contextInfo;
    this.transports = transports;
    this.victims = victims;
    this.animalVictims = animalVictims;
  }
}

export class OccurrenceUserModel {
  id: string;
  name?: string;
  nickname?: string;

  constructor({ id, name, nickname }: OccurrenceUserModel) {
    this.id = id;
    this.name = name;
    this.nickname = nickname;
  }
}

export class OccurrenceContextInfoModel {
  mainReasonId: string;
  complementaryReasons?: string[];
  clippings?: string[];
  massacre: boolean;
  policeUnit?: string;
  relatedNews?: string;
  observations?: string;

  constructor({
    mainReasonId,
    complementaryReasons,
    clippings,
    massacre,
    policeUnit,
    relatedNews,
    observations
  }: OccurrenceContextInfoModel) {
    this.mainReasonId = mainReasonId;
    this.complementaryReasons = complementaryReasons;
    this.clippings = clippings;
    this.massacre = massacre;
    this.policeUnit = policeUnit;
    this.relatedNews = relatedNews;
    this.observations = observations;
  }
}

export class OccurrenceTransportModel {
  id: string;
  occurrenceId: string;
  transportId: string;
  interruptedTransport: boolean;
  dateInterruption?: Date;
  releaseDate?: Date;
  transportDescription?: string;

  constructor({
    id,
    occurrenceId,
    transportId,
    interruptedTransport,
    dateInterruption,
    releaseDate,
    transportDescription
  }: OccurrenceTransportModel) {
    this.id = id;
    this.occurrenceId = occurrenceId;
    this.transportId = transportId;
    this.interruptedTransport = interruptedTransport;
    this.dateInterruption = dateInterruption;
    this.releaseDate = releaseDate;
    this.transportDescription = transportDescription;
  }
}

export class OccurrenceHumanVictimsModel {
  id: string;
  occurrenceId: string;
  type: EVictimsType;
  situation: EVictimsSituation;
  name?: string;
  circumstances: string[];
  deathDate?: Date;
  personType: EVictimsTypePerson;
  qualifications?: string[];
  age?: number;
  ageGroupId: string;
  genreId: string;
  race: EVictimsRace;
  placeId: string;
  serviceStatusId?: string;
  partieId?: string;
  politicalPositionId?: string;
  politicalStatusId?: string;
  coorporationId?: string;
  agentPositionId?: string;
  agentStatusId?: string;
  unit?: string;
  generalObservation?: string;

  constructor({
    id,
    occurrenceId,
    type,
    situation,
    name,
    circumstances,
    deathDate,
    personType,
    qualifications,
    age,
    ageGroupId,
    genreId,
    race,
    placeId,
    serviceStatusId,
    partieId,
    politicalPositionId,
    politicalStatusId,
    coorporationId,
    agentPositionId,
    agentStatusId,
    unit,
    generalObservation
  }: OccurrenceHumanVictimsModel) {
    this.id = id;
    this.occurrenceId = occurrenceId;
    this.type = type;
    this.situation = situation;
    this.name = name;
    this.circumstances = circumstances;
    this.deathDate = deathDate;
    this.personType = personType;
    this.qualifications = qualifications;
    this.age = age;
    this.ageGroupId = ageGroupId;
    this.genreId = genreId;
    this.race = race;
    this.placeId = placeId;
    this.serviceStatusId = serviceStatusId;
    this.partieId = partieId;
    this.politicalPositionId = politicalPositionId;
    this.politicalStatusId = politicalStatusId;
    this.coorporationId = coorporationId;
    this.agentPositionId = agentPositionId;
    this.agentStatusId = agentStatusId;
    this.unit = unit;
    this.generalObservation = generalObservation;
  }
}

export class OccurrenceAnimalVictimsModel {
  id: string;
  occurrenceId: string;
  type: EVictimsType;
  circumstances: string[];
  situation: EVictimsSituation;
  animalTypeId: string;
  name?: string;
  deathDate?: Date;

  constructor({
    id,
    occurrenceId,
    type,
    circumstances,
    situation,
    animalTypeId,
    name,
    deathDate
  }: OccurrenceAnimalVictimsModel) {
    this.id = id;
    this.occurrenceId = occurrenceId;
    this.type = type;
    this.circumstances = circumstances;
    this.situation = situation;
    this.animalTypeId = animalTypeId;
    this.name = name;
    this.deathDate = deathDate;
  }
}
