import { useSelector } from 'react-redux';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import {
  Dash,
  Occurrences,
  Notifications,
  Users,
  Regions,
  Home,
  Sobre,
  API,
  PostList,
  Impacto,
  RedesSociais,
  Transparencia,
  Dados,
  ReportsPost,
  PostDetails,
  ReportsById,
  Login
} from '../pages/index';
import { RootState } from '../store';
import EditVariableModal from '../pages/EditVariables';
import { PostDetailsType } from '../pages/Blog/presentation/views/post_details/index.types';
import Dashboard from '../pages/Dashboard/presentation/views/dashboard';
import { Container, Content } from '../index.styled';
import { NavbarHeader, NavbarLeft } from '../components/index';
import 'bootstrap/dist/css/bootstrap.min.css';
import CreateEditOccurrence from '../pages/RefactorOccurrences/presentation/views/create_edit';
import { useContext } from 'react';
import { AppContext } from '../shared/providers/AppProvider';

function RequireAuth({ children }: any) {
  const { state } = useContext(AppContext);

  return !!state.token ? children : <Navigate to={'/login'} />;
}

function DashboardLayout() {
  return (
    <>
      <Container>
        <NavbarHeader />
        <NavbarLeft />
        <Content>
          <Outlet />
        </Content>
      </Container>
    </>
  );
}

const RoutesParams: React.FC = () => {
  const { user } = useSelector((state: RootState) => state.clickState);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <DashboardLayout />
          </RequireAuth>
        }
      >
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="*" element={<Dashboard />} />

        <Route path="/occurrences" element={<Occurrences />} />
        {user.role === 'Administrador' ? (
          <Route path="/variables" element={<EditVariableModal />} />
        ) : null}
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/users" element={<Users />} />
        <Route path="/regions" element={<Regions />} />
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<Sobre />} />
        <Route path="/api" element={<API />} />
        <Route path="/blog" element={<PostList />} />
        <Route
          path="/blog/:identifier"
          element={<PostDetails detailsType={PostDetailsType.BY_SLUG} />}
        />
        <Route
          path="/blog/id/:identifier"
          element={<PostDetails detailsType={PostDetailsType.BY_ID} />}
        />
        <Route path="/impact" element={<Impacto />} />
        <Route path="/social-networks" element={<RedesSociais />} />
        <Route path="/transparency" element={<Transparencia />} />
        <Route path="/data" element={<Dados />} />
        <Route path="/data/:slug" element={<ReportsPost />} />
        <Route path="/data/id/:id" element={<ReportsById />} />
        <Route path="/dash" element={<Dash />} />
      </Route>
      <Route path="/occurrences">
        <Route path="create" element={<CreateEditOccurrence />} />
        <Route path="edit/:id" element={<CreateEditOccurrence />} />
      </Route>
    </Routes>
  );
};

export default RoutesParams;
