import React, { useState } from 'react';
import { Container, Btn } from './index.style';

type list = {};

interface IProps {
  list: list[];
  onClick: (listName: any) => void;
}

const ButtonSubline: React.FC<IProps> = props => {
  const [active, setActive] = useState(props.list[0]);

  return (
    <Container>
      {props.list.map(id => {
        return (
          <Btn
            active={active === id}
            onClick={() => {
              if (active === id) return;
              props.onClick(id);
              setActive(id);
            }}
          >
            {id}
          </Btn>
        );
      })}
    </Container>
  );
};

export default ButtonSubline;
