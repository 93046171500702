import { api } from '../index';

export async function postImage(token: string, dados: any) {
  try {
    const resposta = await api.post('/uploads', dados, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Image) erro', e);
  }
}

export async function getImageById(token: string, id: string) {
  try {
    const resposta = await api.get(`/uploads/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Image) erro', e);
  }
}

export async function deleteImage(token: string, id: string) {
  try {
    const resposta = await api.delete(`/uploads/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Image) erro', e);
  }
}

export async function editImage(
  token: string,
  id: string | undefined,
  dados: any
) {
  try {
    const resposta = await api.put(`/uploads/${id}`, dados, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.warn('(Image) erro', e);
  }
}

export async function getImageSocialMedia(token: string, id?: string) {
  try {
    let param = '';

    if (id != undefined && id != null && id != '') {
      param = id;
    } else {
      param = '453762c8-ea3e-4491-ac98-f21cfee00348';
    }

    const resp = await api.get(`/uploads/${param}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resp.data;
  } catch (error) {
    console.log(error);
  }
}
