import styled from 'styled-components';

export const Card = styled.div`
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
`;

export const Header = styled.header`
  background: linear-gradient(180deg, #2c3841 8.01%, #23526f 126.28%);
  border-radius: 6px 6px 0px 0px;
  padding: 20px 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  > h3 {
    color: ${props => props.theme.colors.white};
  }
`;

export const TitleStyled = styled.div`
  display: flex;
  flex-direction: row;

  & > div > h3,
  & > h5 {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
  }

  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 22px !important;
`;

export const Section = styled.section`
  padding: 20px 24px;
  display: flex;

  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;

  > img {
    margin-right: 15px;
  }

  > div {
    > h5 {
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #6e6b7b;
    }

    > input {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 21px !important;
      color: #2c3941 !important;
    }
  }
`;

export const ContentImg = styled.div`
  display: flex;

  > img {
    margin-right: 10px;
  }
`;

export const Container = styled.div``;

export const ImagePerson = styled.img`
  width: 60px;
  height: 60px;
`;

export const ImageAwards = styled.img`
  width: 192px;
  height: 108px;
`;

export const ImagePartners = styled.img`
  width: 292px;
  height: 108px;
`;

export const ImageOpen = styled.img`
  width: 192px;
  height: 141px;
`;

export const ContainerValues = styled.div`
  width: 270px;
  height: 116px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageValues = styled.img`
  width: 80px;
  height: 80px;
`;

export const ImageB3 = styled.img`
  width: 193px;
  height: 141px;
`;

export const NoStyle = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  strong,
  b {
    font-family: 'Montserrat', sans-serif !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 15px !important;
    line-height: 24px !important;
    color: #6e6b7b !important;
  }
`;

export const ImageIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ImageT1 = styled.img`
  width: 239px;
  height: 106px;
`;

export const IconeFile = styled.img`
  width: 25px;
  height: 25px;
`;

export const CardFile = styled.div`
  width: 239px;
  height: 170px;
`;

export const Line1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Col1 = styled.div``;
export const Col2 = styled.div`
  width: 200px;
`;
export const Col3 = styled.div`
  width: 52px;
`;
export const ImageFinance = styled.img`
  width: 199px;
  height: 51px;
`;

export const DivBtns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 370px;
`;

export const DivBtn1 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: ${props => props.theme.colors.secondary};
  color: #fff;
`;

export const DivBtn2 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: transparent;
  border: 1px solid ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.secondary};
`;

export const DivBtn3 = styled.button`
  padding: 8px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: #ea5455;
`;

export const ContainerBtn3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
