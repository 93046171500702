import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import './styles.css';
import { GOOGLE_MAPS_API_KEY } from '../../constants/maps';

const containerStyle = {
  width: '100%',
  height: '100%'
};

// Coordenas do Brasil
const center = {
  lat: -8.229130937218047,
  lng: -53.46824631232607
};

interface MapSearchProps {
  onPositionSelected?(data: { lat: number; lng: number }): void;
  selectedPosition?: { lat: number; lng: number };
  handleMapLoad?: (map: google.maps.Map) => void;
}

const MapSearch: React.FC<MapSearchProps> = ({
  onPositionSelected,
  selectedPosition,
  handleMapLoad
}) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: 'pt-br',
    libraries: ['places']
  });

  const [currentPosition, setCurrentPosition] = useState<any>(null);

  function onMapClickHandler({ latLng }: any) {
    const { lat, lng } = latLng;
    const _localization = { lat: lat(), lng: lng() };
    onPositionSelected?.(_localization);
  }

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          setCurrentPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude
          });
        },
        err => {}
      );
    } else {
      alert('Sorry, your browser does not support HTML5 geolocation.');
    }
  }, []);

  if (!isLoaded) return null;

  return (
    <div
      id="map"
      style={{
        height: '386px',
        width: '100%',
        margin: '30px 0',
        backgroundColor: 'transparent'
      }}
    >
      <GoogleMap
        onLoad={handleMapLoad}
        mapContainerStyle={containerStyle}
        center={
          selectedPosition?.lat ? selectedPosition : currentPosition || center
        }
        options={{ disableDefaultUI: true }}
        zoom={selectedPosition?.lat || currentPosition ? 17 : 4}
      >
        {selectedPosition && (
          <Marker
            position={selectedPosition}
            draggable
            onDragEnd={onMapClickHandler}
            icon="/assets/img/ic_pin.png"
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default MapSearch;
