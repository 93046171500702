export class PostEndpoint {
  public static posts = '/posts';
  public static postById = (id: string) => `/posts/${id}`;
  public static postBySlug = (slug: string) => `/posts/slug/${slug}`;
}

export class DashboardEndpoint {
  public static statistics = '/statistics/v2';
  public static annualStatistics = '/annual-statistics/v2';
}

export class OccurrenceEndpoint {
  public static occurrenceById = (id: string) => `/occurrences/cms/v2/${id}`;
  public static createOccurrence = '/occurrences/cms/v2';

  public static sources = '/sources';
  public static reasons = '/reasons';
  public static clippings = '/clippings';
  public static transports = '/transports';

  public static qualifications = '/qualifications';
  public static ageGroups = '/age-group';
  public static genres = '/genres';
  public static races = '/victims-enums/race-type';
  public static places = '/places';
  public static circumstances = '/circumstances';

  public static status = '/status';
  public static positions = '/positions';
  public static coorporations = '/coorporations';
  public static parties = '/parties';

  public static animals = '/animals';
}

export class AdressSharedEndpoint {
  public static regions = '/regions';
  public static states = '/states';
  public static cities = '/cities';
  public static neighborhoods = '/neighborhoods';

  public static citiesByStateId = (id: string) => `/cities/state/${id}`;
  public static neighborhoodsByCityId = (id: string) =>
    `/neighborhoods/city/${id}`;
  public static subNeighborhoodsByCityId = (id: string) =>
    `/subneighborhoods/city/${id}`;
  public static localitiesByCityId = (id: string) => `/localities/city/${id}`;
}
