import { CityModel } from '../../../../../shared/domain/models/CityModel';
import { NeighborhoodModel } from '../../../../../shared/domain/models/NeighborhoodModel';
import { RegionModel } from '../../../../../shared/domain/models/RegionModel';
import { IRequestStatus } from '../../../../../shared/interface/requestStatus';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import {
  AnnualStatisticsModel,
  StatisticsModel
} from '../../models/DashboardModel';

export default class StatisticsState {
  regionsRequestStatus: IRequestStatus<RegionModel[]>;
  citiesRequestStatus: IRequestStatus<CityModel[]>;
  neighborhoodsRequestStatus: IRequestStatus<NeighborhoodModel[]>;
  statisticsRequestStatus: IRequestStatus<StatisticsModel>;
  annualStatisticsRequestStatus: IRequestStatus<AnnualStatisticsModel>;
  statisticsQueryParams: StatisticQueryParams;
  annualStatisticsQueryParams: AnnualStatisticQueryParams;

  constructor(
    regionsRequestStatus: IRequestStatus<RegionModel[]> = RequestStatus.idle(),
    citiesRequestStatus: IRequestStatus<CityModel[]> = RequestStatus.idle(),
    neighborhoodsRequestStatus: IRequestStatus<
      NeighborhoodModel[]
    > = RequestStatus.idle(),
    statisticsRequestStatus: IRequestStatus<StatisticsModel> = RequestStatus.idle(),
    annualStatisticsRequestStatus: IRequestStatus<AnnualStatisticsModel> = RequestStatus.idle(),
    statisticsQueryParams: StatisticQueryParams = new StatisticQueryParams(),
    annualStatisticsQueryParams: AnnualStatisticQueryParams = new AnnualStatisticQueryParams()
  ) {
    this.regionsRequestStatus = regionsRequestStatus;
    this.citiesRequestStatus = citiesRequestStatus;
    this.neighborhoodsRequestStatus = neighborhoodsRequestStatus;
    this.statisticsRequestStatus = statisticsRequestStatus;
    this.annualStatisticsRequestStatus = annualStatisticsRequestStatus;
    this.statisticsQueryParams = statisticsQueryParams;
    this.annualStatisticsQueryParams = annualStatisticsQueryParams;
  }
}

export class StatisticQueryParams {
  initialdate: string = `${new Date().getFullYear()}-01-01`;
  finaldate: string = new Date().toISOString().slice(0, 10);
  region: string = '';
  cities: { value: string; label: string }[] = [];
  neighborhoods: { value: string; label: string; cityId: string }[] = [];

  constructor(data?: Partial<StatisticQueryParams>) {
    Object.assign(this, data);
  }
}

export class AnnualStatisticQueryParams {
  year: string = new Date().getFullYear().toString();

  constructor(data?: Partial<StatisticQueryParams>) {
    Object.assign(this, data);
  }
}
