import { NeighborhoodModel } from '../../domain/models/NeighborhoodModel';
import { BasicEntity } from './BasicEntity';
import CityEntity from './CityEntity';

export default class NeighborhoodEntity extends BasicEntity {
  city: CityEntity = new CityEntity();

  constructor(data?: Partial<NeighborhoodEntity>) {
    super(data);
    this.city = new CityEntity(data?.city);
  }

  toDomain(): NeighborhoodModel {
    return new NeighborhoodModel({
      id: this.id,
      name: this.name,
      city: this.city.toDomain()
    });
  }
}
