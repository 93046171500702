import styled from 'styled-components';

export const Container = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px 22px;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  width: 80%;
  height: 50px;
  background-color: ${props => props.theme.colors.white};

  > label {
    cursor: pointer;
    margin-right: 12px;
  }

  > input {
    border: none;
    outline: none;
    width: 100%;
  }
`;
