import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  padding: 16px;
`;

export const HeaderNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h4`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  color: #2c3941;
`;

export const HeaderButton = styled.button`
  padding: 12px;
  color: #fff;
  background: ${props => props.theme.colors.secondary};
  border-radius: 6px;
  text-align: center;
  border: none;
`;

export const CenterContainer = styled.div``;

export const CardWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
