import React, { useEffect, useState } from 'react';
import { Container, Fieldset, Form } from './index.style';
import { Modal, Offcanvas } from 'react-bootstrap';
import { InterfaceProps } from './index.types';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { api, getRegions, getStates, getUsers } from '../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_REGIONS,
  ADD_STATES,
  ADD_USERS,
  RootState
} from '../../../../store';
import { ModalSucesso, SwitchButtonSecondary } from '../../../../components';

const ModalCriar: React.FC<InterfaceProps> = ({ isModal, onHide }) => {
  const dispatch = useDispatch();
  const [isModalErrorEmail, setModalErrorEmail] = useState(false);
  const [isModalSucessoEmail, setModalSucessoEmail] = useState(false);
  const { token, regions, states } = useSelector(
    (state: RootState) => state.clickState
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    setError,
    watch
  } = useForm({
    defaultValues: {
      role: 'Selecione',
      name: '',
      email: '',
      phone_number: '',
      password: '',
      active: true,
      state: 'Selecione',
      password1: '',
      region: ''
    }
  });

  const onSubmit = (dados: any) => {
    console.log(dados);

    let aux = {
      role: dados.role,
      name: dados.name,
      email: dados.email,
      phone_number: dados.phone_number,
      password: dados.password,
      active: dados.active,
      state: dados.state
    };

    api
      .post('/signup', aux)
      .catch(erro => {
        console.warn('(Usuário) Erro:', erro.response.data);

        erro.response.data.message.forEach((chave: any, valor: any) => {
          if (chave === 'conta de e-mail já cadastrada') {
            onHide();
            setModalErrorEmail(true);
          }
        });
      })
      .then(resposta => {
        if (resposta != null) {
          getUsers(token, 1, 30).then(resp => {
            dispatch({ type: ADD_USERS, users: resp.data });
          });
          onHide();
          setModalSucessoEmail(true);
        } else {
          console.warn('rrr', resposta);
        }
      });
  };

  useEffect(() => {
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
    getRegions(token).then(resp => {
      dispatch({ type: ADD_REGIONS, regions: resp });
    });
  }, []);

  let password = watch('password');

  return (
    <>
      <Offcanvas show={isModal} onHide={onHide} placement="end">
        <Container>
          <header>
            <h3>Adicionar moderador</h3>
          </header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <label>Tipo de acesso</label>
              <select {...register('role', { required: true })}>
                <option>Selecione</option>
                <option value="Administrador">Administrador</option>
                <option value="Coordenador">Coordenador</option>
                <option value="Regional">Regional</option>
                <option value="Mobile">Mobile</option>
              </select>
              {errors.role && (
                <p style={{ color: 'red' }}> Selecione um tipo de acesso </p>
              )}
            </Fieldset>

            <Fieldset>
              <label>Nome</label>
              <input
                type="text"
                placeholder="Digite o nome do moderador"
                {...register('name', { required: true })}
              />
              {errors.name && (
                <p style={{ color: 'red' }}> Digite o nome do moderador</p>
              )}
            </Fieldset>

            <Fieldset>
              <label>E-mail</label>
              <input
                type="text"
                placeholder="Digite o e-mail do moderador"
                {...register('email', { required: true })}
              />
              {errors.email && (
                <p style={{ color: 'red' }}> Digite o e-mail do moderador</p>
              )}
            </Fieldset>

            <Fieldset>
              <label>Telefone</label>
              <InputMask
                type="text"
                style={{ width: '100%' }}
                mask={'(99) 99999-9999'}
                placeholder="Digite o telefone do moderador"
                {...register('phone_number', {
                  required: true,
                  pattern: /\(\d{2}\)\s\d{5}\-\d{4}/i
                })}
              />
              {errors.phone_number && (
                <p style={{ color: 'red' }}> Digite o telefone do moderador</p>
              )}
            </Fieldset>

            <Fieldset>
              <label>Estado</label>
              <select
                {...register('region', {
                  onChange: e => {
                    console.log(e.target.value);
                    states.forEach(estado => {
                      if (e.target.value == estado.name) {
                        setValue(`state`, estado.id);
                      }
                    });
                  }
                })}
              >
                <option>Selecione</option>
                {regions?.map((chave: any, valor: any) => {
                  if (chave.enabled) {
                    return <option value={chave.state}>{chave.state}</option>;
                  }
                })}
              </select>
              {errors.state && (
                <p style={{ color: 'red' }}>
                  Selecione um estado para o moderador
                </p>
              )}
            </Fieldset>

            <Fieldset>
              <label>Senha para acesso</label>
              <input
                type="password"
                placeholder="Digite a senha do moderador"
                {...register('password', { required: true })}
              />
            </Fieldset>

            <Fieldset>
              <label>Confirmar as senhas</label>
              <input
                type="password"
                placeholder="Redigite a senha do moderador"
                {...register('password1', {
                  required: true,
                  onChange: (e: any) => {
                    let valor = e.target.value;
                    if (valor != password) {
                      setError('password1', {
                        type: 'value',
                        message: 'Senhas não combinam'
                      });
                    } else {
                      setError('password1', { type: 'value', message: '' });
                    }
                  }
                })}
              />
              {errors.password1 && (
                <p style={{ color: 'red' }}> {errors.password1.message}</p>
              )}
            </Fieldset>

            <Fieldset>
              <label>Status</label>
              <div>
                <p>Inativo</p>
                <SwitchButtonSecondary
                  onClick={e => {
                    console.log('active', !e);
                    setValue('active', !e);
                  }}
                  checked={getValues('active')}
                />
                <p>Ativo</p>
              </div>
              {errors.active && (
                <p style={{ color: 'red' }}> Selecione o status do moderador</p>
              )}
            </Fieldset>

            <footer>
              <button
                onClick={() => {
                  onHide();
                  reset({
                    role: 'Selecione',
                    name: '',
                    email: '',
                    phone_number: '',
                    password: '',
                    active: true,
                    state: 'Selecione'
                  });
                }}
              >
                Cancelar
              </button>
              <button type="submit">Finalizar</button>
            </footer>
          </Form>
        </Container>
      </Offcanvas>

      <Modal
        show={isModalErrorEmail}
        onHide={() => {
          setModalErrorEmail(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h1>E-mail já cadastrado!!!!</h1>
          <p>Conta de e-mail já cadastrada!!!</p>
        </Modal.Body>
      </Modal>

      <ModalSucesso
        isModal={isModalSucessoEmail}
        onHide={() => {
          setModalSucessoEmail(false);
          reset();
        }}
        title="Moderador adicionado"
        description="O moderador foi adicionado com sucesso."
      />
    </>
  );
};

export default ModalCriar;
