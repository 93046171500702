import moment from 'moment';
import { api } from '../index';

export async function getReports(
  token?: string,
  order?: string,
  page?: string,
  take?: string,
  region?: string,
  search?: string
) {
  try {
    let params = new URLSearchParams();
    if (order != undefined && order != '') {
      params.append('order', order);
    }
    if (page != undefined && page != '') {
      params.append('page', page);
    } else {
      params.append('page', '1');
    }
    if (take != undefined && take != '') {
      params.append('take', '50');
    }
    if (region != undefined && region != '') {
      params.append('region', region);
    }
    if (search != undefined && region != '') {
      params.append('search', search);
    }

    const resp = await api.get(`/reports`, {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    });

    return resp.data;
  } catch (error) {
    console.log(error);
  }
}

export async function getReportsById(token: string, id?: string) {
  try {
    const resposta = await api.get(`/reports/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(reports) Erro: ', erro);
    return null;
  }
}

export async function getReportsBySlug(token: string, slug?: string) {
  try {
    const resposta = await api.get(`/reports/slug/${slug}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(reports) Erro: ', erro);
    return null;
  }
}

export async function createReports(token: string, dados: any) {
  try {
    const resposta = await api.post(`/reports`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(reports) Erro: ', erro);
    return null;
  }
}

export async function deleteReports(token: string, id: string) {
  try {
    const resposta = await api.delete(`/reports/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(reports) Erro: ', erro);
    return null;
  }
}

export async function editReports(token: string, id: string, dados: any) {
  try {
    const resposta = await api.put(`/reports/${id}`, dados, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(reports) Erro: ', erro);
    return null;
  }
}
