import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  min-height: 100vh;
  padding: 20px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const Nav = styled.nav``;

export const NewAdmin = styled.button`
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.secondary};
  border-radius: 6px;
  border: none;
  padding: 10px 26px;
`;

export const ContainerBoxes = styled.div`
  width: 100%;
`;

export const Box = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 20px;
  gap: 20px;
`;

export const Card = styled.div`
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 16px;
  min-width: 165px;

  > img {
    margin-left: 40px;
  }
`;

export const Number = styled.h3``;

export const Image = styled.img`
  width: 50px;
  height: 50px;
`;

export const Region = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #6e6b7b;
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const Search = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 18px 22px;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  width: 100%;
  height: 50px;
  background-color: ${props => props.theme.colors.white};

  > label {
    cursor: pointer;
    margin-right: 12px;
  }

  > input {
    border: none;
    outline: none;
    width: 100%;
  }
`;

export const Select = styled.select`
  background: #ffffff;
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 5px;
  resize: none;
  outline: none;
  display: block;
  width: 30%;
  height: 38px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 100px;
`;

export const ContainerBtn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 40px;
`;

export const PrevNext = styled.button<{ to: string; disabled: boolean }>`
  background-color: transparent;
  cursor: pointer;
  border: none;
  opacity: ${props => (props.disabled ? 0.3 : 1)};

  > img {
    width: 36px;
    height: 36px;
  }

  > img {
    ${props => {
      if (props.to == 'next') {
        return `
          transform: rotate(0deg);
        `;
      } else if (props.to == 'prev') {
        return `
          transform: rotate(190deg);
        `;
      }
    }}
  }
`;

export const Page = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  background: none;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.black};
`;

export const AtualPage = styled.button`
  width: 36px;
  height: 36px;
  border: none;
  background: ${props => props.theme.colors.secondary};
  border-radius: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.colors.white};
`;
