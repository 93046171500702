import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Badge, Card, ContainerBtn, Content, Title } from './index.styled';
import { contentType, IProps } from './index.types';
import moment from 'moment';
import { dateSvg, editicon, fileSvg, trashRed } from '../../../../../../assets';
import { Delete } from '../../..';
import ModalEditAcademicResearch from '../../../Edit/AcademicResearch';

const AcademicResearch: React.FC<IProps> = ({ content }) => {
  function converData(data: any) {
    return moment(data).format('DD/MM/YYYY');
  }

  const [isModalApagar, setModalApagar] = useState(false);
  const [isModalEditar, setModalEditar] = useState(false);
  const [contentSend, setContentSend] = useState<contentType>({
    id: '',
    publication_date: '',
    title: '',
    link: '',
    theme: '',
    indicators_fc: '',
    sub_identifiers_fc: '',
    keyword: '',
    media_type: '',
    vehicle_name: '',
    program_or_section: '',
    access_type: '',
    scope: '',
    continent: '',
    country: '',
    state: '',
    city: '',
    place: '',
    emphasis: '',
    spokesman: '',
    exclusive: false,
    hook: '',
    digital: false,
    type_of_contact: '',
    contact_channel: '',
    bait: '',
    axis_fc: '',
    year: 0,
    federative_power: '',
    document_type: '',
    status: '',
    document_details: '',
    subject_area: '',
    public_reference: '',
    contact: '',
    fc_influence: '',
    type_material: '',
    field_knowledge: '',
    associated_theme_fc: '',
    inputs_used: '',
    author: '',
    institutional_binding: '',
    magazine_name: '',
    type: '',
    createdAt: '',
    updatedAt: ''
  });

  return (
    <>
      <Card>
        <Content>
          <section>
            <div>
              {content.state != '' ? (
                <Badge>{content.state}</Badge>
              ) : (
                <span style={{ display: 'none' }} />
              )}
              {content.access_type != '' ? (
                <Badge>{content.access_type}</Badge>
              ) : (
                <span style={{ display: 'none' }} />
              )}
              <Badge>{content.digital ? 'Digital' : 'Não digital'}</Badge>
            </div>
            <Title>{content.title}</Title>
          </section>
          <div>
            <div>
              <img src={fileSvg} />
              <p>
                {' '}
                por <strong>{content.vehicle_name}</strong>
              </p>
            </div>
            <div>
              <img src={dateSvg} />
              <p>{converData(content.publication_date)}</p>
            </div>
          </div>
          <ContainerBtn>
            <button
              onClick={() => {
                setContentSend(content);
                setModalEditar(true);
              }}
            >
              <img src={editicon} alt="" />
              Editar
            </button>
            <button
              onClick={() => {
                setModalApagar(true);
                setContentSend(content);
              }}
            >
              <img src={trashRed} alt="" />
              Excluir
            </button>
          </ContainerBtn>
        </Content>
      </Card>

      <Delete
        isModal={isModalApagar}
        onHide={() => setModalApagar(false)}
        content={contentSend}
      />

      <ModalEditAcademicResearch
        isModal={isModalEditar}
        onHide={() => setModalEditar(false)}
        content={contentSend}
      />
    </>
  );
};

export default AcademicResearch;
