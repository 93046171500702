import styled from 'styled-components';

export const Container = styled.header`
  background: #f3f2f7 !important;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin-bottom: 24px;

  > div {
    background: #f3f2f7 !important;
  }
`;

export const Title = styled.h6`
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5e5873;
  margin-right: 48px;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const DeleteButton = styled.button`
  color: red;
  background: transparent;
  border: none;
`;
