import React, { useEffect, useState } from 'react';
import {
  Container,
  Header,
  Back,
  Content,
  Div,
  Section,
  Icon,
  ImageContent,
  DivImages,
  ImagesContent,
  DivImage,
  LinkStyled,
  ImageHeader,
  DivNAv,
  ButtonNav,
  DivNav,
  ButtonNAv,
  ImageTrash,
  ContainerCenter,
  ContentHTML,
  ButtonTop
} from './index.styled';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useParams } from 'react-router';

import paper from '../../../assets/paper.png';
import date from '../../../assets/calendar.png';
import learnMore from '../../../assets/learnMore.png';
import share from '../../../assets/share-dark.png';
import facebook from '../../../assets/facebook-dark.png';
import twitter from '../../../assets/twitter-dark.png';
import telegram from '../../../assets/telegram-dark.png';
import linkedin from '../../../assets/linkedin-dark.png';

import { getPostsById, convertData, getReportsById } from '../../../services';
import { Col, Row } from 'react-bootstrap';

import renderHTML from 'react-render-html';
import { downloadBlack, editicon, redTrash } from '../../../assets';
import { ModalEditar, ModalApagar } from '../Modals/index';
import { typeContent } from '../Modals/Edit/index.types';

const ReportById: React.FC = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.clickState);

  const [post, setPost] = useState<any>({});

  let { id } = useParams();

  useEffect(() => {
    getReportsById(token, id).then(resp => {
      setPost(resp);
    });
  }, [id]);

  useEffect(() => {
    console.log('Report:', post);
  }, [post]);

  const [isModalEditar, setModalEditar] = useState(false);
  const [isModalApagar, setModalApagar] = useState(false);
  const [conteudoDoPost, setConteudoDoPost] = useState<typeContent>({
    title: '',
    author: { id: '' },
    region: { id: '' },
    file: {
      id: '',
      file: ''
    },
    cover: {
      id: '',
      file: ''
    },
    file_width: '',
    file_name: '',
    thumbnail: {
      id: '',
      file: ''
    },
    date_post: '',
    authors: '',
    description: '',
    type: '',
    emphasis: true,
    items: [
      {
        group: 'bloco 1',
        group_items: [
          {
            image: {
              id: '',
              file: ''
            },
            description: '',
            content: ''
          }
        ],
        typeBlock: 'text',
        quantBlock: '1'
      }
    ]
  });

  return (
    <Container>
      <>
        <DivNAv>
          <LinkStyled to="/data">Voltar</LinkStyled>
          <div>
            <ButtonNav
              onClick={() => {
                setModalEditar(true);
                setConteudoDoPost(post);
              }}
            >
              <img src={editicon} alt="" />
              Editar post
            </ButtonNav>
            <ButtonNAv
              onClick={() => {
                setModalApagar(true);
                setConteudoDoPost(post);
              }}
            >
              <img src={redTrash} />
              Deletar postagem
            </ButtonNAv>
          </div>
        </DivNAv>

        <Header>
          <ImageHeader src={post.cover?.file} />

          {post.file_name != null && post.file_name != undefined && (
            <ButtonTop href={post.file?.file} target="_blank">
              <img src={downloadBlack} />
              Baixar Relatórios
            </ButtonTop>
          )}
        </Header>

        <Content>
          <p>{post.region?.state}</p>
          <h3>{post.title}</h3>
          <div>
            <Div>
              <div>
                <Icon src={paper} />
                <p>{post.authors}</p>
              </div>
              <div>
                <Icon src={date} />
                <p>{convertData(post.createdAt)}</p>
              </div>
            </Div>
            <div>
              <a href="">
                {' '}
                <img src={share} alt="" />{' '}
              </a>
              <a href="">
                {' '}
                <img src={facebook} alt="" />{' '}
              </a>
              <a href="">
                {' '}
                <img src={twitter} alt="" />{' '}
              </a>
              <a href="">
                {' '}
                <img src={telegram} alt="" />{' '}
              </a>
              <a href="">
                {' '}
                <img src={linkedin} alt="" />{' '}
              </a>
            </div>
          </div>
        </Content>

        <ContainerCenter>
          <div>
            {post.items?.map((item: any) => {
              if (item.group == 'bloco 1') {
                return (
                  <Row>
                    {item?.group_items?.map((chave: any) => {
                      return (
                        <Col xs={12}>
                          {chave?.content != undefined && chave?.content != '' && (
                            <>
                              <ContentHTML>
                                {renderHTML(chave?.content)}
                              </ContentHTML>
                            </>
                          )}
                          {chave?.description != undefined &&
                            chave?.description != '' && (
                              <DivImage>
                                <ImageContent src={chave?.image.file} />
                                <p>{chave?.description}</p>
                              </DivImage>
                            )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              } else {
                return (
                  <Row>
                    {item?.group_items?.map((chave?: any) => {
                      return (
                        <Col xs={6}>
                          {chave?.content != undefined && chave?.content != '' && (
                            <>
                              <ContentHTML>
                                {renderHTML(chave?.content)}
                              </ContentHTML>
                            </>
                          )}
                          {chave?.description != undefined &&
                            chave?.description != '' && (
                              <DivImages>
                                <ImagesContent src={chave?.image.file} />
                                <p>{chave?.description}</p>
                              </DivImages>
                            )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              }
            })}
          </div>
        </ContainerCenter>
      </>
      {/* 
                    <ModalEditar
                        isModal={isModalEditar}
                        onHide={()=>setModalEditar(false)}      
                        setPost={setPost}
                        conteudo={conteudoDoPost} 
                    />  */}

      <ModalApagar
        isModal={isModalApagar}
        onHide={() => setModalApagar(false)}
        conteudo={conteudoDoPost}
      />

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setModalEditar(false)}
        conteudoDoPost={conteudoDoPost}
        setPost={setPost}
      />
    </Container>
  );
};

export default ReportById;
