import styled, { css } from 'styled-components';
import { ILabel } from './index.types';

export const Group = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Label = styled.label<ILabel>`
    ${props =>
      props.checked
        ? css`
            background-color: ${props.color};
            color: #fff !important;
            padding: 1px 9px;
            border-radius: 17px;
          `
        : css`
            background-color: #cbcbcb;
            color: #6e6b7b !important;
            padding: 1px 9px;
            border-radius: 17px;
          `}}
`;
