import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { QualificationModel } from '../../domain/models/QualificationModel';

export enum QualificationType {
  NOTYPE = '',
  SERVICE = 'Servico',
  AGENT = 'Agente',
  POLITICAL = 'Politico'
}

export class QualificationEntity extends BasicEntity {
  type: QualificationType;

  constructor(data?: Partial<QualificationEntity>) {
    super(data);
    this.type = data?.type || QualificationType.NOTYPE;
  }

  toDomain(): QualificationModel {
    return new QualificationModel({
      id: this.id,
      name: this.name,
      type: this.type
    });
  }
}
