export type FormData = {
  id?: string;
  name: string;
  type: string;
  city: string;
};

export const DefaultFormData = {
  id: '',
  name: '',
  type: '',
  city: ''
};

export enum Variable {
  UNKNOWN,
  REASON,
  CLIPPINGS,
  TRANSPORTS,
  PLACES,
  COORPORATIONS,
  ANIMALS,
  CIRCUMSTANCE,
  POSITIONS,
  QUALIFICATIONS,
  NEIGHBORHOODS,
  SUBNEIGHBORHOODS,
  LOCALITIES,
  PARTIES
}

export enum ActionType {
  EDIT,
  ADD,
  DELETE,
  UNKNOWN
}
