import React, { useState } from 'react';
import { Radio, RadioLabel } from './index.style';

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  labelText?: string;
}

const RadioButon: React.FC<IProps> = ({ labelText, ...props }) => {
  return (
    <Radio>
      <input {...props} type="radio" />
      <RadioLabel>{labelText}</RadioLabel>
    </Radio>
  );
};

export default RadioButon;
