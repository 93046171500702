import React, { useContext, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ADD_LOGIN_USER, ADD_TOKEN } from './store';
import RoutesParams from './routes';
import {} from './components/index';
import { getCookie } from './services';
import { AppContext } from './shared/providers/AppProvider';
import { OccurrenceProvider } from './pages/RefactorOccurrences/providers/OccurrenceProvider';
import { DashboardProvider } from './pages/Dashboard/providers/DashboardProvider';
import { BlogProvider } from './pages/Blog/providers/BlogProvider';
import { logoDark } from './assets';
import { LoadingImageStyled, LoadingImageWrapper } from './index.styled';

const App: React.FC = ({}) => {
  const { appUsecase, apiRepository } = useContext(AppContext);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(true);

  async function refreshToken() {
    let token = getCookie();

    if (!!token) {
      let resp = await apiRepository.refreshToken(token);
      resp.handle({
        onSuccess(data) {
          appUsecase.setToken(data?.token);
          dispatch({ type: ADD_LOGIN_USER, user: data.user });
          dispatch({ type: ADD_TOKEN, token: data.token });
        },
        onFailure(_) {
          appUsecase.setToken('');
          dispatch({ type: ADD_LOGIN_USER, user: {} });
          dispatch({ type: ADD_TOKEN, token: '' });
        }
      });
    }

    setLoading(false);
  }

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingImageWrapper>
          <LoadingImageStyled src={logoDark} />
        </LoadingImageWrapper>
      ) : (
        <OccurrenceProvider>
          <DashboardProvider>
            <BlogProvider>
              <BrowserRouter>
                <RoutesParams />
              </BrowserRouter>
            </BlogProvider>
          </DashboardProvider>
        </OccurrenceProvider>
      )}
    </>
  );
};

export default App;
