import { createContext, useContext, useState } from 'react';
import { AppContext } from '../../../shared/providers/AppProvider';
import { PostRepositoryImpl } from '../data/repositories_impl/PostRepositoryImpl';
import { PostRepository } from '../domain/repositories/PostRepository';
import PostActionsState from '../domain/usecases/post_actions/PostActionsState';
import PostActionsUsecase from '../domain/usecases/post_actions/PostActionsUsecase';
import PostDetailsState from '../domain/usecases/post_details/PostDetailsState';
import PostDetailsUsecase from '../domain/usecases/post_details/PostDetailsUsecase';
import PostListState from '../domain/usecases/post_list/PostListState';
import PostListUsecase from '../domain/usecases/post_list/PostListUsecase';
import usePrevious from '../../../shared/providers/usePrevious';

interface IBlogContext {
  postListState: PostListState;
  postListUsecase: PostListUsecase;
  postDetailsUsecase: PostDetailsUsecase;
  postDetailsState: PostDetailsState;
  postActionsUsecase: PostActionsUsecase;
  postActionsState: PostActionsState;
  previousPostActionsState: PostActionsState | undefined;
  postRepository: PostRepository;
}

export const BlogContext = createContext<IBlogContext>({} as IBlogContext);

export const BlogProvider = ({ children }: any) => {
  const { apiRepository, adressSharedRepository } = useContext(AppContext);

  const [postListState, setPostListState] = useState<PostListState>(
    new PostListState()
  );

  const [postDetailsState, setPostDetailsState] = useState<PostDetailsState>(
    new PostDetailsState()
  );
  const [postActionsState, setPostActionsState] = useState<PostActionsState>(
    new PostActionsState()
  );

  const previousPostActionsState = usePrevious(postActionsState);

  const postRepository = new PostRepositoryImpl(apiRepository);

  const postListUsecase: PostListUsecase = new PostListUsecase(
    postListState,
    setPostListState,
    postRepository,
    adressSharedRepository
  );

  const postDetailsUsecase: PostDetailsUsecase = new PostDetailsUsecase(
    postDetailsState,
    setPostDetailsState,
    postRepository,
    adressSharedRepository
  );

  const postActionsUsecase: PostActionsUsecase = new PostActionsUsecase(
    postActionsState,
    setPostActionsState,
    postRepository,
    adressSharedRepository
  );

  return (
    <BlogContext.Provider
      value={{
        postListState,
        postListUsecase,
        postDetailsUsecase,
        postDetailsState,
        postActionsUsecase,
        postActionsState,
        previousPostActionsState,
        postRepository
      }}
    >
      {children}
    </BlogContext.Provider>
  );
};
