import React from 'react';
import axios from 'axios';

let reportedErrors: string[] = [];

export async function reportErrorHandler({
  error,
  context,
  msgContext
}: {
  error: Error;
  context?: {
    reportLocation: {
      filePath: string;
      lineNumber: number;
      functionName: string;
    };
  };
  msgContext?: string;
}) {
  const projectId = process.env.REACT_APP_GOOGLE_APP_ID;
  const apiKey = process.env.REACT_APP_GOOGLE_WEB_API_KEY;
  const apiUrl = `https://clouderrorreporting.googleapis.com/v1beta1/projects/${projectId}/events:report?key=${apiKey}`;

  const errorMessage = msgContext
    ? `msgContext: ${msgContext}`
    : '' + `msg: ${error.message} | stack: ${error.stack}`;

  if (reportedErrors.includes(errorMessage)) {
    console.warn('Error already reported:', error);
    return;
  }

  const request = {
    serviceContext: {
      service: `fogo-cruzado-cms-${process.env.REACT_APP_ENV_FLAVOR?.toLowerCase()}`,
      version: '1.0.0'
    },
    message: errorMessage,
    context: context
  };

  try {
    await axios.post(apiUrl, request);
    reportedErrors.push(errorMessage);
  } catch (error) {
    console.error('Reporting: ', error);
  }
}

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    if (process.env.REACT_APP_ENV_FLAVOR === 'DEV') {
      console.error(error, errorInfo);
    } else {
      const context = {
        reportLocation: {
          filePath: errorInfo.componentStack,
          lineNumber: 0,
          functionName: error?.name || ''
        }
      };

      reportErrorHandler({
        error,
        context
      });
    }

    this.setState({ hasError: true });
  }

  render() {
    return this.props.children;
  }
}

function GlobalErrorHandler({ children }: Props) {
  return <ErrorBoundary>{children}</ErrorBoundary>;
}

export default GlobalErrorHandler;
