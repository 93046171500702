/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import {
  Container,
  DeleteButtonContainer,
  InputText,
  InputSelect,
  Header,
  Title
} from './styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { trashSvg } from '../../../../../../../assets';
import { IPropsVictimAnimal } from '../form-types';
import {
  Label,
  LabelContainer
} from '../../../../../../RadioText/index.styled';
import { Radio } from '@mui/material';
import CustomControllerSelect from '../../../../../../CustomControllerSelect';

const Animals: React.FC<IPropsVictimAnimal> = ({
  onDelete,
  idVictim,
  register,
  errors,
  watch,
  control,
  setValue
}) => {
  const { animals, circumstances } = useSelector(
    (state: RootState) => state.clickState
  );

  const [circunstancias, setCircunstancias] = useState<any>([]);

  useEffect(() => {
    console.log('circunmstances:', circumstances);
    if (circumstances != undefined || circumstances != null) {
      let aux: any[] = [];
      circumstances.forEach(id => {
        if (id.type == 'both' || id.type == 'animal') {
          aux.push(id);
        }
      });
      setCircunstancias(aux);
    }
  }, [circumstances]);

  return (
    <Container>
      <Header>
        <Title>Animal {idVictim + 1}#</Title>

        <input type="hidden" {...register(`victims_animal.${idVictim}.id`)} />
        <input type="hidden" {...register(`victims_animal.${idVictim}._id`)} />

        <div className="d-flex flex-row">
          <LabelContainer color="#FF9F43">
            <Radio
              checked={
                watch(`victims_animal.${idVictim}.situation`) === 'Wounded'
              }
              onChange={v =>
                setValue(
                  `victims_animal.${idVictim}.situation`,
                  v.target.value as any
                )
              }
              value={'Wounded'}
              name={'Wounded'}
              data-cy={`occurence-form-victims-animal-${idVictim}-situation-wounded`}
            />
            <Label
              checked={
                watch(`victims_animal.${idVictim}.situation`) === 'Wounded'
              }
              color={'#FF9F43'}
            >
              Ferido
            </Label>
          </LabelContainer>
          <LabelContainer color={'#EA5455'}>
            <Radio
              checked={watch(`victims_animal.${idVictim}.situation`) === 'Dead'}
              onChange={v =>
                setValue(
                  `victims_animal.${idVictim}.situation`,
                  v.target.value as any
                )
              }
              value={'Dead'}
              name={'Dead'}
              data-cy={`occurence-form-victims-animal-${idVictim}-situation-dead`}
            />
            <Label
              checked={watch(`victims_animal.${idVictim}.situation`) === 'Dead'}
              color={'#EA5455'}
            >
              Morto
            </Label>
          </LabelContainer>
        </div>

        {errors?.situation && (
          <p className="text-danger">{(errors?.situation as any)?.message}</p>
        )}

        <DeleteButtonContainer>
          <Button
            variant="light"
            style={{
              color: 'red',
              backgroundColor: 'transparent',
              border: 'none'
            }}
            onClick={onDelete}
          >
            Excluir Bloco
            <img src={trashSvg} style={{ marginLeft: '10px' }} />
          </Button>
        </DeleteButtonContainer>
      </Header>

      <Row>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Tipo de animal</Form.Label>
            <InputSelect
              {...register(`victims_animal.${idVictim}.animal_type`)}
              data-cy={`occurence-form-victims-animal-${idVictim}-animal-type`}
            >
              <option value="">Selecione</option>
              {animals?.map(item => (
                <option key={item.id} value={item.id}>
                  {item.type}
                </option>
              ))}
            </InputSelect>
            {errors?.animal_type && (
              <p className="text-danger">{errors?.animal_type?.message}</p>
            )}
          </Form.Group>
        </Col>

        <Col sm={3}>
          <Form.Group>
            <Form.Label>Nome</Form.Label>
            <InputText
              {...register(`victims_animal.${idVictim}.name`)}
              data-cy={`occurence-form-victims-animal-${idVictim}-name`}
            />
            {errors?.name && (
              <p className="text-danger">{errors?.name?.message}</p>
            )}
          </Form.Group>
        </Col>

        <Col sm={4}>
          <Form.Group>
            <Form.Label>Circunstância</Form.Label>

            <CustomControllerSelect
              control={control}
              name={`victims_animal.${idVictim}.circumstances`}
              options={circunstancias}
              classNamePrefix={`occurence-form-victims-animal-${idVictim}-circumstances`}
            />
            {errors?.circumstances && (
              <p className="text-danger">
                {(errors?.circumstances as any)?.message}
              </p>
            )}
          </Form.Group>
        </Col>
      </Row>

      {watch(`victims_animal.${idVictim}.situation`) === 'Dead' && (
        <Row>
          <Col sm={3}>
            <Form.Group>
              <Form.Label>Data da morte</Form.Label>
              <InputText
                type="datetime-local"
                {...register(`victims_animal.${idVictim}.death_date`)}
                data-cy={`occurence-form-victims-animal-${idVictim}-death-date`}
              />
            </Form.Group>
            {errors?.death_date && (
              <p className="text-error">{errors?.death_date?.message}</p>
            )}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Animals;
