import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  facebookIcon,
  whatsappIcon,
  twitterIcon,
  telegramIcon,
  instagramIcon,
  youtubeIcon,
  linkedinIcon,
  editIconOrange
} from '../../assets';
import { BlueComponent } from '../../components';
import { getSocialNetworks } from '../../services';
import { ADD_SOCIAL_NETWORKS, RootState } from '../../store';
import {
  Container,
  ImageIcon,
  ButtonNav,
  ButtonHeader,
  HeaderNav,
  ButtonHeader2,
  ContainerWrap
} from './index.style';
import { ModalCriar, ModalEditar, ModalEditarImagem } from './Modals';

const RedesSociais: React.FC = () => {
  const { token, social_networks } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getSocialNetworks(token).then(resp =>
      dispatch({ type: ADD_SOCIAL_NETWORKS, social_networks: resp })
    );
  }, []);

  useEffect(() => {
    console.log(social_networks, 'social networks');
  }, [social_networks]);

  const [isModalEditar, setIsModalEditar] = useState(false);
  const [isModalEditarImagem, setIsModalEditarImagem] = useState(false);
  const [isModalCriar, setIsModalCriar] = useState(false);
  const [contentEditar, setContentEditar] = useState({
    id: '',
    facebook: '',
    twitter: '',
    telegram: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    region: {
      id: '',
      region: '',
      state: '',
      enabled: false
    }
  });
  const [contentEditarImage, setContentEditarImage] = useState({
    id: '',
    facebook: '',
    twitter: '',
    telegram: '',
    instagram: '',
    youtube: '',
    linkedin: '',
    whatsapp: '',
    region: {
      id: '',
      region: '',
      state: '',
      enabled: false
    }
  });

  return (
    <Container>
      <ButtonNav>
        <HeaderNav>Redes sociais</HeaderNav>
        <div>
          <ButtonHeader2
            onClick={() => {
              setIsModalEditarImagem(true);
            }}
          >
            <img src={editIconOrange} alt="" />
            Editar imagem
          </ButtonHeader2>
          <ButtonHeader
            onClick={() => {
              setIsModalCriar(true);
            }}
          >
            Cadastrar redes sociais
          </ButtonHeader>
        </div>
      </ButtonNav>
      <ContainerWrap>
        {social_networks.map(chave => {
          return (
            <>
              <BlueComponent
                btnEdit={true}
                isModal={false}
                isShow={false}
                onHide={() => {}}
                onClickCancel={() => {}}
                onClickSave={() => {}}
                onClickEdit={() => {
                  setIsModalEditar(true);
                  setContentEditar(chave);
                }}
                titleComponent={
                  chave.region?.state == 'Geral'
                    ? 'Institucional'
                    : chave.region?.state
                }
                titleS="redes"
              >
                <Row>
                  <Col>
                    <a href={chave.facebook} target="_blank">
                      <ImageIcon src={facebookIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.twitter} target="_blank">
                      <ImageIcon src={twitterIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.telegram} target="_blank">
                      <ImageIcon src={telegramIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.instagram} target="_blank">
                      <ImageIcon src={instagramIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.youtube} target="_blank">
                      <ImageIcon src={youtubeIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.linkedin} target="_blank">
                      <ImageIcon src={linkedinIcon} />
                    </a>
                  </Col>
                  <Col>
                    <a href={chave.whatsapp} target="_blank">
                      <ImageIcon src={whatsappIcon} />
                    </a>
                  </Col>
                </Row>
              </BlueComponent>
              <br />
            </>
          );
        })}
      </ContainerWrap>

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setIsModalEditar(false)}
        content={contentEditar}
      />

      <ModalCriar
        isModal={isModalCriar}
        onHide={() => setIsModalCriar(false)}
      />

      <ModalEditarImagem
        isModal={isModalEditarImagem}
        onHide={() => setIsModalEditarImagem(false)}
      />
    </Container>
  );
};

export default RedesSociais;
