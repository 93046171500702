import { api } from '../index';

export async function getStates(token: string) {
  try {
    const resposta = await api.get('/states', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Estados) Erro:', erro);
    return null;
  }
}

export async function getRegions(token: string, params?: any) {
  try {
    const resposta = await api.get('/regions', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });
    let resp = null;
    if (resposta != null) {
      resp = resposta.data;
    } else {
      resp = resposta;
    }
    return resp;
  } catch (erro) {
    console.warn('(Regiões) Erro:', erro);
    return null;
  }
}

export async function getStatistics(token: string) {
  try {
    const resposta = await api.get('/statistics', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Estatisticas) Erro:', erro);
    return null;
  }
}

export async function getAnnualStatistics(
  token: string,
  initial_date: string,
  final_date: string
) {
  try {
    let params = new URLSearchParams();
    params.append('initialdate', initial_date);
    params.append('finaldate', final_date);

    const resposta = await api.get('/annual-statistics', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Estatisticas anuais) Erro: ', erro);
    return null;
  }
}

export async function getUsers(
  token: string,
  page?: number,
  take?: number,
  order?: string,
  name?: string,
  email?: string,
  role?: string[]
) {
  try {
    let params = new URLSearchParams();

    if (order != undefined && order != null && order != '') {
      params.append('order', order);
    } else {
      params.append('order', 'ASC');
    }

    if (page != undefined && page != null && page != 0) {
      params.append('page', page.toString());
    } else {
      params.append('page', '1');
    }

    if (take != undefined && take != null && take != 0) {
      params.append('take', take.toString());
    } else {
      params.append('take', '30');
    }

    if (name != undefined && name != null && name != '') {
      params.append('name', name);
    }

    if (email != undefined && email != null && email != '') {
      params.append('email', email);
    }

    if (role != undefined && role != null) {
      params.append('role', role.toString());
    }

    const response = await api.get('/users', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });

    return response.data;
  } catch (erro) {
    return null;
  }
}

export async function getVictims(token: string) {
  try {
    const resposta = await api.get('/victims', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Vitimas) Erro:', erro);
    return null;
  }
}

export async function getQuestions(token: string) {
  try {
    const resposta = await api.get('/questions', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Questões) Erro:', erro);
    return null;
  }
}

export async function getPolicies(token: string) {
  try {
    const resposta = await api.get('/policies', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Políticas) Erro:', erro);
    return null;
  }
}

export async function getSourcers(token: string) {
  try {
    const resposta = await api.get('/sources', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Fontes) Erro:', erro);
    return null;
  }
}

export async function getReasons(token: string) {
  try {
    const resposta = await api.get('/reasons', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Motívo) Erro:', erro);
    return null;
  }
}

export async function getClippings(token: string) {
  try {
    const resposta = await api.get('/clippings', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Recorte) Erro:', erro);
    return null;
  }
}

export async function getTransports(token: string) {
  try {
    const resposta = await api.get('/transports', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Transportes) Erro:', erro);
    return null;
  }
}

export async function getQualifications(token: string) {
  try {
    const resposta = await api.get('/qualifications', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Qualificações) Erro:', erro);
    return null;
  }
}

export async function getStatus(token: string) {
  try {
    const resposta = await api.get('/status', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Status) Erro:', erro);
    return null;
  }
}

export async function getCircumstances(token: string) {
  try {
    const resposta = await api.get('/circumstances', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Circuunstancias) Erro:', erro);
    return null;
  }
}

export async function getCities(token: string) {
  try {
    const resposta = await api.get('/cities', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Cidades) Erro:', erro);
    return null;
  }
}

export async function getNeighborhoods(token: string) {
  try {
    const resposta = await api.get('/neighborhoods', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Bairro) Erro:', erro);
    return null;
  }
}
export async function getSubNeighborhoods(token: string) {
  try {
    const resposta = await api.get('/subneighborhoods', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Bairro) Erro:', erro);
    return null;
  }
}

export async function getLocalities(token: string) {
  try {
    const resposta = await api.get('/localities', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Bairro) Erro:', erro);
    return null;
  }
}

export async function getAgeGroup(token: string) {
  try {
    const resposta = await api.get('/age-group', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Faixa etária) Erro:', erro);
    return null;
  }
}

export async function getGenres(token: string) {
  try {
    const resposta = await api.get('/genres', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Generos) Erro:', erro);
    return null;
  }
}

export async function getPlaces(token: string) {
  try {
    const resposta = await api.get('/places', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Lugar/Local) Erro:', erro);
    return null;
  }
}

export async function getPositions(token: string) {
  try {
    const resposta = await api.get('/positions', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Posições) Erro:', erro);
    return null;
  }
}

export async function getCoorporations(token: string) {
  try {
    const resposta = await api.get('/coorporations', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Corporações) Erro:', erro);
    return null;
  }
}

export async function getAnimals(token: string) {
  try {
    const resposta = await api.get('/animals', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Animais) Erro:', erro);
    return null;
  }
}

export async function getSocialNetworks(token: string) {
  try {
    const resposta = await api.get('/social-networks', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Redes sociais) Erro:', erro);
    return null;
  }
}

export async function getParties(token: string) {
  try {
    const resposta = await api.get('/parties', {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Parties) Erro:', erro);
    return null;
  }
}
