import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row, Tab, Tabs } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  BlueComponent,
  ModalSucesso,
  RadioButton
} from '../../../../../components';
import {
  createArticles,
  getArticles,
  getCities,
  getStates
} from '../../../../../services';
import {
  ADD_ARTICLES,
  ADD_CITIES,
  ADD_STATES,
  RootState
} from '../../../../../store';
import { Container } from './index.style';
import { IProps, PropI } from './index.types';

const FormType: React.FC<PropI> = ({ index, setValue, getValues }) => {
  useEffect(() => {
    if (getValues(index) == 'Livre') {
      setChecked(true);
      setNotChecked(false);
    } else {
      setChecked(false);
      setNotChecked(true);
    }
  }, []);

  const [isChecked, setChecked] = useState(false);
  const [isNotChecked, setNotChecked] = useState(false);

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="formType">
              <input
                type="radio"
                id="formType"
                name="formType"
                value="Livre"
                checked={isChecked == true ? true : false}
                onChange={() => {
                  setValue(index, 'Livre');
                  setChecked(true);
                  setNotChecked(false);
                }}
              />
              {' Livre'}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="formType">
              <input
                type="radio"
                id="formType"
                name="formType"
                value="Assinatura"
                checked={isNotChecked == true ? true : false}
                onChange={() => {
                  setValue(index, 'Assinatura');
                  setChecked(false);
                  setNotChecked(true);
                }}
              />
              {' Assinatura'}
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const Exclusive: React.FC<PropI> = ({ index, setValue, getValues }) => {
  useEffect(() => {
    if (getValues(index) == true) {
      setChecked(true);
      setNotChecked(false);
    } else {
      setChecked(false);
      setNotChecked(true);
    }
  }, []);

  const [isChecked, setChecked] = useState(false);
  const [isNotChecked, setNotChecked] = useState(false);

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="exclusive">
              <input
                type="radio"
                id="exclusive"
                name="exclusive"
                value="true"
                checked={isChecked == true ? true : false}
                onChange={() => {
                  setValue(index, true);
                  setChecked(true);
                  setNotChecked(false);
                }}
              />
              {' Sim'}
            </Form.Label>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="exclusive">
              <input
                type="radio"
                id="exclusive"
                name="exclusive"
                value="false"
                checked={isNotChecked == true ? true : false}
                onChange={() => {
                  setValue(index, false);
                  setChecked(false);
                  setNotChecked(true);
                }}
              />
              {' Não'}
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const Digital: React.FC<PropI> = ({ index, setValue, getValues }) => {
  useEffect(() => {
    if (getValues(index) == true) {
      setChecked(true);
    } else {
      setChecked(false);
    }
    console.log(getValues(index));
  }, []);

  const [isChecked, setChecked] = useState(false);

  return (
    <>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label htmlFor="digital">
              {'Matéria não digital '}
              <input
                type="checkbox"
                id="digital"
                name="digital"
                checked={isChecked == true ? true : false}
                onChange={() => {
                  setValue(index, !isChecked);
                  setChecked(!isChecked);
                }}
              />
              {' Materia digital'}
            </Form.Label>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

const ModalCreatePress: React.FC<IProps> = ({ isModal, onHide }) => {
  const refBtn = useRef<any>();

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    defaultValues: {
      publication_date: '',
      title: '',
      link: '',
      theme: '',
      indicators_fc: '',
      sub_identifiers_fc: '',
      keyword: '',
      media_type: '',
      vehicle_name: '',
      program_or_section: '',
      access_type: 'Livre',
      scope: '',
      continent: '',
      country: '',
      state: '',
      city: '',
      place: '',
      emphasis: '',
      spokesman: '',
      exclusive: true,
      hook: '',
      digital: true,
      type_of_contact: '',
      contact_channel: '',
      bait: '',
      axis_fc: '',
      year: 2022,
      federative_power: '',
      document_type: '',
      status: '',
      document_details: '',
      subject_area: '',
      public_reference: '',
      contact: '',
      fc_influence: '',
      type_material: '',
      field_knowledge: '',
      associated_theme_fc: '',
      inputs_used: '',
      author: '',
      institutional_binding: '',
      magazine_name: '',
      type: 'Imprensa'
    }
  });

  const [isVisible, setVisible] = useState(false);

  function onSubmit(dados: any) {
    let aux = {
      ...dados
    };

    console.log(aux);

    createArticles(token, aux).then(r => {
      getArticles(token).then(resp => {
        dispatch({ type: ADD_ARTICLES, articles: resp.data });
      });
      onHide();
      setVisible(true);
    });
  }

  const { token, states, cities } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
    getCities(token).then(resp => {
      dispatch({ type: ADD_CITIES, cities: resp });
    });
  }, []);

  const [listCities, setListCities] = useState([
    {
      id: '',
      name: ''
    }
  ]);

  const digital = watch('digital');
  const title = watch('title');

  return (
    <Container>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          refBtn.current.click();
        }}
        titleComponent={<div>Incluir matéria - Imprensa</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Tabs defaultActiveKey="Sobre a matéria" className="mb-3">
            <Tab eventKey="Sobre a matéria" title="Sobre a matéria">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Data da publicação</Form.Label>
                    <Form.Control
                      type="date"
                      {...register(`publication_date`)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <Form.Control
                      maxLength={150}
                      {...register(`title`, {
                        required: true,
                        maxLength: 150,
                        onChange: () => {
                          if (title.length == 150) {
                            setError('title', {
                              type: 'maxLength',
                              message: 'Máximo de caracteres alcançado'
                            });
                          }
                        }
                      })}
                    />
                    {errors.title && <p>{errors.title.message}</p>}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Digital
                    index={`digital`}
                    getValues={getValues}
                    setValue={setValue}
                  />
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Link</Form.Label>
                    <Form.Control type="url" {...register(`link`)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Tema</Form.Label>
                    <Form.Select {...register(`theme`)}>
                      {[
                        'Armamentos',
                        'Educação',
                        'Saúde',
                        'Segurança',
                        'Tecnologia',
                        'Transportes',
                        'Outros'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Indicadores da Fogo Cruzado</Form.Label>
                    <Form.Select {...register(`indicators_fc`)}>
                      {[
                        'Ação/Operação policial',
                        'Agente de segurança vítima',
                        'Bala perdida',
                        'Baleados',
                        'Chacinas',
                        'Crianças e/ou adolescentes',
                        'Entorno de Unidades de ensino ou saúde',
                        'Gênero',
                        'Idosos',
                        'Motorista de app',
                        'Outros',
                        'Outros recortes',
                        'Projetos especiais',
                        'Residência',
                        'Tiros',
                        'Transporte/Via interrompida',
                        'Bar',
                        'Tiros de helicóptero',
                        'Ataque sob rodas',
                        'Político',
                        'Ex-detento',
                        'Presídio',
                        'Shopping',
                        'Tiroteiro contínuo',
                        'Vendedor ambulante',
                        'Mototaxista'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  {digital == true && (
                    <>
                      <Form.Group>
                        <Form.Label>Subindicadores da Fogo Cruzado</Form.Label>
                        <Form.Select {...register(`sub_identifiers_fc`)}>
                          {[
                            'Rotina',
                            'Leste metropolitano',
                            'Baixada fluminense',
                            'Relatório',
                            'Levantamentos',
                            'Grupos armados',
                            'Leis e políticas'
                          ].map(chave => {
                            return <option value={chave}>{chave}</option>;
                          })}
                        </Form.Select>
                      </Form.Group>
                    </>
                  )}
                </Col>
                <Col>
                  {digital == true && (
                    <>
                      <Form.Group>
                        <Form.Label>Palavra-chave</Form.Label>

                        <Form.Control {...register(`keyword`)} />
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Tipo de mídia</Form.Label>
                    <Form.Select {...register(`media_type`)}>
                      {[
                        'Impresso',
                        'TV',
                        'Rádio',
                        'Podcast',
                        'Vídeo em redes sociais',
                        'Outros'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>
            </Tab>
            <Tab eventKey="Sobre o veículo" title="Sobre o veículo">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Nome do veículo</Form.Label>
                    <Form.Control
                      {...register(`vehicle_name`, { required: true })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Programa/Seção</Form.Label>
                    <Form.Control {...register(`program_or_section`)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Tipo de acesso</Form.Label>

                    <FormType
                      index={'access_type'}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Escopo</Form.Label>
                    <Form.Select {...register(`scope`)}>
                      {['Internacional', 'Nacional', 'Regional', 'Local'].map(
                        chave => {
                          return <option value={chave}>{chave}</option>;
                        }
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Continente</Form.Label>
                    <Form.Select {...register(`continent`)}>
                      {[
                        'África',
                        'América do Sul',
                        'América Central',
                        'América do Nórte',
                        'Ásia',
                        'Europa',
                        'Oceania'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>País</Form.Label>
                    <Form.Control {...register(`country`)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Estado</Form.Label>
                    <Form.Control {...register(`state`)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control {...register(`city`)} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Local</Form.Label>

                    <Form.Control {...register(`place`)} />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="Relação com a FC" title="Relação com a FC">
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Destaque</Form.Label>
                    <Form.Select {...register(`emphasis`)}>
                      {[
                        'Capa',
                        'Na página (online)',
                        'Matéria longa',
                        'Reportagem na chamada do jornal',
                        'Título/subtítulo',
                        'Uso de gráficos',
                        'Outros',
                        'Nenhum'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Porta-voz</Form.Label>
                    <Form.Select {...register(`spokesman`)}>
                      {[
                        'Aspas',
                        'Vídeo',
                        'Entrevista',
                        'Áudio',
                        'Sem porta-voz'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Exclusiva</Form.Label>

                    <Exclusive
                      index={'exclusive'}
                      setValue={setValue}
                      getValues={getValues}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Gancho</Form.Label>
                    <Form.Select {...register(`hook`)}>
                      {[
                        'Data comemorativa',
                        'Repercussão de caso novo',
                        'Análise de príodos',
                        'Análise de políticas públicas',
                        'Não se aplica',
                        'Produto',
                        'Outros'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Tipo de contato com o Jornalista</Form.Label>
                    <Form.Select {...register(`type_of_contact`)}>
                      {['Venda', 'Sob demanda', 'Espontâneo', 'Réplica'].map(
                        chave => {
                          return <option value={chave}>{chave}</option>;
                        }
                      )}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Canal de contato com o Jornalista</Form.Label>
                    <Form.Select {...register(`contact_channel`)}>
                      {[
                        'Zap de imprensa',
                        'Mailing',
                        'Contato individual (email)',
                        'Contato individual (zap)',
                        'Contato individual (ligação)',
                        'Sem contato direto'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Isca</Form.Label>
                    <Form.Select {...register(`bait`)}>
                      {[
                        'Relatórios',
                        'Levantamentos especiais',
                        'Cobertura de enventos específicos',
                        'Press release',
                        'Outros'
                      ].map(chave => {
                        return <option value={chave}>{chave}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Label>Eixo do Fogo Cruzado</Form.Label>
                  <Form.Select {...register(`axis_fc`)}>
                    {[
                      'Praça RJ',
                      'Praça PE',
                      'Instituto/Metodologia',
                      'Instituto/Comunicação',
                      'Instituto/Projetos especiais'
                    ].map(chave => {
                      return <option value={chave}>{chave}</option>;
                    })}
                  </Form.Select>
                </Col>
                <Col></Col>
              </Row>
            </Tab>
          </Tabs>

          <br />
          <button type="submit" style={{ display: 'none' }} ref={refBtn}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Link criado"
        description="O link foi criado com sucesso."
      />
    </Container>
  );
};

export default ModalCreatePress;
