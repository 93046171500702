import React, { useContext, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import moment from 'moment';

import { Container, Button } from './index.styled';
import { Modal } from 'react-bootstrap';
import { IProps } from './index.types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../store';
import { ModalSucesso } from '../../../../../../../components';
import { BlogContext } from '../../../../../providers/BlogProvider';

const DatePicker: React.FC<IProps> = ({
  isModal,
  onHide,
  setValue,
  index,
  index2,
  getValues,
  onHideAll
}) => {
  const { postActionsUsecase } = useContext(BlogContext);

  let [data, setData] = useState('');
  let [hora, setHora] = useState('');
  const [isVisible, setVisible] = useState(false);

  async function onSubmit(data: any) {
    await postActionsUsecase.createPost({ data });

    onHide();
    onHideAll();
    setVisible(true);
  }

  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <Container>
            <h4 style={{ textAlign: 'center' }}>Agendar</h4>

            <Calendar
              onChange={(e: any) => {
                let dt = moment(e).format('YYYY-MM-DD');
                setData(dt);
              }}
            />
            <br />

            <input
              type="time"
              onChange={(e: any) => {
                setHora(e.target.value);
              }}
            />

            <br />

            <Button
              disabled={data != '' && hora != '' ? false : true}
              type="submit"
              onClick={() => {
                let dt = `${data}T${hora}:00.000Z`;

                setValue(index, dt);
                setValue(index2, 'Agendada');

                let values = getValues();

                onSubmit(values);
              }}
            >
              Agendar
            </Button>
          </Container>
        </Modal.Body>
      </Modal>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Postagem agendada"
        description="A Postagem foi agendada com sucesso."
      />
    </>
  );
};

export default DatePicker;
