import { createContext, useContext, useState } from 'react';
import { AppContext } from '../../../shared/providers/AppProvider';
import { OccurrenceRepositoryImpl } from '../data/repositories_impl/OccurrenceRepositoryImpl';
import { OccurrenceRepository } from '../domain/repositories/OccurrenceRepository';
import CreateEditOccurrenceState from '../domain/usecases/create_edit/CreateEditOccurrenceState';
import CreateEditOccurrenceUsecase from '../domain/usecases/create_edit/CreateEditOccurrenceUsecase';
import usePrevious from '../../../shared/providers/usePrevious';

interface IOccurrenceContext {
  createEditOccurrenceState: CreateEditOccurrenceState;
  previousCreateEditOccurrenceState: CreateEditOccurrenceState | undefined;
  createEditOccurrenceUsecase: CreateEditOccurrenceUsecase;
  occurrenceRepository: OccurrenceRepository;
}

export const OccurrenceContext = createContext<IOccurrenceContext>(
  {} as IOccurrenceContext
);

export const OccurrenceProvider = ({ children }: any) => {
  const { apiRepository, adressSharedRepository } = useContext(AppContext);

  const [createEditOccurrenceState, setCreateEditOccurrenceState] =
    useState<CreateEditOccurrenceState>(new CreateEditOccurrenceState());
  const previousCreateEditOccurrenceState = usePrevious(
    createEditOccurrenceState
  );

  const occurrenceRepository = new OccurrenceRepositoryImpl(apiRepository);

  const createEditOccurrenceUsecase: CreateEditOccurrenceUsecase =
    new CreateEditOccurrenceUsecase(
      createEditOccurrenceState,
      setCreateEditOccurrenceState,
      adressSharedRepository,
      occurrenceRepository
    );

  return (
    <OccurrenceContext.Provider
      value={{
        createEditOccurrenceState,
        previousCreateEditOccurrenceState,
        createEditOccurrenceUsecase,
        occurrenceRepository
      }}
    >
      {children}
    </OccurrenceContext.Provider>
  );
};
