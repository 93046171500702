import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Row, Col, Image } from 'react-bootstrap';
import { IProps } from './index.types';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  CardFile,
  Col1,
  Col2,
  Col3,
  Container,
  ContainerBtn3,
  DivBtn1,
  DivBtn2,
  DivBtn3,
  DivBtns,
  IconeFile,
  ImageB3,
  ImageFinance,
  ImageIcon,
  ImageOpen,
  ImageT1,
  Line1,
  NoStyle
} from './index.style';
import {
  contentIcon,
  fileBlack,
  fileRed,
  imgIcon,
  titleIcon,
  trashRed
} from '../../../../assets';
import { BlueComponent, FileUploader } from '../../../../components/index';
import {
  editTransparencies,
  getHome,
  getTransparencies,
  putHome
} from '../../../../services';
import { ADD_HOME, ADD_TRANSPARENCIES, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';

import { ImgUp, ModalSucesso, CardStyled } from '../../../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import renderHTML from 'react-render-html';

import moment from 'moment';
import { FormikConsumer } from 'formik';

const ModalEditar: React.FC<IProps> = ({ isModal, onHide, content, id }) => {
  const imgTemp = 'https://picsum.photos/190/150';

  const { register, handleSubmit, getValues, setValue, reset, control } =
    useForm({
      defaultValues: {
        id: '',
        name: '',
        title: '',
        description: '',
        image: { id: '', file: '' },
        file: { id: '', file: '' },
        items: [
          {
            title: '',
            reports: [
              {
                file: {
                  id: '',
                  file: ''
                },
                image: {
                  id: '',
                  file: ''
                },
                title: '',
                createAt: '',
                updateAt: '',
                file_width: ''
              }
            ],
            description: '',
            image: {
              id: '',
              file: ' '
            },
            file: {
              id: '',
              file: ''
            }
          }
        ]
      }
    });

  const { append, prepend, remove, swap, move, insert, fields, update } =
    useFieldArray({
      control,
      name: 'items'
    });

  useEffect(() => {
    if (content !== undefined) {
      reset(content);
      console.log(id, 'indext');
    }
  }, [content]);

  const refBtn = useRef<any>();

  const { token, home } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  function onSubmit(dados: any) {
    console.log(dados, 'dados');

    editTransparencies(token, dados.id, dados).then(resp => {
      getTransparencies(token).then(resposta => {
        dispatch({ type: ADD_TRANSPARENCIES, transparencies: resposta });
      });
      onHide();
      setVisible(true);
    });
  }

  const [name, setName] = useState();

  useEffect(() => {
    console.log('name', name);
  }, [name]);

  function convertData(data: string) {
    let dt = moment(data).format('DD/MM/YYYY - HH:mm');
    return `${dt} `;
  }

  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          refBtn.current.click();
        }}
        titleComponent={<div>Editar bloco</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          {content.name == 'Abertura' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Imagem</Form.Label>
                        <ImgUp
                          getValues={getValues}
                          setValue={setValue}
                          index={`image`}
                          imgWidth={235}
                          imgHeight={156}
                          width={235}
                          height={156}
                          containerWidth={235}
                          containerHeight={156}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 1' && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`title`)}
                      onChange={(e: any) => {
                        setValue(`title`, e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Descrição</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`description`)}
                      onChange={(e: any) => {
                        setValue(`description`, e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 2' && (
            <Row>
              {fields.map((item: any, index: any) => {
                return (
                  <>
                    <Col>
                      {item.title != undefined && (
                        <>
                          <Row>
                            <Form.Group>
                              <Form.Label>Titulo</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.title`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.title`, e);
                                }}
                              />
                            </Form.Group>
                          </Row>
                        </>
                      )}
                      {item.description != undefined && (
                        <>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Descrição</Form.Label>
                                <ReactQuill
                                  theme="snow"
                                  formats={[
                                    'header',
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strike',
                                    'blockquote',
                                    'list',
                                    'bullet',
                                    'indent',
                                    'link',
                                    'image',
                                    'video'
                                  ]}
                                  modules={{
                                    toolbar: [
                                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                      [
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote'
                                      ],
                                      [
                                        { list: 'ordered' },
                                        { list: 'bullet' },
                                        { indent: '-1' },
                                        { indent: '+1' }
                                      ],
                                      ['link', 'image'],
                                      ['clean'],
                                      ['image', 'video']
                                    ]
                                  }}
                                  value={getValues(
                                    `items.${index}.description`
                                  )}
                                  onChange={(e: any) => {
                                    setValue(`items.${index}.description`, e);
                                  }}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      )}
                      {item.image != undefined && (
                        <>
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Label>Imagem</Form.Label>
                                <ImgUp
                                  getValues={getValues}
                                  setValue={setValue}
                                  index={`items.${index}.image`}
                                  imgWidth={235}
                                  imgHeight={156}
                                  width={235}
                                  height={156}
                                  containerWidth={235}
                                  containerHeight={156}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </>
                );
              })}
            </Row>
          )}
          {content.name == 'Bloco 3' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Imagem</Form.Label>
                        <ImgUp
                          getValues={getValues}
                          setValue={setValue}
                          index={`image`}
                          imgWidth={235}
                          imgHeight={156}
                          width={235}
                          height={156}
                          containerWidth={235}
                          containerHeight={156}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 4' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Imagem</Form.Label>
                        <ImgUp
                          getValues={getValues}
                          setValue={setValue}
                          index={`image`}
                          imgWidth={235}
                          imgHeight={156}
                          width={235}
                          height={156}
                          containerWidth={235}
                          containerHeight={156}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 5' && (
            <>
              {fields.map((item, index: any) => {
                return (
                  <>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Titulo</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.title`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.title`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Descrição</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.description`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.description`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <DivBtns>
                          <DivBtn1
                            type="button"
                            onClick={() => {
                              let lista: any[] = [...item.reports];
                              lista.push({
                                title: '',
                                file: {
                                  id: '',
                                  file: ''
                                },
                                image: {
                                  id: '',
                                  file: ''
                                }
                              });

                              update(0, {
                                ...item,
                                reports: lista
                              });

                              console.log(item, 'vl');
                            }}
                          >
                            Adicionar relatório
                          </DivBtn1>
                          <DivBtn1
                            type="button"
                            onClick={() => {
                              let lista: any[] = [...item.reports];
                              lista.pop();

                              update(0, {
                                ...item,
                                reports: lista
                              });

                              console.log(item, 'vl');
                            }}
                          >
                            Remover o ultimo relatório
                          </DivBtn1>
                        </DivBtns>
                      </Col>
                    </Row>
                    <br />

                    <Row>
                      {item?.reports?.map((id: any, idx: any) => {
                        return (
                          <>
                            <Col xs={3}>
                              <CardStyled>
                                <Form.Group>
                                  <Form.Label>Titulo</Form.Label>
                                  <Form.Control
                                    {...register(
                                      `items.${index}.reports.${idx}.title`
                                    )}
                                  />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Label>Relatório</Form.Label>
                                  <FileUploader
                                    getValues={getValues}
                                    setValue={setValue}
                                    index={`items.${index}.reports.${idx}.file`}
                                    index1={`items.${index}.reports.${idx}.file_width`}
                                    index2={`items.${index}.reports.${idx}.description`}
                                    imgWidth={390}
                                    imgHeight={60}
                                    width={390}
                                    height={60}
                                    containerWidth={390}
                                    containerHeight={60}
                                  />
                                </Form.Group>

                                <Form.Group>
                                  <Form.Label>Capa do relatório</Form.Label>
                                  <ImgUp
                                    getValues={getValues}
                                    setValue={setValue}
                                    index={`items.${index}.reports.${idx}.image`}
                                    imgWidth={270}
                                    imgHeight={127}
                                    width={270}
                                    height={127}
                                    containerWidth={270}
                                    containerHeight={127}
                                  />
                                </Form.Group>
                              </CardStyled>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                  </>
                );
              })}
            </>
          )}
          {content.name == 'Bloco 6' && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`title`)}
                      onChange={(e: any) => {
                        setValue(`title`, e);
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <DivBtns>
                    <DivBtn1
                      type="button"
                      onClick={() => {
                        append({
                          image: {
                            id: '',
                            file: ''
                          },
                          description: ''
                        });

                        console.log(getValues(), 'vl');
                      }}
                    >
                      Adicionar apoiador
                    </DivBtn1>
                  </DivBtns>
                </Col>
              </Row>
              <br />
              <Row>
                {fields.map((item: any, index: any) => {
                  return (
                    <>
                      <Col xs={3}>
                        <CardStyled>
                          <ContainerBtn3>
                            <DivBtn3
                              type="button"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <img src={trashRed} /> Apagar bloco
                            </DivBtn3>
                          </ContainerBtn3>

                          <Form.Group>
                            <Form.Label>Imagem</Form.Label>
                            <ImgUp
                              getValues={getValues}
                              setValue={setValue}
                              index={`items.${index}.image`}
                              imgWidth={191}
                              imgHeight={49}
                              width={241}
                              height={93}
                              containerWidth={241}
                              containerHeight={93}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>Link</Form.Label>
                            <Form.Control
                              type="url"
                              {...register(`items.${index}.description`)}
                            />
                          </Form.Group>
                        </CardStyled>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </>
          )}

          <br />
          <button type="submit" style={{ display: 'none' }} ref={refBtn}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Informações editadas"
        description="As informações foram editadas com sucesso."
      />
    </>
  );
};

export default ModalEditar;
