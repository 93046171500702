import React, { useState } from 'react';
import { IProps } from './index.types';
import {
  Card,
  Image,
  Tag,
  ImageIcon,
  Container,
  Content,
  LinkStyled,
  ContainerSwitch,
  HeaderCover
} from './index.styled';
import { timePost, authorPost } from '../../assets/index';
import moment from 'moment';
import { SwitchGreen } from '..';
import { editReports, getReports } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_REPORTS, RootState } from '../../store';
import Loading from '../Loading';
import { tempImg } from '../../constants/tempImg';

const CardReports: React.FC<IProps> = ({ content }) => {
  let { token } = useSelector((state: RootState) => state.clickState);
  let dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);

  function convertData(data: string) {
    let dt = moment(data).format('DD/MM/YYYY HH:mm');
    return `${dt} `;
  }

  function onClickSwitchEmphasis(value: boolean) {
    try {
      let aux = {
        ...content,
        author: content.author?.id,
        region: content.region?.id,
        emphasis: value
      };
      delete aux.comments;
      editReports(token, content.id, aux).then(_ => {
        getReports(token).then(resp =>
          dispatch({ type: ADD_REPORTS, reports: resp.data })
        );
      });
    } catch (e) {
      alert('Erro ao editar o relatório!');
      console.error(e);
    }
  }

  const toPath =
    content.slug != null && content.slug != '' && content.slug != ' '
      ? `/data/${content.slug}`
      : `/data/id/${content.id}`;

  return (
    <Card>
      <HeaderCover to={toPath} style={{ margin: isLoading ? 'auto 0' : '' }}>
        <div style={{ display: isLoading ? 'block' : 'none' }}>
          <Loading />
        </div>

        <Image
          src={
            content.cover != null || content.cover != undefined
              ? content.cover.file
              : tempImg
          }
          style={{ display: !isLoading ? 'block' : 'none' }}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </HeaderCover>
      <ContainerSwitch>
        <label
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <span style={{ marginRight: '10px' }}>Relatório em destaque</span>
          <SwitchGreen
            checked={content.emphasis}
            onClick={e => onClickSwitchEmphasis(e)}
          />
        </label>
      </ContainerSwitch>
      <Container to={toPath}>
        <Tag>{content.region?.state}</Tag>

        <LinkStyled>{content.title}</LinkStyled>

        <Content>
          <div>
            <div>
              <ImageIcon src={authorPost} />
              <p>
                por <b>{content.authors}</b>
              </p>
            </div>
            <div>
              <ImageIcon src={timePost} />
              <p>Atualizado em {convertData(content.updatedAt)}</p>
            </div>
          </div>
        </Content>
      </Container>
    </Card>
  );
};

export default CardReports;
