import { Modal } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const ScrollView = styled.div`
  height: 690px;
  overflow: scroll;
  overflow-x: hidden;
`;

export const Title = styled.div`
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 23px !important;
  color: #2c3941 !important;
`;

export const ModalStyled = styled(Modal)`
  > div {
    padding: 4.5% 7% 1%;
    > div {
      /* width: 1250px !important;
            height: 965px !important;  
            margin: 10px auto  !important;     */
    }
  }

  /* & > div > div {
        width: 1250px !important;
        height: 965px !important;  
        margin: 10px auto  !important;
    } */
`;

export const ModalBodyStyled = styled(Modal.Body)`
  padding: 24px !important;
  overflow-y: hidden !important;
`;

export const Text = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #2c3941;
`;

export const ModalHeaderStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const DivBtn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 5px;
`;

export const ButtonOcccurrences = styled(NavLink)`
  border: 1px solid #2c3941;
  border-radius: 6px;
  padding: 10px;
  display: flex;
  text-align: center;
  color: #2c3941;
  text-decoration: none;

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    color: #2c3941;
  }
`;
