import * as Yup from 'yup';
import { BaseForm } from '../../../../shared/domain/forms/BaseForm';

export class CreateEditOccurrenceContextInfoForm extends BaseForm<CreateEditOccurrenceContextInfoForm> {
  mainReasonId: string = '';
  complementaryReasons: string[] = [];
  clippings: string[] = [];
  massacre: boolean | undefined = undefined;
  policeUnit: string = '';
  relatedNews: string = '';
  observations: string = '';

  validationSchema = ({
    isRelatedNewsRequired = false
  }: {
    isRelatedNewsRequired?: boolean;
  }) =>
    Yup.object().shape({
      mainReasonId: Yup.string()
        .nullable()
        .required(this.requiredDefaultMsg('motivo principal')),
      massacre: Yup.boolean()
        .nullable()
        .required(this.requiredDefaultMsg('chacina')),
      relatedNews: this.yupValidationForRelatedNews(
        isRelatedNewsRequired,
        'links de notícias relacionadas'
      )
    });

  constructor(data?: Partial<CreateEditOccurrenceContextInfoForm>) {
    super();
    Object.assign(this, data);
  }

  yupValidationForRelatedNews(
    isRelatedNewsRequired: boolean,
    fieldMsg: string
  ) {
    return Yup.string().when({
      is: () => isRelatedNewsRequired,
      then: Yup.string().nullable().required(this.requiredDefaultMsg(fieldMsg)),
      otherwise: Yup.string().nullable()
    });
  }
}
