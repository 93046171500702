import styled from 'styled-components';

export const Container = styled.div`
  width: 370px;
  height: 547px;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Button = styled.button`
  border: none;
  padding: 10px;
  color: #fff;
  border-radius: 6px;
  background: linear-gradient(91.25deg, #f83600 -1.52%, #fe8c00 103.51%),
    linear-gradient(180deg, #ee0979 0%, #ff6a00 100%), #ffffff;
`;
