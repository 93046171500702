import { AuthorModel } from '../../domain/models/AuthorModel';

export default class AuthorEntity {
  id: string = '';
  name: string = '';
  nickname: string = '';
  email: string = '';

  constructor(data?: Partial<AuthorEntity>) {
    Object.assign(this, data);
  }

  toDomain(): AuthorModel {
    return new AuthorModel({
      id: this.id,
      name: this.name,
      nickname: this.nickname,
      email: this.email
    });
  }
}
