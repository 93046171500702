import * as Yup from 'yup';
import { BaseForm } from '../../../../shared/domain/forms/BaseForm';

export class CreateEditOccurrenceAnimalVictimsForm extends BaseForm<CreateEditOccurrenceAnimalVictimsForm> {
  id: string = '';
  occurrenceId: string = '';
  type: string = '';
  situation: string = '';
  animalTypeId: string = '';
  name: string = '';
  circumstances: string[] = [];
  deathDate: string = '';

  validationSchema = Yup.object().shape({
    situation: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('situação do animal')),
    circumstances: Yup.array()
      .nullable()
      .of(Yup.string())
      .min(1)
      .required(this.requiredDefaultMsg('circunstâncias')),
    animalTypeId: Yup.string()
      .nullable()
      .required(this.requiredDefaultMsg('tipo de animal'))
  });

  constructor(data?: Partial<CreateEditOccurrenceAnimalVictimsForm>) {
    super();
    Object.assign(this, data);
  }
}
