/* eslint-disable jsx-a11y/alt-text */
import { Modal } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { send } from '../../assets';
import { RootState } from '../../store';
import { IOccurrence } from '../../shared/interface/occurrence';
import SuccessSimpleModal from '../SuccessSimpleModal';

import {
  ModalContent,
  ModalCheckIcon,
  ModalTitle,
  ModalSubtitle,
  ModalDescription,
  ModalFooter,
  ModalButtonOutlined,
  ModalButton
} from './styles';
import { createOccurrenceNotification } from '../../services/functions/notification';
import ErrorSimpleModal from '../ErrorSimpleModal';
import Loading from '../Loading';

interface ISuccessModalProps {
  title?: string;
  subtitle?: string;
  open: boolean;
  onClose(): void;
  occurrenceId?: string;
}

const SuccessOccurrenceModal: React.FC<ISuccessModalProps> = ({
  title,
  subtitle,
  open,
  onClose,
  occurrenceId
}) => {
  const [isVisibleMsg, setVisibleMsg] = useState<string | false>(false);
  const [isLoading, setLoading] = useState(false);
  const selector = useSelector((state: RootState) => state.clickState);

  async function sendNotifications() {
    if (!occurrenceId) return;

    setLoading(true);
    try {
      const _response = await createOccurrenceNotification(
        selector.token,
        occurrenceId
      );

      if (_response?.status === 201) {
        requestAnimationFrame(() => setVisibleMsg('success'));
      }
    } catch (error) {
      requestAnimationFrame(() => setVisibleMsg('error'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={open}
        onClose={onClose}
      >
        <ModalContent>
          <ModalCheckIcon />

          <ModalTitle>{title}</ModalTitle>
          <ModalSubtitle>{subtitle}</ModalSubtitle>
          <ModalDescription>
            Deseja enviar um alerta para todos os usuários que se enquadram
            nessa ocorrência?
          </ModalDescription>

          <ModalFooter>
            <ModalButtonOutlined onClick={onClose}>
              Não enviar
            </ModalButtonOutlined>
            <ModalButton
              onClick={sendNotifications}
              data-cy="occurence-form-success-confirm"
            >
              {isLoading ? (
                <>
                  <Loading size="sm" />
                  Enviando...
                </>
              ) : (
                <>
                  <img src={send} />
                  Sim, enviar
                </>
              )}
            </ModalButton>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <SuccessSimpleModal
        title="Alerta enviado"
        message="Um alerta foi enviado a todos os usuários que se enquadraram na ocorrência"
        open={isVisibleMsg === 'success'}
        onClose={() => {
          onClose();
          setVisibleMsg(false);
        }}
      />

      <ErrorSimpleModal
        title="Falha na ação"
        message="Houve uma falha na tentativa de envio das notificações."
        open={isVisibleMsg === 'error'}
        onClose={() => {
          onClose();
          setVisibleMsg(false);
        }}
      />
    </>
  );
};

export default SuccessOccurrenceModal;
