import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { closered, paper } from '../../assets';
import { getFileById, postFile, deleteFile } from '../../services';
import { RootState } from '../../store';
import {
  CardImage,
  ButtonDelete,
  ButtonUpload,
  ImageStyled,
  ImageContainer
} from './index.styled';
import { IProps } from './index.types';

const FileUp: React.FC<IProps> = ({
  setValue,
  index,
  index1,
  index2,
  getValues,
  height,
  width,
  style,
  containerWidth,
  containerHeight
}) => {
  const referencia = useRef<HTMLInputElement>(null);
  const [fileRequestLoading, setFileRequestLoading] = useState(false);

  const [img, setImg] = useState('');

  async function upNewFile(e: any) {
    try {
      let arq = e.target.files[0];
      let width = (arq.size / 1000).toFixed(0);

      setFileRequestLoading(true);

      let reader = new FileReader();
      reader.readAsDataURL(arq);

      reader.onloadend = () => {
        let base64data = reader.result;
        let dados = { file: base64data };

        postFile(token, dados)
          .then((resp: any) => {
            setValue(index, resp);
            setImg(resp);

            setValue(index1, width.toString());
            setValue(index2, arq.name);
          })
          .finally(() => {
            setFileRequestLoading(false);
          });
      };
    } catch (e) {
      alert('Erro ao tentar salvar arquivo!');
      console.error(e);
    }
  }

  async function removeFile() {
    try {
      let id = Object(getValues(index));

      referencia.current?.value && (referencia.current.value = '');
      deleteFile(token, id.id).then(_ => {
        setValue(index, '');
        setImg('');
      });
    } catch (e) {
      console.error(e);
    }
  }

  async function getAlreadyExistedFile() {
    try {
      let value = getValues(index);

      if (value != '') {
        let obj = Object(value).id;

        if (obj === undefined || obj === null || obj === '') return;
        getFileById(token, obj).then(resp => {
          setImg(resp.file);
          setValue(index, resp);
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getAlreadyExistedFile();
  }, []);

  const { token } = useSelector((state: RootState) => state.clickState);

  return (
    <>
      <Form.Control
        type="file"
        ref={referencia}
        accept="application/pdf,application/vnd.ms-excel,.zip,.rar,.7z,.gz"
        onChange={(e: any) => upNewFile(e)}
        multiple={false}
        style={{ display: 'none' }}
      />

      {img == '' || img == null || img == undefined ? (
        <ButtonUpload
          type="button"
          onClick={() => {
            referencia?.current?.click();
          }}
          style={style}
          width={containerWidth}
          height={containerHeight}
        >
          {fileRequestLoading
            ? 'Carregando relatório...'
            : ' Importar relatório'}
        </ButtonUpload>
      ) : (
        <>
          <CardImage width={width} height={height}>
            <ImageContainer>
              <ImageStyled
                src={paper}
                alt=""
                style={{ objectFit: 'contain' }}
              />
              <div
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {getValues(index2)}
              </div>
            </ImageContainer>

            <ButtonDelete
              type="button"
              onClick={() => removeFile()}
              width={width}
              height={height}
            >
              <img src={closered} />
            </ButtonDelete>
          </CardImage>
        </>
      )}
    </>
  );
};

export default FileUp;
