import styled from 'styled-components';

export const Radio = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  > input {
    accent-color: green;
    width: 18px;
    height: 18px;
  }
`;

export const RadioLabel = styled.label`
  margin-left: 10px !important;
  cursor: pointer;
`;
