import React, { useState, useEffect } from 'react';
import {
  Container,
  Nav,
  Card,
  Header,
  Section,
  Content,
  ContentImg,
  DivHeaderTitle,
  NoStyle,
  NoStyleTwo,
  ImageIcon
} from './index.style';
import { Col, Modal, Row } from 'react-bootstrap';

import { titleIcon, contentIcon, imgIcon } from '../../assets/index';

import { Tabs, Tab } from 'react-bootstrap';
import { getHome, getImageById } from '../../services';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_HOME, RootState } from '../../store';
import { ModalEditar } from './Modals/index';
import { tipoConteudo } from './Modals/Edit/index.types';

import { BlueComponent, RowStyled, ColumnStyled } from '../../components/index';

import renderHTML from 'react-render-html';

const Home: React.FC = () => {
  const imgTemp = 'https://picsum.photos/190/150';

  const dispatch = useDispatch();
  const { token, home } = useSelector((state: RootState) => state.clickState);

  useEffect(() => {
    getHome(token).then(resp => dispatch({ type: ADD_HOME, home: resp }));
  }, []);

  useEffect(() => {
    if (home != undefined) {
      console.log('home', home);
    }
  }, [home]);

  const [isModalEditar, setIsModalEditar] = useState<boolean>(false);
  const [contentEditar, setContentEditar] = useState<tipoConteudo>({
    id: '',
    title: '',
    description: '',
    image: {
      id: '',
      file: ''
    },
    name: ''
  });

  return (
    <Container>
      <Tabs defaultActiveKey={'Abertura'} className="mb-3">
        {home?.map(item => {
          return (
            <Tab
              eventKey={item.name}
              title={<DivHeaderTitle>{item.name}</DivHeaderTitle>}
            >
              <BlueComponent
                btnEdit={true}
                isModal={false}
                isShow={false}
                onHide={() => {}}
                onClickCancel={() => {}}
                onClickSave={() => {}}
                onClickEdit={() => {
                  setIsModalEditar(true);
                  setContentEditar(item);
                }}
                titleComponent={item.name}
              >
                <Row>
                  <Col>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(item.title)}</NoStyleTwo>
                        </div>
                      </div>
                      <div>
                        <ImageIcon src={contentIcon} />
                        <div>
                          <p>Texto</p>
                          <NoStyle>{renderHTML(item.description)}</NoStyle>
                        </div>
                      </div>
                    </Content>
                  </Col>
                  <Col xs={4}>
                    <Row>
                      <Col xs={2}>
                        <img src={imgIcon} alt="" width={32} height={32} />
                      </Col>
                      <Col xs={10}>
                        <img
                          src={
                            item.image.file == '' ? imgTemp : item.image.file
                          }
                          style={{
                            width: '192px',
                            height: '141px',
                            objectFit: 'cover'
                          }}
                          alt=""
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </BlueComponent>
            </Tab>
          );
        })}
      </Tabs>

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setIsModalEditar(false)}
        content={contentEditar}
      />
    </Container>
  );
};

export default Home;
