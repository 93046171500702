import { EOccurrenceStatus } from '../../shared/enums/ocurrenceStatus';
import { api } from '../index';

export function createOccurrences(token: string, { id, ...dados }: any) {
  return api.post(`/occurrences`, dados, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function editOccurrences(
  token: string,
  id: string | undefined,
  dados: any
) {
  return api.put(`/occurrences/${id}`, dados, {
    headers: { Authorization: `Bearer ${token}` }
  });
}

export function editStatusOccurrenceById({
  token,
  id,
  status
}: {
  token: string;
  id: string;
  status: EOccurrenceStatus;
}) {
  return api.put(
    `/occurrences/update-status/${id}`,
    { status: status },
    {
      headers: { Authorization: `Bearer ${token}` }
    }
  );
}

export async function deleteOccurrences(token: string, id: string) {
  try {
    const resposta = await api.delete(`/occurrences/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Ocorrencias) Erro:', erro);
    return null;
  }
}

export async function getOccurrences(token: string, page?: number) {
  let params = new URLSearchParams();
  params.append('page', page != undefined ? page?.toString() : '0');
  params.append('order', 'DESC');

  try {
    const resposta = await api.get('/occurrences/v2', {
      headers: { Authorization: `Bearer ${token}` },
      params: params
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Ocorrencias) Erro:', erro);
    return null;
  }
}

export async function getOccurrencesById(token: string, id: string) {
  try {
    const resposta = await api.get(`/occurrences/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Ocorrencias) Erro:', erro);
    return null;
  }
}

type orderType = { value: 'ASC' | 'DESC' };

export async function getOccurrencesByParams(
  token: string,
  order?: orderType | string,
  page?: number,
  take?: number,
  status?: string,
  initial_date?: string,
  final_date?: string,
  city?: string,
  region?: string,
  search?: string,
  document_number?: string,
  state?: string,
  initial_date_create?: string,
  final_date_create?: string,
  agent_presence?: string,
  circumstance?: string,
  qualification?: string,
  place?: string
) {
  let params = new URLSearchParams();

  if (order != undefined) {
    params.append('order', order.toString());
  } else {
    params.append('order', 'DESC');
  }

  if (initial_date != undefined && initial_date != '') {
    params.append('initialdate', initial_date);
  }
  if (final_date != undefined && final_date != '') {
    params.append('finaldate', final_date);
  }

  if (initial_date_create != undefined && initial_date_create != '') {
    params.append('initialdate_createdat', initial_date_create);
  }
  if (final_date_create != undefined && final_date_create != '') {
    params.append('finaldate_createdat', final_date_create);
  }

  if (region != undefined) {
    params.append('region', region);
  }
  if (search != undefined) {
    params.append('search', search);
  }
  if (city != undefined) {
    params.append('city', city);
  }
  if (agent_presence != undefined) {
    params.append('agentpresence', agent_presence);
  }
  if (page != undefined) {
    params.append('page', page.toString());
  } else {
    params.append('page', '1');
  }
  if (take != undefined) {
    params.append('take', take.toString());
  } else {
    params.append('take', '50');
  }
  if (status != undefined && status != '') {
    params.append('status', status.toString());
  }
  if (document_number != undefined) {
    params.append('document', document_number);
  }
  if (state != undefined) {
    params.append('state', state);
  }
  if (circumstance != '' && circumstance != undefined) {
    params.append('circumstance', circumstance);
  }
  if (qualification != '' && qualification != undefined) {
    params.append('qualification', qualification);
  }
  if (place != '' && place != undefined) {
    params.append('place', place);
  }

  try {
    const resposta = await api.get('/occurrences/v2', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: params
    });
    let resp = resposta.data;
    return resp;
  } catch (erro) {
    console.warn('(Ocorrencias) Erro:', erro);
    return null;
  }
}
