/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Col } from 'react-bootstrap';
import { IOccurrence } from '../../shared/interface/occurrence';
import {
  Button,
  ButtonOutlined,
  Card,
  CardButtons,
  CardDetails,
  CardLeft,
  CardOccurrence,
  CardRight,
  DescriptionText
} from './styles';

import alertDark from '../../assets/alertDark.png';
import localWhite from '../../assets/localIcon2.png';
import agenteWhite from '../../assets/policeIcon2.png';
import personWhite from '../../assets/personIcon2.png';
import Loading from '../Loading';

interface IHeaderOccurrence {
  occurrence?: IOccurrence;
  onClickFinish?(): void;
  onClickGoBack?(): void;
  isLoading?: boolean;
}

const OccurrenceCreationHeader: React.FC<IHeaderOccurrence> = ({
  occurrence,
  onClickFinish,
  onClickGoBack,
  isLoading
}) => {
  return (
    <CardOccurrence>
      <Card>
        <CardLeft>
          <header>
            Registrado por <b>moderador</b>
          </header>

          <div>
            <div>
              <img src={alertDark} width={28} height={28} />
              <div>
                <h3>Tiros...</h3>
                <p>--/--/---- - --:--</p>
              </div>
            </div>
            <div>
              <img src={localWhite} width={28} height={28} />
              <div>
                <p>{occurrence?.address || '---'}</p>
                <p>---</p>
              </div>
            </div>
          </div>
        </CardLeft>

        <CardRight>
          <p>#{occurrence?.document_number}</p>
          <div>
            <CardDetails>
              <div>
                <h3>Civis</h3>
                <div>
                  <img src={personWhite} width={28} height={28} />
                  <p>
                    {occurrence?.number_civilians_wounded} - feridos /{' '}
                    {occurrence?.number_civilians_dead} - morte
                  </p>
                </div>
              </div>

              <div>
                <h3>Agentes de segurança</h3>
                <div>
                  <img src={agenteWhite} width={28} height={28} />
                  <p>
                    {occurrence?.number_agent_wounded} - feridos /{' '}
                    {occurrence?.number_agent_dead} - morte
                  </p>
                </div>
              </div>
            </CardDetails>

            <Col sm={6}>
              <h5>Descrição da ocorrência</h5>
              <DescriptionText>
                {occurrence?.description || '---'}
              </DescriptionText>
            </Col>

            <CardButtons>
              <Button
                type="submit"
                data-cy="occurence-form-submit"
                onClick={onClickFinish}
              >
                {isLoading ? (
                  <>
                    <Loading size="sm" />
                    Processando...
                  </>
                ) : (
                  'Finalizar registro'
                )}
              </Button>

              <ButtonOutlined onClick={onClickGoBack}>Voltar</ButtonOutlined>
            </CardButtons>
          </div>
        </CardRight>
      </Card>
    </CardOccurrence>
  );
};

export default OccurrenceCreationHeader;
