import styled from 'styled-components';
import { IProps } from './index.types';

export const ColumnStyle = styled.div<IProps>`
  ${props => {
    if (props.width > 0) {
      return `
                width: ${props.width}px;
            `;
    } else {
      return `
                width: 100%;
            `;
    }
  }}

  display: flex;
  flex-direction: column;
`;
