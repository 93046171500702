import { Field, FieldInputProps } from 'formik';
import React from 'react';

const FormDateInput: React.FC<{
  field: FieldInputProps<any>;
  dataCy?: string;
  value: string;
  disabled: boolean;
  min?: string;
  max?: string;
  handleChange: (fieldName: string, fieldValue: any) => void;
  handleBlur: (e: any) => void;
  isInvalid: (fieldName: string) => boolean | undefined;
}> = ({
  field,
  dataCy,
  value,
  disabled,
  min,
  max,
  handleChange,
  handleBlur,
  isInvalid
}) => {
  return (
    <Field
      as="input"
      type="datetime-local"
      name={field.name}
      data-cy={dataCy}
      value={value}
      onChange={(e: any) => handleChange(field.name, e.target.value)}
      onBlur={handleBlur}
      className={
        isInvalid(field.name) ? 'form-control is-invalid' : 'form-control'
      }
      disabled={disabled}
      min={min}
      max={max}
    />
  );
};

export default FormDateInput;
