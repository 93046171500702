/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { Col } from 'react-bootstrap';
import { IOccurrence } from '../../shared/interface/occurrence';
import {
  Button,
  ButtonOutlined,
  Card,
  CardButtons,
  CardDetails,
  CardLeft,
  CardOccurrence,
  CardRight,
  DescriptionText,
  Timestamps
} from './styles';

import alertOrange from '../../assets/alertOrange2.png';
import alertRed from '../../assets/redAlert2.png';
import localWhite from '../../assets/localIcon2.png';
import agenteWhite from '../../assets/policeIcon2.png';
import personWhite from '../../assets/personIcon2.png';
import moment from 'moment';
import {
  convertOfTimestampsToDateTime,
  convertToDateAndHours
} from '../../utils/date';
import Loading from '../Loading';

interface IHeaderOccurrenceEdit {
  occurrence: IOccurrence;
  onClickFinish(): void;
  onClickGoBack(): void;
  onApproved?(status: string): void;
  isLoading?: boolean;
}

const OccurrenceEditHeader: React.FC<IHeaderOccurrenceEdit> = ({
  occurrence,
  onClickFinish,
  onClickGoBack,
  onApproved,
  isLoading
}) => {
  const occurrenceWithShot =
    occurrence?.number_civilians_wounded >= 1 ||
    occurrence?.number_civilians_dead >= 1 ||
    occurrence?.number_agent_dead >= 1 ||
    occurrence?.number_agent_wounded >= 1
      ? true
      : false;

  return (
    <CardOccurrence>
      <Card>
        <CardLeft withShot={occurrenceWithShot}>
          <header>
            Registrado por{' '}
            <b>
              {occurrence.user.name === '' || null || undefined
                ? occurrence?.admin?.name
                : occurrence?.user?.name || occurrence?.user?.nickname}
            </b>
          </header>

          <div>
            <div>
              <img
                src={occurrenceWithShot ? alertRed : alertOrange}
                width={28}
                height={28}
              />
              <div>
                <h3>
                  {occurrenceWithShot
                    ? 'Tiros com baleados'
                    : 'Tiros sem baleados'}
                </h3>
                <p>
                  {convertToDateAndHours(occurrence?.date) ||
                    '--/--/---- - --:--'}
                </p>
              </div>
            </div>
            <div>
              <img src={localWhite} width={28} height={28} />
              <div>
                <p>{occurrence?.address || '---'}</p>
                <p>{occurrence?.state?.name || '---'}</p>
              </div>
            </div>
          </div>
        </CardLeft>

        <CardRight>
          <div>
            <h6>#{occurrence?.document_number}</h6>

            <div style={{ alignItems: 'flex-end' }}>
              <Timestamps>
                Data de cadastro:{' '}
                <Timestamps bold>
                  {convertOfTimestampsToDateTime(occurrence?.createdAt)}
                </Timestamps>
              </Timestamps>

              <Timestamps>
                Última atualização:{' '}
                <Timestamps bold>
                  {convertOfTimestampsToDateTime(occurrence?.updatedAt)}
                </Timestamps>
              </Timestamps>
            </div>
          </div>

          <div>
            <CardDetails>
              <div>
                <h3>Civis</h3>
                <div>
                  <img src={personWhite} width={28} height={28} />
                  <p>
                    {occurrence?.number_civilians_wounded} - feridos /{' '}
                    {occurrence?.number_civilians_dead} - morte
                  </p>
                </div>
              </div>

              <div>
                <h3>Agentes de segurança</h3>
                <div>
                  <img src={agenteWhite} width={28} height={28} />
                  <p>
                    {occurrence?.number_agent_wounded} - feridos /{' '}
                    {occurrence?.number_agent_dead} - morte
                  </p>
                </div>
              </div>
            </CardDetails>

            <Col sm={6}>
              <h5>Descrição da ocorrência</h5>
              <DescriptionText>
                {occurrence?.description || '---'}
              </DescriptionText>
            </Col>

            <CardButtons>
              <Button
                type="submit"
                data-cy="occurence-form-submit"
                onClick={() => {
                  if (occurrence.status === 'Waiting') {
                    onApproved?.('Approved');
                    onClickFinish();
                  } else {
                    onClickFinish();
                  }
                }}
              >
                {isLoading ? (
                  <>
                    <Loading size="sm" />
                    Processando...
                  </>
                ) : occurrence.status === 'Waiting' ? (
                  'Finalizar apovação'
                ) : (
                  'Finalizar edição'
                )}
              </Button>

              <ButtonOutlined onClick={onClickGoBack}>Voltar</ButtonOutlined>
            </CardButtons>
          </div>
        </CardRight>
      </Card>
    </CardOccurrence>
  );
};

export default OccurrenceEditHeader;
