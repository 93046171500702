import { io, Socket } from 'socket.io-client';
import SocketState from '../../context/socket/state';
import SocketEvents from './events';

export default class SocketService {
  socketInstance: Socket = io(`${process.env.REACT_APP_API_BASE_URL}`);
  socketEvent: SocketEvents;

  constructor(setValue: React.Dispatch<React.SetStateAction<SocketState>>) {
    this.socketEvent = new SocketEvents(this, setValue);
  }
}
