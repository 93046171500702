import { RegionModel } from '../../domain/models/RegionModel';

export default class RegionEntity {
  id: string = '';
  region: string = '';
  state: string = '';
  enabled: boolean = false;

  constructor(data?: Partial<RegionEntity>) {
    Object.assign(this, data);
  }

  toDomain(): RegionModel {
    return new RegionModel({
      id: this.id,
      region: this.region,
      state: this.state,
      enabled: this.enabled
    });
  }
}
