import styled from 'styled-components';
import { PropsDev } from './index.types';

export const CardImage = styled.div<PropsDev>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #d8d6de;
  background: #f8f8f8;
  width: 100%;
  height: 315px;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;

  position: relative;

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return `
                display: flex;
                flex-direction: column;
                flex-align: center;
                justify-content: center;
                align-items: center;

                width: calc(${props.width}px + 10px) !important;
                height: calc(${props.height}px + 10px) !important; 
            `;
    }
  }}
`;

export const ImageStyled = styled.img<PropsDev>`
  width: 100%;
  height: 315px;
  object-fit: cover;

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return ` 
                width: ${props.width}px !important;
                height: ${props.height}px !important; 
            `;
    }
  }}
`;

export const ButtonEdit = styled.button<PropsDev>`
  position: absolute;
  top: 25px !important;
  right: 20px;

  text-decoration: none;
  color: transparent;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  padding: 6px;

  width: 32px;
  height: 32px;

  & > img {
    width: 32px !important;
    height: 32px !important;
  }

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return `
                top: 5px !important;
                right: 5px  !important;
            `;
    }
  }}
`;

export const ButtonDelete = styled.button<PropsDev>`
  position: absolute;
  top: 25px !important;
  right: 20px;

  text-decoration: none;
  color: transparent;
  border: none;
  background-color: transparent;

  & > img {
    width: 32px;
    height: 32px;
  }

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return `
                top: 5px !important;
                right: 5px  !important;
            `;
    }
  }}
`;

export const ButtonUpload = styled.button<PropsDev>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px dashed black;
  background: #f8f8f8;

  width: 100%;
  height: 315px;

  ${props => {
    if (props.width != undefined && props.height != undefined) {
      return ` 
                width: ${props.width}px !important;
                height: ${props.height}px !important; 
            `;
    }
  }}
`;
