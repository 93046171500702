import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { ReasonModel } from '../../domain/models/ReasonModel';

export class ReasonEntity extends BasicEntity {
  constructor(data?: Partial<ReasonEntity>) {
    super(data);
  }

  toDomain(): ReasonModel {
    return new ReasonModel({
      id: this.id,
      name: this.name
    });
  }
}
