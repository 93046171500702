import { AdressSharedRepository } from '../../../../../shared/domain/repositories/AdressSharedRepository';
import { RequestStatus } from '../../../../../shared/types/RequestStatus';
import { UseContextNotifier } from '../../../../../shared/types/UseContextNotifier';
import { PostDetailsType } from '../../../presentation/views/post_details/index.types';
import { PostRepository } from '../../repositories/PostRepository';
import PostDetailsState from './PostDetailsState';

export default class PostDetailsUsecase extends UseContextNotifier<PostDetailsState> {
  postRepository: PostRepository;
  adressSharedRepository: AdressSharedRepository;

  constructor(
    state: PostDetailsState = new PostDetailsState(),
    setState: React.Dispatch<React.SetStateAction<PostDetailsState>>,
    postRepository: PostRepository,
    adressSharedRepository: AdressSharedRepository
  ) {
    super(state, setState);
    this.postRepository = postRepository;
    this.adressSharedRepository = adressSharedRepository;
  }

  public async getPostDetails({
    postGetType,
    identifier
  }: {
    postGetType: PostDetailsType;
    identifier: string;
  }) {
    this.updateState({
      ...this.state,
      postDetailsRequestStatus: RequestStatus.loading()
    });

    var resp;

    if (postGetType === PostDetailsType.BY_ID) {
      resp = await this.postRepository.getPostById(identifier);
    } else {
      resp = await this.postRepository.getPostBySlug(identifier);
    }

    resp.handle({
      onSuccess: data => {
        this.updateState({
          ...this.state,
          postDetailsRequestStatus: RequestStatus.succeeded(data)
        });
      },
      onFailure: error => {
        this.updateState({
          ...this.state,
          postDetailsRequestStatus: RequestStatus.failed(error)
        });
      }
    });
  }
}
