import React, { useEffect, useState } from 'react';
import { Container, Form, Fieldset } from './index.style';
import { Modal, Offcanvas } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  api,
  editUsers,
  getRegions,
  getStates,
  getUsers
} from '../../../../../services';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_REGIONS,
  ADD_STATES,
  ADD_USERS,
  RootState
} from '../../../../../store';
import { IProps } from './index.types';
import {
  ModalEditTrue,
  ModalSucesso,
  RadioGroup,
  SwitchButtonSecondary
} from '../../../../../components';

const ModalMobile: React.FC<IProps> = ({ isModal, onHide, user }) => {
  const [isModalErrorEdit, setModalErrorEdit] = useState(false);
  const [isModalSucessoEdit, setModalSucessoEdit] = useState(false);
  const dispatch = useDispatch();
  const { token, regions, states } = useSelector(
    (state: RootState) => state.clickState
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    setValue
  } = useForm({
    defaultValues: {
      role: 'Selecione',
      name: '',
      email: '',
      phone_number: '',
      active: true,
      state: '',
      city: '',
      id: '',
      nickname: '',
      trusted: true,
      region: '',
      security_answer: '',
      security_question: ''
    }
  });

  const onSubmit = (dados: any) => {
    let aux = Object.assign(user, {
      ...dados,
      trusted: Boolean(dados.trusted)
    });
    console.log('aux', aux);

    editUsers(token, aux.id, aux)
      .catch(erro => {
        console.log(erro);
        console.warn('(Usuário) Erro:', erro.response.data);
        onHide();
        setModalErrorEdit(true);
      })
      .then(resp => {
        if (resp != null) {
          getUsers(token).then(resp => {
            dispatch({ type: ADD_USERS, users: resp.data });
            onHide();
            setModalSucessoEdit(true);
            console.log(resp);
          });
        } else {
          console.warn('rrr', resp);
        }
      });
  };

  useEffect(() => {
    getStates(token).then(resp => {
      dispatch({ type: ADD_STATES, states: resp });
    });
  }, []);

  useEffect(() => {
    if (user != undefined) {
      let aux = {
        ...user,
        region: user.region != null ? user.region.id : user.region
      };
      reset(aux);
      console.log({
        dados: aux,
        hook: getValues()
      });
    }
  }, [user]);

  return (
    <>
      <Offcanvas show={isModal} onHide={onHide} placement="end">
        <Container>
          <header>
            <h3>Editar usuário</h3>
          </header>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Fieldset>
              <label>Usuário parceiro? (considerado de confiança)</label>
              <RadioGroup
                onChange={e => {
                  console.warn(e);
                  setValue('trusted', e);
                }}
                value={getValues('trusted')}
              />
            </Fieldset>

            <Fieldset>
              <label>Status</label>
              <span>
                <p>Inativo</p>
                <SwitchButtonSecondary
                  onClick={e => {
                    console.log(!e);
                    setValue('active', !e);
                  }}
                  checked={getValues('active')}
                />
                <p>Ativo</p>
              </span>
              {errors.active && (
                <p style={{ color: 'red' }}> Selecione o status do moderador</p>
              )}
            </Fieldset>
            <footer>
              <button
                onClick={() => {
                  onHide();
                  reset({
                    role: 'Selecione',
                    name: '',
                    email: '',
                    phone_number: '',
                    active: true,
                    state: '',
                    city: '',
                    id: '',
                    nickname: '',
                    region: '',
                    security_answer: '',
                    security_question: '',
                    trusted: false
                  });
                }}
              >
                Cancelar
              </button>
              <button type="submit">Finalizar</button>
            </footer>
          </Form>
        </Container>
      </Offcanvas>

      <ModalSucesso
        isModal={isModalSucessoEdit}
        onHide={() => setModalSucessoEdit(false)}
        title="Usuário editado"
        description="O usuário foi editado com sucesso."
      />

      <Modal
        show={isModalErrorEdit}
        onHide={() => {
          setModalErrorEdit(false);
        }}
      >
        <div>
          <h1>Erro ao editar</h1>
          <p>Ocorreu um erro ao editar o usuário!</p>
        </div>
      </Modal>
    </>
  );
};

export default ModalMobile;
