import React from 'react';
import { Modal } from 'react-bootstrap';
import { IProps } from './index.types';
import { Container } from './index.style';
import { close, ss } from '../../assets';

const ModalSucesso: React.FC<IProps> = ({
  isModal,
  onHide,
  title,
  description
}) => {
  return (
    <>
      <Modal show={isModal} onHide={onHide}>
        <Container>
          <button onClick={onHide}>
            <img src={close} alt="" />
          </button>
          <div>
            <img src={ss} alt="" />
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </Container>
      </Modal>
    </>
  );
};

export default ModalSucesso;
