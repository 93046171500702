import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Row, Col, Image } from 'react-bootstrap';
import { IProps } from './index.types';
import { Card, Header, Section, Content, TitleStyled } from './index.style';

import { useForm } from 'react-hook-form';

import {
  editWhiteIcon,
  titleIcon,
  contentIcon,
  imgIcon
} from '../../../../assets';
import { BlueComponent } from '../../../../components/index';
import { getHome, getImageById, putHome } from '../../../../services';
import { ADD_HOME, RootState } from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';

import { ImgUp } from '../../../../components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import renderHTML from 'react-render-html';

import { ModalSucesso } from '../../../../components';

const ModalEditar: React.FC<IProps> = ({ isModal, onHide, content }) => {
  const imgTemp = 'https://picsum.photos/190/150';

  const { register, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: {
      id: '',
      title: '',
      description: '',
      image: {
        id: '',
        file: ''
      }
    }
  });

  useEffect(() => {
    if (content != undefined) {
      reset(content);
      console.log(content);
    }
  }, [content]);

  const refBtn = useRef<any>();

  const { token, home } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  const [isVisible, setVisible] = useState(false);

  function onSubmit(dados: any) {
    console.log(dados);

    putHome(token, dados, dados.id).then(resp => {
      getHome(token).then(resposta => {
        dispatch({ type: ADD_HOME, home: resposta });
      });
      onHide();
      setVisible(true);
    });
  }

  const [name, setName] = useState();

  useEffect(() => {
    console.log('name', name);
  }, [name]);

  const [isSuccess, setSuccess] = useState(false);

  return (
    <>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          refBtn.current.click();
        }}
        titleComponent={<div>Editar bloco</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col xs={8}>
              <Form.Group>
                <Form.Label>Titulo</Form.Label>

                <ReactQuill
                  theme="snow"
                  formats={[
                    'header',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video'
                  ]}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' }
                      ],
                      ['link', 'image'],
                      ['clean'],
                      ['image', 'video']
                    ]
                  }}
                  value={getValues(`title`)}
                  onChange={(e: any) => {
                    setValue(`title`, e);
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Texto</Form.Label>

                <ReactQuill
                  theme="snow"
                  formats={[
                    'header',
                    'bold',
                    'italic',
                    'underline',
                    'strike',
                    'blockquote',
                    'list',
                    'bullet',
                    'indent',
                    'link',
                    'image',
                    'video'
                  ]}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [
                        { list: 'ordered' },
                        { list: 'bullet' },
                        { indent: '-1' },
                        { indent: '+1' }
                      ],
                      ['link', 'image'],
                      ['clean'],
                      ['image', 'video']
                    ]
                  }}
                  value={getValues(`description`)}
                  onChange={(e: any) => {
                    setValue(`description`, e);
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column'
                }}
              >
                <Form.Label>Imagem</Form.Label>

                <ImgUp
                  getValues={getValues}
                  setValue={setValue}
                  index={`image`}
                />
              </Form.Group>
            </Col>
          </Row>

          <br />
          <button type="submit" style={{ display: 'none' }} ref={refBtn}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Informações editadas"
        description="As informações foram editadas com sucesso."
      />
    </>
  );
};

export default ModalEditar;
