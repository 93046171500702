import React, { useEffect, useState } from 'react';
import {
  Container,
  Action,
  Item,
  Role,
  Name,
  Active,
  Card,
  Trusted
} from './index.style';

import PopupState, {
  bindToggle,
  bindPopper,
  InjectedProps,
  Variant
} from 'material-ui-popup-state';
import Popper from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

import arow from '../../assets/arow.png';
import action from '../../assets/action.png';
import close from '../../assets/close.png';
import trash from '../../assets/trash.png';
import editIcon from '../../assets/editIcon.png';

import {
  ModalEditar,
  ModalApagar,
  ModalMobile,
  ModalAlterarSenha
} from '../../pages/Users/Modals/index';
import { isDefined } from '../../utils/definedValue';

interface Data {
  role: string;
  users: any[];
}

const TableList: React.FC<Data> = ({ role, users }) => {
  const [isModalEditar, setModalEditar] = useState<any>(false);
  const [isModalAlterarSenha, setModalAlterarSenha] = useState<any>(false);
  const [isModalMobile, setModalMobile] = useState<any>(false);
  const [isModalApagar, setModalApagar] = useState<any>(false);
  const [user, setUser] = useState<any>();

  function organizaNome(organiza: boolean) {
    alert(organiza);
  }

  return (
    <>
      {role === 'Administrador' && (
        <Container>
          <thead>
            <tr>
              <Item>
                <div>
                  NOME
                  <div>
                    <button>
                      <img src={arow} alt="" />
                    </button>
                    <button>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>

              <Item>
                <div>
                  E-MAIL
                  <div>
                    <button>
                      <img src={arow} alt="" />
                    </button>
                    <button>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>
              <th>TIPO DE ACESSO</th>
              <th>CONFIÁVEL</th>
              <Item>
                <div>
                  ESTADO
                  <div>
                    <button>
                      <img src={arow} alt="" />
                    </button>
                    <button>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>
              <th>STATUS</th>
              <th>AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((id: any) => {
              if (id?.role !== 'Mobile') {
                return (
                  <tr>
                    <Name>{id.name === null ? id.nickname : id.name}</Name>
                    <td>{id.email}</td>
                    <Role access={id?.role}>
                      <p>{id?.role}</p>
                    </Role>
                    <td>
                      <Trusted active={id.trusted}>
                        <p>{id.trusted === true ? 'Sim' : 'Não'}</p>
                      </Trusted>
                    </td>
                    <td>{isDefined(id?.region) ? id?.region?.state : ''}</td>
                    <Active active={id.active}>
                      <p>{id.active ? 'Ativo' : 'Inativo'}</p>
                    </Active>
                    <Action>
                      <PopupState variant="popper" popupId="demo-popup-popper">
                        {(popupState: {
                          open: (
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | null
                              | undefined
                          ) => void;
                          close: () => void;
                          toggle: (
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | null
                              | undefined
                          ) => void;
                          onMouseLeave: (
                            event: React.SyntheticEvent<any, Event>
                          ) => void;
                          setOpen: (
                            open: boolean,
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | undefined
                          ) => void;
                          isOpen: boolean;
                          anchorEl: HTMLElement | undefined;
                          setAnchorEl: (anchorEl: HTMLElement) => any;
                          setAnchorElUsed: boolean;
                          disableAutoFocus: boolean;
                          popupId: string | undefined;
                          variant: Variant;
                          _childPopupState: InjectedProps | undefined;
                          _setChildPopupState: (
                            popupState: InjectedProps | null | undefined
                          ) => void;
                        }) => (
                          <span>
                            <button {...bindToggle(popupState)}>
                              <img src={action} alt="" />
                            </button>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Card>
                                    <button {...bindToggle(popupState)}>
                                      <img
                                        src={close}
                                        alt=""
                                        width={35}
                                        height={35}
                                      />
                                    </button>
                                    <div>
                                      <button
                                        onClick={() => {
                                          setModalEditar(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={editIcon}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Editar</p>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setModalAlterarSenha(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={editIcon}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Alterar Senha</p>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setModalApagar(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={trash}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Excluir</p>
                                      </button>
                                    </div>
                                  </Card>
                                </Fade>
                              )}
                            </Popper>
                          </span>
                        )}
                      </PopupState>
                    </Action>
                  </tr>
                );
              }
            })}
          </tbody>
        </Container>
      )}
      {role === 'Mobile' && (
        <Container>
          <thead>
            <tr>
              <Item>
                <div>
                  USUARIOS/APELIDOS
                  <div>
                    <button onClick={() => organizaNome(true)}>
                      <img src={arow} alt="" />
                    </button>
                    <button onClick={() => organizaNome(false)}>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>
              <th>CONFIÁVEL</th>
              <th>PERGUNTAS DE SEGURANÇA</th>
              <Item>
                <div>
                  REGIÃO
                  <div>
                    <button>
                      <img src={arow} alt="" />
                    </button>
                    <button>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>
              <Item>
                <div>
                  ESTADO
                  <div>
                    <button>
                      <img src={arow} alt="" />
                    </button>
                    <button>
                      <img
                        src={arow}
                        alt=""
                        style={{ transform: 'scaleY(-1)' }}
                      />
                    </button>
                  </div>
                </div>
              </Item>
              <th>STATUS</th>
              <th>AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((id: any) => {
              if (id?.role === 'Mobile') {
                return (
                  <tr>
                    <Name>{id.name == null ? id.nickname : id.name}</Name>
                    <td>
                      <Trusted active={id.trusted}>
                        <p>{id.trusted === true ? 'Sim' : 'Não'}</p>
                      </Trusted>
                    </td>
                    <td>{id.security_question}</td>
                    <td>{id?.region?.region}</td>
                    <td>{id?.region?.state}</td>
                    <Active active={id.active}>
                      <p>{id.active === true ? 'Ativo' : 'Inativo'}</p>
                    </Active>
                    <Action>
                      <PopupState variant="popper" popupId="demo-popup-popper">
                        {(popupState: {
                          open: (
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | null
                              | undefined
                          ) => void;
                          close: () => void;
                          toggle: (
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | null
                              | undefined
                          ) => void;
                          onMouseLeave: (
                            event: React.SyntheticEvent<any, Event>
                          ) => void;
                          setOpen: (
                            open: boolean,
                            eventOrAnchorEl?:
                              | HTMLElement
                              | React.SyntheticEvent<any, Event>
                              | undefined
                          ) => void;
                          isOpen: boolean;
                          anchorEl: HTMLElement | undefined;
                          setAnchorEl: (anchorEl: HTMLElement) => any;
                          setAnchorElUsed: boolean;
                          disableAutoFocus: boolean;
                          popupId: string | undefined;
                          variant: Variant;
                          _childPopupState: InjectedProps | undefined;
                          _setChildPopupState: (
                            popupState: InjectedProps | null | undefined
                          ) => void;
                        }) => (
                          <span>
                            <button {...bindToggle(popupState)}>
                              <img src={action} alt="" />
                            </button>
                            <Popper {...bindPopper(popupState)} transition>
                              {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={350}>
                                  <Card>
                                    <button
                                      {...bindToggle(popupState)}
                                      id={'btn' + id.id}
                                    >
                                      <img
                                        src={close}
                                        alt=""
                                        width={35}
                                        height={35}
                                      />
                                    </button>
                                    <div>
                                      <button
                                        onClick={() => {
                                          setModalMobile(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={editIcon}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Editar</p>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setModalAlterarSenha(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={editIcon}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Alterar Senha</p>
                                      </button>
                                      <button
                                        onClick={() => {
                                          setModalApagar(true);
                                          setUser(id);
                                        }}
                                      >
                                        <img
                                          src={trash}
                                          alt=""
                                          width={20}
                                          height={20}
                                        />
                                        <p>Excluir</p>
                                      </button>
                                    </div>
                                  </Card>
                                </Fade>
                              )}
                            </Popper>
                          </span>
                        )}
                      </PopupState>
                    </Action>
                  </tr>
                );
              }
            })}
          </tbody>
        </Container>
      )}

      <ModalEditar
        isModal={isModalEditar}
        onHide={() => setModalEditar(false)}
        user={user}
        role={role}
      />

      <ModalAlterarSenha
        isModal={isModalAlterarSenha}
        onHide={() => {
          setModalAlterarSenha(false);
        }}
        user={user}
      />

      <ModalMobile
        isModal={isModalMobile}
        onHide={() => setModalMobile(false)}
        user={user}
        role={role}
      />

      <ModalApagar
        isModal={isModalApagar}
        onHide={() => setModalApagar(false)}
        user={user}
        role={role}
      />
    </>
  );
};

export default TableList;
