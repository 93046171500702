import { UseContextNotifier } from '../../../types/UseContextNotifier';
import AppState from './AppState';

export default class AppUsecase extends UseContextNotifier<AppState> {
  constructor(
    state: AppState = new AppState(),
    setState: React.Dispatch<React.SetStateAction<AppState>>
  ) {
    super(state, setState);
  }

  public setToken(token: string) {
    this.updateState({
      ...this.state,
      token
    });
  }
}
