import { EResult } from '../enums/result';
import { IResult } from '../interface/result';
import { AppError } from './AppError';

class SuccessResult<T> implements IResult<T> {
  status: EResult.Success;
  body: T;
  handle: <K>({
    onSuccess,
    onFailure
  }: {
    onSuccess: (data: any) => K;
    onFailure: (error: any) => K;
  }) => K;

  constructor(body: T) {
    this.status = EResult.Success;
    this.body = body;
    this.handle = ({ onSuccess, onFailure: _ }) => onSuccess(body);
  }
}

class FailureResult<T> implements IResult<T> {
  status: EResult.Failure;
  body: T;
  handle: <K>({
    onSuccess,
    onFailure
  }: {
    onSuccess: (data: any) => K;
    onFailure: (error: any) => K;
  }) => K;

  constructor(body: T) {
    this.status = EResult.Failure;
    this.body = body;
    this.handle = ({ onSuccess: _, onFailure }) => onFailure(body);
  }
}

export class Result {
  static success<T>(body: T): IResult<T> {
    return new SuccessResult(body);
  }

  static failure(error: AppError): IResult<any> {
    return new FailureResult(error);
  }
}
