import { CityModel } from '../../domain/models/CityModel';
import { LocalityModel } from '../../domain/models/LocalityModel';
import { NeighborhoodModel } from '../../domain/models/NeighborhoodModel';
import { RegionModel } from '../../domain/models/RegionModel';
import { StateModel } from '../../domain/models/StateModel';
import { SubNeighborhoodModel } from '../../domain/models/SubNeighborhoodModel';
import { AdressSharedRepository } from '../../domain/repositories/AdressSharedRepository';
import { ApiRepository } from '../../domain/repositories/ApiRepository';
import { AdressSharedEndpoint } from '../../endpoint';
import { IResult } from '../../interface/result';
import { AppUnknownError } from '../../types/AppError';
import { Result } from '../../types/Result';
import CityEntity from '../entities/CityEntity';
import LocalityEntity from '../entities/LocalityEntity';
import NeighborhoodsEntity from '../entities/NeighborhoodEntity';
import RegionEntity from '../entities/RegionEntity';
import StateEntity from '../entities/StateEntity';
import SubNeighborhoodsEntity from '../entities/SubNeighborhoodsEntity';

export class AdressSharedRepositoryImpl implements AdressSharedRepository {
  apiRepository: ApiRepository;

  constructor(apiRepository: ApiRepository) {
    this.apiRepository = apiRepository;
  }

  public async getRegions(): Promise<IResult<RegionModel[]>> {
    try {
      let response = await this.apiRepository.get(AdressSharedEndpoint.regions);

      return response.handle({
        onSuccess(data) {
          let entities: Array<RegionEntity> = data.map(
            (item: any) => new RegionEntity(item)
          );
          let models: Array<RegionModel> = entities.map((item: RegionEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getStates(): Promise<IResult<StateModel[]>> {
    try {
      let response = await this.apiRepository.get(AdressSharedEndpoint.states);

      return response.handle({
        onSuccess(data) {
          let entities: Array<StateEntity> = data.map(
            (item: any) => new StateEntity(item)
          );
          let models: Array<StateModel> = entities.map((item: StateEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getCities(): Promise<IResult<CityModel[]>> {
    try {
      let response = await this.apiRepository.get(AdressSharedEndpoint.cities);

      return response.handle({
        onSuccess(data) {
          let entities: Array<CityEntity> = data.map(
            (item: any) => new CityEntity(item)
          );
          let models: Array<CityModel> = entities.map((item: CityEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getCitiesByStateId(
    stateId: string
  ): Promise<IResult<CityModel[]>> {
    try {
      let response = await this.apiRepository.get(
        AdressSharedEndpoint.citiesByStateId(stateId)
      );

      return response.handle({
        onSuccess(data) {
          let entities: Array<CityEntity> = data.map(
            (item: any) => new CityEntity(item)
          );
          let models: Array<CityModel> = entities.map((item: CityEntity) =>
            item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getNeighborhoods(): Promise<IResult<NeighborhoodModel[]>> {
    try {
      let response = await this.apiRepository.get(
        AdressSharedEndpoint.neighborhoods
      );

      return response.handle({
        onSuccess(data) {
          let entities: Array<NeighborhoodsEntity> = data.map(
            (item: any) => new NeighborhoodsEntity(item)
          );
          let models: Array<NeighborhoodModel> = entities.map(
            (item: NeighborhoodsEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getNeighborhoodsByCityId(
    cityId: string
  ): Promise<IResult<NeighborhoodModel[]>> {
    try {
      let response = await this.apiRepository.get(
        AdressSharedEndpoint.neighborhoodsByCityId(cityId)
      );

      return response.handle({
        onSuccess(data) {
          let entities: Array<NeighborhoodsEntity> = data.map(
            (item: any) => new NeighborhoodsEntity(item)
          );
          let models: Array<NeighborhoodModel> = entities.map(
            (item: NeighborhoodsEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getSubNeighborhoodsByCityId(
    cityId: string
  ): Promise<IResult<SubNeighborhoodModel[]>> {
    try {
      let response = await this.apiRepository.get(
        AdressSharedEndpoint.subNeighborhoodsByCityId(cityId)
      );

      return response.handle({
        onSuccess(data) {
          let entities: Array<SubNeighborhoodsEntity> = data.map(
            (item: any) => new SubNeighborhoodsEntity(item)
          );
          let models: Array<SubNeighborhoodModel> = entities.map(
            (item: SubNeighborhoodsEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }

  public async getLocalitiesByCityId(
    cityId: string
  ): Promise<IResult<LocalityModel[]>> {
    try {
      let response = await this.apiRepository.get(
        AdressSharedEndpoint.localitiesByCityId(cityId)
      );

      return response.handle({
        onSuccess(data) {
          let entities: Array<LocalityEntity> = data.map(
            (item: any) => new LocalityEntity(item)
          );
          let models: Array<LocalityModel> = entities.map(
            (item: LocalityEntity) => item.toDomain()
          );

          return Result.success(models);
        },
        onFailure(error) {
          return Result.failure(error);
        }
      });
    } catch (e) {
      return Result.failure(new AppUnknownError());
    }
  }
}
