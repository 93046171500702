/* eslint-disable jsx-a11y/alt-text */
import { Modal } from '@mui/material';
import React from 'react';
import { send } from '../../assets';

import {
  ModalContent,
  ModalIcon,
  ModalTitle,
  ModalSubtitle,
  ModalFooter,
  ModalButtonOutlined,
  ModalButton
} from './styles';

interface IModalConfirmProps {
  open: boolean;
  onClose(): void;
  onClickConfirm?(): void;

  title: string;
  message?: string;
  messageBold?: string;
  textConfirm?: string;
}

const ModalConfirm: React.FC<IModalConfirmProps> = ({
  open,
  onClose,
  onClickConfirm,
  title,
  message,
  messageBold,
  textConfirm
}) => {
  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      open={open}
      onClose={onClose}
    >
      <ModalContent>
        <ModalIcon />

        <ModalTitle>{title || 'Title'}</ModalTitle>
        <ModalSubtitle bold>
          {messageBold || 'message'}
          <ModalSubtitle>{message}</ModalSubtitle>
        </ModalSubtitle>

        <ModalFooter>
          <ModalButtonOutlined onClick={onClose}>
            Não, cancelar
          </ModalButtonOutlined>
          <ModalButton
            onClick={() => {
              onClickConfirm?.();
              onClose();
            }}
          >
            {textConfirm || 'Sim, confirmar'}
          </ModalButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
