import styled from 'styled-components';

export const Container = styled.div``;

export const ImageIcon = styled.img``;

export const ButtonNav = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const ButtonHeader = styled.button`
  width: 203px;
  height: 46px;

  border: none;
  border-radius: 6px;
  background: ${props => props.theme.colors.secondary};
  color: #fff;
`;

export const ButtonHeader2 = styled.button`
  width: 190px;
  height: 46px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.4px;
  border-radius: 6px;
  color: ${props => props.theme.colors.secondary};
  border: 1px solid ${props => props.theme.colors.secondary};
  background: #ffffff;

  > img {
    margin-right: 10px;
  }

  margin-right: 10px;
`;

export const HeaderNav = styled.h5`
  font-family: Montserrat !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #2c3941 !important;
`;

export const ContainerWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    width: 780px;
    margin-bottom: 20px;
  }
`;
