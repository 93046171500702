import React, { useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { GOOGLE_MAPS_API_KEY } from '../../constants/maps';
import { useJsApiLoader } from '@react-google-maps/api';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const InputSearchMap: React.FC<any> = props => {
  const [places, setPlaces] = useState<any[]>([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: 'pt-br',
    libraries: ['places']
  });

  useEffect(() => {
    const value = props.value;
    var div: any = document.getElementById(props.name);

    if (!isLoaded || !div) return;

    var service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: 'br' }
      },
      function (results, status) {
        setPlaces(results || []);
      }
    );
  }, [isLoaded, props.value]);

  function searchAddressByLatitudeLongitude() {
    if (isLoaded) {
      const geoService = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(props.latitude, props.longitude);
      geoService.geocode({ location: latlng }, (data, status) => {
        if (!data) return;
        const [geoResult] = data;
        props.onChange(geoResult?.formatted_address);
      });
    }
  }

  useEffect(() => {
    searchAddressByLatitudeLongitude();
  }, [props.changePosition]);

  return (
    <Typeahead
      id={`typeahead_${props.name}`}
      options={places?.map((v: any) => v.description)}
      inputProps={{
        name: props.name,
        id: props.name,
        value: props.value
      }}
      onInputChange={(v, e) => {
        props.onChange(v);
      }}
      emptyLabel="Nenhum resultado para exibir."
      onBlur={props.onBlur}
      selected={props.value ? [props.value] : []}
      onChange={(selecteds: any) => {
        const [selected] = selecteds;

        props.onChange?.(selected);

        const geoService = new google.maps.Geocoder();
        geoService.geocode({ address: selected }, (data, status) => {
          if (!data) return;

          const [geoResult] = data;
          const location = {
            lat: geoResult?.geometry?.location.lat?.(),
            lng: geoResult?.geometry?.location?.lng?.()
          };
          props.onLocationChange?.(location);
        });
      }}
    />
  );
};

export const RefactorInputSearchMap: React.FC<any> = props => {
  const [places, setPlaces] = useState<any[]>([]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    language: 'pt-br',
    libraries: ['places']
  });

  useEffect(() => {
    const value = props.value;

    if (!isLoaded) return;

    var service = new google.maps.places.AutocompleteService();

    service.getPlacePredictions(
      {
        input: value,
        componentRestrictions: { country: 'br' }
      },
      function (results, status) {
        setPlaces(results || []);
      }
    );
  }, [isLoaded, props.value]);

  function searchAddressByLatitudeLongitude() {
    if (isLoaded) {
      const geoService = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(props.latitude, props.longitude);
      geoService.geocode({ location: latlng }, (data, status) => {
        if (!data) return;
        const [geoResult] = data;
        console.warn(geoResult);
        props.onChange(geoResult?.formatted_address);
      });
    }
  }

  useEffect(() => {
    searchAddressByLatitudeLongitude();
  }, [props.changePosition]);

  return (
    <Typeahead
      {...props}
      id={`typeahead-${props.name}`}
      disabled={props.disabled}
      options={places?.map((v: any) => v.description)}
      emptyLabel="Nenhum resultado para exibir."
      selected={props.value ? [props.value] : []}
      onInputChange={(v, _) => props.onChange(v)}
      onChange={(selecteds: any) => {
        const selected = selecteds[0];
        if (selected) {
          props.onChange?.(selected);

          const geoService = new google.maps.Geocoder();
          geoService.geocode({ address: selected }, (data, _) => {
            if (!data) return;

            const [geoResult] = data;
            const location = {
              lat: geoResult?.geometry?.location.lat?.(),
              lng: geoResult?.geometry?.location?.lng?.()
            };
            props.onLocationChange?.(location);
          });
        }
      }}
    />
  );
};

export default InputSearchMap;
