import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import ReactQuill from 'react-quill';
import { useDispatch, useSelector } from 'react-redux';
import { contentIcon, imgIcon, titleIcon } from '../../../../assets';
import { BlueComponent, ImgUp, ModalSucesso } from '../../../../components';
import { editAboutApi, getAboutApi } from '../../../../services';
import { RootState } from '../../../../store';
import { ADD_ABOUT_API } from '../../../../shared/actions';
import { ImageIcon, NoStyle } from './index.style';
import { IProps } from './index.types';

const ModalEditar: React.FC<IProps> = ({ isModal, onHide, content }) => {
  const { register, handleSubmit, getValues, setValue, reset, control } =
    useForm({
      defaultValues: {
        id: '',
        name: '',
        title: '',
        description: '',
        image: {
          id: '',
          file: ''
        },
        items: [
          {
            image: {
              id: '',
              file: ''
            },
            order: 0,
            title: '',
            description: ''
          }
        ]
      }
    });

  const { append, prepend, remove, swap, move, insert, fields, update } =
    useFieldArray({
      control,
      name: 'items'
    });

  const { token, about_api } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (content != undefined) {
      reset(content);
      console.log(content, getValues());
    }
  }, [content]);

  const referencia = useRef<any>();

  function onSubmit(dados: any) {
    console.log(dados, 'dados');

    editAboutApi(token, dados.id, dados).then(resp => {
      getAboutApi(token).then(r => {
        dispatch({ type: ADD_ABOUT_API, about_api: r });
      });
      onHide();
      setVisible(true);
    });
  }

  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <BlueComponent
        btnEdit={false}
        isModal={true}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickSave={() => {
          referencia.current.click();
        }}
        onClickEdit={() => {}}
        titleComponent={<div>Editar bloco</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <button ref={referencia} type="submit" style={{ display: 'none' }}>
            Ok
          </button>

          {content.name == 'Abertura' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Imagem</Form.Label>
                    <ImgUp
                      getValues={getValues}
                      setValue={setValue}
                      index={`image`}
                      imgWidth={235}
                      imgHeight={156}
                      width={235}
                      height={156}
                      containerWidth={235}
                      containerHeight={156}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 1' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Imagem</Form.Label>
                    <ImgUp
                      getValues={getValues}
                      setValue={setValue}
                      index={`image`}
                      imgWidth={235}
                      imgHeight={156}
                      width={235}
                      height={156}
                      containerWidth={235}
                      containerHeight={156}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 2' && (
            <>
              <Row>
                {fields.map((chave: any, index: any) => {
                  return (
                    <>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Titulo</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.title`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.title`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Descrição</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.description`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.description`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Imagem</Form.Label>
                              <ImgUp
                                getValues={getValues}
                                setValue={setValue}
                                index={`items.${index}.image`}
                                imgWidth={48}
                                imgHeight={48}
                                width={270}
                                height={116}
                                containerWidth={270}
                                containerHeight={116}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </>
                  );
                })}
              </Row>
            </>
          )}
          {content.name == 'Bloco 3' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Imagem</Form.Label>
                    <ImgUp
                      getValues={getValues}
                      setValue={setValue}
                      index={`image`}
                      imgWidth={235}
                      imgHeight={156}
                      width={235}
                      height={156}
                      containerWidth={235}
                      containerHeight={156}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
          {content.name == 'Bloco 4' && (
            <>
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`title`)}
                          onChange={(e: any) => {
                            setValue(`title`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`description`)}
                          onChange={(e: any) => {
                            setValue(`description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
                <Col xs={3}>
                  <Form.Group>
                    <Form.Label>Imagem</Form.Label>
                    <ImgUp
                      getValues={getValues}
                      setValue={setValue}
                      index={`image`}
                      imgWidth={235}
                      imgHeight={156}
                      width={235}
                      height={156}
                      containerWidth={235}
                      containerHeight={156}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}

          <br />
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Informações editadas"
        description="As informações foram editadas com sucesso."
      />
    </>
  );
};

export default ModalEditar;
