import React, { useContext } from 'react';
import FormBody from '../../components/form_body';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';

import { Form, Row, Col } from 'react-bootstrap';
import { Divider } from '../../../../../../../shared/styles';
import { RefactorSelectWithSearch } from '../../../../../../../components/SelectWithSearch';
import {
  CustomForm,
  FormItem
} from '../../../../../../../shared/components/custom_form';
import FormCheckRadio from '../../../../../../../shared/components/form_check_radio';
import { ReasonModel } from '../../../../../domain/models/ReasonModel';

const CreateEditOcurrenceContextInfoFormComponent: React.FC = () => {
  const { createEditOccurrenceUsecase, createEditOccurrenceState } =
    useContext(OccurrenceContext);

  function filterComplementaryReasonOptions(
    data: ReasonModel[],
    mainReasonId: string
  ) {
    const { reasonActionPolice, reasonOperationPolice } =
      createEditOccurrenceState;
    const filteredData = data?.filter(reason => {
      if (
        mainReasonId === reasonActionPolice.id ||
        mainReasonId === reasonOperationPolice.id
      ) {
        return (
          reason.id !== reasonActionPolice.id &&
          reason.id !== reasonOperationPolice.id
        );
      } else {
        return reason.id !== mainReasonId;
      }
    });
    return filteredData?.map(reason => ({
      label: reason.name,
      value: reason.id
    }));
  }

  const getIsRelatedNewsRequired = () => {
    return (
      createEditOccurrenceState.fieldsForValidation.pressSource?.id ===
      createEditOccurrenceState.detailsForm.sourceId
    );
  };

  const dataCy = (field: string) => `contextInfoForm-${field}`;

  return (
    <FormBody
      title="Informações de contexto"
      description="Complementação com informações de contexto dos tiroteios/disparos (apenas administradores)."
    >
      <CustomForm
        validationSchema={createEditOccurrenceState.contextInfoForm.validationSchema(
          {
            isRelatedNewsRequired: getIsRelatedNewsRequired()
          }
        )}
        initialValues={createEditOccurrenceState.contextInfoForm}
        forwardedRef={createEditOccurrenceState.contextInfoForm.ref}
        onSubmit={() => {}}
        handleFormChange={
          createEditOccurrenceUsecase.handleContextInfoFormChange
        }
        component={({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          isInvalid
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Row>
              <Col md={4}>
                <FormItem
                  name="mainReasonId"
                  label="Motivo principal*"
                  failRequest={createEditOccurrenceState.reasonsRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getReasons()
                  }
                  component={field => (
                    <Form.Select
                      name={field.name}
                      data-cy={dataCy(field.name)}
                      value={values[field.name]}
                      onChange={(e: any) => {
                        handleChange(field.name, e.target.value);
                        handleChange('complementaryReasons', '');
                      }}
                      onBlur={handleBlur}
                      isInvalid={isInvalid(field.name)}
                    >
                      {createEditOccurrenceState.reasonsRequestStatus.maybeMap({
                        idle: () => <option>Carregando motivos...</option>,
                        loading: () => <option>Carregando...</option>,
                        failed: () => (
                          <option>
                            Erro. Clique no botão acima para carregar as opções!
                          </option>
                        ),
                        succeeded(data) {
                          return (
                            <>
                              <option value="">Selecione</option>
                              {data?.map(reason => (
                                <option value={reason.id}>{reason.name}</option>
                              ))}
                            </>
                          );
                        }
                      })}
                    </Form.Select>
                  )}
                />
              </Col>
              <Col md={4}>
                <FormItem
                  name="complementaryReasons"
                  label="Motivos complementares"
                  failRequest={createEditOccurrenceState.reasonsRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getReasons()
                  }
                  component={field => (
                    <RefactorSelectWithSearch
                      dataCy={dataCy(field.name)}
                      options={createEditOccurrenceState.reasonsRequestStatus.maybeMap(
                        {
                          idle: () => [],
                          loading: () => [],
                          failed: () => [],
                          succeeded(data) {
                            return filterComplementaryReasonOptions(
                              data,
                              values['mainReasonId']
                            );
                          }
                        }
                      )}
                      placeholder={createEditOccurrenceState.reasonsRequestStatus.maybeMap(
                        {
                          idle: () => 'Selecione',
                          loading: () => 'Carregando...',
                          failed: () =>
                            'Erro. Clique no botão acima para carregar as opções!',
                          succeeded: () => 'Selecione'
                        }
                      )}
                      onChange={options =>
                        handleChange(
                          field.name,
                          options.map((option: { value: any }) => option.value)
                        )
                      }
                      value={values[field.name]}
                      isDisabled={false}
                      isMulti={true}
                    />
                  )}
                />
              </Col>
              <Col md={4}>
                <FormItem
                  name="clippings"
                  label="Outros recortes"
                  failRequest={createEditOccurrenceState.clippingsRequestStatus.maybeMap(
                    {
                      failed: () => true
                    }
                  )}
                  onPressedRefresh={() =>
                    createEditOccurrenceUsecase.getClippings()
                  }
                  component={field => (
                    <RefactorSelectWithSearch
                      dataCy={dataCy(field.name)}
                      options={createEditOccurrenceState.clippingsRequestStatus.maybeMap(
                        {
                          idle: () => [],
                          loading: () => [],
                          failed: () => [],
                          succeeded(data) {
                            return data?.map(clipping => ({
                              label: clipping.name,
                              value: clipping.id
                            }));
                          }
                        }
                      )}
                      placeholder={createEditOccurrenceState.clippingsRequestStatus.maybeMap(
                        {
                          idle: () => 'Selecione',
                          loading: () => 'Carregando...',
                          failed: () =>
                            'Erro. Clique no botão acima para carregar as opções!',
                          succeeded: () => 'Selecione'
                        }
                      )}
                      onChange={options =>
                        handleChange(
                          field.name,
                          options.map((option: { value: any }) => option.value)
                        )
                      }
                      value={values[field.name]}
                      isDisabled={false}
                      isMulti={true}
                      fieldName="outros recortes"
                    />
                  )}
                />
              </Col>
            </Row>

            <Divider height={24} />
            <Row>
              <Col md={4}>
                <Row>
                  <Col>
                    <FormItem
                      name="policeUnit"
                      label="Unidades policiais presentes"
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name)}
                          type="text"
                          placeholder="Digite as unidades policiais presentes..."
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Divider height={24} />
                <Row>
                  <Col>
                    <FormItem
                      name="massacre"
                      label="Chacina*"
                      component={field => (
                        <FormCheckRadio
                          field={field}
                          dataCy={dataCy(field.name)}
                          handleChange={handleChange}
                          isInvalid={isInvalid}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col md={6}>
                    <FormItem
                      name="relatedNews"
                      label={
                        getIsRelatedNewsRequired()
                          ? 'Links de notícias relacionadas*'
                          : 'Links de notícias relacionadas'
                      }
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name)}
                          as="textarea"
                          placeholder="Digite as notícias relacionada..."
                          rows={3}
                          style={{ minHeight: '125px' }}
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>

                  <Col md={6}>
                    <FormItem
                      name="observations"
                      label="Observação"
                      component={field => (
                        <Form.Control
                          {...field}
                          data-cy={dataCy(field.name)}
                          as="textarea"
                          placeholder="Digite as observações..."
                          rows={3}
                          style={{ minHeight: '125px' }}
                          isInvalid={isInvalid(field.name)}
                          onChange={(e: any) =>
                            handleChange(field.name, e.target.value)
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      />
    </FormBody>
  );
};

export default CreateEditOcurrenceContextInfoFormComponent;
