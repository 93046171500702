import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { PartieModel } from '../../domain/models/PartieModel';

export class PartieEntity extends BasicEntity {
  constructor(data?: Partial<PartieEntity>) {
    super(data);
  }

  toDomain(): PartieModel {
    return new PartieModel({
      id: this.id,
      name: this.name
    });
  }
}
