import SocketService from '.';
import { Socket } from 'socket.io-client';
import SocketState from '../../context/socket/state';

export default class SocketEvents {
  socket: Socket;
  setValue: React.Dispatch<React.SetStateAction<SocketState>>;

  constructor(
    socketService: SocketService,
    setValue: React.Dispatch<React.SetStateAction<SocketState>>
  ) {
    this.socket = socketService.socketInstance;
    this.setValue = setValue;
    this.listeningUpdateListOfOccurrences();
  }

  listeningUpdateListOfOccurrences() {
    this.socket.on('update-list-of-occurrences', () => {
      this.setValue((state: SocketState) => {
        state.listOfOccurrences = state.listOfOccurrences + 1;
        return { ...state };
      });
    });
  }
}
