import React, { useEffect, useState } from 'react';
import {
  Container,
  ImagePerson,
  ImageAwards1,
  ImageAwards2,
  ImagePartners,
  ImageOpen,
  ImageValues,
  ContainerValues,
  NoStyle,
  ImageIcon,
  ImageB3,
  Content,
  NoStyleTwo,
  TitleTab,
  CardValues,
  CardContainer,
  Partners
} from './index.styled';

import { titleIcon, contentIcon, imgIcon } from '../../assets';

import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { BlueComponent } from '../../components';
import { getAbout } from '../../services';
import {
  ADD_ABOUT,
  ADD_AWARDS,
  ADD_PARTNERS,
  ADD_TEAMS,
  RootState
} from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSobre } from './Modals';
import { tipoConteudo } from './Modals/Edit/index.types';

import renderHTML from 'react-render-html';

const Sobre: React.FC = () => {
  const { token, about } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  useEffect(() => {
    getAbout(token).then(resp => {
      dispatch({ type: ADD_ABOUT, about: resp });
    });
  }, [token]);

  const [isModalEditar, setIsModalEditar] = useState<boolean>(false);
  const [contentEditar, setContentEditar] = useState<tipoConteudo>({
    id: '',
    description: '',
    title: '',
    items: [
      {
        name: '',
        image: {
          id: '',
          file: ''
        },
        order: 0,
        description: '',
        subtitle: '',
        person: [
          {
            name: '',
            role: '',
            group: '',
            image: {
              id: '',
              file: ''
            },
            order: 0,
            description: ''
          }
        ]
      }
    ]
  });

  useEffect(() => {
    console.log('About:', about);
  }, [about]);

  return (
    <Container>
      <Tabs defaultActiveKey={'Abertura'} className="mb-3">
        {about?.map((chave: any) => {
          return (
            <Tab
              eventKey={chave.name}
              title={<TitleTab>{chave.name}</TitleTab>}
            >
              <BlueComponent
                btnEdit={true}
                isModal={false}
                isShow={false}
                onHide={() => {}}
                onClickCancel={() => {}}
                onClickSave={() => {}}
                onClickEdit={() => {
                  setIsModalEditar(true);
                  setContentEditar(chave);
                }}
                titleComponent={<div>{chave.name}</div>}
              >
                {chave.name == 'Abertura' && (
                  <>
                    {chave.items.map((item: any) => {
                      return (
                        <>
                          <Row>
                            <Col>
                              <Content>
                                <div>
                                  <ImageIcon src={titleIcon} />
                                  <div>
                                    <p>Títutlo</p>
                                    <NoStyleTwo>
                                      {renderHTML(item.title)}
                                    </NoStyleTwo>
                                  </div>
                                </div>
                                <div>
                                  <ImageIcon src={contentIcon} />
                                  <div>
                                    <p>Texto</p>
                                    <NoStyle>
                                      {renderHTML(item.description)}
                                    </NoStyle>
                                  </div>
                                </div>
                              </Content>
                            </Col>
                            <Col>
                              <div style={{ display: 'flex' }}>
                                <ImageIcon
                                  src={imgIcon}
                                  style={{ marginRight: '10px' }}
                                />
                                <ImageB3 src={item?.image.file} />
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    })}
                  </>
                )}
                {chave.name == 'Bloco 1' && (
                  <>
                    <Row>
                      {chave.items.map((item: any) => {
                        return (
                          <>
                            <Col>
                              <Content>
                                <div>
                                  <ImageIcon src={titleIcon} />
                                  <div>
                                    <p>Títutlo</p>
                                    <NoStyleTwo>
                                      {renderHTML(item.title)}
                                    </NoStyleTwo>
                                  </div>
                                </div>
                                <div>
                                  <ImageIcon src={contentIcon} />
                                  <div>
                                    <p>Texto</p>
                                    <NoStyle>
                                      {renderHTML(item.description)}
                                    </NoStyle>
                                  </div>
                                </div>
                              </Content>
                            </Col>
                          </>
                        );
                      })}
                    </Row>
                    <br />
                  </>
                )}
                {chave.name == 'Bloco 2' && (
                  <>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                        </div>
                      </div>
                    </Content>

                    <CardContainer>
                      {chave.items.map((item: any) => {
                        return (
                          <CardValues>
                            <div>
                              <ImageIcon src={titleIcon} />
                              <div>
                                <p>Subtítulo</p>
                                <NoStyle>{renderHTML(item.title)}</NoStyle>
                              </div>
                            </div>
                            <div>
                              <ImageIcon src={contentIcon} />
                              <div>
                                <p>Texto</p>
                                <NoStyle>
                                  {renderHTML(item.description)}
                                </NoStyle>
                              </div>
                            </div>
                            <div>
                              <ImageValues src={item.image?.file} />
                            </div>
                          </CardValues>
                        );
                      })}
                    </CardContainer>
                  </>
                )}
                {chave.name == 'Bloco 3' && (
                  <Row>
                    <Content>
                      {chave.items.map((item: any) => {
                        return (
                          <>
                            {item.title != undefined && (
                              <div>
                                <ImageIcon src={titleIcon} />
                                <div>
                                  <p>Títutlo</p>
                                  <NoStyleTwo>
                                    {renderHTML(item.title)}
                                  </NoStyleTwo>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })}

                      <section>
                        {chave.items.map((item: any) => {
                          return (
                            <>
                              {item.description != undefined && (
                                <div>
                                  <ImageIcon src={contentIcon} />
                                  <div>
                                    <p>Texto</p>
                                    <NoStyle>
                                      {renderHTML(item.description)}
                                    </NoStyle>
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </section>
                      <section style={{ width: '1055px' }}>
                        {chave.items.map((item: any) => {
                          return (
                            <>
                              {item.image?.file != undefined && (
                                <div style={{ display: 'flex' }}>
                                  <ImageIcon
                                    src={imgIcon}
                                    style={{ marginRight: '10px' }}
                                  />
                                  <ImageB3 src={item?.image.file} />
                                </div>
                              )}
                            </>
                          );
                        })}
                      </section>
                    </Content>
                  </Row>
                )}
                {chave.name == 'Bloco 4' && (
                  <>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                        </div>
                      </div>
                    </Content>
                    {chave.items.map((item: any, index: any) => {
                      return (
                        <>
                          <Content>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '-10px',
                                marginBottom: '10px'
                              }}
                            >
                              <div>
                                <NoStyleTwo>
                                  {'Grupo ' + (index + 1)}
                                </NoStyleTwo>
                              </div>
                              <div>
                                <p>{item.group_name}</p>
                              </div>
                            </div>
                          </Content>
                          {item.person.map((person: any) => {
                            return (
                              <Row>
                                <Col xs={1}>
                                  <ImagePerson src={person.image?.file} />
                                </Col>
                                <Col>
                                  <p>
                                    <b>{person.name + ',' + person.role}</b>
                                  </p>
                                  <p>{person.description}</p>
                                </Col>
                              </Row>
                            );
                          })}
                        </>
                      );
                    })}
                  </>
                )}
                {chave.name == 'Bloco 5' && (
                  <>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                        </div>
                      </div>
                    </Content>
                    {chave.items.map((item: any, index: any) => {
                      return (
                        <Row style={{ marginBottom: '20px' }}>
                          <Col>
                            <Content>
                              <div>
                                <ImageIcon src={titleIcon} />
                                <div>
                                  <p>Títutlo</p>
                                  <NoStyleTwo>
                                    {renderHTML(item.title)}
                                  </NoStyleTwo>
                                </div>
                              </div>
                            </Content>
                          </Col>
                          <Col>
                            <Content>
                              <div>
                                <ImageIcon src={contentIcon} />
                                <div>
                                  <p>Texto</p>
                                  <NoStyle>
                                    {renderHTML(item.description)}
                                  </NoStyle>
                                </div>
                              </div>
                            </Content>
                          </Col>
                          <Col>
                            <div style={{ display: 'flex' }}>
                              <ImageIcon
                                src={imgIcon}
                                style={{ marginRight: '10px' }}
                              />
                              <ImageB3 src={item?.image.file} />
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                )}
                {chave.name == 'Bloco 6' && (
                  <>
                    <Content>
                      <div>
                        <ImageIcon src={titleIcon} />
                        <div>
                          <p>Títutlo</p>
                          <NoStyleTwo>{renderHTML(chave.title)}</NoStyleTwo>
                        </div>
                      </div>
                    </Content>
                    {chave.items.map((item: any, index: any) => {
                      return (
                        <Row style={{ marginBottom: '20px' }}>
                          <Col>
                            <Content>
                              <div>
                                <ImageIcon src={titleIcon} />
                                <div>
                                  <p>Títutlo</p>
                                  <NoStyleTwo>
                                    {renderHTML(item.title)}
                                  </NoStyleTwo>
                                </div>
                              </div>
                            </Content>
                          </Col>
                          <Col>
                            <Content>
                              <div>
                                <ImageIcon src={contentIcon} />
                                <div>
                                  <p>Texto</p>
                                  <NoStyle>
                                    {renderHTML(item.description)}
                                  </NoStyle>
                                </div>
                              </div>
                            </Content>
                          </Col>
                          <Col>
                            <div style={{ display: 'flex' }}>
                              <ImageIcon
                                src={imgIcon}
                                style={{ marginRight: '10px' }}
                              />
                              <Partners>
                                <div>
                                  <img src={item.image.file} />
                                </div>
                              </Partners>
                            </div>
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                )}
              </BlueComponent>
            </Tab>
          );
        })}
      </Tabs>

      <ModalSobre
        isModal={isModalEditar}
        onHide={() => setIsModalEditar(false)}
        content={contentEditar}
      />
    </Container>
  );
};

export default Sobre;
