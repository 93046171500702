import { StateModel } from '../../domain/models/StateModel';
import { BasicEntity } from './BasicEntity';

export default class StateEntity extends BasicEntity {
  constructor(data?: Partial<StateEntity>) {
    super(data);
  }

  toDomain(): StateModel {
    return new StateModel({
      id: this.id,
      name: this.name
    });
  }
}
