import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { CircumstanceModel } from '../../domain/models/CircumstanceModel';

export enum CircumstanceType {
  HUMAN = 'human',
  ANIMAL = 'animal',
  BOTH = 'both'
}

export class CircumstanceEntity extends BasicEntity {
  type: CircumstanceType;

  constructor(data?: Partial<CircumstanceEntity>) {
    super(data);
    this.type = data?.type || CircumstanceType.BOTH;
  }

  toDomain(): CircumstanceModel {
    return new CircumstanceModel({
      id: this.id,
      name: this.name,
      type: this.type
    });
  }
}
