import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { IProps } from './index.types';
import { ADD_REGIONS, ADD_REPORTS, RootState } from '../../../../store/index';
import { useSelector, useDispatch } from 'react-redux';

import {
  getRegions,
  postImage,
  getPosts,
  deletePost,
  deleteReports,
  getReports
} from '../../../../services';
import {
  BtnCancel,
  ButtonClear,
  ButtonDashed,
  ButtonDown,
  ButtonUp,
  CardStyled,
  ContainerButtons,
  ContainerCard,
  ContainerFoooter,
  ContainerTB,
  LineDashed,
  LinkStyled
} from './index.styled';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { arrowDown, arrowUp, trashRed } from '../../../../assets/index';
import { ImgUp } from '../../../../components';
import { createPost } from '../../../../services/functions/posts';
import { ADD_BLOG_POSTS } from '../../../../shared/actions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Navigate } from 'react-router-dom';

const ModalApagar: React.FC<IProps> = ({ isModal, onHide, conteudo }) => {
  const dispatch = useDispatch();
  const { token, regions, user } = useSelector(
    (state: RootState) => state.clickState
  );

  useEffect(() => {}, []);

  return (
    <>
      <Modal
        show={isModal}
        onHide={onHide}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body style={{ backgroundColor: '#E5E5E5' }}>
          <h1 style={{ textAlign: 'center' }}>Apagar post</h1>
          <p style={{ textAlign: 'center' }}>Deseja apagar o post?</p>

          <ContainerFoooter>
            <BtnCancel onClick={() => onHide()}>Cancelar</BtnCancel>
            <LinkStyled
              onClick={() => {
                deleteReports(token, conteudo?.id).then((r: any) => {
                  console.log(r);
                  getReports(token).then(resp => {
                    dispatch({ type: ADD_REPORTS, reports: resp.data });
                    onHide();
                  });
                });
              }}
              to="/data"
            >
              Apagar
            </LinkStyled>
          </ContainerFoooter>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalApagar;
