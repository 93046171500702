import { ERequestStatus } from '../enums/requestStatus';
import { IRequestStatus } from '../interface/requestStatus';

class IdleRequestStatus<T> implements IRequestStatus<T> {
  status: ERequestStatus.Idle;
  maybeMap: <K>({
    idle,
    loading,
    succeeded,
    failed
  }: {
    idle?: () => any;
    loading?: () => any;
    succeeded?: (data: T) => K;
    failed?: (error: any) => K;
  }) => K;

  constructor() {
    this.status = ERequestStatus.Idle;
    this.maybeMap = ({ idle, loading: _, succeeded: __, failed: ___ }) =>
      idle !== null && idle !== undefined ? idle() : (() => null as any)();
  }
}

class LoadingRequestStatus<T> implements IRequestStatus<T> {
  status: ERequestStatus.Loading;
  maybeMap: <K>({
    idle,
    loading,
    succeeded,
    failed
  }: {
    idle?: () => any;
    loading?: () => any;
    succeeded?: (data: T) => K;
    failed?: (error: any) => K;
  }) => K;

  constructor() {
    this.status = ERequestStatus.Loading;
    this.maybeMap = ({ idle: _, loading, succeeded: __, failed: ___ }) =>
      loading !== null && loading !== undefined
        ? loading()
        : (() => null as any)();
  }
}

class SucceededRequestStatus<T> implements IRequestStatus<T> {
  status: ERequestStatus.Succeeded;
  data: T;
  maybeMap: <K>({
    idle,
    loading,
    succeeded,
    failed
  }: {
    idle?: () => any;
    loading?: () => any;
    succeeded?: (data: T) => K;
    failed?: (error: any) => K;
  }) => K;

  constructor(data: T) {
    this.status = ERequestStatus.Succeeded;
    this.data = data;
    this.maybeMap = ({ idle: _, loading: __, succeeded, failed: ___ }) =>
      succeeded !== null && succeeded !== undefined
        ? succeeded(data)
        : (() => null as any)();
  }
}

class FailedRequestStatus<T> implements IRequestStatus<T> {
  status: ERequestStatus.Failed;
  error: T;
  maybeMap: <K>({
    idle,
    loading,
    succeeded,
    failed
  }: {
    idle?: () => any;
    loading?: () => any;
    succeeded?: (data: T) => K;
    failed?: (error: any) => K;
  }) => K;

  constructor(error: T) {
    this.status = ERequestStatus.Failed;
    this.error = error;
    this.maybeMap = ({ idle: _, loading: __, succeeded: ___, failed }) =>
      failed !== null && failed !== undefined
        ? failed(error)
        : (() => null as any)();
  }
}

export class RequestStatus {
  static idle<T>(): IRequestStatus<T> {
    return new IdleRequestStatus();
  }

  static loading<T>(): IRequestStatus<T> {
    return new LoadingRequestStatus();
  }

  static succeeded<T>(data: T): IRequestStatus<T> {
    return new SucceededRequestStatus(data);
  }

  static failed<T>(error: T): IRequestStatus<T> {
    return new FailedRequestStatus(error);
  }
}
