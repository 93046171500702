import { api } from '../index';

export async function postFile(token: string, dados: any) {
  try {
    const resposta = await api.post('/uploads', dados, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.error('(uploads.postFile) Error:', e);
  }
}

export async function getFileById(token: string, id: string) {
  try {
    const resposta = await api.get(`/uploads/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.error('(uploads.getFileById) Error:', e);
  }
}

export async function deleteFile(token: string, id: string) {
  try {
    const resposta = await api.delete(`/uploads/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return resposta.data;
  } catch (e) {
    console.error('(uploads.deleteFile) Error:', e);
  }
}
