import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Header,
  Content,
  Div,
  Section,
  Icon,
  LinkStyled,
  ImageHeader,
  ButtonNav,
  DivNav,
  ButtonDelete,
  Post,
  ImgContent,
  ImgPost,
  TwoBlocks,
  ImgPostSolo
} from './index.styled';
import { useParams } from 'react-router';

import paper from '../../../../../assets/paper.png';
import date from '../../../../../assets/calendar.png';
import share from '../../../../../assets/share-dark.png';
import facebook from '../../../../../assets/facebook-dark.png';
import twitter from '../../../../../assets/twitter-dark.png';
import telegram from '../../../../../assets/telegram-dark.png';
import linkedin from '../../../../../assets/linkedin-dark.png';
import editicon from '../../../../../assets/editicon.svg';
import {
  getPostsById,
  convertData,
  getPostsBySlug
} from '../../../../../services';

import renderHTML from 'react-render-html';
import { redTrash } from '../../../../../assets';
import { ModalApagar } from '../../components/modals/index';
import ModalHandlePost from '../../components/modals/handle_post';
import { EHandlePostModalType } from '../../components/modals/handle_post/index.types';
import { IProps } from './index.types';
import Loading from '../../../../../components/Loading';
import { BlogContext } from '../../../providers/BlogProvider';

const PostDetails: React.FC<IProps> = ({ detailsType }) => {
  let { identifier } = useParams();

  const { postDetailsUsecase } = useContext(BlogContext);

  const [isModalEditar, setModalEditar] = useState(false);
  const [isModalApagar, setModalApagar] = useState(false);
  const [isLoadingImg, setIsLoadingImg] = useState(true);

  async function requestPostDetails() {
    await postDetailsUsecase.getPostDetails({
      postGetType: detailsType,
      identifier: identifier ?? ''
    });
  }

  useEffect(() => {
    requestPostDetails();
  }, [identifier]);

  return (
    <Container>
      <DivNav>
        <LinkStyled to="/blog">Voltar</LinkStyled>
        <div>
          <ButtonNav
            onClick={() => {
              setModalEditar(true);
            }}
          >
            <img src={editicon} alt="" />
            Editar post
          </ButtonNav>
          <ButtonDelete
            onClick={() => {
              setModalApagar(true);
            }}
          >
            <img src={redTrash} />
            Deletar postagem
          </ButtonDelete>
        </div>
      </DivNav>

      {postDetailsUsecase.state.postDetailsRequestStatus.maybeMap({
        loading() {
          return (
            <div style={{ margin: 'auto 0' }}>
              <Loading />
            </div>
          );
        },
        succeeded(post) {
          return (
            <>
              <Header>
                <div
                  style={{
                    display: isLoadingImg ? 'block' : 'none'
                  }}
                >
                  <Loading />
                </div>

                <ImageHeader
                  src={post?.cover?.file}
                  style={{ display: !isLoadingImg ? 'block' : 'none' }}
                  onError={() => {
                    setIsLoadingImg(false);
                  }}
                  onLoad={() => {
                    setIsLoadingImg(false);
                  }}
                />
              </Header>

              <Content>
                <div>{post?.region?.state}</div>
                <h3>{post?.title}</h3>
                <div>
                  <Div>
                    <div>
                      <Icon src={paper} />
                      <p>{post?.authors}</p>
                    </div>
                    <div>
                      <Icon src={date} />
                      <p>{convertData(post?.createdAt)}</p>
                    </div>
                  </Div>
                  <div>
                    <a href="">
                      {' '}
                      <img src={share} alt="" />{' '}
                    </a>
                    <a href="">
                      {' '}
                      <img src={facebook} alt="" />{' '}
                    </a>
                    <a href="">
                      {' '}
                      <img src={twitter} alt="" />{' '}
                    </a>
                    <a href="">
                      {' '}
                      <img src={telegram} alt="" />{' '}
                    </a>
                    <a href="">
                      {' '}
                      <img src={linkedin} alt="" />{' '}
                    </a>
                  </div>
                </div>
              </Content>

              <Post>
                {post?.items?.map((items: any) => {
                  if (items.group == 'bloco 1') {
                    return (
                      <Section>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <>
                              <ImgContent>
                                {group_items?.image?.file != '' &&
                                group_items?.image?.file != undefined ? (
                                  <ImgPost src={group_items?.image?.file} />
                                ) : (
                                  <div style={{ display: 'none' }} />
                                )}
                                <div>
                                  {group_items?.description?.length > 0
                                    ? renderHTML(group_items?.description)
                                    : ''}
                                </div>
                              </ImgContent>
                              <div className="app">
                                {group_items?.content?.length > 0
                                  ? renderHTML(group_items?.content)
                                  : ''}
                              </div>
                            </>
                          );
                        })}
                      </Section>
                    );
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'image'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <ImgPostSolo>
                              {group_items?.image?.file != '' &&
                              group_items?.image?.file != undefined ? (
                                <img src={group_items?.image?.file} />
                              ) : (
                                <div style={{ display: 'none' }} />
                              )}
                              <div>
                                {group_items?.description?.length > 0
                                  ? renderHTML(group_items?.description)
                                  : ''}
                              </div>
                            </ImgPostSolo>
                          );
                        })}
                      </TwoBlocks>
                    );
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'text'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <div className="app">
                              {group_items?.content?.length > 0
                                ? renderHTML(group_items?.content)
                                : ''}
                            </div>
                          );
                        })}
                      </TwoBlocks>
                    );
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '2' &&
                    items.typeBlock == 'code'
                  ) {
                    return (
                      <TwoBlocks>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <div className="app">
                              {group_items?.content?.length > 0
                                ? renderHTML(group_items?.content)
                                : ''}
                            </div>
                          );
                        })}
                      </TwoBlocks>
                    );
                  } else if (
                    items.group == 'bloco 2' &&
                    items.quantBlock == '1' &&
                    items.typeBlock == 'image'
                  ) {
                    return (
                      <ImgPostSolo>
                        {items?.group_items?.map((group_items: any) => {
                          return (
                            <>
                              {group_items?.image?.file != '' &&
                              group_items?.image?.file != undefined ? (
                                <img src={group_items?.image?.file} />
                              ) : (
                                <div style={{ display: 'none' }} />
                              )}
                              <div>
                                {group_items?.description?.length > 0
                                  ? renderHTML(group_items?.description)
                                  : ''}
                              </div>
                            </>
                          );
                        })}
                      </ImgPostSolo>
                    );
                  }
                })}
              </Post>

              <ModalApagar
                isModal={isModalApagar}
                onHide={() => setModalApagar(false)}
                content={post}
              />

              <ModalHandlePost
                isModal={isModalEditar}
                onHide={() => setModalEditar(false)}
                postContent={post}
                callBackPostAction={requestPostDetails}
                modalType={EHandlePostModalType.EDIT}
              />
            </>
          );
        }
      })}
    </Container>
  );
};

export default PostDetails;
