import { useEffect, useRef } from 'react';
import Select from 'react-select';

interface IProps {
  placeholder: string;
  dataCy?: string;
  options: any;
  isDisabled: boolean;
  onChange: (e: any) => void;
  value: any;
  isMulti?: boolean;
  isInvalid?: boolean;
  fieldName?: string;
}

const SelectWithSearch: React.FC<IProps> = props => {
  const styles = {
    singleValue: (provided: any) => ({
      ...provided,
      color: 'gray'
    })
  };

  function onChangeSelect(e: any) {
    if (e) props.onChange(e.value);
    else props.onChange('');
  }

  return (
    <>
      <Select
        aria-labelledby={props.placeholder}
        aria-label={props.placeholder}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        onChange={onChangeSelect}
        styles={styles}
        value={props.value}
        isClearable
      />
    </>
  );
};

export const RefactorSelectWithSearch: React.FC<IProps> = props => {
  const selectRef = useRef<any>(null);

  const errorColor = '#dc3545'; // Same danger color from bootstrap

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      color: props.isInvalid ? errorColor : 'gray',
      borderColor: props.isInvalid ? errorColor : provided?.borderColor,
      boxShadow: props.isInvalid
        ? `0 0 0 0.1px ${errorColor}`
        : provided?.boxShadow,
      ':hover': {
        ...provided[':hover'],
        borderColor: props.isInvalid ? errorColor : provided?.borderColor
      }
    })
  };

  function onChangeSelect(selectedOptions: any) {
    if (Array.isArray(selectedOptions) && props.isMulti) {
      props.onChange(selectedOptions);
    } else {
      const option = selectedOptions?.value ?? '';
      props.onChange(option);
    }
  }

  useEffect(() => {
    if (!props.value) {
      selectRef.current.clearValue();
    }
  }, [props.value]);

  return (
    <>
      <Select
        id={props.dataCy}
        ref={selectRef}
        aria-labelledby={props.placeholder}
        aria-label={props.placeholder}
        options={props.options}
        placeholder={props.placeholder}
        isDisabled={props.isDisabled}
        onChange={onChangeSelect}
        styles={customStyles}
        value={props.options.filter((option: { value: string }) =>
          props.value?.includes(option.value)
        )}
        isClearable
        isMulti={props.isMulti}
      />
      {props.isInvalid && (
        <div
          style={{
            fontSize: '0.8rem',
            color: errorColor
          }}
        >
          Campo {props.fieldName} é obrigatório
        </div>
      )}
    </>
  );
};

export default SelectWithSearch;
