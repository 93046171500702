import React, { useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { BlueComponent, ModalSucesso } from '../../../../components';
import {
  editSocialNetworks,
  getRegions,
  getSocialNetworks
} from '../../../../services';
import { ADD_REGIONS, ADD_SOCIAL_NETWORKS, RootState } from '../../../../store';
import { IProps } from './index.types';

const ModalEditar: React.FC<IProps> = ({ isModal, onHide, content }) => {
  const { register, handleSubmit, getValues, setValue, reset } = useForm({
    defaultValues: {
      id: '',
      facebook: '',
      twitter: '',
      telegram: '',
      instagram: '',
      youtube: '',
      linkedin: '',
      whatsapp: '',
      region: '',
      image: {
        id: '',
        file: ''
      }
    }
  });

  const { token, regions } = useSelector(
    (state: RootState) => state.clickState
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getRegions(token).then(resp =>
      dispatch({ type: ADD_REGIONS, regions: resp })
    );
  }, []);
  useEffect(() => {
    if (content != undefined) {
      let aux = {
        ...content,
        region: content.region != null ? content.region.id : ''
      };
      console.log('content', content, aux);
      reset(aux);
    }
  }, [content]);

  const referencia = useRef<any>();

  function onSubmit(dados: any) {
    console.log(dados);

    editSocialNetworks(token, dados.id, dados).then(resp => {
      getSocialNetworks(token).then(resposta => {
        dispatch({ type: ADD_SOCIAL_NETWORKS, social_networks: resposta });
      });
      onHide();
      setVisible(true);
    });
  }

  const [isVisible, setVisible] = useState(false);

  return (
    <>
      <BlueComponent
        isModal={true}
        btnEdit={false}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickEdit={() => {}}
        onClickSave={() => {
          referencia.current.click();
        }}
        titleComponent={<div>Editar redes</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Região</Form.Label>
                <Form.Select {...register(`region`)}>
                  {regions?.map(chave => {
                    if (chave.enabled == true) {
                      return (
                        <option value={chave.id}>
                          {chave.state == 'Geral'
                            ? 'Institucional'
                            : chave.state}
                        </option>
                      );
                    }
                  })}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Facebook</Form.Label>
                <Form.Control {...register(`facebook`)} />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Twitter</Form.Label>
                <Form.Control {...register(`twitter`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Telegram</Form.Label>
                <Form.Control {...register(`telegram`)} />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Instagram</Form.Label>
                <Form.Control {...register(`instagram`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Youtube</Form.Label>
                <Form.Control {...register(`youtube`)} />
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Linkedin</Form.Label>
                <Form.Control {...register(`linkedin`)} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Whatsapp</Form.Label>
                <Form.Control {...register(`whatsapp`)} />
              </Form.Group>
            </Col>
          </Row>
          <br />

          <button type="submit" style={{ display: 'none' }} ref={referencia}>
            Salvar
          </button>
        </Form>
      </BlueComponent>

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Redes editadas"
        description="As redes foram editadas com sucesso."
      />
    </>
  );
};

export default ModalEditar;
