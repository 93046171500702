import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ADD_CLIPPINGS,
  ADD_REASONS,
  ADD_TRANSPORTS,
  ADD_COORPORATIONS,
  ADD_PLACES,
  RootState,
  ADD_ANIMALS,
  ADD_CIRCUMSTANCES,
  ADD_POSITIONS,
  ADD_QUALIFICATIONS,
  ADD_NEIGHBORHOODS,
  ADD_SUBNEIGHBORHOODS,
  ADD_LOCALITIES,
  ADD_PARTIES
} from '../../store';
import VariableTable from './VariableTable';
import { BodyContainer } from './styles';
import ActionModal from './ActionModal';
import {
  createAnimals,
  createCircumstances,
  createClippings,
  createCoorporations,
  createLocalities,
  createNeighborhoods,
  createParties,
  createPlaces,
  createPositions,
  createQualifications,
  createReasons,
  createSubNeighborhoods,
  createTransports,
  deleteAnimals,
  deleteCircumstances,
  deleteClippings,
  deleteCoorporations,
  deleteLocalities,
  deleteNeighborhoods,
  deleteParties,
  deletePlaces,
  deletePositions,
  deleteQualifications,
  deleteReasons,
  deleteSubNeighborhoods,
  deleteTransports,
  editAnimals,
  editCircumstances,
  editClippings,
  editCoorporations,
  editLocalities,
  editNeighborhoods,
  editParties,
  editPlaces,
  editPositions,
  editQualifications,
  editReasons,
  editSubNeighborhoods,
  editTransports,
  getAnimals,
  getCircumstances,
  getClippings,
  getCoorporations,
  getLocalities,
  getNeighborhoods,
  getParties,
  getPlaces,
  getPositions,
  getQualifications,
  getReasons,
  getSubNeighborhoods,
  getTransports
} from '../../services';
import { ActionType, DefaultFormData, FormData, Variable } from './form-types';
import ErrorSimpleModal from '../../components/ErrorSimpleModal';
import SuccessSimpleModal from '../../components/SuccessSimpleModal';

const EditVariableModal: React.FC = () => {
  const {
    token,
    neighborhoods,
    cities,
    reasons,
    clippings,
    transports,
    subneighborhoods,
    localities,
    places,
    coorporations,
    animals,
    circumstances,
    positions,
    qualifications,
    parties
  } = useSelector((state: RootState) => state.clickState);

  const [showActionModal, setShowActionModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [titleAddModal, setTitleAddModal] = useState('');
  const [labelAddModal, setLabelAddModal] = useState('');

  const [valueEdit, setValueEdit] = useState<FormData>(DefaultFormData);

  const [variable, setVariable] = useState<Variable>(Variable.UNKNOWN);
  const [actionType, setActionType] = useState<ActionType>(ActionType.UNKNOWN);
  const [variableType, setVariableType] = useState(' ');

  const dispatch = useDispatch();

  function refreshReasons() {
    getReasons(token).then(resp =>
      dispatch({ type: ADD_REASONS, reasons: resp })
    );
  }

  function refreshClippings() {
    getClippings(token).then(resp =>
      dispatch({ type: ADD_CLIPPINGS, clippings: resp })
    );
  }

  function refreshTransports() {
    getTransports(token).then(resp =>
      dispatch({ type: ADD_TRANSPORTS, transports: resp })
    );
  }

  function refreshPlaces() {
    getPlaces(token).then(resp => dispatch({ type: ADD_PLACES, places: resp }));
  }

  function refreshCoorporations() {
    getCoorporations(token).then(resp =>
      dispatch({ type: ADD_COORPORATIONS, coorporations: resp })
    );
  }

  function refreshAnimals() {
    getAnimals(token).then(resp =>
      dispatch({ type: ADD_ANIMALS, animals: resp })
    );
  }

  function refreshCircumstances() {
    getCircumstances(token).then(resp =>
      dispatch({ type: ADD_CIRCUMSTANCES, circumstances: resp })
    );
  }

  function refreshPositions() {
    getPositions(token).then(resp =>
      dispatch({ type: ADD_POSITIONS, positions: resp })
    );
  }

  function refreshQualifications() {
    getQualifications(token).then(resp =>
      dispatch({ type: ADD_QUALIFICATIONS, qualifications: resp })
    );
  }

  function refreshNeighborhoods() {
    getNeighborhoods(token).then(resp =>
      dispatch({ type: ADD_NEIGHBORHOODS, neighborhoods: resp })
    );
  }

  function refreshSubNeighborhoods() {
    getSubNeighborhoods(token).then(resp => {
      dispatch({ type: ADD_SUBNEIGHBORHOODS, subneighborhoods: resp });
    });
  }

  function refreshLocalities() {
    getLocalities(token).then(resp => {
      dispatch({ type: ADD_LOCALITIES, localities: resp });
    });
  }

  function refreshParties() {
    getParties(token).then(resp => {
      dispatch({ type: ADD_PARTIES, parties: resp });
    });
  }

  async function createVariable(value: any) {
    switch (variable) {
      case Variable.REASON:
        await createReasons(token, value);
        refreshReasons();
        break;
      case Variable.CLIPPINGS:
        await createClippings(token, value);
        refreshClippings();
        break;
      case Variable.TRANSPORTS:
        await createTransports(token, value);
        refreshTransports();
        break;
      case Variable.PLACES:
        await createPlaces(token, value);
        refreshPlaces();
        break;
      case Variable.COORPORATIONS:
        await createCoorporations(token, value);
        refreshCoorporations();
        break;
      case Variable.ANIMALS:
        await createAnimals(token, value);
        refreshAnimals();
        break;
      case Variable.CIRCUMSTANCE:
        await createCircumstances(token, value);
        refreshCircumstances();
        break;
      case Variable.POSITIONS:
        await createPositions(token, value);
        refreshPositions();
        break;
      case Variable.QUALIFICATIONS:
        await createQualifications(token, value);
        refreshQualifications();
        break;
      case Variable.NEIGHBORHOODS:
        await createNeighborhoods(token, value);
        refreshNeighborhoods();
        break;
      case Variable.SUBNEIGHBORHOODS:
        await createSubNeighborhoods(token, value);
        refreshSubNeighborhoods();
        break;
      case Variable.LOCALITIES:
        await createLocalities(token, value);
        refreshLocalities();
        break;
      case Variable.PARTIES:
        await createParties(token, value);
        refreshParties();
        break;
    }

    setShowActionModal(false);
  }

  async function editVariable(value: any) {
    switch (variable) {
      case Variable.REASON:
        await editReasons(token, value.id, value);
        refreshReasons();
        break;
      case Variable.CLIPPINGS:
        await editClippings(token, value.id, value);
        refreshClippings();
        break;
      case Variable.TRANSPORTS:
        await editTransports(token, value.id, value);
        refreshTransports();
        break;
      case Variable.PLACES:
        await editPlaces(token, value.id, value);
        refreshPlaces();
        break;
      case Variable.COORPORATIONS:
        await editCoorporations(token, value.id, value);
        refreshCoorporations();
        break;
      case Variable.ANIMALS:
        await editAnimals(token, value.id, value);
        refreshAnimals();
        break;
      case Variable.CIRCUMSTANCE:
        await editCircumstances(token, value.id, value);
        refreshCircumstances();
        break;
      case Variable.POSITIONS:
        await editPositions(token, value.id, value);
        refreshPositions();
        break;
      case Variable.QUALIFICATIONS:
        await editQualifications(token, value.id, value);
        refreshQualifications();
        break;
      case Variable.NEIGHBORHOODS:
        await editNeighborhoods(token, value.id, value);
        refreshNeighborhoods();
        break;
      case Variable.SUBNEIGHBORHOODS:
        await editSubNeighborhoods(token, value.id, value);
        refreshSubNeighborhoods();
        break;
      case Variable.LOCALITIES:
        await editLocalities(token, value.id, value);
        refreshLocalities();
        break;
      case Variable.PARTIES:
        await editParties(token, value.id, value);
        refreshParties();
        break;
    }
    setShowActionModal(false);
  }

  async function deleteVariable(id: string) {
    switch (variable) {
      case Variable.REASON:
        await deleteReasons(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshReasons();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.CLIPPINGS:
        await deleteClippings(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshClippings();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.TRANSPORTS:
        await deleteTransports(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshTransports();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.PLACES:
        await deletePlaces(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshPlaces();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.COORPORATIONS:
        await deleteCoorporations(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshCoorporations();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.ANIMALS:
        await deleteAnimals(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshAnimals();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.CIRCUMSTANCE:
        await deleteCircumstances(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshCircumstances();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.POSITIONS:
        await deletePositions(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshPositions();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.QUALIFICATIONS:
        await deleteQualifications(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshQualifications();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.NEIGHBORHOODS:
        await deleteNeighborhoods(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshNeighborhoods();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.SUBNEIGHBORHOODS:
        await deleteSubNeighborhoods(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshSubNeighborhoods();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.LOCALITIES:
        await deleteLocalities(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshLocalities();
            setShowSuccessModal(true);
          }
        });
        break;
      case Variable.PARTIES:
        await deleteParties(token, id).then(response => {
          if (response == null) {
            setShowErrorModal(true);
          } else {
            refreshParties();
            setShowSuccessModal(true);
          }
        });
        break;
    }
  }

  function onClickAddAction(title: string, label: string, variable: Variable) {
    setTitleAddModal(title);
    setLabelAddModal(label);
    setVariable(variable);
    setActionType(ActionType.ADD);
    setShowActionModal(true);
  }

  function onClickEditAction(
    title: string,
    label: string,
    variable: Variable,
    value: any
  ) {
    setTitleAddModal(title);
    setLabelAddModal(label);
    setVariable(variable);
    setActionType(ActionType.EDIT);
    setValueEdit(value);
    setShowActionModal(true);
  }

  function onClickDeleteAction(variable: Variable, value: any) {
    setTitleAddModal('Tem certeza que deseja remover?');
    setVariable(variable);
    setActionType(ActionType.DELETE);
    setValueEdit(value);
    setShowActionModal(true);
  }

  function onClickConfirmActionModal(value: any) {
    if (actionType === ActionType.ADD) {
      createVariable(value);
    } else if (actionType === ActionType.EDIT) {
      editVariable(value);
    } else if (actionType === ActionType.DELETE) {
      deleteVariable(value.id);
      setShowActionModal(false);
    }
  }

  return (
    <>
      <BodyContainer>
        <VariableTable
          values={neighborhoods}
          title="Bairros"
          titleTable="BAIRROS"
          variable={Variable.NEIGHBORHOODS}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction(
              'Adicionar bairros',
              'Bairro:',
              Variable.NEIGHBORHOODS
            );
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar bairro',
              'Bairro:',
              Variable.NEIGHBORHOODS,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.NEIGHBORHOODS, value)
          }
          options={cities?.map(item => ({ value: item.id, name: item.name }))}
        />

        <VariableTable
          values={subneighborhoods}
          title="Sub bairros"
          titleTable="SUB BAIRROS"
          variable={Variable.SUBNEIGHBORHOODS}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction(
              'Adicionar sub bairro',
              'Sub bairro:',
              Variable.SUBNEIGHBORHOODS
            );
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar sub bairro',
              'Sub bairro:',
              Variable.SUBNEIGHBORHOODS,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.SUBNEIGHBORHOODS, value)
          }
          options={cities?.map(item => ({ value: item.id, name: item.name }))}
        />

        <VariableTable
          values={localities}
          title="Localidades"
          titleTable="LOCALIDADES"
          variable={Variable.LOCALITIES}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction(
              'Adicionar localidade',
              'Localidade:',
              Variable.LOCALITIES
            );
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar localidade',
              'Localidade:',
              Variable.LOCALITIES,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.LOCALITIES, value)
          }
          options={cities?.map(item => ({ value: item.id, name: item.name }))}
        />

        <VariableTable
          values={reasons}
          title="Motivo principal/Motivo complementar"
          titleTable="MOTIVO"
          variable={Variable.REASON}
          onClickButtonAdd={() =>
            onClickAddAction('Adicionar motivo', 'Motivo:', Variable.REASON)
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar motivo',
              'Motivo:',
              Variable.REASON,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.REASON, value)
          }
        />

        <VariableTable
          values={clippings}
          title="Recortes"
          titleTable="RECORTES"
          variable={Variable.CLIPPINGS}
          onClickButtonAdd={() =>
            onClickAddAction(
              'Adicionar recorte',
              'Recorte:',
              Variable.CLIPPINGS
            )
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar recorte',
              'Recorte:',
              Variable.CLIPPINGS,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.CLIPPINGS, value)
          }
        />

        <VariableTable
          values={transports}
          title="Tipos de transportes"
          titleTable="TRANSPORTES"
          variable={Variable.TRANSPORTS}
          onClickButtonAdd={() =>
            onClickAddAction(
              'Adicionar transporte',
              'Transporte:',
              Variable.TRANSPORTS
            )
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar transporte',
              'Transporte:',
              Variable.TRANSPORTS,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.TRANSPORTS, value)
          }
        />

        <VariableTable
          values={qualifications}
          title="Qualificações"
          titleTable="QUALIFICAÇÕES"
          variable={Variable.QUALIFICATIONS}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction(
              'Adicionar qualificação',
              'Qualificação:',
              Variable.QUALIFICATIONS
            );
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar qualificação',
              'Qualificação:',
              Variable.QUALIFICATIONS,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.QUALIFICATIONS, value)
          }
          options={[
            { value: 'Politico', name: 'Político' },
            { value: 'Agente', name: 'Agente' },
            { value: 'Servico', name: 'Serviço' },
            { value: '', name: 'Sem tipo' }
          ]}
        />

        <VariableTable
          values={places}
          title="Local - Vítimas"
          titleTable="LOCAIS"
          variable={Variable.PLACES}
          onClickButtonAdd={() =>
            onClickAddAction('Adicionar local', 'Local:', Variable.PLACES)
          }
          onClickButtonEdit={value => {
            onClickEditAction('Editar local', 'Local:', Variable.PLACES, value);
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.PLACES, value)
          }
        />

        <VariableTable
          values={circumstances}
          title="Circunstâncias"
          titleTable="CIRCUNSTÂNCIAS"
          variable={Variable.CIRCUMSTANCE}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction(
              'Adicionar circunstância',
              'Circunstância:',
              Variable.CIRCUMSTANCE
            );
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar circunstância',
              'Circunstância:',
              Variable.CIRCUMSTANCE,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.CIRCUMSTANCE, value)
          }
          options={[
            { value: 'both', name: 'Ambos' },
            { value: 'animal', name: 'Animal' },
            { value: 'human', name: 'Humano' }
          ]}
        />

        <VariableTable
          values={coorporations}
          title="Corporação do agente - Vítimas"
          titleTable="CORPORAÇÕES"
          variable={Variable.COORPORATIONS}
          onClickButtonAdd={() =>
            onClickAddAction(
              'Adicionar corporação',
              'Corporação:',
              Variable.COORPORATIONS
            )
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar corporação',
              'Corporação:',
              Variable.COORPORATIONS,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.COORPORATIONS, value)
          }
        />

        <VariableTable
          values={positions}
          title="Cargos"
          titleTable="CARGOS"
          variable={Variable.POSITIONS}
          onClickButtonAdd={value => {
            setVariableType(value.type);
            onClickAddAction('Adicionar cargo', 'Cargo:', Variable.POSITIONS);
          }}
          onClickButtonEdit={value =>
            onClickEditAction(
              'Editar cargo',
              'Cargo:',
              Variable.POSITIONS,
              value
            )
          }
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.POSITIONS, value)
          }
          options={[
            { value: 'Politico', name: 'Político' },
            { value: 'Agente', name: 'Agente' }
          ]}
        />

        <VariableTable
          values={parties}
          title="Partido político"
          titleTable="PARTIDO POLÍTICO"
          variable={Variable.PARTIES}
          onClickButtonAdd={() =>
            onClickAddAction(
              'Adicionar partido político',
              'Partido político:',
              Variable.PARTIES
            )
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar partido político',
              'Partido político:',
              Variable.PARTIES,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.PARTIES, value)
          }
        />

        <VariableTable
          values={animals}
          title="Tipos de animais"
          titleTable="ANIMAIS"
          variable={Variable.ANIMALS}
          onClickButtonAdd={() =>
            onClickAddAction('Adicionar animal', 'Animal', Variable.ANIMALS)
          }
          onClickButtonEdit={value => {
            onClickEditAction(
              'Editar animal',
              'Animal:',
              Variable.ANIMALS,
              value
            );
          }}
          onClickButtonDelete={value =>
            onClickDeleteAction(Variable.ANIMALS, value)
          }
        />
      </BodyContainer>

      <ActionModal
        open={showActionModal}
        onClose={() => {
          setValueEdit(DefaultFormData);
          setShowActionModal(false);
        }}
        title={titleAddModal}
        label={labelAddModal}
        onClickConfirm={onClickConfirmActionModal}
        editValue={valueEdit}
        actionType={actionType}
        variable={variable}
        variableType={variableType}
      />

      <ErrorSimpleModal
        title="Falha na ação"
        message="Houve uma falha na ação executada."
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />

      <SuccessSimpleModal
        title="Variável deletada"
        message="A variável foi deletada com sucesso."
        open={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
      />
    </>
  );
};

export default EditVariableModal;
