import React from 'react';
import { Container, Button } from './index.styled';

const AddItemOnFormButton: React.FC<{
  name: string;
  onClick: () => void;
  dataCy?: string;
}> = ({ name, onClick, dataCy }) => {
  return (
    <Container>
      <Button data-cy={dataCy} onClick={onClick}>
        {name}
      </Button>
    </Container>
  );
};

export default AddItemOnFormButton;
