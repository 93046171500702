import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  ImageContainer,
  ImageBloco2,
  ImagePerson,
  ImageOpen,
  ImageValues,
  ImageAwards,
  DivBtns,
  DivBtn1,
  DivBtn2,
  DivBtn3,
  ContainerBtn3
} from './index.styled';

import { titleIcon, contentIcon, imgIcon } from '../../../../assets';

import { Button, Col, Form, Row, Tab, Tabs, Card } from 'react-bootstrap';
import { BlueComponent, ImgUp, ModalSucesso } from '../../../../components';
import {
  editAbout,
  getAbout,
  getAwards,
  getPartners,
  getTeams,
  putTeams,
  createTeams,
  getAboutById
} from '../../../../services';
import {
  ADD_ABOUT,
  ADD_AWARDS,
  ADD_PARTNERS,
  ADD_TEAMS,
  RootState
} from '../../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
import { IProps } from './index.types';
import ReactQuill from 'react-quill';
import { AnyTxtRecord } from 'dns';
import { ImageIcon } from '../../index.styled';
import { trashRed } from '../../../../assets/index';
import CreateUser from './CreateUser/index';
import EditUser from './EditUser/index';
import { trashCircle, editIconOrange } from '../../../../assets/index';

const ModalSobre: React.FC<IProps> = ({ isModal, onHide, content }) => {
  const [opening, setOpening] = useState(true);
  const [blockOne, setBlockOne] = useState(false);
  const [blockTwo, setBlockTwo] = useState(false);
  const [blockTree, setBlockTree] = useState(false);
  const [blockFor, setBlockFor] = useState(false);
  const [blockFive, setBlockFive] = useState(false);

  const imgTemp = 'https://picsum.photos/190/150';

  const { token, about } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();
  const [contParceiros, setContParceiros] = useState(0);
  const [order, setOrder] = useState<
    {
      value: number;
      label: number;
    }[]
  >([]);

  function onSubmit(dados: any) {
    console.log(dados, 'dados');

    editAbout(token, dados.id, dados).then(resp => {
      getAbout(token).then(resp => {
        dispatch({ type: ADD_ABOUT, about: resp });
      });
      onHide();
      setVisible(true);
    });
  }

  const referencia = useRef<any>();

  const [arrayId, setArrayId] = useState('');
  const [aboutId, setAboutId] = useState('');

  useEffect(() => {
    if (content != undefined) {
      let aux = {
        ...content,
        number: content.number == null ? 0 : content.number
      };
      reset(aux);
      console.log(content, getValues());
    }
  }, [content]);

  const { register, handleSubmit, getValues, setValue, control, reset } =
    useForm({
      defaultValues: {
        name: '',
        title: '',
        number: 0,
        items: [
          {
            group_name: '',
            number: 0,
            person: [
              {
                image: {
                  id: '',
                  file: ''
                },
                order: 0,
                group: '',
                role: '',
                name: '',
                description: ''
              }
            ],
            image: {
              id: '',
              file: ''
            },
            title: '',
            description: '',
            order: 0
          }
        ]
      }
    });

  const { append, prepend, remove, swap, move, insert, fields, update } =
    useFieldArray({
      control,
      name: 'items'
    });

  function getItems(cont: number) {
    let aux: any[] = [];
    for (let i = 0; i < cont; i++) {
      aux.push({
        value: i,
        label: i
      });
    }
    return aux;
  }

  const [isVisible, setVisible] = useState(false);

  const [isCreateUser, setCreateUser] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [isEditUser, setEditUser] = useState(false);
  const [userTem, setUserTemp] = useState({
    image: {
      id: '',
      file: ''
    },
    order: 0,
    group: '',
    role: '',
    name: '',
    description: ''
  });

  const [indexUser, setIndexUser] = useState(0);
  const [itemsAbout4, setItemsAbout4] = useState<any>();

  return (
    <Container>
      <BlueComponent
        btnEdit={false}
        isModal={true}
        isShow={isModal}
        onHide={onHide}
        onClickCancel={onHide}
        onClickSave={() => {
          referencia.current.click();
        }}
        onClickEdit={() => {}}
        titleComponent={<div>Editar bloco</div>}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <button ref={referencia} type="submit" style={{ display: 'none' }}>
            Ok
          </button>
          {content.name == 'Abertura' && (
            <>
              {fields?.map((chave: any, index: any) => {
                return (
                  <>
                    <Row>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Titulo</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.title`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.title`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <br />
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Descrição</Form.Label>
                              <ReactQuill
                                theme="snow"
                                formats={[
                                  'header',
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote',
                                  'list',
                                  'bullet',
                                  'indent',
                                  'link',
                                  'image',
                                  'video'
                                ]}
                                modules={{
                                  toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                    [
                                      'bold',
                                      'italic',
                                      'underline',
                                      'strike',
                                      'blockquote'
                                    ],
                                    [
                                      { list: 'ordered' },
                                      { list: 'bullet' },
                                      { indent: '-1' },
                                      { indent: '+1' }
                                    ],
                                    ['link', 'image'],
                                    ['clean'],
                                    ['image', 'video']
                                  ]
                                }}
                                value={getValues(`items.${index}.description`)}
                                onChange={(e: any) => {
                                  setValue(`items.${index}.description`, e);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      <Col>
                        <Row>
                          <Col>
                            <Form.Group>
                              <Form.Label>Imagem</Form.Label>
                              <ImgUp
                                getValues={getValues}
                                setValue={setValue}
                                index={`items.${index}.image`}
                                imgWidth={235}
                                imgHeight={156}
                                width={235}
                                height={156}
                                containerWidth={235}
                                containerHeight={156}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                );
              })}
            </>
          )}
          {content.name == 'Bloco 1' && (
            <Row>
              {fields?.map((chave: any, index: any) => {
                return (
                  <>
                    <Col>
                      <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`items.${index}.title`)}
                          onChange={(e: any) => {
                            setValue(`items.${index}.title`, e);
                          }}
                        />
                      </Form.Group>
                      <br />
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`items.${index}.description`)}
                          onChange={(e: any) => {
                            setValue(`items.${index}.description`, e);
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </>
                );
              })}
            </Row>
          )}
          {content.name == 'Bloco 2' && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`title`)}
                      onChange={(e: any) => {
                        setValue(`title`, e);
                      }}
                    />
                  </Form.Group>
                  <br />
                </Col>
              </Row>
              <Row>
                {fields?.map((chave: any, index: any) => {
                  console.log(chave);
                  return (
                    <>
                      <Col>
                        <Form.Group>
                          <Form.Label>Subtitulo</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.title`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.title`, e);
                            }}
                          />
                        </Form.Group>

                        <br />
                        <Form.Group>
                          <Form.Label>Descrição</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.description`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.description`, e);
                            }}
                          />
                        </Form.Group>
                        <br />
                        <ImgUp
                          getValues={getValues}
                          setValue={setValue}
                          index={`items.${index}.image`}
                          imgWidth={48}
                          imgHeight={48}
                          width={270}
                          height={116}
                          containerWidth={270}
                          containerHeight={116}
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
            </>
          )}
          {content.name == 'Bloco 3' && (
            <Row>
              {fields?.map((chave: any, index: any) => {
                return (
                  <>
                    <Col>
                      {chave.title != '' && chave.title != undefined && (
                        <>
                          <Form.Group>
                            <Form.Label>Titulo</Form.Label>
                            <ReactQuill
                              theme="snow"
                              formats={[
                                'header',
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote',
                                'list',
                                'bullet',
                                'indent',
                                'link',
                                'image',
                                'video'
                              ]}
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                  [
                                    'bold',
                                    'italic',
                                    'underline',
                                    'strike',
                                    'blockquote'
                                  ],
                                  [
                                    { list: 'ordered' },
                                    { list: 'bullet' },
                                    { indent: '-1' },
                                    { indent: '+1' }
                                  ],
                                  ['link', 'image'],
                                  ['clean'],
                                  ['image', 'video']
                                ]
                              }}
                              value={getValues(`items.${index}.title`)}
                              onChange={(e: any) => {
                                setValue(`items.${index}.title`, e);
                              }}
                            />
                          </Form.Group>
                        </>
                      )}

                      <br />
                      <Form.Group>
                        <Form.Label>Descrição</Form.Label>
                        <ReactQuill
                          theme="snow"
                          formats={[
                            'header',
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote',
                            'list',
                            'bullet',
                            'indent',
                            'link',
                            'image',
                            'video'
                          ]}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [
                                'bold',
                                'italic',
                                'underline',
                                'strike',
                                'blockquote'
                              ],
                              [
                                { list: 'ordered' },
                                { list: 'bullet' },
                                { indent: '-1' },
                                { indent: '+1' }
                              ],
                              ['link', 'image'],
                              ['clean'],
                              ['image', 'video']
                            ]
                          }}
                          value={getValues(`items.${index}.description`)}
                          onChange={(e: any) => {
                            setValue(`items.${index}.description`, e);
                          }}
                        />
                      </Form.Group>

                      <br />
                      <ImgUp
                        getValues={getValues}
                        setValue={setValue}
                        index={`items.${index}.image`}
                        imgWidth={235}
                        imgHeight={156}
                        width={235}
                        height={156}
                        containerWidth={235}
                        containerHeight={156}
                      />
                    </Col>
                  </>
                );
              })}
            </Row>
          )}
          {content.name == 'Bloco 4' && (
            <>
              <Row>
                <Form.Group>
                  <Form.Label>Título</Form.Label>
                  <ReactQuill
                    theme="snow"
                    formats={[
                      'header',
                      'bold',
                      'italic',
                      'underline',
                      'strike',
                      'blockquote',
                      'list',
                      'bullet',
                      'indent',
                      'link',
                      'image',
                      'video'
                    ]}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, 3, 4, 5, 6, false] }],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [
                          { list: 'ordered' },
                          { list: 'bullet' },
                          { indent: '-1' },
                          { indent: '+1' }
                        ],
                        ['link', 'image'],
                        ['clean'],
                        ['image', 'video']
                      ]
                    }}
                    value={getValues('title')}
                    onChange={(e: any) => {
                      setValue('title', e);
                    }}
                  />
                </Form.Group>
              </Row>
              <br />

              <Tabs
                defaultActiveKey={
                  content != undefined ? content.items[0].group_name : 'Direção'
                }
                className="mb-3"
              >
                {fields?.map((chave: any, idItems: number) => {
                  //console.log('Group:', chave);
                  return (
                    <Tab eventKey={chave.group_name} title={chave.group_name}>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Grupo</Form.Label>
                            <Form.Control
                              {...register(`items.${idItems}.group_name`)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br />
                      <Button
                        onClick={() => {
                          setCreateUser(true);
                          setGroupName(chave.group_name);
                          setArrayId(chave.id);
                          setAboutId(content.id);
                        }}
                        style={{
                          backgroundColor: '#E0733D',
                          border: 'none'
                        }}
                      >
                        Adicionar Pessoa
                      </Button>
                      <br />
                      <div style={{ marginTop: '10px' }}>
                        {chave?.person?.map((id: any, index: any) => {
                          return (
                            <Card
                              style={{
                                borderRadius: '6px',
                                marginBottom: '10px'
                              }}
                            >
                              <Card.Body>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end'
                                  }}
                                >
                                  <Button
                                    onClick={() => {
                                      setEditUser(true);
                                      setGroupName(chave.group_name);
                                      setArrayId(chave.id);
                                      setAboutId(content.id);
                                      setUserTemp(id);
                                      setIndexUser(index);
                                    }}
                                    style={{
                                      backgroundColor: 'transparent',
                                      borderRadius: '6px',
                                      border: '1px solid #E0733D',
                                      marginRight: '10px',
                                      color: '#E0733D'
                                    }}
                                  >
                                    <img src={editIconOrange} /> Editar
                                  </Button>
                                  <Button
                                    onClick={() => {
                                      getAboutById(token, content.id).then(
                                        (resp: any) => {
                                          let dados = { ...resp };
                                          resp?.items?.forEach((c: any) => {
                                            if (
                                              c.group_name == chave.group_name
                                            ) {
                                              c?.person?.splice(index, 1);
                                            }
                                          });

                                          editAbout(
                                            token,
                                            content.id,
                                            resp
                                          ).then((resposta: any) => {
                                            if (resposta != null) {
                                              getAbout(token).then(
                                                (respo: any) => {
                                                  dispatch({
                                                    type: ADD_ABOUT,
                                                    about: respo
                                                  });

                                                  getAboutById(
                                                    token,
                                                    content.id
                                                  ).then(r => {
                                                    reset(r);
                                                  });
                                                }
                                              );
                                            }
                                          });
                                        }
                                      );
                                    }}
                                    style={{
                                      background: 'transparent',
                                      borderRadius: '6px',
                                      border: '1px solid #EA5455',
                                      color: '#EA5455'
                                    }}
                                  >
                                    <img src={trashRed} /> Apagar
                                  </Button>
                                </div>
                                <Row>
                                  <Col xs={2}>
                                    <img
                                      src={id.image.file}
                                      style={{
                                        width: '105px',
                                        height: '105px'
                                      }}
                                    />
                                  </Col>
                                  <Col xs={10}>
                                    <Row>
                                      <Col>
                                        <p>
                                          <b>Nome:</b>
                                        </p>
                                        <p>{id.name}</p>
                                      </Col>
                                      <Col>
                                        <p>
                                          <b>Cargo:</b>
                                        </p>
                                        <p>{id.role}</p>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        <p>
                                          <b>Descrição:</b>
                                        </p>
                                        <p>{id.description}</p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          );
                        })}
                      </div>
                    </Tab>
                  );
                })}
              </Tabs>
            </>
          )}

          {content.name == 'Bloco 5' && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`title`)}
                      onChange={(e: any) => {
                        setValue(`title`, e);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Form.Group>
                    <Form.Label>
                      Número de prêmios: {getValues(`number`)}
                    </Form.Label>
                    <DivBtns>
                      <DivBtn1
                        type="button"
                        onClick={() => {
                          setValue(`number`, Number(getValues(`number`)) + 1);
                          append({
                            image: {
                              id: '',
                              file: ''
                            },
                            order: 0,
                            title: '',
                            description: ''
                          });
                        }}
                      >
                        Adicionar Prêmio
                      </DivBtn1>
                    </DivBtns>
                  </Form.Group>
                </Col>
              </Row>

              <br />
              {fields?.map((chave: any, index: any) => {
                return (
                  <>
                    <ContainerBtn3>
                      <DivBtn3
                        type="button"
                        onClick={() => {
                          setValue(`number`, Number(getValues(`number`)) - 1);
                          remove(index);
                        }}
                      >
                        <img src={trashRed} />
                      </DivBtn3>
                    </ContainerBtn3>

                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Titulo</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.title`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.title`, e);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Ordem</Form.Label>
                          <Form.Control
                            type="number"
                            min="0"
                            placeholder="Ordem na lista"
                            {...register(`items.${index}.order`, {
                              valueAsNumber: true
                            })}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Descrição</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.description`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.description`, e);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Imagem</Form.Label>
                          <ImgUp
                            getValues={getValues}
                            setValue={setValue}
                            index={`items.${index}.image`}
                            imgWidth={288}
                            imgHeight={146}
                            width={288}
                            height={146}
                            containerWidth={288}
                            containerHeight={146}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                  </>
                );
              })}
            </>
          )}
          {content.name == 'Bloco 6' && (
            <>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Titulo</Form.Label>
                    <ReactQuill
                      theme="snow"
                      formats={[
                        'header',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video'
                      ]}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [
                            'bold',
                            'italic',
                            'underline',
                            'strike',
                            'blockquote'
                          ],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' }
                          ],
                          ['link', 'image'],
                          ['clean'],
                          ['image', 'video']
                        ]
                      }}
                      value={getValues(`title`)}
                      onChange={(e: any) => {
                        setValue(`title`, e);
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={2}>
                  <Form.Group>
                    <Form.Label>
                      Número de parceiros: {getValues(`number`)}
                    </Form.Label>
                    <DivBtns>
                      <DivBtn1
                        type="button"
                        onClick={() => {
                          setValue(`number`, Number(getValues(`number`)) + 1);
                          append({
                            image: {
                              id: '',
                              file: ''
                            },
                            order: 0,
                            title: '',
                            description: ''
                          });
                        }}
                      >
                        Adicionar Prêmio
                      </DivBtn1>
                    </DivBtns>
                  </Form.Group>
                </Col>
              </Row>
              <br />
              {fields?.map((chave: any, index: any) => {
                return (
                  <>
                    <ContainerBtn3>
                      <DivBtn3
                        type="button"
                        onClick={() => {
                          setValue(`number`, Number(getValues(`number`)) - 1);
                          remove(index);
                        }}
                      >
                        <img src={trashRed} />
                      </DivBtn3>
                    </ContainerBtn3>
                    <Row>
                      <Col>
                        <Form.Group>
                          <Form.Label>Titulo</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.title`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.title`, e);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Descrição</Form.Label>
                          <ReactQuill
                            theme="snow"
                            formats={[
                              'header',
                              'bold',
                              'italic',
                              'underline',
                              'strike',
                              'blockquote',
                              'list',
                              'bullet',
                              'indent',
                              'link',
                              'image',
                              'video'
                            ]}
                            modules={{
                              toolbar: [
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                [
                                  'bold',
                                  'italic',
                                  'underline',
                                  'strike',
                                  'blockquote'
                                ],
                                [
                                  { list: 'ordered' },
                                  { list: 'bullet' },
                                  { indent: '-1' },
                                  { indent: '+1' }
                                ],
                                ['link', 'image'],
                                ['clean'],
                                ['image', 'video']
                              ]
                            }}
                            value={getValues(`items.${index}.description`)}
                            onChange={(e: any) => {
                              setValue(`items.${index}.description`, e);
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group>
                          <Form.Label>Imagem</Form.Label>
                          <ImgUp
                            getValues={getValues}
                            setValue={setValue}
                            index={`items.${index}.image`}
                            imgWidth={191}
                            imgHeight={49}
                            width={241}
                            height={93}
                            containerWidth={241}
                            containerHeight={93}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                  </>
                );
              })}
            </>
          )}

          <br />
        </Form>
      </BlueComponent>

      <CreateUser
        group_name={groupName}
        isVisible={isCreateUser}
        onHide={() => {
          setCreateUser(false);
        }}
        arrayId={arrayId}
        aboutId={aboutId}
        setAbout={reset}
      />

      <EditUser
        group_name={groupName}
        isVisible={isEditUser}
        onHide={() => {
          setEditUser(false);
        }}
        arrayId={arrayId}
        aboutId={aboutId}
        user={userTem}
        idUser={indexUser}
        setAbout={reset}
      />

      <ModalSucesso
        isModal={isVisible}
        onHide={() => setVisible(false)}
        title="Informações editadas"
        description="As informações foram editadas com sucesso."
      />
    </Container>
  );
};

export default ModalSobre;
