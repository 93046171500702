import styled from 'styled-components';
import { close } from '../../../assets';

interface IButtonProps {
  color: string;
}

export const ModalContent = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 6px;
  background-color: ${props => props.theme.colors.white};
`;

export const ModalTitle = styled.h3`
  padding-bottom: 20px;
  text-align: start;
`;

export const Label = styled.label``;

export const ModalCloseButton = styled.button`
  height: 34px;
  width: 34px;

  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #ffffff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.0001);
  align-self: flex-end;
  margin-top: -20px;
  margin-right: -20px;
  border: none;
`;

export const ModalCloseIcon = styled.img.attrs({
  src: close
})`
  height: 20px;
  width: 20px;
`;

export const InputText = styled.input`
  background: ${props => (props.disabled ? '#DDDDDD' : '#ffffff')};
  border: 1px solid #d8d6de;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px;
  resize: none;
  outline: none;
  display: block;
  width: 100%;
  height: 38px;
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  flex-direction: row;
  text-align: center;
`;

export const Button = styled.button<IButtonProps>`
  margin-top: 40px;
  margin-left: 10px;
  background: ${props => props.color};
  border: none;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  padding: 10px 20px;
  font-size: 16px;
  :disabled {
    background-color: gray;
  }
`;
