import { Modal } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ActionType, FormData, Variable } from '../form-types';
import {
  Button,
  ContainerButton,
  InputText,
  ModalCloseButton,
  ModalCloseIcon,
  ModalContent,
  ModalTitle,
  Label
} from './styles';

export interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  label: string;
  onClickConfirm: (value: any) => void;
  editValue: FormData;
  actionType: ActionType;
  variable: Variable;
  variableType: string;
}

const ActionModal: React.FC<IProps> = props => {
  const { register, watch, setValue, getValues } = useForm<FormData>();

  useEffect(() => {
    if (props.actionType === ActionType.ADD) {
      if (hasTypeInVariable(props.variable)) {
        setValue('type', props.variableType);
        setValue('city', props.variableType);
      } else {
        setValue('type', '');
        setValue('city', '');
      }
      setValue('name', '');
      setValue('id', '');
    } else {
      setValue('name', props.editValue.name);
      setValue('id', props.editValue.id);
      setValue('type', props.editValue.type);
      setValue('city', props.editValue.city);
    }
  }, [props.open]);

  function hasTypeInVariable(variable: Variable) {
    return [
      Variable.CIRCUMSTANCE,
      Variable.POSITIONS,
      Variable.QUALIFICATIONS,
      Variable.NEIGHBORHOODS,
      Variable.SUBNEIGHBORHOODS,
      Variable.LOCALITIES
    ].includes(variable);
  }

  function disableButton() {
    if (props.variable === Variable.ANIMALS) {
      return !watch('type');
    } else {
      return !watch('name');
    }
  }

  function onClick() {
    if (
      props.actionType === ActionType.EDIT ||
      props.actionType === ActionType.DELETE
    ) {
      props.onClickConfirm({
        id: getValues('id'),
        name: getValues('name'),
        type: getValues('type'),
        city: getValues('city')
      });
    } else if (props.actionType === ActionType.ADD) {
      props.onClickConfirm({
        name: getValues('name'),
        type: getValues('type'),
        city: getValues('city')
      });
    }
  }

  return (
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      open={props.open}
      onClose={props.onClose}
    >
      <ModalContent>
        <ModalCloseButton onClick={props.onClose}>
          <ModalCloseIcon />
        </ModalCloseButton>
        <ModalTitle>{props.title}</ModalTitle>
        {props.actionType === ActionType.DELETE ? null : (
          <>
            <Label>{props.label}</Label>
            {props.variable === Variable.ANIMALS ? (
              <InputText {...register('type')}></InputText>
            ) : (
              <InputText {...register('name')}></InputText>
            )}
          </>
        )}

        <ContainerButton>
          <Button color="#EA5455" onClick={props.onClose}>
            Cancelar
          </Button>
          <Button color="#45BD5F" disabled={disableButton()} onClick={onClick}>
            Confirmar
          </Button>
        </ContainerButton>
      </ModalContent>
    </Modal>
  );
};

export default ActionModal;
