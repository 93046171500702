import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 260px auto;
  overflow: scroll;
  height: 100vh;

  grid-template-areas:
    'AS MH'
    'AS CT';
`;

export const Content = styled.div`
  grid-area: CT;
  background-color: #f8f8f8 !important;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 150px;
`;

const fadeInOut = keyframes`
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
`;

export const LoadingImageWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingImageStyled = styled.img`
  max-width: 30%;
  animation: ${fadeInOut} 1.5s linear infinite;
`;
