import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { SourceModel } from '../../domain/models/SourceModel';

export class SourceEntity extends BasicEntity {
  constructor(data?: Partial<SourceEntity>) {
    super(data);
  }

  toDomain(): SourceModel {
    return new SourceModel({
      id: this.id,
      name: this.name
    });
  }
}
