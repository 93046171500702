import React, { useEffect, useState } from 'react';
import { IProps } from './index.types';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { ImgUp } from '../../../../../components/index';
import { useForm } from 'react-hook-form';
import {
  getAbout,
  getAboutById,
  editAbout
} from '../../../../../services/index';
import { DefaultRootState, useDispatch, useSelector } from 'react-redux';
import { RootState, ADD_ABOUT } from '../../../../../store';

const CreateUser: React.FC<IProps> = ({
  isVisible,
  onHide,
  group_name,
  arrayId,
  aboutId,
  setAbout
}) => {
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      image: {
        id: '',
        file: ''
      },
      order: 0,
      group: '',
      role: '',
      name: '',
      description: ''
    }
  });

  useEffect(() => {
    if (group_name != undefined && group_name != null && group_name != '') {
      setValue('group', group_name);
    }
  }, [group_name]);

  const { token } = useSelector((state: RootState) => state.clickState);
  const dispatch = useDispatch();

  function onSubmit(dados: any) {
    console.log(dados, 'resp');

    getAboutById(token, aboutId).then((resp: any) => {
      resp?.items?.forEach((chave: any) => {
        if (chave.group_name == group_name) {
          chave?.person?.push(dados);
          chave = Object.assign(chave, { number: chave?.number + 1 });
        }
      });

      editAbout(token, aboutId, resp).then((resp: any) => {
        console.log(resp, 'criada nova pessoa!');
        onHide();

        getAbout(token).then(resp => {
          dispatch({ type: ADD_ABOUT, about: resp });

          getAboutById(token, aboutId).then((r: any) => {
            setAbout(r);
          });
        });
      });
    });
  }

  return (
    <>
      <Modal
        show={isVisible}
        onHide={onHide}
        size="xl"
        style={{ zIndex: '9999' }}
      >
        <Modal.Header>
          <h3 style={{ width: '100%', textAlign: 'center' }}>
            Adicionar pessoa
          </h3>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Grupo</Form.Label>
                  <Form.Control
                    type="text"
                    disabled={true}
                    {...register('group')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label style={{ display: 'none' }}>Ordem</Form.Label>
                  <Form.Control
                    type="hidden"
                    disabled={true}
                    {...register('order')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={2}>
                <ImgUp
                  index={`image`}
                  getValues={getValues}
                  setValue={setValue}
                  imgWidth={105}
                  imgHeight={105}
                  width={105}
                  height={105}
                  containerWidth={105}
                  containerHeight={105}
                />
              </Col>
              <Col xs={10}>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Nome:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o nome da pessoa"
                        {...register('name')}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Cargo:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Digite o cargo da pessoa"
                        {...register('role')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Descrição</Form.Label>
                      <Form.Control
                        as="textarea"
                        style={{ height: '190px' }}
                        placeholder="Digite uma descrição para a pessoa"
                        {...register('description')}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
            <br />
            <Row>
              <Col
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  type="reset"
                  style={{
                    border: 'none',
                    backgroundColor: '#EA5455',
                    color: '#fff',
                    width: '203px',
                    height: '46px',
                    marginRight: '10px'
                  }}
                  onClick={() => {
                    onHide();
                    reset({
                      group: group_name,
                      order: 0
                    });
                  }}
                >
                  Cancelar
                </Button>
                <input
                  type="submit"
                  style={{
                    border: 'none',
                    backgroundColor: '#E0733D',
                    color: '#fff',
                    width: '203px',
                    height: '46px'
                  }}
                  value="Salvar pessoa"
                />
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreateUser;
