import React, { useEffect, useState } from 'react';
import { Content } from './index.style';
import { Button, Col, Image, Modal, Row } from 'react-bootstrap';
import checkedImg from './checked.svg';
import { IProps } from './intex.types';
import uncheckedImg from './unchecked.svg';
import conclued from './conclued.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_REGIONS, RootState } from '../../../../store';
import { editRegions, getRegions, getRegionsById } from '../../../../services';
import { ModalEditTrue } from '../../../../components';

const ModalAlterar: React.FC<IProps> = ({ isModal, onHide, checked, id }) => {
  const { token, regions } = useSelector(
    (state: RootState) => state.clickState
  );
  const [isModalSuccess, setIsModalSuccess] = useState(false);
  const dispatch = useDispatch();

  function alterateData() {
    getRegionsById(token, id).then(resp => {
      let aux = resp;
      aux.enabled = aux.enabled ? false : true;

      let data = Object.assign(resp, aux);
      editRegions(token, id, data).then(resp => {
        getRegions(token).then(respR => {
          dispatch({ type: ADD_REGIONS, regions: respR });
          setIsModalSuccess(true);
        });
      });
    });
  }

  return (
    <>
      <Modal show={isModal} onHide={onHide} centered={true}>
        <Content>
          <Image src={checked ? uncheckedImg : checkedImg} />
          <h2> {checked ? 'Desabilitar' : 'Habilitar'} região </h2>
          <p>
            Você tem certeza que deseja {checked ? 'desabilitar' : 'habilitar'}
            esta região?
          </p>
          <div>
            <button onClick={() => onHide()}>Não, cancelar</button>
            <button
              onClick={() => {
                onHide();
                alterateData();
              }}
            >
              Sim, {checked ? 'Desabilitar' : 'Habilitar'}
            </button>
          </div>
        </Content>
      </Modal>

      <Modal
        show={isModalSuccess}
        onHide={() => setIsModalSuccess(false)}
        centered={true}
      >
        <ModalEditTrue
          close={() => setIsModalSuccess(false)}
          title={`Região ${checked ? 'desabilitada' : 'habilitada'}`}
          subTitle={`A região foi ${
            checked ? 'desabilitar' : 'habilitar'
          } com sucesso`}
        />
      </Modal>
    </>
  );
};

export default ModalAlterar;
