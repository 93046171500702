import { BasicModel } from '../../domain/models/BasicModel';

export class BasicEntity {
  id: string = '';
  name: string = '';

  constructor(data?: Partial<BasicEntity>) {
    Object.assign(this, data);
  }

  toDomain(): BasicModel {
    return new BasicModel({
      id: this.id,
      name: this.name
    });
  }
}
