import React, { useContext } from 'react';
import FormBody from '../../components/form_body';
import { OccurrenceContext } from '../../../../../providers/OccurrenceProvider';

import { Form, Row, Col } from 'react-bootstrap';
import {
  CustomForm,
  FormItem
} from '../../../../../../../shared/components/custom_form';
import AddItemOnFormButton from '../../../../../../../shared/components/add_item_on_form_button';
import { Divider } from '../../../../../../../shared/styles';
import { RefactorSelectWithSearch } from '../../../../../../../components/SelectWithSearch';
import FormItemCount from '../form_item_count';
import FormItemHeader from '../form_item_header';
import { Field } from 'formik';
import FormDateInput from '../../../../../../../shared/components/form_date_input';
import { CircumstanceType } from '../../../../../data/entities/CircumstanceEntity';

const CreateEditOcurrenceAnimalVictimsFormComponent: React.FC = () => {
  const { createEditOccurrenceUsecase, createEditOccurrenceState } =
    useContext(OccurrenceContext);

  const dataCy = (field: string, index?: number) => {
    let prefix = 'animalVictimForm';

    if (index !== null && index !== undefined) {
      return `${prefix}-${index}-${field}`;
    }
    return `${prefix}-${field}`;
  };

  return (
    <FormBody
      title="Vítimas animais"
      description="Complementação caso haja animais baleados (apenas administradores)."
    >
      <Row>
        <Col md={2}>
          <AddItemOnFormButton
            name="Incluir animal"
            dataCy={dataCy('addAnimalVictimButton')}
            onClick={() =>
              createEditOccurrenceUsecase.addNewAnimalVictimsForm()
            }
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Total de vítimas animais"
            value={createEditOccurrenceState.animalVictimsCount.total}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Animais mortos"
            value={createEditOccurrenceState.animalVictimsCount.dead}
          />
        </Col>
        <Col md={2}>
          <FormItemCount
            label="Animais feridos"
            value={createEditOccurrenceState.animalVictimsCount.wounded}
          />
        </Col>
      </Row>

      {createEditOccurrenceState.animalVictimsForm.map(
        (animalVictimForm, index) => (
          <div key={animalVictimForm.uniqueKey}>
            <Divider height={24} />

            <CustomForm
              validationSchema={animalVictimForm.validationSchema}
              initialValues={animalVictimForm}
              forwardedRef={animalVictimForm.ref}
              onSubmit={() => {}}
              handleFormChange={(field, value) =>
                createEditOccurrenceUsecase.handleAnimalVictimsFormChange(
                  index,
                  field,
                  value
                )
              }
              component={({
                values,
                handleChange,
                handleSubmit,
                handleBlur,
                isInvalid
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <FormItemHeader
                    title="ANIMAL"
                    index={index}
                    onDelete={() =>
                      createEditOccurrenceUsecase.removeAnimalVictimsForm(index)
                    }
                    children={
                      <>
                        <FormItem
                          name="situation"
                          label="Situação do animal*"
                          component={field => (
                            <>
                              <Form.Check inline>
                                <Field
                                  as={Form.Check.Input}
                                  type="radio"
                                  id={field.name}
                                  name={field.name}
                                  data-cy={
                                    dataCy(field.name, index) + '-wounded'
                                  }
                                  value="Wounded"
                                  onChange={(e: any) => {
                                    handleChange(field.name, e.target.value);

                                    handleChange('deathDate', '');
                                  }}
                                  isInvalid={isInvalid(field.name)}
                                />
                                <Form.Check.Label
                                  htmlFor={field.name}
                                  style={{ color: '#FF9F43' }}
                                >
                                  Ferido
                                </Form.Check.Label>
                              </Form.Check>

                              <Form.Check inline>
                                <Field
                                  as={Form.Check.Input}
                                  type="radio"
                                  id={field.name}
                                  name={field.name}
                                  data-cy={dataCy(field.name, index) + '-dead'}
                                  value="Dead"
                                  onChange={(e: any) =>
                                    handleChange(field.name, e.target.value)
                                  }
                                  isInvalid={isInvalid(field.name)}
                                />
                                <Form.Check.Label
                                  htmlFor={field.name}
                                  style={{ color: '#EA5455' }}
                                >
                                  Morto
                                </Form.Check.Label>
                              </Form.Check>
                            </>
                          )}
                        />
                      </>
                    }
                  />
                  <Row>
                    <Col md={4}>
                      <FormItem
                        name="animalTypeId"
                        label="Tipo de animal*"
                        failRequest={createEditOccurrenceState.animalsRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getAnimals()
                        }
                        component={field => (
                          <Form.Select
                            name={field.name}
                            data-cy={dataCy(field.name, index)}
                            value={values[field.name]}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                            onBlur={handleBlur}
                            isInvalid={isInvalid(field.name)}
                          >
                            {createEditOccurrenceState.animalsRequestStatus.maybeMap(
                              {
                                idle: () => <option>Carregando...</option>,
                                loading: () => <option>Carregando...</option>,
                                failed: () => (
                                  <option>
                                    Erro. Clique no botão acima para carregar as
                                    opções!
                                  </option>
                                ),
                                succeeded(data) {
                                  return (
                                    <>
                                      <option value="">Selecione</option>
                                      {data?.map(animal => (
                                        <option value={animal.id}>
                                          {animal.type}
                                        </option>
                                      ))}
                                    </>
                                  );
                                }
                              }
                            )}
                          </Form.Select>
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <FormItem
                        name="name"
                        label="Nome"
                        component={field => (
                          <Form.Control
                            {...field}
                            data-cy={dataCy(field.name, index)}
                            type="text"
                            placeholder="Digite o nome do animal..."
                            isInvalid={isInvalid(field.name)}
                            onChange={(e: any) =>
                              handleChange(field.name, e.target.value)
                            }
                          />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <FormItem
                        name="circumstances"
                        label="Circunstâncias"
                        failRequest={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                          {
                            failed: () => true
                          }
                        )}
                        onPressedRefresh={() =>
                          createEditOccurrenceUsecase.getCircumstances()
                        }
                        component={field => (
                          <RefactorSelectWithSearch
                            dataCy={dataCy(field.name, index)}
                            options={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                              {
                                idle: () => [],
                                loading: () => [],
                                failed: () => [],
                                succeeded(data) {
                                  return data
                                    ?.filter(
                                      circumstance =>
                                        circumstance.type !==
                                        CircumstanceType.HUMAN
                                    )
                                    .map(circumstance => ({
                                      label: circumstance.name,
                                      value: circumstance.id
                                    }));
                                }
                              }
                            )}
                            placeholder={createEditOccurrenceState.circumstancesRequestStatus.maybeMap(
                              {
                                idle: () => 'Selecione',
                                loading: () => 'Carregando...',
                                failed: () =>
                                  'Erro. Clique no botão acima para carregar as opções!',
                                succeeded: () => 'Selecione'
                              }
                            )}
                            onChange={options =>
                              handleChange(
                                field.name,
                                options.map((option: any) => option.value)
                              )
                            }
                            value={values[field.name]}
                            isDisabled={false}
                            isMulti={true}
                            isInvalid={isInvalid(field.name)}
                            fieldName="circunstâncias"
                          />
                        )}
                      />
                    </Col>
                  </Row>

                  <Divider height={24} />
                  <Col md={2}>
                    <FormItem
                      name="deathDate"
                      label="Data da morte"
                      component={field => (
                        <FormDateInput
                          field={field}
                          dataCy={dataCy(field.name, index)}
                          value={values[field.name]}
                          handleChange={handleChange}
                          isInvalid={isInvalid}
                          handleBlur={handleBlur}
                          disabled={
                            values['situation'].toLowerCase() !== 'dead'
                          }
                        />
                      )}
                    />
                  </Col>
                </Form>
              )}
            />
          </div>
        )
      )}
    </FormBody>
  );
};

export default CreateEditOcurrenceAnimalVictimsFormComponent;
