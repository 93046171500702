import { BasicEntity } from '../../../../shared/data/entities/BasicEntity';
import { CoorporationModel } from '../../domain/models/CoorporationModel';

export class CoorporationEntity extends BasicEntity {
  constructor(data?: Partial<CoorporationEntity>) {
    super(data);
  }

  toDomain(): CoorporationModel {
    return new CoorporationModel({
      id: this.id,
      name: this.name
    });
  }
}
